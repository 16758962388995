<template>
  <div class="grid">
    <div class="col-12 lg:col-10 xl:col-5 gap-3 flex-column flex">
      <!-- Card for customers booking -->
      <div v-if="userType != 'staff'" class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block font-medium mb-5 text-xl">Customer Bookings</span>
            <div class="flex" style="gap: 18px">
              <div class="flex-column">
                <span class="block text-500 font-medium mb-2">Pending</span>
                <i v-if="customerBookingData.loading" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                <div v-else class="text-900 font-medium text-xl">
                  {{ customerBookingData.pending_count }}
                </div>
              </div>
              <div class="flex-column">
                <span class="block text-500 font-medium mb-2">Ready to Notify</span>
                <i v-if="customerBookingData.loading" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                <div v-else class="text-900 font-medium text-xl">
                  {{ customerBookingData.ready_to_notify_count }}
                </div>
              </div>
              <div class="flex-column">
                <span class="block text-500 font-medium mb-2">Completed</span>
                <i v-if="customerBookingData.loading" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                <div v-else class="text-900 font-medium text-xl">
                  {{ customerBookingData.completed_count }}
                </div>
              </div>

              <div class="flex-column">
                <span class="block text-500 font-medium mb-2">Total</span>
                <i v-if="customerBookingData.loading" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                <div v-else class="text-900 font-medium text-xl">
                  {{ customerBookingData.total_count }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
            style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
          </div>
        </div>
      </div>

      <!-- Card for PreOrders -->
      <div v-if="userType != 'staff'" class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block font-medium mb-5 text-xl">Pre-Orders</span>
            <div class="flex" style="gap: 18px">
              <div class="flex-column">
                <span class="block text-500 font-medium mb-2">Pending Order</span>
                <i v-if="preOrderData.isLoading" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                <div v-else class="text-900 font-medium text-xl">
                  {{ preOrderData.pending_order_count }}
                </div>
              </div>
              <div class="flex-column">
                <span class="block text-500 font-medium mb-2">Pending Arrive</span>
                <i v-if="preOrderData.isLoading" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                <div v-else class="text-900 font-medium text-xl">
                  {{ preOrderData.pending_arrive_count }}
                </div>
              </div>
              <div class="flex-column">
                <span class="block text-500 font-medium mb-2">Ready to Notify</span>
                <i v-if="preOrderData.isLoading" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                <div v-else class="text-900 font-medium text-xl">
                  {{ preOrderData.ready_to_notify_count }}
                </div>
              </div>
              <div class="flex-column">
                <span class="block text-500 font-medium mb-2">Completed</span>
                <i v-if="preOrderData.isLoading" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                <div v-else class="text-900 font-medium text-xl">
                  {{ preOrderData.completed_count }}
                </div>
              </div>

              <div class="flex-column">
                <span class="block text-500 font-medium mb-2">Total</span>
                <i v-if="preOrderData.isLoading" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                <div v-else class="text-900 font-medium text-xl">
                  {{ preOrderData.total_count }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
            style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      userType: "staff",
      customerBookingData: {
        pending_count: null,
        ready_to_notify_count: null,
        completed_count: null,
        total_count: null,
        readyToNotifiyCount: null,
        loading: false,
      },
      preOrderData: {
        isLoading: false,
      },
      lineData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Revenue",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.4,
          },
          {
            label: "Sales",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.4,
          },
        ],
      },
      items: [
        { label: "Add New", icon: "pi pi-fw pi-plus" },
        { label: "Remove", icon: "pi pi-fw pi-minus" },
      ],
    };
  },
  productService: null,
  created() {
    this.userType = localStorage.getItem("user_type")
  },
  mounted() {
    if (this.userType != 'staff') {
      this.getCustomerBookingData();
      this.getPreOrderData();
    }

    if (this.userType == 'staff') {
      this.$router.push({ name: "customer-notification" });
    }
  },
  methods: {
    getCustomerBookingData: async function () {
      this.customerBookingData.loading = true;
      await axios({
        method: "GET",
        url: "customerbooking/statistic",
      }).then(
        (result) => {
          this.customerBookingData.pending_count = result.data.pending_count;
          this.customerBookingData.ready_to_notify_count = result.data.ready_to_notify_count;
          this.customerBookingData.completed_count = result.data.completed_count;
          this.customerBookingData.total_count = result.data.total_count;
        },
        (error) => {
          console.log(error.response.data);
        }
      );

      this.customerBookingData.loading = false;
    },

    getPreOrderData: async function () {
      this.preOrderData.isLoading = true;
      await axios({
        method: "GET",
        url: "pre-orders/statistics",
      }).then(
        (result) => {
          this.preOrderData.pending_order_count = result.data.pending_order_count;
          this.preOrderData.pending_arrive_count = result.data.pending_arrive_count;
          this.preOrderData.ready_to_notify_count = result.data.ready_to_notify_count;
          this.preOrderData.completed_count = result.data.completed_count;
          this.preOrderData.total_count = result.data.total_count;
        },
        (error) => {
          console.log(error.response.data);
        }
      );
      this.preOrderData.isLoading = false;

    }
  },
};
</script>
