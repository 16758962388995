<template>
  <div class="w-12 lg:w-3 mx-auto">
    <div class="card justify-content-center align-items-center py-6 px-5">
      <div class="mb-3 flex justify-content-center">
        <h4>Login</h4>
      </div>
      <div class="mb-3 flex justify-content-center">
        <IconField class=" w-full">
          <InputIcon class="pi pi-user" />
          <InputText type="text" placeholder="Username" name="username" v-model="username" class="w-full" />
        </IconField>
      </div>
      <div class="mb-3 flex justify-content-center">
        <IconField class=" w-full">
          <InputIcon class="pi pi-lock" />
          <InputText type="password" placeholder="Password" name="password" v-model="password" class="w-full"
            @keyup.enter="login" />
        </IconField>
      </div>

      <div class="flex align-items-center gap-2">
        <InputSwitch id="loginEcommerceSwitch" v-model="loginEcommerce" />
        <label for="loginEcommerceSwitch" class="cursor-pointer"
          @click="loginEcommerce = !loginEcommerce">E-Commerce</label>
      </div>

      <div class="mt-3 mb-3 flex justify-content-center">
        <Button label="Login" class="w-full mr-2 mb-2 mt-2 mx-auto" @click="login" :loading="loading"></Button>
      </div>
      <div id="error-message" class="p-error">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useItemsStore } from "../stores/items";


export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
      loading: false,
      loginEcommerce: false,
    };
  },
  methods: {
    login: function () {
      this.loading = true;
      axios({
        method: "POST",
        url: "user/login",
        data: {
          username: this.username,
          password: this.password,
          is_ecommerce: this.loginEcommerce,
        },
      }).then(
        (result) => {
          if (result) {
            this.loading = false;
            localStorage.setItem("access_token", result.data.token);

            if (this.loginEcommerce) {
              localStorage.setItem("access_token_ecommerce", result.data.token_ecommerce);
              localStorage.setItem("roles", JSON.stringify(result.data.roles));
            }
            else {
              localStorage.removeItem("access_token_ecommerce");
              localStorage.removeItem("roles");
            }

            localStorage.setItem("username", result.data.user.username);
            localStorage.setItem("user_id", result.data.user.id);
            localStorage.setItem("user_type", result.data.user.type);

            axios.defaults.headers.common = {
              'Authorization': `Bearer ${result.data.token}`,
              'Accept': 'application/json',
            };

            if (result.data.user.require_new_password) {
              this.$router.push({
                path: "/changepassword",
                query: {
                  redirect: this.$route.query.redirect,
                },
              });
            } else {
              const itemsStore = useItemsStore();
              itemsStore.getItemsFromBackend();
              if (this.$route.query.redirect) {
                this.$router.push({ path: this.$route.query.redirect });
              } else {
                this.$router.push({ path: "/" });
              }
            }
          }
        },
        (error) => {
          this.loading = false;
          this.errorMessage = error.response.data.message;
        }
      );
    },
  },
};
</script>