<template>
    <h2>Ecommerce - Categories</h2>

    <div class="flex gap-3">

        <div v-if="mode == 'add'" class="card w-3 h-max">
            <div class="mb-2">Name</div>
            <InputText class="w-12" type="text" v-model="inputData.name" />

            <div class="mb-2 mt-4">Slug</div>
            <InputText class="w-12" type="text" v-model="inputData.slug" />

            <div class="mb-2 mt-4">Parent</div>
            <Dropdown class="w-full" v-model="inputData.selectedParent" :options="categories" optionLabel="nameLabel"
                placeholder="Select a parent" />

            <Button class="mt-4" label="Add New" @click="addNew"></Button>
        </div>
        <div v-if="mode == 'edit'" class="card w-3 h-max">
            <div class="mb-2">Name</div>
            <InputText class="w-12" type="text" v-model="inputData.name" />

            <div class="mb-2 mt-4">Slug</div>
            <InputText class="w-12" type="text" v-model="inputData.slug" />

            <div class="mb-2 mt-4">Parent</div>
            <Dropdown class="w-full" v-model="inputData.selectedParent" :options="categories" optionLabel="nameLabel"
                placeholder="Select a parent" />

            <div class="mt-4 flex gap-3">
                <Button severity="secondary" label="Cancel" @click="cancelEdit"></Button>
                <Button label="Save" @click="saveChanges"></Button>
            </div>
        </div>
        <div class="card w-8">
            <DataTable :value="categories" size="small" responsiveLayout="scroll" scrollable
                scrollHeight="calc(100vh - 250px)">
                <Column field="nameLabel" header="Name"></Column>
                <Column field="slug" header="Slug"></Column>
                <Column field="parent.slug" header="Parent"></Column>
                <Column field="products_count" header="Products Count"></Column>
                <Column heasder="Actions">
                    <template #body="{ data }">
                        <div class="flex gap-1">
                            <Button @click="edit(data)" text label="Edit"></Button>
                            <Button v-if="data.products_count == 0" @click="deleteBrand(data)" text label="Delete"
                                severity="danger"></Button>
                            <Button @click="shiftOrder(data.slug, 'up')" icon="pi pi-arrow-up"
                                aria-label="Shift Order Up" outlined rounded :disabled="data.isFirst" />
                            <Button @click="shiftOrder(data.slug, 'down')" icon="pi pi-arrow-down"
                                aria-label="Shift Order Down" outlined rounded :disabled="data.isLast" />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
import router from '@/router';
// import moment from "moment";


export default {
    data() {
        return {
            isLoading: { categories: false },
            categories: [],
            mode: "add",
            inputData: {
                name: "",
                slug: "",
                selectedParent: null
            }
        }
    },
    watch: {
        'inputData.name': function (val) {
            this.inputData.slug = val
                .replace(/[^A-Za-z0-9-\s]/g, "")
                .replace(/\s+/g, "-")
                .toLowerCase();
        },
        'inputData.slug': function (val) {
            this.inputData.slug = val
                .replace(/[^A-Za-z0-9-\s]/g, "")
                .replace(/\s+/g, "-")
                .toLowerCase();
        },
    },
    mounted: function () {
        this.getCategories();
    },
    methods: {
        appendChildren: function (item, level) {
            let temp = [];
            let index = 0;
            item.children.forEach((child) => {
                // Check if item is first or last in the current level
                child.isLast = false;
                child.isFirst = false;

                if (index == 0) {
                    child.isFirst = true;
                }

                if (index == item.children.length - 1) {
                    child.isLast = true;
                }

                index += 1;

                child.level = level;
                child.nameLabel = " > ".repeat(level) + child.name;
                temp.push(child);
                if (child.children.length > 0) {
                    temp = temp.concat(this.appendChildren(child, level + 1));
                }
            });
            return temp;
        },
        getCategories: async function () {
            this.isLoading.categories = true;

            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/product-categories-admin', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                }
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200 && result.status != 201) {
                        console.log("Error not 200/201");
                        console.log(result);
                        return;
                    }


                    result.json().then((data) => {
                        let tempCategories = [];

                        // Flatten the tree
                        let index = 0;
                        data.product_categories_tree.forEach((item) => {
                            item.isLast = false;
                            item.isFirst = false;

                            if (index == 0) {
                                item.isFirst = true;
                            }

                            if (index == data.product_categories_tree.length - 1) {
                                item.isLast = true;
                            }

                            index += 1;

                            item.level = 0;
                            item.nameLabel = item.name;
                            tempCategories.push(item);
                            if (item.children.length > 0) {
                                tempCategories = tempCategories.concat(this.appendChildren(item, 1));
                            }
                        });

                        // let indexLevel0 = 0;
                        // Flatten the tree
                        // data.product_categories_tree.forEach((item) => {
                        //     // Check if item is first or last in the current level
                        //     item.isLast = false;
                        //     item.isFirst = false;

                        //     if (indexLevel0 == 0) {
                        //         item.isFirst = true;
                        //     }

                        //     if (indexLevel0 == data.product_categories_tree.length - 1) {
                        //         item.isLast = true;
                        //     }

                        //     indexLevel0 += 1;

                        //     item.level = 0;
                        //     item.nameLabel = item.name;
                        //     tempCategories.push(item);

                        //     if (item.children.length > 0) {
                        //         item.children.forEach((child) => {
                        //             child.level = 1;
                        //             child.parent = item;
                        //             child.nameLabel = " > " + child.name;
                        //             tempCategories.push(child);
                        //             if (child.children.length > 0) {
                        //                 child.children.forEach((child2) => {
                        //                     child2.level = 2;
                        //                     child2.parent = child;
                        //                     child2.nameLabel = " >  > " + child2.name;
                        //                     tempCategories.push(child2);
                        //                     if (child2.children.length > 0) {
                        //                         child2.children.forEach((child3) => {
                        //                             child3.level = 3;
                        //                             child3.parent = child2;
                        //                             child3.nameLabel = " >  >  > " + child3.name;
                        //                             tempCategories.push(child3);
                        //                         });
                        //                     }
                        //                 });
                        //             }
                        //         });
                        //     }
                        // });
                        this.categories = tempCategories;
                    });

                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
            this.isLoading.categories = false;
        },
        edit(category) {
            this.mode = "edit"

            // Shallow copy
            this.inputData = Object.assign({}, category);
            this.inputData.original_slug = category.slug
            this.inputData.selectedParent = category.parent
        },
        cancelEdit() {
            this.mode = "add"
            this.inputData = {
                name: "",
                slug: "",
                selectedParent: null
            }
        },
        async shiftOrder(itemSlug, direction) {
            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/product-categories-admin/' + itemSlug + '/order', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    direction: direction,
                    steps: 1,
                })
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200 && result.status != 201) {
                        console.log("Error - Not 200/201");
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "warn",
                                summary: "Failed",
                                detail: data.message,
                                life: 3000,
                            });
                            return;
                        });
                    }
                    else {
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Success",
                                detail: data.message,
                                life: 3000,
                            });
                        });

                        // Reload page
                        this.getCategories();
                        this.cancelEdit();
                    }
                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
        },
        async saveChanges() {
            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/product-categories-admin/' + this.inputData.original_slug, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    name: this.inputData.name,
                    slug: this.inputData.slug,
                    parent_slug: this.inputData.selectedParent ? this.inputData.selectedParent.slug : null
                })
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200 && result.status != 201) {
                        console.log("Error - Not 200/201");
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "warn",
                                summary: "Failed",
                                detail: data.message,
                                life: 3000,
                            });
                            return;
                        });
                    }
                    else {
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Success",
                                detail: data.message,
                                life: 3000,
                            });
                        });

                        // Reload page
                        this.getCategories();
                        this.cancelEdit();
                    }


                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
        },
        async deleteBrand(category) {
            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/product-categories-admin/' + category.slug, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                }
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200 && result.status != 201) {
                        console.log("Error - Not 200/201");
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "warn",
                                summary: "Failed",
                                detail: data.message,
                                life: 3000,
                            });
                            return;
                        });
                    }
                    else {
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Success",
                                detail: data.message,
                                life: 3000,
                            });
                        });

                        // Reload page
                        this.getCategories();
                        this.cancelEdit();
                    }


                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
        },
        async addNew() {
            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/product-categories-admin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    name: this.inputData.name,
                    slug: this.inputData.slug,
                    parent_slug: this.inputData.selectedParent ? this.inputData.selectedParent.slug : null
                })
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200 && result.status != 201) {
                        console.log("Error - Not 200/201");
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "warn",
                                summary: "Failed",
                                detail: data.message,
                                life: 3000,
                            });
                            return;
                        });
                    }
                    else {
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Success",
                                detail: data.message,
                                life: 3000,
                            });
                        });

                        // Reload page
                        this.getCategories();
                        this.cancelEdit();
                    }
                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );

        }
    },
};
</script>

<style scoped></style>
