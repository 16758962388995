<script setup>
import { defineProps } from 'vue'
import { useToast } from 'primevue/usetoast'

const toast = useToast()

const props = defineProps({
  rows: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const excludeKeys = {
  id: true,
  purchase_request_id: true,
  purchase_order_id: true,
  sort_order: true,
  created_at: true,
  updated_at: true,
  cartons: true,
}

const copy = () => {
  if (props.type == 'purchase_inventory_summary') {
    const keysToExclude = {
      avg_qty_sold_per_day: true,
      generated_avg_qty_sold_per_mth: true,
      generated_avg_sales_per_mth: true,
      median_days_between_sales: true,
      stock_end_date: true,
      last_sold_date: true,
      last_arrive_date: true,
      balsqty_before_arrival: true,
      qty_sold_year: true,
      qty_sold_half_year: true,
      days_in_stock: true,
      latest_remark: true,
      latest_remark_date: true,
      STOCKGROUP: true,
      BALSQTY: true,
      REFPRICE: true,
      qty_sold_hagenerated_last_sold_dayslf_year: true,
      generated_stock_end_days: true,
      generated_stock_end_days_including_po: true,
      generated_last_sold_days: true,
    }

    Object.keys(keysToExclude).forEach(key => {
      excludeKeys[key] = true;
    });
  }

  const rows = props.rows;
  let text = "";

  const keys = [];

  // Get header by iterating over all rows and getting the keys, then delete duplicates
  rows.forEach(row => {
    Object.keys(row).forEach(key => {
      if (excludeKeys[key]) {
        return;
      }

      if (!keys.includes(key)) {
        keys.push(key);
      }
    });
  });

  text = keys.join("\t") + "\n";

  // Add rows
  rows.forEach(row => {
    // Check if row qty is 0 or empty, if yes then skip
    if (props.type == 'reorder_stock' && (row.order_qty === 0 || row.order_qty === "")) {
      return;
    }

    keys.forEach(key => {

      if (excludeKeys[key]) {
        return;
      }

      // Check if key exists in row, if not add empty string
      if (!Object.keys(row).includes(key) || row[key] === null || row[key] === undefined) {
        row[key] = "";
      }

      // Replace tabs, newlines and carriage returns with spaces
      row[key] = row[key].toString().replace(/\t|\n|\r/g, " ");

      text += row[key] + "\t";
    });
    text = text.slice(0, -1); // Remove last tab
    text += "\n";
  });

  navigator.clipboard.writeText(text)
  toast.add({
    severity: 'success', summary: 'Copied', detail: "You can now paste to SQL",
    life: 1000,
  })
}
</script>

<template>
  <Button icon="pi pi-copy" @click="copy" class="h-min" label="Copy to Excel" :disabled="props.disabled"></Button>
</template>

<style></style>