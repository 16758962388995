<template>
    <h2>Ecommerce - Logs</h2>

    <div class="overflow-scroll bg-white p-3" style="max-height: calc(100vh - 300px)">

        <div v-for="log in logs" :key="log" class="mb-1">
            {{ log }}
        </div>
    </div>
</template>

<script>
import router from '@/router';
// import moment from "moment";
export default {
    data() {
        return {
            isLoading: { logs: false },
            logs: [],
        }
    },
    mounted: function () {
        this.getLogs();
    },
    methods: {
        getLogs: async function () {
            this.isLoading.logs = true;

            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/admin/logs', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                }
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200) {
                        console.log("Error");
                        console.log(result);
                        return;
                    }

                    result.json().then((data) => {
                        this.logs = data.data;
                    });
                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
            this.isLoading.logs = false;
        }
    },
};
</script>

<style scoped></style>
