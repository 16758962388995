<template>
    <div class="text-lg font-bold flex align-items-center gap-3 mt-6 mb-2">
        Options
        <div v-if="isEditing">
            <Button class="py-0 px-2" label="Save" @click="save()" text></Button>
            <Button class="py-0 px-2" label="Cancel" @click="cancel()" severity="danger" text></Button>
        </div>
        <div v-else>
            <Button class="py-0 px-2" label="Edit" @click="edit()" text></Button>
        </div>
    </div>

    <!-- Editing -->
    <div v-if="isEditing">
        <div v-for="option, index in editedOptions" :key="index" class="flex gap-3 mt-1">
            <div class="flex gap-3 py-2 px-3 border-solid border-1 border-round w-3">
                <InputText v-model="option.name" class="p-inputtext border-none" />
            </div>

            <div class="flex gap-3 py-2 px-3 border-solid border-1 border-round">
                <VueDraggableNext class="list-group flex flex-wrap gap-2 align-items-center"
                    :list="option.product_option_values_object" ghost-class="ghost" handle=".handle">
                    <div class="list-group-item bg-gray-100 rounded-md border-round text-center cursor-pointer relative px-3 py-2 flex align-items-center gap-3"
                        v-for="element in option.product_option_values_object" :key="element.id">
                        <InputText v-model="element.name" class="border-none" style="max-width: 150px;" />
                        <i class="pi pi-bars handle"></i>
                        <Button v-if="element.no_of_variants == 0" icon="pi pi-trash" severity="secondary" rounded text
                            @click="deleteProductOptionValue(option.product_option_values_object, element.id)" />
                    </div>
                    <Button icon="pi pi-plus" severity="secondary" rounded
                        @click="addProductOptionValue(option.product_option_values_object)" />

                </VueDraggableNext>
            </div>
        </div>
        <div class="flex gap-3 mt-3">
            <div class="flex gap-3 py-2 px-3 border-solid border-1 border-round w-3">
                <InputText v-model="newProductOption.name" class="p-inputtext border-none" />
            </div>
            <div class="flex gap-3 py-2 px-3 border-solid border-1 border-round w-8">
                <div
                    class="list-group-item bg-gray-100 rounded-md border-round text-center cursor-pointer relative px-3 py-2 flex align-items-center gap-3">
                    <InputText v-model="newProductOption.value" class="border-none" style="max-width: 150px;" />
                </div>
            </div>
        </div>
    </div>


    <!-- Not Editing -->
    <div v-else>
        <div v-for="option, index in props.product.product_options" :key="index" class="flex gap-3 mt-1">
            <div class="flex gap-3 py-2 px-3 border-solid border-1 border-round">
                {{ option.name }}
            </div>

            <div class="flex gap-3 py-2 px-3 border-solid border-1 border-round">
                <div v-for="value_object, index in option.product_option_values_object" :key="index">
                    {{ value_object.name }}
                </div>
            </div>
        </div>
    </div>

</template>



<script setup>
import { VueDraggableNext } from 'vue-draggable-next'
import { ref, onMounted, defineModel, defineProps, defineEmits, defineExpose } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, maxValue } from '@vuelidate/validators'
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
const router = useRouter();
const route = useRoute();
const toast = useToast();

// const isEditing = defineModel('isEditing', { required: true })

const isEditing = ref(false);
const editedOptions = ref(null);

const props = defineProps({
    product: {
        type: Object,
        required: true
    }
})

const cancel = () => {
    isEditing.value = false;
    editedOptions.value = null;
}

const edit = () => {
    emit('edit')
    isEditing.value = true;
    editedOptions.value = JSON.parse(JSON.stringify(props.product.product_options));

    newProductOption.value.name = null;
    newProductOption.value.value = null;
}

// ------- NEW PRODUCT OPTION START ------- //
const newProductOption = ref({
    name: null,
    value: null,
})

const addNewProductOption = () => {
}

// ------- NEW PRODUCT OPTION END ------- //

const emit = defineEmits(['edit', 'saved'])

const addProductOptionValue = (product_option_values_object) => {
    // Get the smallest id
    let smallestId = 0;
    product_option_values_object.forEach(element => {
        if (element.id < smallestId) {
            smallestId = element.id;
        }
    });

    if (smallestId >= 0) {
        smallestId = -1;
    }
    else {
        smallestId--;
    }

    product_option_values_object.push({
        id: smallestId,
        name: null,
    });
}

const deleteProductOptionValue = (array, id) => {
    array.splice(array.findIndex(element => element.id == id), 1);
}

const save = async () => {

    const data = {
        'product_options': editedOptions.value
    }

    if (newProductOption.value.name != null && newProductOption.value.name != "" && newProductOption.value.value != null && newProductOption.value.value != "") {
        data['new_product_option_name'] = newProductOption.value.name;
        data['new_product_option_value'] = newProductOption.value.value;
    }

    await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/products-admin/' + route.params.slug, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
            'Accept': 'application/json',
        },
        body: JSON.stringify(data)
    }).then(
        (result) => {
            if (result.status == 401) {
                router.push('/login');
                return;
            }

            if (result.status != 200) {
                console.log("Error - Not 200");

                result.json().then((data) => {
                    toast.add({
                        severity: "warn",
                        summary: "Failed. ( product_options )",
                        detail: data.message,
                        life: 3000,
                    });
                });
                return;
            }
            else {
                result.json().then((data) => {
                    emit('saved');

                    isEditing.value = false;
                    editedOptions.value = null;

                    toast.add({
                        severity: "success",
                        summary: "Success. ( product_options )",
                        detail: data.message,
                        life: 3000,
                    });

                    return;
                });
            }


        }
    ).catch(
        (error) => {
            console.log("Error");
            console.log(error);
        }
    );

    emit('saved');
}

</script>


<style scoped></style>