export function copyToClipboard(textToCopy, toast = null) {
    navigator.clipboard.writeText(textToCopy);
    if (toast != null) {
        toast.add({
            severity: "success",
            summary: "Copied to clipboard",
            detail: textToCopy,
            life: 3000,
        });
    }
}