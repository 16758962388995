<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { VueWinBox } from 'vue-winbox'
import { useItemsStore } from '../../stores/items';
import { copyToClipboard } from '../../utils/copyToClipboard';
import WindowItemSearchEventBus from '@/components/WindowItemSearch/WindowItemSearchEventBus';
import JsBarcode from 'jsbarcode';

const route = useRoute();

const itemsStore = useItemsStore();

const searchResult = ref([]);
const isLoading = ref(false);

const isShown = ref(false);

const formRef = ref();
const wbRef = ref();
const inputTextSearchRef = ref();
const windowRef = ref();

const inputSearch = ref('');
const heightOfItems = ref('0px');

const selectedItems = ref([]);

//WinBox options
const options = {
    title: 'Item Search',
    class: 'modern',
    onclose: () => {
        wbRef.value?.winbox?.hide();
        return true;
    },
    onresize: () => {
        updateItemsScrollHeight();
    },
    hidden: true,
    x: '200px',
    y: '450px',
    width: '1000px',
    index: 5,
    background: '#6366F1',
}

const searchItem = async () => {
    if (inputSearch.value == '') {
        return;
    }

    isLoading.value = true;
    searchResult.value = await itemsStore.searchItemFromBackend(inputSearch.value);
    isLoading.value = false;

    WindowItemSearchEventBus.emit('search', inputSearch.value);
}

const updateItemsScrollHeight = () => {
    const height = wbRef.value?.winbox?.height ?? 0;
    if (height == 0) {
        return;
    }

    const formHeight = formRef.value?.clientHeight + 35 ?? 35;
    heightOfItems.value = (height - formHeight) + 'px';
}

const keydownHandler = (e) => {
    if (e.key === 'F9') {
        if (route.name == "login" || route.name == "changepassword") {
            return;
        }

        if (isShown.value) {
            wbRef.value?.winbox?.hide();
            isShown.value = false;
        } else {
            wbRef.value?.winbox?.show();
            isShown.value = true;

            updateItemsScrollHeight();

            // Focus on input text
            inputTextSearchRef.value.$el.focus();
            inputTextSearchRef.value.$el.select();
        }
    }


    // If tab is clicked then focus on input text
    if (e.key === 'Tab') {
        // Check if current focus is in this window, if yes then tab to input text
        if (windowRef.value.contains(document.activeElement)) {
            e.preventDefault();
            inputTextSearchRef.value.$el.focus();
            inputTextSearchRef.value.$el.select();
        }
    }
}

// Copy barcode image to clipboard
const copyBarcodeImageToClipboard = (code, $toast) => {
    var canvas = document.createElement("canvas");
    JsBarcode(canvas, code,
        { format: "CODE128", width: 25, height: 470, fontSize: 180, textMargin: -10 }
    );

    canvas.toBlob(function (blob) {
        const item = new ClipboardItem({ "image/png": blob });
        navigator.clipboard.write([item]);
    });

    $toast.add({ severity: 'success', summary: 'Success', detail: 'Barcode image (' + code + ') copied to clipboard' });
}

// onmounted
onMounted(() => {
    updateItemsScrollHeight();

    // On click F9, toggle the window wbRef
    window.addEventListener('keydown', keydownHandler)
})

onUnmounted(() => {
    window.removeEventListener('keydown', keydownHandler)
})
</script>

<template>
    <VueWinBox ref="wbRef" :options="options">
        <div ref="windowRef" class="flex flex-column">
            <form ref="formRef" @submit.prevent="searchItem">
                <div class="flex p-2 gap-2 align-items-center">
                    <div class="font-semibold text-lg ">Search Item:</div>
                    <InputText ref="inputTextSearchRef" class="flex-grow-1" type="text" v-model="inputSearch"
                        autofocus />
                    <Button type="submit" class="" label="Search"></Button>
                </div>
                <div class="flex p-2 gap-2 align-items-center"
                    v-if="route.name == 'purchase-requests-single' || route.name == 'purchase-orders-single'">
                    <Button @click="WindowItemSearchEventBus.emit('add', selectedItems)"
                        :disabled="selectedItems.length <= 0" icon="pi pi-plus" size="small" aria-label="Add"></Button>
                    Selected: {{ selectedItems.length }} item(s)
                </div>
            </form>
            <div :style="{ 'height': heightOfItems }">
                <DataTable :value="searchResult" v-model:selection="selectedItems" :metaKeySelection="true"
                    selectionMode="multiple" responsiveLayout="scroll" :loading="isLoading" size="small" scrollable
                    scrollHeight="flex">
                    <Column header="Actions">
                        <template #body="{ data }">
                            <div class="flex gap-1">

                                <Button
                                    v-if="route.name == 'purchase-requests-single' || route.name == 'purchase-orders-single'"
                                    @click="WindowItemSearchEventBus.emit('add', [data])" icon="pi pi-plus" size="small"
                                    aria-label="Add"></Button>

                                <Button @click="copyToClipboard(data.CODE + ' - ' + data.DESCRIPTION, $toast)"
                                    size="small" icon="pi pi-copy" aria-label="Copy"></Button>
                            </div>
                        </template>
                    </Column>
                    <Column field="CODE" header="Code">
                        <template #body="{ data }">
                            <div class="flex gap-2 align-items-center">
                                <a :href="'/items/' + data.CODE" target="_blank">{{ data.CODE }}</a>
                                <i class="pi pi-copy cursor-pointer" @click="copyToClipboard(data.CODE, $toast)"></i>
                                <i class="pi pi-images cursor-pointer"
                                    @click="copyBarcodeImageToClipboard(data.CODE, $toast)"></i>
                            </div>
                        </template>
                    </Column>
                    <Column header="Description">
                        <template #body="{ data }">
                            {{ data.DESCRIPTION }}
                            <i class="pi pi-copy cursor-pointer" @click="copyToClipboard(data.DESCRIPTION, $toast)"></i>
                        </template>
                    </Column>

                    <Column field="BALSQTY" header="Qty"></Column>
                    <Column field="REFPRICE" header="Price"> </Column>
                </DataTable>
            </div>
        </div>
    </VueWinBox>
</template>

<style>
.wb-full {
    display: none;
}
</style>