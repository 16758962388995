<template>
    <div>
        <div class="text-4xl font-medium">Tools - Stock Issue</div>

        <!-- Date Range -->
        <div class="flex gap-6 mt-6">
            <div class="flex flex-column gap-2">
                <div class="font-medium text-2xl">Date From</div>
                <Calendar v-model="dateFrom" dateFormat="yy-mm-dd" />
            </div>
            <div class="flex flex-column gap-2">
                <div class="font-medium text-2xl">Date To</div>
                <Calendar v-model="dateTo" dateFormat="yy-mm-dd" />
            </div>
        </div>

        <!-- Select Items by -->
        <div class="flex align-items-center mt-6">
            <div class="text-lg font-medium mr-4">Select Items By:</div>
            <Dropdown v-model="inputs.selectedItemSelectionMethod" :options="itemSelectionMethods"
                placeholder="Select a method" @change="resetItemSelectionMethod" />

            <IconField v-if="inputs.selectedItemSelectionMethod &&
                inputs.selectedItemSelectionMethod == 'Item Search'
            " class="">
                <InputIcon class="pi pi-search" />
                <InputText v-model="inputs.itemSearchQuery" placeholder="Search item" />
            </IconField>
        </div>

        <!-- Stock Group -->
        <SelectStockGroup v-if="inputs.selectedItemSelectionMethod &&
            inputs.selectedItemSelectionMethod == 'Stock Group'"
            v-model:selectedStockGroups="inputs.selectedStockGroups" :preselectedStockGroups="preselectedStockGroups"
            class="mt-4" />

        <div class="flex flex-column gap-1 mt-5">
            <div class="font-medium text-2xl">Show Shop Use</div>
            <InputSwitch v-model="showShopUse" />
        </div>

        <Button label="Find Stock Issue" class="p-button-raised mt-4" @click="findStockIssue" :loading="isLoading"
            :disabled="inputs.selectedStockGroups.length == 0 && inputs.itemSearchQuery == ''" />
    </div>

    <!-- Data Table -->
    <DataTable ref="dt" :value="lines" responsiveLayout="scroll" class="mt-8 p-datatable-sm" :loading="isLoading"
        sortMode="multiple" scrollable v-model:selection="selectedLines" dataKey="DTLKEY" scrollHeight="650px"
        size="small" var="line" :rowClass="rowClass">

        <template #header>
            <div class="flex gap-3">
                <ButtonCopyToExcel :rows="lines" :disabled="lines.length <= 0" type="stock_issue_lines">
                </ButtonCopyToExcel>
                <Button label="Batch Update Claimed/Settled " class="p-button-raised"
                    @click="showDialogViewBatchUpdateClaimSettled" :loading="isLoading"
                    :disabled="selectedLines.length == 0" />
            </div>
        </template>
        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="DOCNO" header="IS No" :sortable="true" headerStyle="width:9rem"> </Column>
        <Column field="DOCDATE" header="IS Date" :sortable="true" headerStyle="width:7rem"> </Column>
        <Column field="DESCRIPTION" header="IS Description" :sortable="true" headerStyle="width:10rem"> </Column>
        <Column field="REASON" header="IS Reason" :sortable="true"> </Column>
        <Column field="REMARK" header="IS Remark" :sortable="true"> </Column>
        <Column field="ITEMCODE" header="Item Code" :sortable="true" headerStyle="width:8rem"> </Column>
        <Column field="ITEM_DESCRIPTION" header="Item Description" :sortable="true" headerStyle="width:32rem"> </Column>
        <Column field="SQTY" header="Qty" :sortable="true"> </Column>

        <Column field="claim_date" header="Claimed" :sortable="true" headerStyle="width:9.5rem">
            <template #body="{ data }">
                <div v-if="data.claim_date" class="flex align-items-center gap-2 justify-content-between">
                    {{ data.claim_date }}
                    <Button style="width:25px; height: 25px; padding:2px;" icon="pi pi-pencil" severity="secondary"
                        size="small" @click="showDialogUpdateSingleClaim(data)" />
                </div>
                <div v-else>
                    <Button icon="pi pi-plus" severity="primary" size="small"
                        @click="showDialogUpdateSingleClaim(data)" />
                </div>
            </template>
        </Column>

        <Column field="claim_remark" header="Claimed Remark" :sortable="true" headerStyle="width:19rem"> </Column>
        <Column field="settled_date" header="Settled" :sortable="true" headerStyle="width:9.5rem">
            <template #body="{ data }">
                <div v-if="data.settled_date" class="flex align-items-center gap-2 justify-content-between">
                    {{ data.settled_date }}
                    <Button style="width:25px; height: 25px; padding:2px;" icon="pi pi-pencil" severity="secondary"
                        size="small" @click="showDialogUpdateSingleSettled(data)" />
                </div>
                <div v-else>
                    <Button icon="pi pi-plus" severity="primary" size="small"
                        @click="showDialogUpdateSingleSettled(data)" />
                </div>
            </template>
        </Column>

        <Column field="settled_remark" header="Settled Remark" :sortable="true" headerStyle="width:19rem"> </Column>
    </DataTable>

    <!-- Show Batch Update Claim/Settled Dialog -->
    <Dialog v-model:visible="dialogViewBatchUpdateClaimSettled.show" modal header="Batch Update Claimed/Settled"
        :style="{ width: '50rem' }">

        <div class="text-xl font-medium">You are currently updating {{ selectedLines.length }} lines.</div>
        <div class="text-lg font-medium">*Existing data (if any) will be overwritten if you save!</div>

        <div class="mt-6 text-lg font-medium">Update Claimed</div>
        <InputSwitch v-model="dialogViewBatchUpdateClaimSettled.update_claimed" />

        <div v-if="dialogViewBatchUpdateClaimSettled.update_claimed">
            <div class="mt-2 text-lg font-medium">Claimed</div>
            <InputSwitch v-model="dialogViewBatchUpdateClaimSettled.isClaimed" />

            <div v-if="dialogViewBatchUpdateClaimSettled.isClaimed">
                <div class="mt-2 text-lg font-medium">Claim Date</div>
                <Calendar v-model="dialogViewBatchUpdateClaimSettled.claim_date" :showButtonBar="true"
                    dateFormat="yy-mm-dd" />

                <div class="mt-2 text-lg font-medium">Claim Remark</div>
                <Textarea v-model="dialogViewBatchUpdateClaimSettled.claim_remark" rows="5" />
            </div>

            <div v-if="existingDataCountClaimed() > 0" class="text-lg font-medium text-red-600">Found {{
                existingDataCountClaimed() }} lines with existing date, saving will overwrite them!</div>

        </div>

        <div class="mt-6 text-lg font-medium">Update Settled</div>
        <InputSwitch v-model="dialogViewBatchUpdateClaimSettled.update_settled" />
        <div v-if="dialogViewBatchUpdateClaimSettled.update_settled">
            <div class="mt-2 text-lg font-medium">Settled</div>
            <InputSwitch v-model="dialogViewBatchUpdateClaimSettled.isSettled" />

            <div v-if="dialogViewBatchUpdateClaimSettled.isSettled">

                <div class="mt-2 text-lg font-medium">Settled Date</div>
                <Calendar v-model="dialogViewBatchUpdateClaimSettled.settled_date" :showButtonBar="true"
                    dateFormat="yy-mm-dd" />

                <div class="mt-2 text-lg font-medium">Settled Remark</div>
                <Textarea v-model="dialogViewBatchUpdateClaimSettled.settled_remark" rows="5" />
            </div>

            <div v-if="existingDataCountSettled() > 0" class="text-lg font-medium text-red-600">Found {{
                existingDataCountSettled() }} lines with existing date, saving will overwrite them!</div>

        </div>

        <div class="flex gap-3 w-100 justify-content-end">
            <Button label="Cancel" class="p-button-raised" severity="secondary"
                @click="dialogViewBatchUpdateClaimSettled.show = false"
                :loading="dialogViewBatchUpdateClaimSettled.isLoading" />
            <Button label="Save" class="p-button-raised" @click="saveDialogViewBatchUpdateClaimSettled"
                :loading="dialogViewBatchUpdateClaimSettled.isLoading" />
        </div>
    </Dialog>

    <!-- Show Single Update Claim Dialog -->
    <Dialog v-model:visible="dialogUpdateSingleClaim.show" modal header="Update Claimed" :style="{ width: '50rem' }">

        <div class="text-xl font-medium">You are currently updating {{ dialogUpdateSingleClaim.line.DOCNO }}.</div>
        <div class="text-lg font-medium">*Existing data (if any) will be overwritten if you save!</div>

        <div class="mt-2 text-lg font-medium">Claimed</div>
        <InputSwitch v-model="dialogUpdateSingleClaim.isClaimed" />

        <div v-if="dialogUpdateSingleClaim.isClaimed">
            <div class="mt-2 text-lg font-medium">Claim Date</div>
            {{ dialogUpdateSingleClaim.claim_date }}
            <Calendar v-model="dialogUpdateSingleClaim.claim_date" :showButtonBar="true" dateFormat="yy-mm-dd" />

            <div class="mt-2 text-lg font-medium">Claim Remark</div>
            <Textarea v-model="dialogUpdateSingleClaim.claim_remark" rows="5" />
        </div>

        <div class="flex gap-3 w-100 justify-content-end">
            <Button label="Cancel" class="p-button-raised" severity="secondary"
                @click="dialogUpdateSingleClaim.show = false" :loading="dialogUpdateSingleClaim.isLoading" />
            <Button label="Save" class="p-button-raised" @click="saveDialogUpdateSingleClaim"
                :loading="dialogUpdateSingleClaim.isLoading" />
        </div>
    </Dialog>


    <!-- Show Single Update Settled Dialog -->
    <Dialog v-model:visible="dialogUpdateSingleSettled.show" modal header="Update Settled" :style="{ width: '50rem' }">

        <div class="text-xl font-medium">You are currently updating {{ dialogUpdateSingleSettled.line.DOCNO }}.</div>
        <div class="text-lg font-medium">*Existing data (if any) will be overwritten if you save!</div>

        <div class="mt-2 text-lg font-medium">Settled</div>
        <InputSwitch v-model="dialogUpdateSingleSettled.isSettled" />

        <div v-if="dialogUpdateSingleSettled.isSettled">
            <div class="mt-2 text-lg font-medium">Settled Date</div>
            <Calendar v-model="dialogUpdateSingleSettled.settled_date" :showButtonBar="true" dateFormat="yy-mm-dd" />

            <div class="mt-2 text-lg font-medium">Settled Remark</div>
            <Textarea v-model="dialogUpdateSingleSettled.settled_remark" rows="5" />
        </div>


        <div class="flex gap-3 w-100 justify-content-end">
            <Button label="Cancel" class="p-button-raised" severity="secondary"
                @click="dialogUpdateSingleSettled.show = false" :loading="dialogUpdateSingleSettled.isLoading" />
            <Button label="Save" class="p-button-raised" @click="saveDialogUpdateSingleSettled"
                :loading="dialogUpdateSingleSettled.isLoading" />
        </div>
    </Dialog>
</template>

<script setup>

// ------------- Dialog Update Single Claim/Settled -----------
const dialogUpdateSingleClaim = ref({
    show: false,
    isLoading: false,
    isClaimed: true,
    claim_date: new Date(),
    claim_remark: null,
    line: null,
})

const showDialogUpdateSingleClaim = (line) => {
    dialogUpdateSingleClaim.value.show = true;
    dialogUpdateSingleClaim.value.line = line;

    if (line.claim_date == null) {
        dialogUpdateSingleClaim.value.isClaimed = false;
        dialogUpdateSingleClaim.value.claim_date = new Date();
        dialogUpdateSingleClaim.value.claim_remark = null;
    } else {
        dialogUpdateSingleClaim.value.claim_date = line.claim_date;
        dialogUpdateSingleClaim.value.claim_remark = line.claim_remark;
    }
}


const saveDialogUpdateSingleClaim = async () => {
    dialogUpdateSingleClaim.value.isLoading = true;
    const line = dialogUpdateSingleClaim.value.line;

    const url = 'stock-issues/update-extended-fields';

    const data = {
        lines: [{
            DTLKEY: line.DTLKEY,
        }],

        update_claimed: true,
        claim_date: dialogUpdateSingleClaim.value.claim_date,
        claim_remark: dialogUpdateSingleClaim.value.claim_remark,

        update_settled: false,
    };

    if (dialogUpdateSingleClaim.value.isClaimed == false) {
        data.claim_date = null;
        data.claim_remark = null;
    }

    data.claim_date = convertPrimeVueCalendar(data.claim_date);

    await axios({
        method: "post",
        url: url,
        data: data,
    }).then(
        () => {
            dialogUpdateSingleClaim.value.show = false;
            findStockIssue();
            selectedLines.value = [];
        },
        (error) => {
            console.log(error.response.data);
        }
    );

    dialogUpdateSingleClaim.value.isLoading = false;
}

const dialogUpdateSingleSettled = ref({
    show: false,
    isLoading: false,
    isSettled: true,
    settled_date: new Date(),
    settled_remark: null,
    line: null,
})


const showDialogUpdateSingleSettled = (line) => {
    dialogUpdateSingleSettled.value.show = true;
    dialogUpdateSingleSettled.value.line = line;

    if (line.settled_date == null) {
        dialogUpdateSingleSettled.value.isSettled = false;
        dialogUpdateSingleSettled.value.settled_date = new Date();
        dialogUpdateSingleSettled.value.settled_remark = null;
    } else {
        dialogUpdateSingleSettled.value.settled_date = line.settled_date;
        dialogUpdateSingleSettled.value.settled_remark = line.settled_remark;
    }
}

const saveDialogUpdateSingleSettled = async () => {
    dialogUpdateSingleSettled.value.isLoading = true;
    const line = dialogUpdateSingleSettled.value.line;

    const url = 'stock-issues/update-extended-fields';

    const data = {
        lines: [{
            DTLKEY: line.DTLKEY,
        }],

        update_claimed: false,
        update_settled: true,
        settled_date: dialogUpdateSingleSettled.value.settled_date,
        settled_remark: dialogUpdateSingleSettled.value.settled_remark,
    };

    if (dialogUpdateSingleSettled.value.isSettled == false) {
        data.settled_date = null;
        data.settled_remark = null;
    }
    data.settled_date = convertPrimeVueCalendar(data.settled_date);

    await axios({
        method: "post",
        url: url,
        data: data,
    }).then(
        () => {
            dialogUpdateSingleSettled.value.show = false;
            findStockIssue();
            selectedLines.value = [];
        },
        (error) => {
            console.log(error.response.data);
        }
    );

    dialogUpdateSingleSettled.value.isLoading = false;
}
//  -------------------------------------------------------


import { ref } from 'vue'
import SelectStockGroup from '../../components/SelectStockGroup.vue';
import ButtonCopyToExcel from '../../components/ButtonCopyToExcel.vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import { convertPrimeVueCalendar } from '../../utils/convertPrimeVueCalendar';

const inputs = ref({
    selectedItemSelectionMethod: "Item Search",
    itemSearchQuery: '',
    selectedStockGroups: [],
});

const itemSelectionMethods = ['Stock Group', 'Item Search'];

const resetItemSelectionMethod = () => {
    inputs.value.itemSearchQuery = '';
    inputs.value.selectedStockGroups = [];
}

const rowClass = (line) => {
    const isSelected = selectedLines.value.includes(line);
    if (isSelected) {
        return [{ 'bg-blue-200': true }];
    }
};

const existingDataCountClaimed = () => {
    return selectedLines.value.filter(line => line.claim_date).length;
}

const existingDataCountSettled = () => {
    return selectedLines.value.filter(line => line.settled_date).length;
}

const router = useRouter();
const route = useRoute();

const query = {};

// Initialize Pre Selected - START
const preselectedStockGroups = ref();

if (route.query.stock_groups) {
    preselectedStockGroups.value = route.query.stock_groups;
    inputs.value.selectedItemSelectionMethod = 'Stock Group';
}

if (route.query.item_search_query) {
    inputs.value.itemSearchQuery = route.query.item_search_query;
}
// Initialize Pre Selected - END

const isLoading = ref(false);

// Default yesterdays's date
const dateFrom = ref(new Date(new Date().setDate(new Date().getDate() - 1)));
const dateTo = ref(new Date(new Date().setDate(new Date().getDate() - 1)));

const showShopUse = ref(false);

const selectedLines = ref([]);

const lines = ref([]);

const updateInputsToQuery = () => {
    query.value = {}

    if (inputs.value.selectedItemSelectionMethod == "Stock Group" && inputs.value.selectedStockGroups && inputs.value.selectedStockGroups.length > 0) {
        query.value.stock_groups = inputs.value.selectedStockGroups.map((stockGroup) => stockGroup.STOCKGROUP)
    }

    if (inputs.value.selectedItemSelectionMethod == "Item Search" && inputs.value.itemSearchQuery) {
        query.value.item_search_query = inputs.value.itemSearchQuery
    }

    // Push date to query
    query.value.date_from = convertPrimeVueCalendar(dateFrom.value);
    query.value.date_to = convertPrimeVueCalendar(dateTo.value);

    router.push({ path: route.fullPath, query: { ...query.value } });
}

const findStockIssue = async () => {
    updateInputsToQuery();

    isLoading.value = true;
    const params = new URLSearchParams();

    if (query.value.stock_groups && query.value.stock_groups.length > 0) {
        params.append("stock_groups", query.value.stock_groups);
    }

    if (query.value.item_search_query && query.value.item_search_query != '') {
        params.append("item_search_query", query.value.item_search_query);
    }

    params.append("date_from", query.value.date_from);
    params.append("date_to", query.value.date_to);
    params.append("export", true);

    await axios({
        method: "GET",
        url: "stock-issues",
        params: params,
    }).then(
        (result) => {
            lines.value = result.data.lines;

            // Convert qty to number
            lines.value.forEach(line => {
                line.SQTY = Number(line.SQTY);
            });

            if (showShopUse.value == false) {
                // check if is description has 'shop use', case insensitive, then remove it
                lines.value = lines.value.filter(line => !line.DESCRIPTION.toLowerCase().includes('shop use'));
            }
        },
        (error) => {
            console.log(error.response.data);
        }
    );
    isLoading.value = false;
}

// Get query from URL then update dateFrom and dateTo
if (route.query.date_from) {
    dateFrom.value = new Date(route.query.date_from);
}

if (route.query.date_to) {
    dateTo.value = new Date(route.query.date_to);
}

// --------- Dialog Batch Update Claim/Settled -------
const dialogViewBatchUpdateClaimSettled = ref({
    show: false,
    isLoading: false,
    update_claimed: false,
    update_settled: false,
    isClaimed: true,
    isSettled: true,
    claim_date: new Date(),
    claim_remark: null,
    settled_date: new Date(),
    settled_remark: null
})

const showDialogViewBatchUpdateClaimSettled = () => {
    dialogViewBatchUpdateClaimSettled.value.show = true;

    dialogViewBatchUpdateClaimSettled.value.update_claimed = false;
    dialogViewBatchUpdateClaimSettled.value.update_settled = false;
    dialogViewBatchUpdateClaimSettled.value.isClaimed = true;
    dialogViewBatchUpdateClaimSettled.value.isSettled = true;
    dialogViewBatchUpdateClaimSettled.value.claim_date = new Date();
    dialogViewBatchUpdateClaimSettled.value.claim_remark = null;
    dialogViewBatchUpdateClaimSettled.value.settled_date = new Date();
    dialogViewBatchUpdateClaimSettled.value.settled_remark = null;
}

const saveDialogViewBatchUpdateClaimSettled = async () => {
    dialogViewBatchUpdateClaimSettled.value.isLoading = true;

    const url = 'stock-issues/update-extended-fields';

    const data = {
        lines: selectedLines.value.map(line => {
            return {
                DTLKEY: line.DTLKEY,
            }
        }),

        update_claimed: dialogViewBatchUpdateClaimSettled.value.update_claimed,
        claim_date: dialogViewBatchUpdateClaimSettled.value.claim_date,
        claim_remark: dialogViewBatchUpdateClaimSettled.value.claim_remark,

        update_settled: dialogViewBatchUpdateClaimSettled.value.update_settled,
        settled_date: dialogViewBatchUpdateClaimSettled.value.settled_date,
        settled_remark: dialogViewBatchUpdateClaimSettled.value.settled_remark,
    };

    if (dialogViewBatchUpdateClaimSettled.value.isClaimed == false) {
        data.claim_date = null;
        data.claim_remark = null;
    }

    if (dialogViewBatchUpdateClaimSettled.value.isSettled == false) {
        data.settled_date = null;
        data.settled_remark = null;
    }

    data.claim_date = convertPrimeVueCalendar(data.claim_date);
    data.settled_date = convertPrimeVueCalendar(data.settled_date);

    await axios({
        method: "post",
        url: url,
        data: data,
    }).then(
        () => {
            dialogViewBatchUpdateClaimSettled.value.show = false;
            findStockIssue();
            selectedLines.value = [];
        },
        (error) => {
            console.log(error.response.data);
        }
    );

    dialogViewBatchUpdateClaimSettled.value.isLoading = false;

}
//  ----------------------------------------
</script>

<style scoped></style>