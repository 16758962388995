
<script setup>

import { ref, onMounted } from "vue";
import { useRouter } from 'vue-router';
// import axios from "axios";
// import moment from 'moment';
const router = useRouter();

const isLoading = ref({
    groups: false,
});

const productGroups = ref([]);

// const dateTimeFormat = (timestampString) => {
//     // If less than 24 hours ago, show hours ago
//     if (moment().diff(moment(timestampString), 'hours') < 24) {
//         return moment(timestampString).fromNow();
//     }

//     // If less than 7 days ago, show days ago
//     if (moment().diff(moment(timestampString), 'days') < 7) {
//         return moment(timestampString).fromNow();
//     }

//     // Display as format "dd MMM yyyy HH:mm"
//     return moment(timestampString).format('DD MMM YYYY, HH:mm');
// }

// const dateFormat = (timestampString) => {
//     return moment(timestampString).format('DD MMM YYYY, HH:mm');

// }

const getMostEfficientImage = (imageJsonData, width) => {
    const originalWidth = imageJsonData.metadata_public.original_size.width
    const orginalUrl = imageJsonData.url

    // If original image size already less than size required, just return the image
    if (originalWidth <= width) {
        return orginalUrl
    }

    const alternativeSizes = imageJsonData.metadata_public.alternative_sizes

    for (let i = 0; i < alternativeSizes.length; i++) {
        if (alternativeSizes[i].width >= width) {
            return alternativeSizes[i].url
        }
    }

    return orginalUrl; // If no image found, return the original image
}
const removeItem = async (productSlug, productGroupId) => {
    let url = process.env.VUE_APP_ECOMMERCE_API_URL + '/product-groups-admin/product';

    await fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            product_slug: productSlug,
            product_group_id: productGroupId,
        })
    }).then(
        (result) => {
            if (result.status == 401) {
                router.push('/login');
                return;
            }

            if (result.status != 200) {
                console.log("Error");
                console.log(result);
                return;
            }

            getProductGroups();
        }
    ).catch(
        (error) => {
            console.log("Error");
            console.log(error);
        }
    );
}

const getProductGroups = async () => {
    isLoading.value.groups = true;

    let url = process.env.VUE_APP_ECOMMERCE_API_URL + '/product-groups-admin';

    await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
            'Accept': 'application/json',
        }
    }).then(
        (result) => {
            const router = useRouter();

            if (result.status == 401) {
                router.push('/login');
                return;
            }

            if (result.status != 200) {
                console.log("Error");
                console.log(result);
                return;
            }

            result.json().then((data) => {
                // To each product_groups add a new property called "inputItem" and "inputItemSuggestions"
                data.product_groups.forEach((group) => {
                    group.products.forEach((product) => {
                        product.inputItem = '';
                        product.inputItemSuggestions = [];
                    });
                });

                productGroups.value = data.product_groups;
            });
        }
    ).catch(
        (error) => {
            console.log("Error");
            console.log(error);
        }
    );
    isLoading.value.groups = false;
}

const addItem = ({ group, inputItem }) => {
    let url = process.env.VUE_APP_ECOMMERCE_API_URL + '/product-groups-admin/product';
    console.log(url)
    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body : JSON.stringify({
            product_group_id: group.id,
            product_slug: inputItem.slug,
        })
    }).then(
        (result) => {
            const router = useRouter();

            if (result.status == 401) {
                router.push('/login');
                return;
            }

            if (result.status != 200 && result.status != 201) {
                console.log("Error");
                console.log(result);
                return;
            }

            result.json().then(() => {
                getProductGroups();
            });
        }
    )
    .catch(
        (error) => {
            console.log("Error");
            console.log(error);
        }
    );
}

const generateItemSuggestions = (group) => {
    let url = process.env.VUE_APP_ECOMMERCE_API_URL + '/products-admin?query=' + group.inputItem;
    fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
            'Accept': 'application/json',
        }
    }).then(
        (result) => {
            const router = useRouter();

            if (result.status == 401) {
                router.push('/login');
                return;
            }

            if (result.status != 200) {
                console.log("Error");
                console.log(result);
                return;
            }

            result.json().then((data) => {
                group.inputItemSuggestions = data.products.data.map((item) => {
                    {
                        return {
                            name: item.name,
                            slug: item.slug,
                        }
                    }
                });
            });
        }
    ).catch(
        (error) => {
            console.log("Error");
            console.log(error);
        }
    );
}

onMounted(() => {
    getProductGroups();
});

</script>

<template>
    <div class="card flex flex-column gap-4">
        <div v-for="group in productGroups" :key="group.id" class="card">
            <div class="text-3xl font-medium">{{ group.name }}</div>
            <div class="text-2xl font-medium mt-2">{{ group.description }}</div>

            <AutoComplete v-model="group.inputItem" :suggestions="group.inputItemSuggestions" optionLabel="name"
                @complete="generateItemSuggestions(group)" forceSelection class="mt-3"
                @item-select="addItem({ group, inputItem: group.inputItem })" />

            <div class="flex mt-3 flex-column">
                <div v-for="product in group.products" :key="product.slug" class="card">
                    <div class="flex align-items-center" style="width: 60px;">
                        <img v-if="product.front_medias[0]" :src="getMostEfficientImage(product.front_medias[0], 80)"
                            width="60" style="object-fit:cover;" />
                    </div>
                    <div class="mt-2">{{ product.name }}</div>

                    <div v-if="product.stock_availability == 'out_of_stock'" class="font-medium text-pink-600 mt-2">OUT OF
                        STOCK</div>

                    <Button label="Remove" icon="pi pi-trash" class="p-button-danger mt-3"
                        @click="removeItem(product.slug, group.id)" />
                </div>
            </div>
        </div>
    </div>
</template>