<template>
    <div>
        <h2>Suppliers</h2>
        <Button type="button" icon="pi pi-plus" label="Add Supplier" class="p-button-outlined mb-2"
            @click="showAddSupplierDialog" />
        <DataTable :value="suppliers" responsiveLayout="scroll" :loading="isLoading.suppliers" class="mt-3"
            size="small">
            <Column field="COMPANYNAME" header="Company">
                <template #body="{ data }">
                    <router-link :to="'/suppliers/' + data.id">{{ data.COMPANYNAME }}</router-link>
                </template>
            </Column>
            <Column field="agent_name" header="Purchaser">
                <template #body="{ data }">
                    {{ data.agent.name }}
                </template>
            </Column>
            <Column field="currency" header="Currency"></Column>
            <Column field="days_to_manufacture" header="Days To Manufacture"></Column>
            <Column field="days_for_shipment" header="Days For Shipment"></Column>
            <Column field="days_of_stock_to_keep" header="Days Of Stock To Keep"></Column>
            <Column field="is_extended" header="Dashboard Created"></Column>
            <Column header="Actions">
                <template #body="slotProps">
                    <Button type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger" label="Delete"
                        size="small" @click="showDeleteSupplierDialog(slotProps.data)" />
                </template>
            </Column>
        </DataTable>


        <!--------------------- Add Supplier Dialog Start ---------------------->
        <Dialog header="Add New Supplier" v-model:visible="dialogAddSupplier.show" :style="{ width: '400px' }"
            :modal="true">
            <form class="flex-column align-items-center justify-content-center" @submit.prevent="addSupplier">
                <InputText type="text" placeholder="Company Name" name="COMPANYNAME"
                    v-model="dialogAddSupplier.COMPANYNAME" class="w-full mb-2" />

                <AutoComplete placeholder="Purchaser" v-model="dialogAddSupplier.agent" class="w-full mb-2" optionLabel="name"
                    :suggestions="filteredAgents" dropdown forceSelection @complete="searchAgent" completeOnFocus />

                <InputText type="text" placeholder="Currency: CNY/MYR" name="currency"
                    v-model="dialogAddSupplier.currency" class="w-full mb-2" />

                <InputNumber v-model="dialogAddSupplier.daysToManufacture" placeholder="Days To Manufacture"
                    class="w-full mb-2 mt-3"></InputNumber>
                <InputNumber v-model="dialogAddSupplier.daysForShipment" placeholder="Days For Shipment"
                    class="w-full mb-2">
                </InputNumber>
                <InputNumber v-model="dialogAddSupplier.daysOfStockToKeep" placeholder="Days Of Stock To Keep"
                    class="w-full mb-2"></InputNumber>
                <input class="hidden" type="submit" value="Submit" />
            </form>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="dialogAddSupplier.show = false" class="p-button-text"
                    :loading="isLoading.dialogAddSupplier" />
                <Button label="Yes" icon="pi pi-check" @click="addSupplier" class="p-button-text"
                    :loading="isLoading.dialogAddSupplier" />
            </template>
        </Dialog>
        <!--------------------- Add Supplier Dialog End ---------------------->

        <!--------------------- Delete Supplier Dialog End ---------------------->
        <Dialog header="Delete Supplier" v-model:visible="dialogDeleteSupplier.show" :style="{ width: '400px' }"
            :modal="true">
            <div class="flex align-items-center justify-content-left">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Are you sure you want to proceed? This cannot be reverted!</span>
            </div>
            <InputText type="text" placeholder="Type Company Name here" name="removeDialogInputCompanyName"
                v-model="dialogDeleteSupplier.inputCompanyName" class="w-full mt-3" />
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="dialogDeleteSupplier.show = false" class="p-button-text"
                    :loading="isLoading.dialogDeleteSupplier" />
                <Button label="Yes" icon="pi pi-check" @click="deleteSupplier" class="p-button-text"
                    :loading="isLoading.dialogDeleteSupplier" />
            </template>
        </Dialog>
        <!--------------------- Delete Supplier Dialog End ---------------------->

    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";

const suppliers = ref([]);
const isLoading = ref({ suppliers: false, dialogAddSupplier: false, dialogDeleteSupplier: false });
const dialogAddSupplier = ref({
    show: false,
    agent: "",
    currency: ""
});
const dialogDeleteSupplier = ref({
    show: false,
    inputCompanyName: "",
    supplier: null
});

onMounted(() => {
    getAllSuppliers();
});


// ---------------------------- //
// ------ Agent Feature ------- //
// ---------------------------- //

const filteredAgents = ref();

const searchAgent = (event) => {
    const query = event.query;

    let url = "agents";
    if (query) {
        url += "?search=" + query;
    }

    axios({
        method: "GET",
        url: url,
    }).then(
        (result) => {
            const agents = result.data.agents.map((agents) => {
                return {
                    ...agents
                };
            });

            filteredAgents.value = agents;
        },
        (error) => {
            console.log(error.response.data);
        }
    );
};
// ---------------------------- //


const getAllSuppliers = function () {
    isLoading.value.suppliers = true;
    axios({
        method: "GET",
        url: "suppliers",
    }).then(
        (result) => {
            suppliers.value = result.data.suppliers;
            isLoading.value.suppliers = false;
        },
        (error) => {
            console.log(error.response.data);
            isLoading.value.suppliers = false;
        }
    );
}

const showAddSupplierDialog = function () {
    dialogAddSupplier.value.show = true;
    dialogAddSupplier.value.COMPANYNAME = "";
    dialogAddSupplier.value.purchaser = "";
    dialogAddSupplier.value.currency = "";
}

const addSupplier = function () {
    isLoading.value.dialogAddSupplier = true;
    axios({
        method: "POST",
        url: "suppliers",
        data: {
            COMPANYNAME: dialogAddSupplier.value.COMPANYNAME,
            agent_id: dialogAddSupplier.value.agent.id,
            currency: dialogAddSupplier.value.currency,
            days_to_manufacture: dialogAddSupplier.value.daysToManufacture,
            days_for_shipment: dialogAddSupplier.value.daysForShipment,
            days_of_stock_to_keep: dialogAddSupplier.value.daysOfStockToKeep,
        }
    }).then(
        () => {
            dialogAddSupplier.value.show = false;
            isLoading.value.dialogAddSupplier = false;

            getAllSuppliers();
        },
        (error) => {
            console.log(error.response.data);
            isLoading.value.dialogAddSupplier = false;
        }
    );
}

const showDeleteSupplierDialog = function (supplier) {
    dialogDeleteSupplier.value.inputCompanyName = "";
    dialogDeleteSupplier.value.show = true;
    dialogDeleteSupplier.value.supplier = supplier;
}

const deleteSupplier = function () {
    if (dialogDeleteSupplier.value.inputCompanyName != dialogDeleteSupplier.value.supplier.COMPANYNAME) {
        dialogDeleteSupplier.value.show = false;
        return;
    }

    isLoading.value.dialogDeleteSupplier = true;
    axios({
        method: "DELETE",
        url: "suppliers/" + dialogDeleteSupplier.value.supplier.id,
    }).then(
        () => {
            dialogDeleteSupplier.value.show = false;
            isLoading.value.dialogDeleteSupplier = false;

            getAllSuppliers();
        },
        (error) => {
            console.log(error.response.data);
            isLoading.value.dialogDeleteSupplier = false;
        }
    );
}


</script>

<style scoped></style>