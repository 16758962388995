<template>

    <div class="text-lg font-bold flex align-items-center gap-3 mt-6 mb-2">
        Variants
        <div v-if="isEditing">
            <Button class="py-0 px-2" label="Save" @click="save()" text></Button>
            <Button class="py-0 px-2" label="Cancel" @click="cancel()" severity="danger" text></Button>
        </div>
        <div v-else>
            <Button class="py-0 px-2" label="Edit" @click="editVariant()" text></Button>
        </div>
    </div>

    <DataTable v-if="isEditing" :value="editedVariant">
        <Column header="SKU" style="width: 10%">
            <template #body="{ data }">
                <InputText v-model="data.sku" style="width:100%;" />
            </template>
        </Column>

        <Column header="Dimensions (cm x cm x cm)" style="width: 15%">
            <template #body="{ data }">
                <InputText v-model="data.length" style="width:33%;" :disabled="!data.sku" />
                <InputText v-model="data.width" style="width:33%;" :disabled="!data.sku" />
                <InputText v-model="data.height" style="width:33%;" :disabled="!data.sku" />
            </template>
        </Column>

        <Column header="Weight (kg)" style="width: 8%">
            <template #body="{ data }">
                <InputText v-model="data.weight" style="width:100%;" :disabled="!data.sku" />
            </template>
        </Column>

        <Column header="Cart Max Qty" style="width: 10%">
            <template #body="{ data }">
                <InputText v-model="data.add_to_cart_quantity_max_value" style="width:100%;" :disabled="!data.sku" />
            </template>
        </Column>

        <Column header="Sync Reduce Qty" style="width: 10%">
            <template #body="{ data }">
                <InputText v-model="data.inventory_quantity_reduce_value" style="width:100%;" :disabled="!data.sku" />
            </template>
        </Column>

        <Column v-if="props.product.type == 'simple'" header="" style="width: 30%">
        </Column>

        <Column header="Available QTY" field="inventory_quantity" style="width: 10%"></Column>

        <Column v-for="option in props.product.product_options" :key="option.name" :header="option.name"
            style="width: 10%">
            <template #body="{ data }">
                <!-- Get the data from array of objects with attribute name:"round" -->
                <span>{{ data.product_option_values.find(x => x.product_option_name === option.name).name
                    }}</span>
            </template>
        </Column>
    </DataTable>
    <DataTable v-else :value="props.product.variants">
        <Column header="SKU" style="width: 10%">
            <template #body="{ data }">
                <span>{{ data.sku }}</span>
            </template>
        </Column>

        <Column header="Dimensions (cm x cm x cm)" style="width: 15%">
            <template #body="{ data }">
                <span>{{ data.length }}</span>
                x
                <span>{{ data.width }}</span>
                x
                <span>{{ data.height }}</span>
            </template>
        </Column>

        <Column header="Weight (kg)" style="width: 10%">
            <template #body="{ data }">
                <span>{{ data.weight }}</span>
            </template>
        </Column>

        <Column header="Cart Max Qty" style="width: 10%">
            <template #body="{ data }">
                <span>{{ data.add_to_cart_quantity_max_value }}</span>
            </template>
        </Column>

        <Column header="Sync Reduce Qty" style="width: 10%">
            <template #body="{ data }">
                <span>{{ data.inventory_quantity_reduce_value }}</span>
            </template>
        </Column>

        <Column header="Available QTY" field="inventory_quantity" style="width: 10%"></Column>

        <Column v-if="props.product.type == 'simple'" header="" style="width: 30%">
            <template>

            </template>
        </Column>


        <Column v-for="option in props.product.product_options" :key="option.name" :header="option.name"
            style="width: 10%">
            <template #body="{ data }">
                <span>{{ data.product_option_values.find(x => x.product_option_name === option.name)?.name
                    }}</span>
            </template>
        </Column>
    </DataTable>

</template>



<script setup>
import { ref, onMounted, defineModel, defineProps, defineEmits, defineExpose } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, maxValue } from '@vuelidate/validators'
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
const router = useRouter();
const route = useRoute();
const toast = useToast();

// const isEditing = defineModel('isEditing', { required: true })

const isEditing = ref(false);
const editedVariant = ref(null);

const props = defineProps({
    product: {
        type: Object,
        required: true
    }
})

const emit = defineEmits(['saved'])

const editVariant = () => {
    isEditing.value = true;
    editedVariant.value = JSON.parse(JSON.stringify(props.product.variants));
}


const cancel = () => {
    isEditing.value = false;
    editedVariant.value = null;
}

defineExpose({
    cancel
});

const save = async () => {
    let updatedData = editedVariant;
    let updateField = null;

    if (props.product.type == 'simple') {
        updateField = 'simple_product_variant'

        // Only update selected fields of editedVariant.value[0]
        updatedData = {
            sku: editedVariant.value[0].sku,
            length: editedVariant.value[0].length,
            width: editedVariant.value[0].width,
            height: editedVariant.value[0].height,
            weight: editedVariant.value[0].weight,
            add_to_cart_quantity_max_value: editedVariant.value[0].add_to_cart_quantity_max_value,
            inventory_quantity_reduce_value: editedVariant.value[0].inventory_quantity_reduce_value,
        };
    }

    if (props.product.type == 'variable') {
        // Check if any variants with id but no sku, if so, show popup and confirm if want to remove variant
        let hasVariantsWithIdButNoSku = editedVariant.value.some((variant) => {
            return variant.id != null && (variant.sku == null || variant.sku == '');
        });

        if (hasVariantsWithIdButNoSku) {
            let result = confirm("You may be attempting to remove from variants. Confirm remove??");
            if (!result) {
                return;
            }
        }

        updateField = 'variable_product_variants_basic_info'

        let existingVariants = editedVariant.value.filter((variant) => {
            return variant.id != null;
        });

        updatedData = existingVariants.map((variant) => {
            return {
                id: variant.id,
                sku: variant.sku,
                length: variant.length,
                width: variant.width,
                height: variant.height,
                weight: variant.weight,
                add_to_cart_quantity_max_value: variant.add_to_cart_quantity_max_value,
                inventory_quantity_reduce_value: variant.inventory_quantity_reduce_value,
            }
        })

        // Check if any variants without an id (new variants)
        let newVariants = editedVariant.value.filter((variant) => {
            return variant.id == null && variant.sku != null;
        });

        newVariants = newVariants.map((variant) => {
            return {
                sku: variant.sku,
                length: variant.length,
                width: variant.width,
                height: variant.height,
                weight: variant.weight,
                add_to_cart_quantity_max_value: variant.add_to_cart_quantity_max_value,
                inventory_quantity_reduce_value: variant.inventory_quantity_reduce_value,
                product_option_values: variant.product_option_values,
            }
        })

        updatedData.push(...newVariants);
    }

    await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/products-admin/' + route.params.slug, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
            'Accept': 'application/json',
        },
        body: JSON.stringify({
            [updateField]: updatedData
        })
    }).then(
        (result) => {
            if (result.status == 401) {
                router.push('/login');
                return;
            }

            if (result.status != 200) {
                console.log("Error - Not 200");

                result.json().then((data) => {
                    toast.add({
                        severity: "warn",
                        summary: "Failed. ( Variant )",
                        detail: data.message,
                        life: 3000,
                    });
                });
                return;
            }
            else {
                result.json().then((data) => {
                    emit('saved');

                    editedVariant.value = null;
                    isEditing.value = false;

                    toast.add({
                        severity: "success",
                        summary: "Success. ( Variant )",
                        detail: data.message,
                        life: 3000,
                    });

                    return;
                });
            }
        }
    ).catch(
        (error) => {
            console.log("Error");
            console.log(error);
        }
    );
}


</script>


<style scoped></style>