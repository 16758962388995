<template>
    <h2>Ecommerce - Brands</h2>

    <div class="flex gap-3">

        <div v-if="mode == 'add'" class="card w-3 h-max">
            <div class="mb-2">Name</div>
            <InputText class="w-12" type="text" v-model="inputData.name" />

            <div class="mb-2 mt-4">Slug</div>
            <InputText class="w-12" type="text" v-model="inputData.slug" />

            <Button class="mt-4" label="Add New" @click="addNew"></Button>
        </div>

        <div v-if="mode == 'edit'" class="card w-3 h-max">
            <div class="mb-2">Name</div>
            <InputText class="w-12" type="text" v-model="inputData.name" />

            <div class="mb-2 mt-4">Slug</div>
            <InputText class="w-12" type="text" v-model="inputData.slug" />

            <div class="mt-4 flex gap-3">
                <Button severity="secondary" label="Cancel" @click="cancelEdit"></Button>
                <Button label="Save" @click="saveChanges"></Button>
            </div>
        </div>
        <div class="card w-8">
            <DataTable :value="brands">
                <Column field="name" header="Name"></Column>
                <Column field="slug" header="Slug"></Column>
                <Column field="products_count" header="Products Count"></Column>
                <Column heasder="Actions">
                    <template #body="{ data }">
                        <Button @click="edit(data)" text label="Edit"></Button>
                        <Button v-if="data.products_count == 0" @click="deleteBrand(data)" text label="Delete" severity="danger"></Button>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
import router from '@/router';
// import moment from "moment";


export default {
    data() {
        return {
            isLoading: { brands: false },
            brands: [],
            mode: "add",
            inputData: {
                name: "",
                slug: ""
            }
        }
    },
    mounted: function () {
        this.getBrands();
    },
    methods: {
        getBrands: async function () {
            this.isLoading.brands = true;

            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/product-brands-admin', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                }
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200 && result.status != 201) {
                        console.log("Error not 200/201");
                        console.log(result);
                        return;
                    }

                    result.json().then((data) => {
                        this.brands = data.product_brands;
                    });
                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
            this.isLoading.brands = false;
        },
        edit(brand) {
            this.mode = "edit"

            // Shallow copy
            this.inputData = Object.assign({}, brand);
            this.inputData.original_slug = brand.slug
        },
        cancelEdit() {
            this.mode = "add"
            this.inputData = {
                name: "",
                slug: ""
            }
        },
        async saveChanges() {
            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/product-brands-admin/' + this.inputData.original_slug, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    name: this.inputData.name,
                    slug: this.inputData.slug,
                })
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200 && result.status != 201) {
                        console.log("Error - Not 200/201");
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "warn",
                                summary: "Failed",
                                detail: data.message,
                                life: 3000,
                            });
                            return;
                        });
                    }
                    else {
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Success",
                                detail: data.message,
                                life: 3000,
                            });
                        });

                        // Reload page
                        this.getBrands();
                        this.cancelEdit();
                    }


                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
        },
        async deleteBrand(brand){
            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/product-brands-admin/' + brand.slug, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                }
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200 && result.status != 201) {
                        console.log("Error - Not 200/201");
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "warn",
                                summary: "Failed",
                                detail: data.message,
                                life: 3000,
                            });
                            return;
                        });
                    }
                    else {
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Success",
                                detail: data.message,
                                life: 3000,
                            });
                        });

                        // Reload page
                        this.getBrands();
                        this.cancelEdit();
                    }


                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
        },
        async addNew() {
            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/product-brands-admin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    name: this.inputData.name,
                    slug: this.inputData.slug,
                })
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200 && result.status != 201) {
                        console.log("Error - Not 200/201");
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "warn",
                                summary: "Failed",
                                detail: data.message,
                                life: 3000,
                            });
                            return;
                        });
                    }
                    else {
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Success",
                                detail: data.message,
                                life: 3000,
                            });
                        });

                        // Reload page
                        this.getBrands();
                        this.cancelEdit();
                    }
                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );

        }
    },
};
</script>

<style scoped></style>
