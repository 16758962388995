<template>
  <div class="grid">
    <div class="col-12 lg:col-4 mx-auto">
      <div class="
          card
          flex-row
          justify-content-center
          align-items-center
          py-6
          px-5
        ">
        <div class="mb-3 flex justify-content-center">
          <h4>Change Password</h4>
        </div>
        <div class="mb-5 flex justify-content-center">
          <IconField class=" w-full">
            <InputIcon class="pi pi-lock" />
            <InputText type="password" placeholder="Current Password" v-model="currentPassword" class="w-full" />
          </IconField>
        </div>
        <div class="mb-3 flex justify-content-center">
          <IconField class=" w-full">
            <InputIcon class="pi pi-lock" />
            <InputText type="password" placeholder="New Password" v-model="newPassword" class="w-full" />
          </IconField>
        </div>
        <div class="mb-3 flex justify-content-center">
          <IconField class=" w-full">
            <InputIcon class="pi pi-lock" />
            <InputText type="password" placeholder="Confirm Password" v-model="confirmPassword" class="w-full"
              @keyup.enter="confirmChangePassword" />
          </IconField>
        </div>
        <div class="mb-3 flex justify-content-center">
          <Button label="Change Password" class="w-full mr-2 mb-2 mx-auto" @click="confirmChangePassword"></Button>
        </div>
        <div id="error-message" class="p-error">{{ errorMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "changePassword",
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      errorMessage: "",
    };
  },
  methods: {
    confirmChangePassword: function () {
      if (this.newPassword !== this.confirmPassword) {
        this.errorMessage = "Password confirmation does not match!";
        return;
      }

      axios({
        method: "POST",
        url: "user/changePassword",
        data: {
          current_password: this.currentPassword,
          new_password: this.newPassword,
        },
      }).then(
        (result) => {
          if (result) {
            this.$router.push({ path: "/login" });
            localStorage.removeItem("access_token");
            localStorage.removeItem("username");
            localStorage.removeItem("user_id");
            localStorage.removeItem("user_type");
          }
        },
        (error) => {
          this.errorMessage = error.response.data.message;
        }
      );
    },
  },
};
</script>

<style scoped></style>
