<template>
  <div>
    <h2>Customer Notification</h2>
    <DataTable :value="customer_bookings" responsiveLayout="scroll" :loading="loadingBookings" size="small">
      <template #header>
        <div class="flex flex-row">
          <Button type="button" icon="pi pi-plus" label="Add Booking" class="p-button-outlined mb-2 mr-4"
            @click="showAddBookingDialog" />
        </div>
        <div class="flex flex-row">
          <div class="flex flex-column">
            <IconField class="mr-1 mb-1">
              <InputIcon class="pi pi-search" />
              <InputText v-model="inputs.search_data" :placeholder="'Search by ' + inputs.search_type.name"
                style="width: 100%" @keydown="textChanged" />
            </IconField>
          </div>
          <Dropdown v-model="inputs.search_type" :options="inputs.availableSearchType" optionLabel="name"
            placeholder="Select" class="mr-4 h-full" @change="searchTypeChanged" />
        </div>
        <TabMenu class="mt-3" :model="inputs.availableTabs" @tab-change="onTabChange($event)" />
        <div class="mt-3">
          {{ this.pagination.total_records }} bookings found.
        </div>
      </template>
      <template #footer>
        <Paginator :rows="pagination.records_per_page" :totalRecords="pagination.total_records"
          @page="onPageChange($event)">
        </Paginator>
      </template>

      <Column field="date" header="Date"></Column>
      <Column header="Customer">
        <template #body="{ data }">
          <div class="flex flex-column">
            <div>{{ data.customer_name }}</div>
            <a :href="'https://wa.me/' + contactWhatsappFilter(data.contact_details)
              " target="_blank" rel="noopener noreferrer">{{ data.contact_details }}</a>
            <a v-if="data.contact_details_2" :href="'https://wa.me/' + contactWhatsappFilter(data.contact_details_2)
              " target="_blank" rel="noopener noreferrer">{{ data.contact_details_2 }}</a>
          </div>
        </template>
      </Column>
      <Column field="agent_name" header="Agent"></Column>
      <Column field="remark" header="Remark" style="max-width: 250px;"></Column>
      <Column field="items" header="Items">
        <template #body="{ data }">
          <table class="items-table">
            <tr>
              <th class="nowrap">Code</th>
              <th style="width:100%">Description</th>
              <th>Qty</th>
              <th class="nowrap">Qty Left</th>
              <th></th>
            </tr>
            <tr v-for=" item in data.items" :key="item.code" :class="{
              readyToNotify:
                item.available_stock >= item.quantity && item.notified == false,
              'only-display-available': !item.customer_wants_display && item.available_stock >= item.quantity && item.available_stock - item.display_count < item.quantity
            }">
              <td class="nowrap">{{ item.code }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.available_stock }}</td>
              <td>
                <Button v-if="item.notified == true" :title="showTimeAgoFromTimestamp(item.notified_at)"
                  :label="showDateFromTimestamp(item.notified_at)" class="p-button-success  nowrap"
                  @click="showToggleOngoingDialog(data, item)" :loading="item.loading" size="small" />
                <Button v-else size="small" label="Ongoing" class="p-button-warning  nowrap"
                  @click="showToggleOngoingDialog(data, item)" :loading="item.loading" />
              </td>
            </tr>
          </table>
        </template>
      </Column>
      <Column>
        <template #body="{ data }">
          <div class="gap-2 flex flex-column">
            <Button size="small" label="Edit" icon="pi pi-pencil" class="p-button-primary mr-2"
              @click="showEditBookingDialog(data)" />
            <Button size="small" v-if="userType != 'staff' || isCreatedLessThan1HourAgo(data)" label="Remove"
              icon="pi pi-trash" class="p-button-danger mr-2" @click="showRemoveBookingDialog(data.id)" />
          </div>
        </template>
      </Column>
    </DataTable>

    <!-- Remove booking dialog -->
    <Dialog header="Remove Booking" v-model:visible="dialogRemoveBooking.show" :style="{ width: '350px' }"
      :modal="true">
      <div class="flex align-items-center justify-content-left">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Are you sure you want to proceed?</span>
      </div>
      <div class="flex align-items-center justify-content-left">
        <div id="error-message" class="p-error mt-2">
          {{ dialogRemoveBooking.errorMessage }}
        </div>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="dialogRemoveBooking.show = false" class="p-button-text"
          :loading="dialogRemoveBooking.loading" />
        <Button label="Yes" icon="pi pi-check" @click="removeBooking" class="p-button-text"
          :loading="dialogRemoveBooking.loading" />
      </template>
    </Dialog>

    <!--------------------- Add Booking Dialog ---------------------->
    <Dialog header="Add New Booking" v-model:visible="dialogAddBooking.show" :style="{ width: '1024px' }" :modal="true">
      <div class="flex-column align-items-center justify-content-center">
        <div class="mb-2">
          <div class="p-float-label">
            <Calendar id="date" v-model="dialogAddBooking.date" :showIcon="true" class="w-full" dateFormat="yy-mm-dd"/>
            <label for="date">Date</label>
          </div>
        </div>
        <div class="flex align-items-center justify-content-center mb-2 gap-2">
          <InputText type="text" placeholder="Customer Name" name="customer_name"
            v-model="dialogAddBooking.customer_name" class="w-full" autofocus />
          <InputText type="text" placeholder="Agent Name" name="agent_name" v-model="dialogAddBooking.agent_name"
            class="w-full" />
        </div>
        <div class="flex align-items-center justify-content-center mb-2 gap-2">
          <InputText type="text" placeholder="Contact Details" name="contact_details"
            v-model="dialogAddBooking.contact_details" class="w-full mb-2" />
          <InputText type="text" placeholder="Contact Details 2 (Optional)" name="contact_details_2"
            v-model="dialogAddBooking.contact_details_2" class="w-full mb-2" />
        </div>
        <InputText type="text" placeholder="Remark (Optional)" name="remark" v-model="dialogAddBooking.remark"
          class="w-full mb-2" />

        <!-- Item Table Card -->
        <div class="card">
          <h5>Items</h5>
          <Button label="Add Item" icon="pi pi-plus" @click="dialogAddItem" class="p-button-text" />

          <DataTable :value="dialogAddBooking.items" editMode="row" dataKey="id" @row-edit-save="onRowEditSave" responsiveLayout="scroll">
            <Column header="Item" style="width: 60%">
              <template #body="{ data }">
                <AutoComplete v-model="data.item" :suggestions="dialogAddBooking.suggestions"
                  @complete="generateSuggestions($event)" forceSelection class="w-full" />
              </template>
            </Column>
            <Column header="Quantity" style="width: 15%">
              <template #body="{ data }">
                <InputText v-model="data.quantity" />
              </template>
            </Column>

            <Column field="display_count" header="Display Qty">
              <template #body="{ data, field }">
                <InputNumber v-model="data[field]" />
              </template>
            </Column>

            <Column field="customer_wants_display" header="Cus Want Display">
              <template #body="{ data, field }">
                <Checkbox v-model="data[field]" :binary="true" />
              </template>
            </Column>

            <Column header="Action" style="width: 10%">
              <template #body="{ data }">
                <Button label="Remove" severity="danger" text @click="removeAddBookingItem(data.id)" />

              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <div class="flex align-items-center justify-content-left">
        <div id="error-message" class="p-error mt-2">
          {{ dialogAddBooking.errorMessage }}
        </div>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="dialogAddBooking.show = false" class="p-button-text"
          :loading="dialogAddBooking.loading" />
        <Button label="Yes" icon="pi pi-check" @click="addBooking" class="p-button-text"
          :loading="dialogAddBooking.loading" />
      </template>
    </Dialog>

    <!-- Edit Booking Dialog -->
    <Dialog header="Edit Booking" v-model:visible="dialogEditBooking.show" :style="{ width: '1024px' }" :modal="true">
      <div class="flex-column align-items-center justify-content-center">
        <div class="mb-2">
          <div class="p-float-label">
            <Calendar id="date" v-model="dialogEditBooking.date" :showIcon="true" class="w-full" dateFormat="yy-mm-dd" />
            <label for="date">Date</label>
          </div>
        </div>
        <div class="flex align-items-center justify-content-center mb-2">
          <InputText type="text" placeholder="Customer Name" name="customer_name"
            v-model="dialogEditBooking.customer_name" class="w-full mr-2" />
          <InputText type="text" placeholder="Agent Name" name="agent_name" v-model="dialogEditBooking.agent_name"
            class="w-full" />
        </div>
        <div class="flex align-items-center justify-content-center mb-2 gap-2">
          <InputText type="text" placeholder="Contact Details" name="contact_details"
            v-model="dialogEditBooking.contact_details" class="w-full mb-2" />
          <InputText type="text" placeholder="Contact Details 2 (Optional)" name="contact_details_2"
            v-model="dialogEditBooking.contact_details_2" class="w-full mb-2" />
        </div>
        <InputText type="text" placeholder="Remark (Optional)" name="remark" v-model="dialogEditBooking.remark"
          class="w-full mb-2" />

        <!-- Item Table Card -->
        <div class="card">
          <h5>Items</h5>
          <Button label="Add Item" icon="pi pi-plus" @click="dialogEditAddItem" class="p-button-text" />

          <!-- Items Data Table -->
          <DataTable :value="dialogEditBooking.items" dataKey="index" responsiveLayout="scroll">

            <Column field="item" header="Item" style="width: 60%">
              <template #body="{ data, field }">
                <AutoComplete v-if="data['id'] == null" v-model="data[field]"
                  :suggestions="dialogEditBooking.suggestions" @complete="dialogEditGenerateSuggestions($event)"
                  forceSelection class="w-full" />

                <div v-else>
                  {{ data[field] }}
                </div>
              </template>
            </Column>

            <Column field="quantity" header="Quantity" style="width: 15%">
              <template #body="{ data, field }">
                <InputNumber v-model="data[field]" />
              </template>
            </Column>

            <Column field="display_count" header="Display Qty">
              <template #body="{ data, field }">
                <InputNumber v-model="data[field]" />
              </template>
            </Column>

            <Column field="customer_wants_display" header="Cus Want Display">
              <template #body="{ data, field }">
                <Checkbox v-model="data[field]" :binary="true" />
              </template>
            </Column>

            <Column header="Actions" style="min-width: 8rem">
              <template #body="{ data }">
                <Button label="Delete" severity="danger" @click="dialogEditDeleteItem(data)"></Button>
              </template>
            </Column>

          </DataTable>
        </div>
      </div>
      <div class="flex align-items-center justify-content-left">
        <div id="error-message" class="p-error mt-2">
          {{ dialogEditBooking.errorMessage }}
        </div>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="dialogEditBooking.show = false" class="p-button-text"
          :loading="dialogEditBooking.loading" />
        <Button label="Yes" icon="pi pi-check" @click="editBooking" class="p-button-text"
          :loading="dialogEditBooking.loading" />
      </template>
    </Dialog>

    <!-- Toggle Ongoing Dialog -->
    <Dialog header="Toggle Ongoing/Notified" v-model:visible="dialogToggleOngoing.show" :style="{ width: '400px' }"
      :modal="true">
      <div class="flex align-items-center justify-content-left">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="dialogToggleOngoing.itemData &&
          !dialogToggleOngoing.itemData.notified
        ">Changing this item to <span class="green-notified">notified</span>.
          Are you sure?</span>
        <span v-else>Changing this item to <span class="orange-ongoing">ongoing</span>.
          Are you sure?</span>
      </div>
      <div class="flex align-items-center justify-content-left">
        <div id="error-message" class="p-error mt-2">
          {{ dialogToggleOngoing.errorMessage }}
        </div>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="dialogToggleOngoing.show = false" class="p-button-text"
          :loading="dialogToggleOngoing.loading" />
        <Button label="Yes" icon="pi pi-check" @click="toggleNotified" class="p-button-text"
          :loading="dialogToggleOngoing.loading" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { convertPrimeVueCalendar } from "../utils/convertPrimeVueCalendar";
export default {
  name: "customerbooking",
  data() {
    return {
      userType: "staff",
      customer_bookings: [],
      inputs: {
        search_data: "",
        search_type: { name: "Item", value: "item" },
        availableSearchType: [
          { name: "Agent", value: "agent" },
          { name: "Customer", value: "customer" },
          { name: "Contact", value: "contact" },
          { name: "Item", value: "item" },
          { name: "Remark", value: "remark" },
        ],
        availableTabs: [
          { label: "Pending", value: "pending" },
          { label: "Ready To Notify", value: "ready_to_notify" },
          { label: "Completed", value: "completed" },
          { label: "All", value: "all" },
        ],
        selectedTabIndex: 0,
        newPage: 0,
      },
      loadingBookings: false,
      timerStopTyping: null,
      pagination: {
        current_page: null,
        records_per_page: null,
        total_records: null,
        total_pages: null,
      },
      dialogRemoveBooking: {
        show: false,
        id: "",
        errorMessage: "",
        loading: false,
      },
      dialogAddBooking: {
        date: null,
        customer_name: null,
        agent_name: null,
        contact_details: null,
        contact_details_2: null,
        remark: null,
        show: false,
        id: "",
        errorMessage: "",
        loading: false,
        items: [
          {
            id: null,
            item: null,
            quantity: 1,
            code: null,
          },
        ],
        suggestions: [],
        idCounter: 0,
      },
      dialogEditBooking: {
        id: null,
        date: null,
        customer_name: null,
        agent_name: null,
        contact_details: null,
        contact_details_2: null,
        remark: null,
        show: false,
        errorMessage: "",
        loading: false,
        items: [
          {
            id: null,
            item: null,
            quantity: null,
            code: null,
            notified: null,
          },
        ],
        suggestions: [],
      },
      dialogToggleOngoing: {
        show: false,
        data: null,
        itemData: null,
        loading: false,
        errorMessage: "",
      },
      showFilterCount: false,
    };
  },
  created() {
    this.userType = localStorage.getItem("user_type")
  },
  mounted: function () {
    this.getBooking();
  },
  methods: {
    isCreatedLessThan1HourAgo(booking) {
      // Check if created_at is less than 1 hour ago
      const created_at = moment(booking.created_at);
      const now = moment();
      const diff = now.diff(created_at, "hours");

      if (diff < 1) {
        return true;
      }

      return false;
    },
    onPageChange: function ($event) {
      this.inputs.newPage = $event.page;
      this.getBooking();
    },
    onTabChange: function ($event) {
      this.inputs.selectedTabIndex = $event.index;
      this.getBooking();
    },
    searchTypeChanged: function () {
      if (this.inputs.search_data != "") {
        this.getBooking();
      }
    },
    getBooking: async function () {
      this.loadingBookings = true;

      const params = new URLSearchParams();
      params.append("order_by_field", "date");
      params.append("order_by_direction", "DESC");

      params.append(
        "filter",
        this.inputs.availableTabs[this.inputs.selectedTabIndex].value
      );
      params.append("page", this.inputs.newPage + 1);

      if (this.inputs.search_data != "") {
        params.append("search_data", this.inputs.search_data);
        params.append("search_type", this.inputs.search_type.value);
      }

      await axios({
        method: "GET",
        url: "customerbooking",
        params: params,
      }).then(
        (result) => {
          this.pagination.current_page =
            result.data.customer_bookings.current_page;
          this.pagination.records_per_page =
            result.data.customer_bookings.per_page;
          this.pagination.total_records = result.data.customer_bookings.total;
          this.pagination.total_pages = result.data.customer_bookings.last_page;

          this.customer_bookings = result.data.customer_bookings.data;
        },
        (error) => {
          console.log(error.response.data);
        }
      );
      this.loadingBookings = false;
    },
    showRemoveBookingDialog: function (id) {
      this.dialogRemoveBooking.show = true;
      this.dialogRemoveBooking.id = id;
    },
    removeBooking: function () {
      this.dialogRemoveBooking.loading = true;
      axios({
        method: "DELETE",
        url: "customerbooking",
        data: {
          id: this.dialogRemoveBooking.id.toString(),
        },
      }).then(
        () => {
          this.dialogRemoveBooking.loading = false;
          this.dialogRemoveBooking.show = false;
          this.dialogRemoveBooking.id = "";
          this.getBooking();
        },
        (error) => {
          this.dialogRemoveBooking.errorMessage = error.response.data.message;
          this.dialogRemoveBooking.loading = false;
        }
      );
    },
    // -------------------------- Dialog Add Booking --------------------------
    showAddBookingDialog: function () {
      this.dialogAddBooking.show = true;
      this.dialogAddBooking.date = new Date();
      this.dialogAddBooking.customer_name = "";
      this.dialogAddBooking.agent_name = "";
      this.dialogAddBooking.contact_details = "";
      this.dialogAddBooking.contact_details_2 = "";
      this.dialogAddBooking.remark = "";
      this.dialogAddBooking.items = [
        {
          id: 0,
          item: null,
          code: null,
          quantity: 1,
          display_count: 0,
          customer_wants_display: false,
        },
      ];
      this.dialogAddBooking.idCounter = 0;
    },
    removeAddBookingItem: function (id) {
      const index = this.dialogAddBooking.items.findIndex(item => item.id === id);

      if (index == -1) {
        return;
      }

      this.dialogAddBooking.items.splice(index, 1);
      this.dialogAddBooking.items = [...this.dialogAddBooking.items];
    },
    dialogAddItem: function () {
      this.dialogAddBooking.idCounter = this.dialogAddBooking.idCounter + 1;
      const newItemId = "generated-" + this.dialogAddBooking.idCounter;
      this.dialogAddBooking.items.push({
        id: newItemId,
        code: null,
        item: null,
        quantity: 1,
        display_count: 0,
        customer_wants_display: false,
      });
    },
    onRowEditSave(event) {
      let { newData, index } = event;
      this.dialogAddBooking.items[index] = newData;
    },
    generateSuggestions: function (event) {
      axios({
        method: "GET",
        url: "items/simple?search=" + event.query,
      }).then(
        (result) => {
          this.dialogAddBooking.suggestions = result.data.items.map((item) => {
            return item.CODE + " - " + item.DESCRIPTION;
          });
        },
        (error) => {
          console.log(error.response.data);
        }
      );
    },
    addBooking: function () {
      this.dialogAddBooking.loading = true;

      //Item to code
      this.dialogAddBooking.items.forEach((item) => {
        if (item.item != null) {
          item.code = item.item.substring(0, 6);
        }
      });

      axios({
        method: "POST",
        url: "customerbooking",
        data: {
          date: convertPrimeVueCalendar(this.dialogAddBooking.date),
          customer_name: this.dialogAddBooking.customer_name,
          agent_name: this.dialogAddBooking.agent_name,
          contact_details: this.dialogAddBooking.contact_details,
          contact_details_2: this.dialogAddBooking.contact_details_2,
          remark: this.dialogAddBooking.remark,
          items: this.dialogAddBooking.items,
        },
      }).then(
        () => {
          this.dialogAddBooking.loading = false;
          this.dialogAddBooking.show = false;
          this.getBooking();
        },
        (error) => {
          if (error.response && error.response.data.message) {
            this.dialogAddBooking.errorMessage = error.response.data.message;
          } else {
            this.dialogAddBooking.errorMessage = "Server error.";
          }
          this.dialogAddBooking.loading = false;
        }
      );
    },

    // -------------------------- Edit Booking --------------------------
    showEditBookingDialog: function (data) {
      this.dialogEditBooking.loading = false;
      this.dialogEditBooking.show = true;
      this.dialogEditBooking.id = data.id;
      this.dialogEditBooking.date = data.date;
      this.dialogEditBooking.customer_name = data.customer_name;
      this.dialogEditBooking.agent_name = data.agent_name;
      this.dialogEditBooking.contact_details = data.contact_details;
      this.dialogEditBooking.contact_details_2 = data.contact_details_2;
      this.dialogEditBooking.remark = data.remark;
      this.dialogEditBooking.items = [...data.items];

      this.dialogEditBooking.items.forEach((item, index) => {
        this.dialogEditBooking.items[index].index = index;
        this.dialogEditBooking.items[index].item =
          item.code + " - " + item.description;
      });
    },
    dialogEditGenerateSuggestions: function (event) {
      axios({
        method: "GET",
        url: "items/simple?search=" + event.query,
      }).then(
        (result) => {
          this.dialogEditBooking.suggestions = result.data.items.map((item) => {
            return item.CODE + " - " + item.DESCRIPTION;
          });
        },
        (error) => {
          console.log(error.response.data);
        }
      );
    },
    showToggleOngoingDialog: function (data, itemData) {
      this.dialogToggleOngoing.data = data;
      this.dialogToggleOngoing.itemData = itemData;
      this.dialogToggleOngoing.loading = false;
      this.dialogToggleOngoing.show = true;
      this.dialogToggleOngoing.errorMessage = "";
    },
    dialogEditAddItem: function () {
      const newIndex = this.dialogEditBooking.items.length;
      this.dialogEditBooking.items.push({
        index: newIndex,
        item: null,
        quantity: 1,
        notified: false,
        id: null,
        display_count: 0,
        customer_wants_display: false,
      });
    },
    dialogEditDeleteItem: function (data) {
      // Remove items from array
      this.dialogEditBooking.items = this.dialogEditBooking.items.filter(item => item.index !== data.index);

      // Reset index
      this.dialogEditBooking.items.forEach((item, index) => {
        item.index = index;
      });

      this.dialogEditBooking.items = [...this.dialogEditBooking.items];
    },
    editBooking: function () {
      this.dialogEditBooking.loading = true;

      //Item to code
      this.dialogEditBooking.items.forEach((item) => {
        if (item.item != null) {
          item.code = item.item.substring(0, 6);
        }
      });

      // Filter items, remove items with no code
      this.dialogEditBooking.items = this.dialogEditBooking.items.filter(item => item.code != null);
      axios({
        method: "POST",
        url: "customerbooking/update",
        data: {
          id: this.dialogEditBooking.id,
          date: convertPrimeVueCalendar(this.dialogEditBooking.date),
          customer_name: this.dialogEditBooking.customer_name,
          agent_name: this.dialogEditBooking.agent_name,
          contact_details: this.dialogEditBooking.contact_details,
          contact_details_2: this.dialogEditBooking.contact_details_2,
          remark: this.dialogEditBooking.remark,
          items: this.dialogEditBooking.items,
        },
      }).then(
        () => {
          this.dialogEditBooking.loading = false;
          this.dialogEditBooking.show = false;
          this.getBooking();
        },
        (error) => {
          if (error.response && error.response.data.message) {
            this.dialogEditBooking.errorMessage = error.response.data.message;
          } else {
            this.dialogEditBooking.errorMessage = "Server error.";
          }
          this.dialogEditBooking.loading = false;
        }
      );
    },
    // -----------------------------------------------------------------------


    toggleNotified: function () {
      this.dialogToggleOngoing.loading = true;

      // const data = this.dialogToggleOngoing.data;
      const itemData = this.dialogToggleOngoing.itemData;

      // const itemsFromData = [...data.items];
      const dataToPost = {
        id: itemData.id,
        notified: !itemData.notified,
      };

      // dataToPost.items.forEach((item, index) => {
      //   if (item.code == itemData.code) {
      //     item.loading = true;
      //     item = { ...item };
      //     item.notified = !item.notified;
      //     dataToPost.items[index] = item;
      //   }
      // });

      axios({
        method: "POST",
        url: "customerbooking/toggleItemNotified",
        data: dataToPost,
      }).then(
        () => {
          this.dialogToggleOngoing.loading = false;
          this.dialogToggleOngoing.show = false;
          this.getBooking();
        },
        (error) => {
          if (error.response && error.response.data.message) {
            this.dialogToggleOngoing.errorMessage = error.response.data.message;
          } else {
            this.dialogToggleOngoing.errorMessage = "Server error.";
          }
          this.dialogToggleOngoing.loading = false;
        }
      );
    },
    textChanged: function () {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.getBooking();
      }, 400);
    },
    contactWhatsappFilter: function (contactNum) {
      // Remove special characters
      contactNum = contactNum.replace(/[^0-9]/g, "");

      // If first char is not 6, add a 6
      if (contactNum.charAt(0) != "6") {
        contactNum = "6".concat(contactNum);
      }

      // Add a plus sign to the front
      contactNum = "+".concat(contactNum);

      return contactNum;
    },
    showTimeAgoFromTimestamp: function (timestamp) {
      return moment(timestamp).fromNow();
    },
    showDateFromTimestamp: function (timestamp) {
      return moment(new Date(timestamp)).format("DD-MMM-YY");
    },
  }
};
</script>

<style scoped lang="scss">
.readyToNotify {
  background: yellow;
}

.only-display-available {
  background: #f9cf87
}

.green-notified {
  font-weight: bold;
  color: rgb(28, 151, 73);
}

.orange-ongoing {
  font-weight: bold;
  color: #ca850d;
}

table.items-table {
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #00000032;
    padding: 2px 6px;
  }
}

.nowrap {
  overflow: hidden;
  white-space: nowrap;
}
</style>
