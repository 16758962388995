<template>
    <Button @click="router.back()" label="Back" severity="secondary" icon="pi pi-arrow-left" outlined> </Button>
    <div class="flex mt-3 gap-3 align-items-center" style="height: 30px;">
        <div class="text-4xl font-medium">Refill Group</div>

        <Button v-if="!isLoading" icon="pi pi-pencil" @click="showModifyRefillGroupDialog()" style="height: 30px;" label="Modify" severity="secondary"
            raised> </Button>
        <Button v-if="!isLoading" @click="showCombineGroupsDialog" label="Combine with another refill group"
            icon="pi pi-arrow-down-left-and-arrow-up-right-to-center" severity="info" style="height: 30px;"> </Button>
    </div>

    <div v-if="!isLoading" class="card mt-3">
        <div class="mb-2 text-lg font-medium">Group Name</div>
        <div> {{ refillGroup.name }}</div>

        <div class="mt-5 mb-2 text-lg font-medium">Group Description</div>
        <div> {{ refillGroup.description }}</div>

        <div class="mb-5 text-lg font-medium">Team Name</div>
        <div> {{ refillGroup.team.name }}</div>

        <div class="mt-5 mb-2 text-lg font-medium">Status</div>
        <div> {{ refillGroup.is_active ? 'Active' : 'Inactive' }} </div>


        <div class="flex gap-3 mt-5 mb-2 align-items-center">
            <div class="text-lg font-medium">Items</div>
            <Button @click="router.push({
                path: '/refill-groups/add-items', query: {
                    refillGroupId: route.params.id
                }
            })" label="Add Items" icon="pi pi-plus" style="height: 35px;"> </Button>
        </div>
        <!-- Data Table -->
        <DataTable ref="dt" :value="refillGroup.items" responsiveLayout="scroll" class="p-datatable-sm"
            :loading="isLoading" sortMode="multiple" scrollable dataKey="id" scrollHeight="calc(100vh - 300px)"
            size="small" var="line">
            <Column field="ST_ITEM_CODE" header="Code" :sortable="true" headerStyle="width:6rem"> </Column>
            <Column field="DESCRIPTION" header="Description" :sortable="true"> </Column>
            <Column field="STOCKGROUP" header="Stock Group" :sortable="true" headerStyle="width:12rem"> </Column>
            <Column field="BALSQTY" header="Bal. Qty" :sortable="true" headerStyle="width:7rem"> </Column>
        </DataTable>
    </div>
    <div v-else class="card mt-3">
        <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
    </div>

    <Dialog v-model:visible="dialogCombineGroups.show" modal header="Combine Refill Group" :style="{ width: '45rem' }">
        <div class="mb-4">

            <div class="mt-2 mb-2 text-lg font-medium">Group to combine with</div>
            <Dropdown v-model="dialogCombineGroups.selectedGroup" :options="dialogCombineGroups.groups"
                optionLabel="name" placeholder="Select a group" />
        </div>

        <!-- Error message -->
        <div v-if="dialogCombineGroups.errorMessage" class="mt-2 text-red-600">{{
            dialogCombineGroups.errorMessage
        }}</div>

        <!-- Return message -->
        <div v-if="dialogCombineGroups.returnMessage" class="mt-2 text-red-700 font-medium">
            <div>
                {{
                    dialogCombineGroups.returnMessage
                }}
            </div>
            <div class="mt-3">
                WARNING - THIS CANNOT BE UNDONE!
            </div>
        </div>



        <div class="flex gap-3 w-100 justify-content-end">
            <Button label="Cancel" class="p-button-raised" severity="secondary"
                @click="dialogCombineGroups.show = false" :loading="dialogCombineGroups.isLoading" />
            <Button :label="dialogCombineGroups.firstLoad ? 'Save' : 'Confirm'" class="p-button-raised"
                @click="saveDialogCombineGroup" :loading="dialogCombineGroups.isLoading" />
        </div>
    </Dialog>


    <!-- Show Modify Dialog -->
    <Dialog v-model:visible="dialogModifyRefillGroup.show" modal header="Modify Refill Group"
        :style="{ width: '45rem' }">
        <div class="mb-4">
            <div class="mt-3 mb-2 text-lg font-medium">Active</div>
            <InputSwitch v-model="dialogModifyRefillGroup.is_active" />

            <div class="mt-2 mb-2 text-lg font-medium">Team</div>
            <Dropdown v-model="dialogModifyRefillGroup.selectedTeam" :options="dialogModifyRefillGroup.teams"
                optionLabel="name" placeholder="Select a team" />

            <div class="mt-3 mb-2 text-lg font-medium">Group Name</div>
            <InputText v-model="dialogModifyRefillGroup.name" type="text" />

            <div class="mt-3 mb-2 text-lg font-medium">Group Description</div>
            <InputText v-model="dialogModifyRefillGroup.description" type="text" />

        </div>

        <!-- Error message -->
        <div v-if="dialogModifyRefillGroup.errorMessage" class="mt-2 text-red-600">{{
            dialogModifyRefillGroup.errorMessage
            }}</div>

        <div class="flex gap-3 w-100 justify-content-end">
            <Button label="Cancel" class="p-button-raised" severity="secondary"
                @click="dialogModifyRefillGroup.show = false" :loading="dialogModifyRefillGroup.isLoading" />
            <Button label="Save" class="p-button-raised" @click="saveDialogModifyRefillGroup"
                :loading="dialogModifyRefillGroup.isLoading" />
        </div>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';

const refillGroup = ref({});
const isLoading = ref(false);

const route = useRoute();
const router = useRouter();

const getRefillGroup = async () => {
    isLoading.value = true;

    await axios({
        method: "GET",
        url: "refill-groups/" + route.params.id,
    }).then(
        (result) => {
            refillGroup.value = result.data.refill_group;
        },
        (error) => {
            console.log("ERROR GETTING REFILL GROUP");
            console.log(error.response.data);
        }
    );

    isLoading.value = false;
}

const dialogCombineGroups = ref({
    show: false,
    isLoading: false,
    errorMessage: null,
    selectedGroup: null,
    groups: [],
});

const showCombineGroupsDialog = async () => {
    dialogCombineGroups.value.show = true;
    dialogCombineGroups.value.selectedGroup = null;
    dialogCombineGroups.value.errorMessage = null;
    dialogCombineGroups.value.returnMessage = null;
    dialogCombineGroups.value.groups = [];
    dialogCombineGroups.value.firstLoad = true;
    dialogCombineGroups.value.isLoading = true;
    await axios({
        method: "GET",
        url: "refill-groups",
    }).then(
        (result) => {
            dialogCombineGroups.value.groups = result.data.refill_groups;

            // Remove current refill group from list
            dialogCombineGroups.value.groups = dialogCombineGroups.value.groups.filter(group => group.id != route.params.id);
        },
        (error) => {
            console.log("ERROR GETTING REFILL GROUPS");
            console.log(error.response.data);
        }
    );
    dialogCombineGroups.value.isLoading = false;
}

const saveDialogCombineGroup = async (confirmed = false) => {
    dialogCombineGroups.value.errorMessage = null;
    dialogCombineGroups.value.returnMessage = null;
    console.log(confirmed);

    dialogCombineGroups.value.isLoading = true;

    if (dialogCombineGroups.value.selectedGroup == null) {
        dialogCombineGroups.value.errorMessage = "Please select a group to combine with";
        dialogCombineGroups.value.isLoading = false;
        return;
    }

    await axios({
        method: "post",
        url: "refill-groups/" + refillGroup.value.id + "/combine",
        data: {
            target_refill_group_id: dialogCombineGroups.value.selectedGroup.id,
            confirmed: !dialogCombineGroups.value.firstLoad
        },
    }).then(
        (result) => {
            if (dialogCombineGroups.value.firstLoad) {
                dialogCombineGroups.value.firstLoad = false;
                dialogCombineGroups.value.returnMessage = result.data.message;
                dialogCombineGroups.value.isLoading = false;
                return;
            }
            dialogCombineGroups.value.show = false;
            getRefillGroup();
        },
        (error) => {
            dialogCombineGroups.value.errorMessage = error.response.data.message;
            console.log("ERROR COMBINING GROUPS");
            console.log(error.response.data);
        }
    );
    dialogCombineGroups.value.isLoading = false;
}

// --------------- Modify Refill Group Dialog ---------------
const dialogModifyRefillGroup = ref({
    isLoading: false,
    show: false,
    name: "",
    description: "",
    is_active: true,
});

const showModifyRefillGroupDialog = () => {
    dialogModifyRefillGroup.value = {
        show: true,
        id: refillGroup.value.id,
        name: refillGroup.value.name,
        description: refillGroup.value.description,
        is_active: refillGroup.value.is_active,
        errorMessage: "",
        teams: [],
        selectedTeam: refillGroup.value.team,
    };

    // Get teams
    axios({
        method: "GET",
        url: "teams",
    }).then(
        (result) => {
            dialogModifyRefillGroup.value.teams = result.data.teams;
        },
        (error) => {
            console.log("ERROR GETTING TEAMS");
            console.log(error.response.data);
        }
    );
}

const saveDialogModifyRefillGroup = async () => {

    dialogModifyRefillGroup.value.isLoading = true;
    dialogModifyRefillGroup.value.errorMessage = "";

    await axios({
        method: "PUT",
        url: "refill-groups/" + dialogModifyRefillGroup.value.id,
        data: {
            name: dialogModifyRefillGroup.value.name,
            description: dialogModifyRefillGroup.value.description,
            is_active: dialogModifyRefillGroup.value.is_active,
            team_id: dialogModifyRefillGroup.value.selectedTeam.id,
        },
    }).then(
        () => {
            dialogModifyRefillGroup.value.show = false;
            getRefillGroup();
        },
        (error) => {
            dialogModifyRefillGroup.value.errorMessage = error.response.data.message;
            console.log(error.response.data);
        }
    );

    dialogModifyRefillGroup.value.isLoading = false;
}
// -------------------------------------------------------------

getRefillGroup();
</script>

<style scoped></style>