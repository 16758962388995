<template>
    <div class="flex gap-3 mb-5">
        <div class="text-4xl font-medium">Shelfing Update</div>
    </div>

    <DataTable :value="lines" responsiveLayout="scroll" :loading="isLoading" size="small">
        <template #header>
            <Button type="button" icon="pi pi-plus" label="Update Shelfing" class="p-button-outlined my-2 mr-4"
                @click="showAddShelfingDirectUpdateDialog" />
        </template>
        <template #footer>
            <Paginator :rows="pagination.records_per_page" :totalRecords="pagination.total_records"
                @page="onPageChange($event)">
            </Paginator>
        </template>

        <Column field="created_at" header="Timestamp">
            <template #body="{ data }">
                <div :title="moment(data.created_at)">
                    {{ moment(data.created_at).fromNow() }}
                </div>
            </template>
        </Column>
        <Column field="item_code" header="Item Code"></Column>
        <Column field="action" header="Action"></Column>
        <Column field="shelf" header="Shelf"></Column>
        <Column field="agent_code" header="Agent Code"></Column>
        <Column field="agent_description" header="Agent Description"></Column>
        <Column field="synced_at" header="Status">
            <template #body="{ data }">
                <div v-if="data.synced_at === null">New</div>
                <div v-else class="text-green-600">Synced</div>
            </template>
        </Column>
        <Column field="" header="">
            <template #body="{ data }">
                <Button v-if="data.synced_at === null" @click="showDeleteShelfingDirectUpdateDialog(data)"
                    label="Remove" severity="danger" style="height:26px;">
                </Button>
            </template>
        </Column>
    </DataTable>

    <!-- Show Add New Update Shelf -->
    <Dialog v-model:visible="dialogAddShelfingDirectUpdate.show" modal header="Update Shelfing"
        :style="{ width: '55rem' }">

        <div class="mt-3 mb-2 text-lg font-medium"> <i class="pi pi-user" style="font-size: 1rem"></i> Agent</div>

        <Dropdown ref="agentDropdownRef" id="agent-dropdown" v-model="dialogAddShelfingDirectUpdate.selectedAgent"
            :options="dialogAddShelfingDirectUpdate.agents" optionLabel="DESCRIPTION" placeholder="Select an Agent"
            class="w-full md:w-7" autoOptionFocus selectOnFocus />

        <div class="mt-4 mb-2 text-lg font-medium">Item</div>
        <AutoComplete v-model="dialogAddShelfingDirectUpdate.selectedItem"
            @change="dialogAddShelfingDirectUpdateItemSelected" autoOptionFocus
            :suggestions="dialogAddShelfingDirectUpdate.itemSuggestions" optionLabel="label"
            @complete="dialogAddShelfingDirectUpdateGenerateItemSuggestions($event)" forceSelection class="w-full" />

        <div v-if="typeof dialogAddShelfingDirectUpdate.selectedItem === 'object' && dialogAddShelfingDirectUpdate.selectedItem !== null"
            class="mt-5">
            <div class="text-lg font-medium">Shelves</div>
            <div class="flex mt-2 gap-2">
                <InputText type="text" v-model="dialogAddShelfingDirectUpdate.inputShelf" class="w-7rem"
                    @keypress.enter="addShelf()" />
                <Button :disabled="dialogAddShelfingDirectUpdate.inputShelf == ''" @click="addShelf()"
                    label="Add Shelf"></Button>
            </div>

            <div class="flex mt-2 gap-3">
                <DataTable :value="dialogAddShelfingDirectUpdate.shelfingsOriginal" responsiveLayout="scroll"
                    size="small" :rowStyle="shelfingRowStyle" class="w-6">
                    <Column field="shelf" header="Shelf (Original)"></Column>

                    <Column field="" header="">
                        <template #body="{ data }">
                            <div class="flex" style="height:28px;">
                                <Button v-if="!data.isRemoved" severity="danger" @click="removeShelf(data.shelf)"
                                    label="Remove" style="height: auto; margin: 0px;"></Button>
                                <Button v-else severity="warning" @click="cancelRemoveShelf(data.shelf)"
                                    label="Cancel Remove" style="height: auto; margin: 0px;"></Button>
                            </div>
                        </template>
                    </Column>
                </DataTable>

                <DataTable :value="dialogAddShelfingDirectUpdate.shelfingsUpdated" responsiveLayout="scroll"
                    size="small" :rowStyle="shelfingRowStyle" class="w-6">
                    <Column field="shelf" header="Shelf (Updated)">
                        <template #body="{ data }">
                            <div class="flex align-items-center" style="height:28px;">{{ data.shelf }}</div>
                        </template>
                    </Column>
                    <Column field="" header="">
                        <template #body="{ data }">
                            <div class="flex" style="height:28px;">
                                <Button v-if="data.isAdded" severity="warning" @click="cancelAddShelf(data.shelf)"
                                    label="Cancel Add" style="height: auto; margin: 0px;"></Button>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <div class="mt-4">
            <!-- Error message -->
            <div v-if="dialogAddShelfingDirectUpdate.errorMessage" class="mt-2 text-red-600">{{
                dialogAddShelfingDirectUpdate.errorMessage
            }}</div>

            <div class="flex gap-3 w-100 justify-content-end mt-2">
                <Button label="Cancel" class="p-button-raised" severity="secondary"
                    @click="dialogAddShelfingDirectUpdate.show = false"
                    :loading="dialogAddShelfingDirectUpdate.isLoading" />
                <Button label="Save" class="p-button-raised" @click="saveDialogAddShelfingDirectUpdate"
                    :loading="dialogAddShelfingDirectUpdate.isLoading" :disabled="!dialogAddHasUpdatedLines" />
            </div>
        </div>
    </Dialog>

    <!-- Dialog Delete Direct Shelfing Line Update -->
    <Dialog v-model:visible="dialogDeleteShelfingDirectUpdate.show" modal header="Delete Shelfing Update"
        :style="{ width: '30rem' }">
        <div class="mt-3 mb-2 text-lg font-medium">Are you sure you want to delete this shelfing update?</div>
        <div class="mt-4">
            <div class="flex gap-3 w-100 justify-content-end mt-2">
                <Button label="Cancel" class="p-button-raised" severity="secondary"
                    @click="dialogDeleteShelfingDirectUpdate.show = false"
                    :loading="dialogDeleteShelfingDirectUpdate.isLoading" />
                <Button label="Delete" class="p-button-raised" @click="saveDialogDeleteShelfingDirectUpdate"
                    severity="danger" :loading="dialogDeleteShelfingDirectUpdate.isLoading" />
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import { ref, nextTick, computed } from 'vue'
import axios from 'axios';
import { errorMessages } from '@vue/compiler-sfc';
import moment from 'moment';

const isLoading = ref(false);
const lines = ref([]);

const pagination = ref({
    current_page: null,
    records_per_page: null,
    total_records: null,
    total_pages: null,
});

const getShelfingDirectUpdateLines = async (page = 1) => {
    isLoading.value = true;

    await axios({
        method: "GET",
        url: "shelfing/direct-update/lines",
        params: {
            page: page,
        },
    }).then(
        (result) => {
            pagination.value.current_page = result.data.lines.current_page;
            pagination.value.records_per_page = result.data.lines.per_page;
            pagination.value.total_records = result.data.lines.total;
            pagination.value.total_pages = result.data.lines.last_page;
            lines.value = result.data.lines.data;
        },
        (error) => {
            isLoading.value = false;
            console.log("ERROR GETTING - Shelfing Direct Update Lines");
            console.log(error.response.data);
        }
    );
    isLoading.value = false;
}


const onPageChange = ($event) => {
    getShelfingDirectUpdateLines($event.page + 1);
};


// ------------------------------
const shelfingRowStyle = (data) => {
    if (data.isRemoved) {
        return { backgroundColor: "#FFC4D1" };
    }

    if (data.isAdded) {
        return { backgroundColor: "#D7ECDE" };
    }
};

const dialogAddShelfingDirectUpdate = ref({
    show: false,
    isLoading: false,
    errorMessage: "",
    selectedItem: null,
    selectedAgent: null,
    itemSuggestions: [],
    agents: null,
    agentsSuggestions: [],
    shelfingsUpdated: [],
    shelfingsOriginal: [],
    inputShelf: "",
});


const agentDropdownRef = ref();

const showAddShelfingDirectUpdateDialog = async () => {
    dialogAddShelfingDirectUpdate.value = {
        show: true,
        isLoading: false,
        selectedItem: null,
        selectedAgent: null,
        itemSuggestions: [],
        agents: null,
        agentsSuggestions: [],
        shelfingsUpdated: [],
        shelfingsOriginal: [],
        inputShelf: "",
    };

    await axios({
        method: "GET",
        url: "sql/agents",
    }).then(
        (result) => {
            dialogAddShelfingDirectUpdate.value.agents = result.data.agents;

        },
        (error) => {
            console.log(error.response.data);
        }
    );

    await nextTick();
    const element = document.getElementById("agent-dropdown");
    if (element) {
        element.getElementsByTagName("span")[0].focus();
    }
}

// -------------

const dialogAddHasUpdatedLines = computed(()=>{
    console.log("sup")
    const hasShelfingsAdded = dialogAddShelfingDirectUpdate.value.shelfingsUpdated.some((shelfing) => {
        return shelfing.isAdded;
    });

    const hasShelfingsRemoved = dialogAddShelfingDirectUpdate.value.shelfingsOriginal.some((shelfing) => {
        return shelfing.isRemoved;
    });

    return hasShelfingsAdded || hasShelfingsRemoved;
})

const saveDialogAddShelfingDirectUpdate = () => {
    const lines = [];

    dialogAddShelfingDirectUpdate.value.shelfingsUpdated.forEach((shelfing) => {
        if (shelfing.isAdded) {
            lines.push({
                item_code: dialogAddShelfingDirectUpdate.value.selectedItem.CODE,
                action: "ADD",
                shelf: shelfing.shelf,
                agent_code: dialogAddShelfingDirectUpdate.value.selectedAgent.CODE,
                agent_description: dialogAddShelfingDirectUpdate.value.selectedAgent.DESCRIPTION,
            });
        }
    });

    dialogAddShelfingDirectUpdate.value.shelfingsOriginal.forEach((shelfing) => {
        if (shelfing.isRemoved) {
            lines.push({
                item_code: dialogAddShelfingDirectUpdate.value.selectedItem.CODE,
                action: "REMOVE",
                shelf: shelfing.shelf,
                agent_code: dialogAddShelfingDirectUpdate.value.selectedAgent.CODE,
                agent_description: dialogAddShelfingDirectUpdate.value.selectedAgent.DESCRIPTION,
            });
        }
    });

    dialogAddShelfingDirectUpdate.value.isLoading = true;

    axios({
        method: "POST",
        url: "shelfing/direct-update/lines",
        data: {
            lines: lines,
        },
    }).then(
        (result) => {
            dialogAddShelfingDirectUpdate.value.isLoading = false;
            dialogAddShelfingDirectUpdate.value.show = false;
            getShelfingDirectUpdateLines();
        },
        (error) => {
            dialogAddShelfingDirectUpdate.value.isLoading = false;
            console.log(error.response.data);
        }
    );


}

const dialogAddShelfingDirectUpdateItemSelected = () => {
    // Check if the selectedItem is an object
    if (typeof dialogAddShelfingDirectUpdate.value.selectedItem !== "object" || dialogAddShelfingDirectUpdate.value.selectedItem === null || dialogAddShelfingDirectUpdate.value.selectedItem.SHELF === null) {
        return;
    }

    const shelfingString = dialogAddShelfingDirectUpdate.value.selectedItem.SHELF;

    dialogAddShelfingDirectUpdate.value.shelfing = [];

    // For loop after separate by comma, remove spaces
    const shelfingArray = shelfingString.split(",").map((shelfing) => {
        return shelfing.trim();
    });

    shelfingArray.forEach((shelf) => {
        // Check if the shelfing is not empty
        if (shelf !== "") {
            // Add the shelfing to the shelfings array
            dialogAddShelfingDirectUpdate.value.shelfingsOriginal.push({
                shelf: shelf,
                isRemoved: false,
            });

            dialogAddShelfingDirectUpdate.value.shelfingsUpdated.push({
                shelf: shelf,
                isAdded: false,
            });
        }
    });

}


const dialogAddShelfingDirectUpdateGenerateItemSuggestions = (event) => {
    axios({
        method: "GET",
        url: "items/simple?search=" + event.query + '&include_fields=SHELF',
    }).then(
        (result) => {
            dialogAddShelfingDirectUpdate.value.itemSuggestions = result.data.items.map((item) => {

                return {
                    ...item, label:
                        item.CODE + " - " + item.DESCRIPTION
                };
            });
        },
        (error) => {
            console.log(error.response.data);
        }
    );
}

const dialogAddShelfingDirectUpdateGenerateAgentSuggestions = (event) => {
    const query = event.query;
    if (dialogAddShelfingDirectUpdate.value.agents == null) {
        return;
    }

    // Check dialogAddShelfingDirectUpdate.value.agents, show suggestions as the event query
    dialogAddShelfingDirectUpdate.value.agentsSuggestions = dialogAddShelfingDirectUpdate.value.agents.filter((agent) => {
        return agent.DESCRIPTION.toLowerCase().includes(query.toLowerCase());
    });
}

const cancelAddShelf = (shelf) => {
    // Remove the shelf from the shelfingsUpdated
    dialogAddShelfingDirectUpdate.value.shelfingsUpdated = dialogAddShelfingDirectUpdate.value.shelfingsUpdated.filter((shelfing) => {
        return shelfing.shelf !== shelf;
    });
}

const cancelRemoveShelf = (shelf) => {
    dialogAddShelfingDirectUpdate.value.shelfingsUpdated.push({
        shelf: shelf,
        isAdded: false,
    });

    dialogAddShelfingDirectUpdate.value.shelfingsOriginal = dialogAddShelfingDirectUpdate.value.shelfingsOriginal.map((shelfing) => {
        if (shelfing.shelf === shelf) {
            return {
                ...shelfing,
                isRemoved: false,
            };
        }

        return shelfing;
    });
}

const removeShelf = (shelf) => {
    dialogAddShelfingDirectUpdate.value.shelfingsUpdated = dialogAddShelfingDirectUpdate.value.shelfingsUpdated.filter((shelfing) => {
        return shelfing.shelf !== shelf;
    });

    dialogAddShelfingDirectUpdate.value.shelfingsOriginal = dialogAddShelfingDirectUpdate.value.shelfingsOriginal.map((shelfing) => {
        if (shelfing.shelf === shelf) {
            return {
                ...shelfing,
                isRemoved: true,
            };
        }

        return shelfing;
    });

}

const addShelf = () => {
    dialogAddShelfingDirectUpdate.value.errorMessage = "";

    const shelf = dialogAddShelfingDirectUpdate.value.inputShelf;

    if (shelf === "") {
        return;
    }

    // Check if shelfingsOriginal already has the shelf
    const isShelfExistInOriginal = dialogAddShelfingDirectUpdate.value.shelfingsOriginal.some((shelfing) => {
        return shelfing.shelf.toLowerCase() === shelf.toLowerCase();
    });

    if (isShelfExistInOriginal) {
        dialogAddShelfingDirectUpdate.value.errorMessage = "Shelf already exists";
        return;
    }

    // Check if shelfingsUpdated already has the shelf
    const isShelfExistInUpdated = dialogAddShelfingDirectUpdate.value.shelfingsUpdated.some((shelfing) => {
        return shelfing.shelf.toLowerCase() === shelf.toLowerCase();
    });

    if (isShelfExistInUpdated) {
        dialogAddShelfingDirectUpdate.value.errorMessage = "Shelf already exists";
        return;
    }


    dialogAddShelfingDirectUpdate.value.shelfingsUpdated.push({
        shelf: shelf,
        isAdded: true,
    });

    dialogAddShelfingDirectUpdate.value.inputShelf = "";
}

// ------------------------------

// ------------------ DELETE ------------------
const dialogDeleteShelfingDirectUpdate = ref({
    show: false,
    isLoading: false,
    errorMessage: "",
    line: null,
});

const showDeleteShelfingDirectUpdateDialog = (line) => {
    dialogDeleteShelfingDirectUpdate.value = {
        show: true,
        isLoading: false,
        errorMessage: "",
        line: line,
    };
}

const saveDialogDeleteShelfingDirectUpdate = () => {
    dialogDeleteShelfingDirectUpdate.value.isLoading = true;

    axios({
        method: "DELETE",
        url: "shelfing/direct-update/lines/" + dialogDeleteShelfingDirectUpdate.value.line.id,
    }).then(
        (result) => {
            dialogDeleteShelfingDirectUpdate.value.isLoading = false;
            dialogDeleteShelfingDirectUpdate.value.show = false;
            getShelfingDirectUpdateLines();
        },
        (error) => {
            dialogDeleteShelfingDirectUpdate.value.isLoading = false;
            console.log(error.response.data);
        }
    );
}
// --------------------------------------------

getShelfingDirectUpdateLines();
</script>

<style scoped></style>