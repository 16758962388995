<template>
    <div class="flex gap-3">
        <div class="text-4xl font-medium">Claim Categories</div>
        <Button label="New" @click="showAddNewCategoryDialog"></Button>
    </div>

    <!-- Data Table -->
    <DataTable ref="dt" :value="categories" responsiveLayout="scroll" class="mt-6 p-datatable-sm" :loading="isLoading"
        sortMode="multiple" scrollable dataKey="id" scrollHeight="750px" size="small" var="line">

        <Column field="name" header="Name" :sortable="true" headerStyle="width:15rem"> </Column>
        <Column field="description" header="Description" :sortable="true"> </Column>
        <Column header="Actions" :sortable="true" headerStyle="width:25rem">
            <template #body="{ data }">
                <div class="flex gap-3">
                    <Button @click="showModifyCategoryDialog(data)" style="height: 30px;" label="Modify"
                        severity="secondary" raised />
                </div>
            </template>
        </Column>
    </DataTable>

    <!-- Show Add New Dialog -->
    <Dialog v-model:visible="dialogAddNewCategory.show" modal header="Add New Category" :style="{ width: '42rem' }">

        <div class="mt-2 mb-2 text-lg font-medium">Name</div>
        <InputText class="" v-model="dialogAddNewCategory.name" type="text" />

        <div class="mt-4 mb-2 text-lg font-medium">Description</div>
        <InputText class="mb-4" v-model="dialogAddNewCategory.description" type="text" />

        <!-- Error message -->
        <div v-if="dialogAddNewCategory.errorMessage" class="mt-2 text-red-600">{{
            dialogAddNewCategory.errorMessage
        }}</div>

        <div class="flex gap-3 w-100 justify-content-end">
            <Button label="Cancel" class="p-button-raised" severity="secondary" @click="dialogAddNewCategory.show = false"
                :loading="dialogAddNewCategory.isLoading" />
            <Button label="Save" class="p-button-raised" @click="saveDialogAddNewCategory"
                :loading="dialogAddNewCategory.isLoading" />
        </div>
    </Dialog>

    <!-- Show Modify Dialog -->
    <Dialog v-model:visible="dialogModifyCategory.show" modal header="Modify Team" :style="{ width: '45rem' }">
        <div class="mb-4">
            <div class="mt-2 mb-2 text-lg font-medium">Team Name</div>
            <InputText v-model="dialogModifyCategory.name" type="text" />

            <div class="mt-3 mb-2 text-lg font-medium">Team Description</div>
            <InputText v-model="dialogModifyCategory.description" type="text" />

        </div>

        <!-- Error message -->
        <div v-if="dialogModifyCategory.errorMessage" class="mt-2 text-red-600">{{
            dialogModifyCategory.errorMessage
            }}</div>

        <div class="flex gap-3 w-100 justify-content-end">
            <Button label="Cancel" class="p-button-raised" severity="secondary" @click="dialogModifyCategory.show = false"
                :loading="dialogModifyCategory.isLoading" />
            <Button label="Save" class="p-button-raised" @click="saveDialogModifyCategory"
                :loading="dialogModifyCategory.isLoading" />
        </div>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
const isLoading = ref(false);

const categories = ref([]);

const getCategories = async () => {
    isLoading.value = true;

    await axios({
        method: "GET",
        url: "claims/categories",
    }).then(
        (result) => {
            categories.value = result.data.categories;
            console.log(result.data.categories);
        },
        (error) => {
            isLoading.value = false;
            console.log("ERROR GETTING");
            console.log(error.response.data);
        }
    );
    isLoading.value = false;
}

const dialogAddNewCategory = ref({
    isLoading: false,
    show: false,
    name: "",
    description: "",
    errorMessage: "",
});

const showAddNewCategoryDialog = () => {
    dialogAddNewCategory.value = {
        isLoading: false,
        show: true,
        name: "",
        description: "",
        errorMessage: "",
    };
}

const saveDialogAddNewCategory = async () => {
    dialogAddNewCategory.value.isLoading = true;
    dialogAddNewCategory.value.errorMessage = "";

    await axios({
        method: "POST",
        url: "claims/categories",
        data: {
            name: dialogAddNewCategory.value.name,
            description: dialogAddNewCategory.value.description
        },
    }).then(
        () => {
            dialogAddNewCategory.value.show = false;
            getCategories();
        },
        (error) => {
            dialogAddNewCategory.value.errorMessage = error.response.data.message;
            console.log(error.response.data);
        }
    );

    dialogAddNewCategory.value.isLoading = false;
}

// --------------- Modify Category ---------------

const dialogModifyCategory = ref({
    isLoading: false,
    show: false,
    name: "",
    description: "",
    errorMessage: "",
});

const showModifyCategoryDialog = (line) => {
    dialogModifyCategory.value = {
        isLoading: false,
        show: true,
        id: line.id,
        name: line.name,
        description: line.description,
        errorMessage: "",
    };
}


const saveDialogModifyCategory = async () => {
    dialogModifyCategory.value.isLoading = true;
    dialogModifyCategory.value.errorMessage = "";

    await axios({
        method: "PATCH",
        url: "claims/categories/" + dialogModifyCategory.value.id,
        data: {
            name: dialogModifyCategory.value.name,
            description: dialogModifyCategory.value.description
        },
    }).then(
        () => {
            dialogModifyCategory.value.show = false;
            getCategories();
        },
        (error) => {
            dialogModifyCategory.value.errorMessage = error.response.data.message;
            console.log(error.response.data);
        }
    );

    dialogModifyCategory.value.isLoading = false;
}

getCategories();



</script>

<style scoped></style>