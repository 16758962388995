export function convertPrimeVueCalendar(calendarDateTime) {
    if(calendarDateTime == null) return null;

    const timezoneOffsetHours = Math.abs(new Date().getTimezoneOffset()) / 60;

    const newDate = new Date(calendarDateTime);
    newDate.setHours(timezoneOffsetHours);

    // Return yyyy-mm-dd
    return newDate.toISOString().split("T")[0];
}