export function contactWhatsappFilter(contactNum) {
    // Remove special characters
    contactNum = contactNum.replace(/[^0-9]/g, "");

    // If first char is not 6, add a 6
    if (contactNum.charAt(0) != "6") {
        contactNum = "6".concat(contactNum);
    }

    // Add a plus sign to the front
    contactNum = "+".concat(contactNum);

    return contactNum;
}