<template>
    <div class="flex gap-3">
        <div class="text-4xl font-medium">Add Items To Group</div>
        <div v-if="refillGroup" class="text-4xl font-medium">({{
            refillGroup.name }})</div>
    </div>

    <div v-if="route.query.refillGroupId == null" class="mt-5 mb-2 flex flex-column gap-2 w-fit">
        <div class="text-lg font-medium">Add Items to Group:</div>
        <Dropdown v-model="inputs.selectedRefillGroup" :options="refillGroups" optionLabel="name"
            placeholder="Select a Refill Group" @change="getRefillGroup"> </Dropdown>
    </div>
    <div class="mt-5 mb-2 flex flex-column gap-2 w-fit">
        <div class="text-lg font-medium">Show items already with Refill Group</div>
        <InputSwitch v-model="filters.show_items_with_refill_group" @change="searchItems" />
    </div>

    <div class="mt-5 mb-2 flex flex-column gap-2 w-fit">
        <div class="text-lg font-medium">Show discontinued (XXX --- ) items</div>
        <InputSwitch v-model="filters.show_discontinued_items" @change="searchItems" />
    </div>
    <!-- Select Items by -->
    <div class="flex align-items-start mt-5 gap-5">
        <div class="flex md:align-items-center select-methods flex-column md:flex-row gap-2 md:gap-0">
            <h5 class="m-0 mr-3">Search Items By:</h5>

            <Dropdown v-model="inputs.selectedItemSelectionMethod" :options="itemSelectionMethods" optionLabel="name"
                placeholder="Select a method" />

            <!-- Item Search -->
            <div v-if="inputs.selectedItemSelectionMethod &&
                inputs.selectedItemSelectionMethod.name == 'Item Search'
            " class="flex justify-content-left flex-column sm:flex-row md:ml-2">
                <IconField class="">
                    <InputIcon class="pi pi-search" />
                    <InputText v-model="inputs.searchItemField" placeholder="Search" style="width: 100%"
                        @keydown="textChanged" />
                </IconField>
            </div>

        </div>
        <div v-if="inputs.selectedItemSelectionMethod &&
            inputs.selectedItemSelectionMethod.name == 'Stock Group'">
            <SelectStockGroup v-model:selectedStockGroups="inputs.selectedStockGroups" :preselectedStockGroups="[]" />
        </div>
    </div>
    <div class="flex mt-8 mb-6 gap-5">
        <div class="w-6">
            <div class="flex gap-3 mb-3 align-items-center" style="height: 30px;">
                <div class="text-lg font-medium">Item Search Result</div>
                <Button v-if="selectedItemSearchResult.length > 1" @click="addSelectedItemsToGroup()"
                    :label="'Add Selected (' + selectedItemSearchResult.length + ' items)'" raised
                    style="height: 30px;" />
            </div>
            <DataTable :value="itemSearchResult" responsiveLayout="scroll" class="p-datatable-sm"
                :loading="isLoading.itemSearchResult" sortMode="multiple" scrollable dataKey="CODE" scrollHeight="600px"
                size="small" var="line" v-model:selection="selectedItemSearchResult" selectionMode="multiple"
                :metaKeySelection="true">
                <Column field="CODE" header="Code" :sortable="true" headerStyle="width:6rem"> </Column>
                <Column field="DESCRIPTION" header="Description" :sortable="true"> </Column>
                <Column field="STOCKGROUP" header="Stock Group" :sortable="true" headerStyle="width:9rem"> </Column>
                <Column field="BALSQTY" header="Bal. Qty" :sortable="true" headerStyle="width:7rem"> </Column>
                <Column field="" header="Action">
                    <template #body="{ data }">
                        <Button :disabled="isSelected(data)" @click="addItemToGroup(data)" label="Add" raised
                            style="height: 30px;" />
                    </template>
                </Column>
            </DataTable>
        </div>

        <!-- Data Table -->
        <div v-if="refillGroup" class="w-6">
            <div class="flex gap-3 mb-3 align-items-center" style="height: 30px;">
                <div class="text-lg font-medium">Items in Refill Group ({{ refillGroup.name }})</div>
            </div>

            <DataTable :value="refillGroup.items" responsiveLayout="scroll" class="p-datatable-sm"
                :loading="isLoading.refillGroup" sortMode="multiple" scrollable dataKey="ST_ITEM_CODE"
                scrollHeight="600px" size="small" var="line">
                <Column field="ST_ITEM_CODE" header="Code" :sortable="true" headerStyle="width:6rem"> </Column>
                <Column field="DESCRIPTION" header="Description" :sortable="true"> </Column>
                <Column field="STOCKGROUP" header="Stock Group" :sortable="true" headerStyle="width:9rem"> </Column>
                <Column field="BALSQTY" header="Bal. Qty" :sortable="true" headerStyle="width:7rem"> </Column>
                <Column field="" header="Action">
                    <template #body="{ data }">
                        <Button @click="removeItemFromGroup(data)" label="Remove" raised style="height: 30px;"
                            severity="danger" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import axios from 'axios';
import { useRoute } from 'vue-router';
import SelectStockGroup from '@/components/SelectStockGroup.vue';

const route = useRoute();

const isLoading = ref({
    refillGroups: false,
    refillGroup: false,
    itemSearchResult: false,
});

const filters = ref({
    show_items_with_refill_group: false,
    show_discontinued_items: false,
})

const refillGroup = ref(null);
const refillGroups = ref(null);

let textInputTimer = null;

const textChanged = () => {
    clearTimeout(textInputTimer);
    textInputTimer = setTimeout(() => {
        searchItems();
    }, 400);
};

const itemSelectionMethods = [
    { name: "Item Search", value: "item_search" },
    { name: "Stock Group", value: "stock_groups" },
];

const inputs = ref({
    selectedRefillGroup: null,
    selectedStockGroups: [],
    selectedItemSelectionMethod: { name: "Item Search", value: "item_search" },
    searchItemField: "",
})

const itemSearchResult = ref([]);
const selectedItemSearchResult = ref([]);

const isSelected = (item) => {
    return refillGroup.value.items.find((groupItem) => groupItem.ST_ITEM_CODE == item.CODE);
}

const getRefillGroups = async () => {
    isLoading.value.refillGroups = true;

    await axios({
        method: "GET",
        url: "refill-groups",
    }).then(
        (result) => {
            refillGroups.value = result.data.refill_groups;

            // Check if there is a refillGroupId in the query
            if (route.query.refillGroupId) {
                inputs.value.selectedRefillGroup = refillGroups.value.find((group) => group.id == route.query.refillGroupId);
                getRefillGroup();
            }
        },
        (error) => {
            console.log("ERROR GETTING REFILL GROUPS");
            console.log(error.response.data);
        }
    );
    isLoading.value.refillGroups = false;
}

const getRefillGroup = async () => {
    isLoading.value.refillGroup = true;

    await axios({
        method: "GET",
        url: "refill-groups/" + inputs.value.selectedRefillGroup.id,
    }).then(
        (result) => {
            refillGroup.value = result.data.refill_group;
        },
        (error) => {
            console.log("ERROR GETTING REFILL GROUP");
            console.log(error.response.data);
        }
    );

    isLoading.value.refillGroup = false;
}

watch(
    () => inputs.value.selectedStockGroups,
    () => {
        searchItems();
    }
)

const searchItems = async () => {
    isLoading.value.itemSearchResult = true;

    const params = {
        search_method: inputs.value.selectedItemSelectionMethod.value,
        show_items_with_refill_group: filters.value.show_items_with_refill_group ? 1 : 0,
        show_discontinued_items: filters.value.show_discontinued_items ? 1 : 0,
    };

    if (inputs.value.selectedItemSelectionMethod.name == "Item Search") {
        if (inputs.value.searchItemField.length < 2) {
            itemSearchResult.value = [];
            isLoading.value.itemSearchResult = false;
            return;
        }

        params.search = inputs.value.searchItemField
    }

    if (inputs.value.selectedItemSelectionMethod.name == "Stock Group") {
        if (inputs.value.selectedStockGroups.length <= 0) {
            return;
        }

        params.stock_groups = inputs.value.selectedStockGroups.map((group) => group.STOCKGROUP);
    }

    await axios({
        method: "GET",
        url: "refill-groups/item-search",
        params: params
    }
    ).then(
        (result) => {
            itemSearchResult.value = result.data.items;
        },
        (error) => {
            console.log("ERROR GETTING ITEMS");
            console.log(error.response.data);
        }
    );

    isLoading.value.itemSearchResult = false;
}

const addItemToGroup = async (itemData) => {
    await axios({
        method: "POST",
        url: "refill-groups/" + refillGroup.value.id + "/items",
        data: {
            items: [
                {
                    code: itemData.CODE,
                }
            ]
        }
    }).then(
        (result) => {
            if (result.status == 201) {
                if (!filters.value.show_items_with_refill_group) {
                    // Remove the item from the search result by splicing it
                    const index = itemSearchResult.value.findIndex((item) => item.CODE == itemData.CODE);

                    if (index > -1) {
                        itemSearchResult.value.splice(index, 1);
                    }
                }
                selectedItemSearchResult.value = [];
                getRefillGroup();
            }
        },
        (error) => {
            console.log("ERROR ADDING ITEM");
            console.log(error.response.data);
        }
    );
}

const addSelectedItemsToGroup = async () => {
    // Filter only items not already in the group
    let items = selectedItemSearchResult.value.filter((selectedItem) => {
        return !refillGroup.value.items.find((groupItem) => groupItem.ST_ITEM_CODE == selectedItem.CODE);
    });

    // Map to code only
    items = items.map((item) => {
        return {
            code: item.CODE,
        }
    });

    await axios({
        method: "POST",
        url: "refill-groups/" + refillGroup.value.id + "/items",
        data: {
            items: items
        }
    }).then(
        (result) => {
            if (result.status == 201) {
                if (!filters.value.show_items_with_refill_group) {
                    // Remove the item from the search result by splicing it
                    selectedItemSearchResult.value.forEach((selectedItem) => {
                        const index = itemSearchResult.value.findIndex((item) => item.CODE == selectedItem.CODE);

                        if (index > -1) {
                            itemSearchResult.value.splice(index, 1);
                        }
                    });
                }

                selectedItemSearchResult.value = [];
                getRefillGroup();
            }
        },
        (error) => {
            console.log("ERROR ADDING ITEM");
            console.log(error.response.data);
        }
    );
}

const removeItemFromGroup = async (itemData) => {
    await axios({
        method: "DELETE",
        url: "refill-groups/" + refillGroup.value.id + "/items",
        data: {
            items: [
                {
                    code: itemData.ST_ITEM_CODE,
                }
            ]
        }
    }).then(
        (result) => {
            getRefillGroup();
        },
        (error) => {
            console.log("ERROR ADDING ITEM");
            console.log(error.response.data);
        }
    );
}

getRefillGroups();

</script>

<style scoped></style>