<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios'
import moment from 'moment';

const router = useRouter();
const route = useRoute();

const purchaseRequests = ref([]);
const isLoading = ref({
    purchaseRequests: false,
});

const query = ref({
    page: 1
});
// ------ Paginator START ------ //
const pagination = ref({
    current_page: 1,
    last_page: 1,
    per_page: 100,
    total: 0,
});

const onPageChange = (event) => {
    query.value.page = event.page + 1;
    router.push({ path: route.fullPath, query: { ...query.value } });
    getPurchaseRequest();
}
// ------ Paginator End ------ //


// ------ DataTable filter START ------ //
const filters = ref();

const initFilters = () => {
    filters.value = {
        date: { value: null, matchMode: 'dateIs' },
        ets: { value: null, matchMode: 'dateIs' },
        eta: { value: null, matchMode: 'dateIs' },
        doc_no: { value: null, matchMode: 'contains' },
        supplier: { value: null, matchMode: 'contains' },
        agent: { value: null, matchMode: 'contains' },
        description: { value: null, matchMode: 'contains' },
    }
}

initFilters();

const onFilter = ($event) => {
    query.value.page = 1;

    // Update query from filters
    let filters = "";

    for (const [filterkey, filterobject] of Object.entries($event.filters)) {
        if (filterobject.value === null) {
            continue;
        }

        if (filters !== "") {
            filters += ",";
        }

        if (filterkey == 'date' || filterkey == 'ets' || filterkey == 'eta') {
            filters += filterkey + "-" + moment(filterobject.value).format("YYYYMMDD") + "-" + filterobject.matchMode;
            continue;
        }

        filters += filterkey + "-" + filterobject.value + "-" + filterobject.matchMode;
    }
    query.value.filters = filters;
    if (filters === "") filters = undefined;

    router.push({ path: route.fullPath, query: { ...query.value } });
    getPurchaseRequest();
};

// ------ DataTable sort START ------ //
const multiSortMeta = ref([]);

const onSort = ($event) => {
    query.value.page = 1;

    // Pluck field string from multiSortMeta
    const sortBy = $event.multiSortMeta.map((item) => {
        const order = item.order === 1 ? "asc" : "desc";
        return item.field + "-" + order;
    });

    query.value.sortBy = sortBy.join(",");
    router.push({ path: route.fullPath, query: { ...query.value } });
    getPurchaseRequest();
}

// ------ DataTable sort END ------ //

const clearFiltersAndSort = () => {
    query.value.page = 1;
    query.value.filters = undefined;
    query.value.sortBy = undefined;
    updateDataTableSortAndFilterFromQuery();

    router.push({ path: route.fullPath, query: { ...query.value } });
    getPurchaseRequest();
}

const updateDataTableSortAndFilterFromQuery = () => {
    multiSortMeta.value = [];
    if (query.value.sortBy !== undefined && query.value.sortBy !== '') {
        const sortFields = query.value.sortBy.split(',');
        sortFields.forEach((sortField) => {
            const sortArray = sortField.split('-');

            const order = sortArray[1] === 'asc' ? 1 : -1;
            multiSortMeta.value.push({ field: sortArray[0], order: order });
        });
        multiSortMeta.value = [...multiSortMeta.value];
    }

    initFilters();
    if (query.value.filters !== undefined && query.value.filters !== '') {
        const filtersFromQuery = query.value.filters.split(',');
        filtersFromQuery.forEach((filterFromQuery) => {
            const filterArray = filterFromQuery.split('-');
            filters.value[filterArray[0]].value = filterArray[1];
            filters.value[filterArray[0]].matchMode = filterArray[2];

            if (filterArray[0] == 'date' || filterArray[0] == 'ets' || filterArray[0] == 'eta') {
                filters.value[filterArray[0]].value = moment(filterArray[1], "YYYYMMDD").toDate();
            }
        });
    }
}

const getPurchaseRequest = () => {
    axios({
        method: "GET",
        url: "purchase-requests",
        params: query.value,
    }).then(
        (result) => {
            // Iterate all and calculate fulfilled percentage
            result.data.purchase_requests.data.forEach((purchaseRequest) => {
                if (purchaseRequest.total_qty != 0) {
                    purchaseRequest.fulfilled_percentage = Math.floor((purchaseRequest.fulfilled_qty / purchaseRequest.total_qty) * 1000) / 10;
                }
                else {
                    purchaseRequest.fulfilled_percentage = 0;
                }


                // Add date

                purchaseRequest.ets = moment(purchaseRequest.date).add('days', purchaseRequest.days_to_manufacture).format("YYYY-MM-DD");
                purchaseRequest.eta = moment(purchaseRequest.date).add('days', purchaseRequest.days_for_shipment + purchaseRequest.days_to_manufacture).format("YYYY-MM-DD");

                pagination.value.current_page = result.data.purchase_requests.current_page;
                pagination.value.last_page = result.data.purchase_requests.last_page;
                pagination.value.per_page = result.data.purchase_requests.per_page;
                pagination.value.total = result.data.purchase_requests.total;
            });

            purchaseRequests.value = result.data.purchase_requests.data;
        },
        (error) => {
            console.log(error.response.data);
        }
    );
}

onMounted(() => {
    // Update query from route
    query.value = { ...route.query };

    updateDataTableSortAndFilterFromQuery();

    getPurchaseRequest();
})
</script>

<template>
    <div>
        <div class="flex align-items-center gap-3 mb-3">
            <div class="text-4xl font-medium">Purchase Requests</div>
            <router-link to="/purchase-requests/new">
                <Button label="New"></Button>
            </router-link>
        </div>
        <DataTable :value="purchaseRequests" responsiveLayout="scroll" lazy :loading="isLoading.purchaseRequests"
            size="small" @sort="onSort" sortMode="multiple" :multiSortMeta="multiSortMeta" v-model:filters="filters"
            @filter="onFilter($event)" filterDisplay="row" resizableColumns columnResizeMode="fit">
            <template #header>
                <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined @click="clearFiltersAndSort()" />
            </template>
            <template #footer>
                <Paginator :rows="pagination.per_page" :totalRecords="pagination.total" @page="onPageChange($event)"
                    :first="(pagination.current_page - 1) * pagination.per_page">
                </Paginator>
            </template>
            <Column field="date" header="Date" :sortable="true" filterField="date" dataType="date"
                :showFilterMenu="false" style="width: 12rem">
                <template #filter="{ filterModel, filterCallback }">
                    <Calendar v-model="filterModel.value" @date-select="filterCallback()" dateFormat="yy-mm-dd"
                        placeholder="yyyy-mm-dd" mask="9999/99/99" class="p-column-filter" />
                </template>
            </Column>
            <Column field="doc_no" header="Doc No" :sortable="true" filterField="doc_no" :showFilterMenu="false"
                style="width: 12rem">
                <template #body="{ data }">
                    <div class="flex gap-2">
                        {{ data.doc_no }}
                        <div v-if="data.is_deleted" class="font-bold text-red-600">Deleted</div>
                    </div>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                        class="p-column-filter" placeholder="Search" />
                </template>
            </Column>
            <Column field="supplier" header="Supplier" :sortable="true" filterField="supplier" :showFilterMenu="false">
                <template #body="{ data }">
                    {{ data.COMPANYNAME }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                        class="p-column-filter" placeholder="Search" />
                </template>
            </Column>
            <Column field="description" header="Description" :sortable="true" filterField="description"
                :showFilterMenu="false">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                        class="p-column-filter" placeholder="Search" />
                </template>
            </Column>
            <Column field="agent" header="Agent" :sortable="true" filterField="agent" :showFilterMenu="false"
                style="width: 12rem">
                <template #body="{ data }">
                    {{ data.agent_name }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                        class="p-column-filter" placeholder="Search" />
                </template>
            </Column>
            <Column field="ets" header="ETS" :sortable="true" class="white-space-nowrap" filterField="ets"
                dataType="date" :showFilterMenu="false" style="width: 12rem">
                <template #filter="{ filterModel, filterCallback }">
                    <Calendar v-model="filterModel.value" @date-select="filterCallback()" dateFormat="yy-mm-dd"
                        placeholder="yyyy-mm-dd" mask="9999/99/99" class="p-column-filter" />
                </template>
            </Column>
            <Column field="eta" header="ETA" :sortable="true" class="white-space-nowrap" filterField="eta"
                dataType="date" :showFilterMenu="false" style="width: 12rem">
                <template #filter="{ filterModel, filterCallback }">
                    <Calendar v-model="filterModel.value" @date-select="filterCallback()" dateFormat="yy-mm-dd"
                        placeholder="yyyy-mm-dd" mask="9999/99/99" class="p-column-filter" />
                </template>
            </Column>
            <Column field="fulfilled_percentage" header="Fulfilled" :sortable="true">
                <template #body="{ data }">
                    {{ data.fulfilled_percentage }} %
                </template>
            </Column>
            <Column field="" header="Actions">
                <template #body="{ data }">
                    <router-link :to="'/purchase-requests/' + data.doc_no">
                        <div class="font-medium">
                            View
                        </div>
                    </router-link>
                </template>
            </Column>
        </DataTable>
    </div>
</template>