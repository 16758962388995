<template>
    <div class="flex gap-3 align-items-center col-12">
        <div class="text-3xl font-medium">Ecommerce - Orders</div>
        <Button v-if="!notification.on" type="button" icon="pi pi-bell" label="Turn On Notification"
            class="p-button-outlined" @click="turnOnNofications" />

        <Button v-if="notification.on && notification.acknowledged == true" type="button" icon="pi pi-refresh"
            class="p-button-outlined mr-2" @click="getOrders()" :loading="isLoading.orders"
            label="{{notification.refreshTimeLeft}}">
            <span v-if="!isLoading.orders" class="pi pi-refresh p-button-icon p-button-icon-left"></span>
            <span v-else class="pi pi-spin pi-spinner p-button-icon p-button-icon-left"></span>
            <span class="p-button-label">Refresh ({{ notification.refreshTimeLeft }}s)</span>
        </Button>


        <Button v-if="notification.on && notification.acknowledged == false" type="button" class="p-button-success mr-2"
            @click="acknowledgeNotification" label="{{notification.resendNotificationTimeLeft}}">
            <span class="pi pi-check p-button-icon p-button-icon-left"></span>
            <span class="p-button-label">OK ({{ notification.resendNotificationTimeLeft }}s)</span>
        </Button>
    </div>
    <div class="col-12">
        <div class="card">
            <div class="flex justify-content-start mb-3 gap-1">
                <Dropdown v-model="selectedAction" :options="actions" optionLabel="name" placeholder="Bulk Actions"
                    class="w-full md:w-24rem" />
                <Button :disabled="selectedOrders.length == 0 || selectedAction == null" label="Apply"
                    @click="updateOrdersStatus"> </Button>
            </div>


            <DataTable v-model:selection="selectedOrders" :value="orders" :loading="isLoading.orders"
                class="p-datatable-md hover:bg-gray-100">
                <template #header>
                    <!-- Search -->
                    <div class="flex flex-row">
                        <div class="flex flex-column">

                            <IconField class="mr-1 mb-1">
                                <InputIcon class="pi pi-search" />
                                <InputText v-model="query.search" style="width: 100%" @keydown="onSearchTextChanged" />
                            </IconField>

                        </div>
                    </div>
                    <!-- Tabs -->
                    <TabMenu :model="availableTabs" @tab-change="onTabChange" v-model:activeIndex="selectedTabIndex" />
                </template>
                <Column selectionMode="multiple" headerStyle="width: 2.5%"></Column>
                <Column header="Order ID" style="width: 10%">
                    <template #body="{ data }">
                        <div>{{ data.id_custom }}</div>
                        <div>{{ data.id_internal }}</div>
                        <div class="customer-note-tooltip" v-if="data.customer_order_note" v-tooltip.bottom="{
            value: data.customer_order_note,
            pt: {
                arrow: {
                    style: {
                        borderBottomColor: 'var(--primary-color)'
                    }
                },
                text: 'bg-primary font-medium'
            }
        }">Note</div>
                    </template>
                </Column>
                <Column header="Date" style="width: 10%">
                    <template #body="{ data }">
                        <div :title="dateFormat(data.created_at)">
                            {{ dateTimeFormat(data.created_at) }}
                        </div>
                    </template>
                </Column>
                <Column header="Customer" style="width: 10%">
                    <template #body="{ data }">
                        <div class="font-medium">{{ data.shipping_address?.first_name + ' ' +
            data.shipping_address?.last_name }}</div>

                        <div v-if="data.shipping_address?.company_name" class="font-medium mt-1">{{
            data.shipping_address?.company_name }}</div>
                        <div class="mt-1">{{ data.user.name }}</div>
                        <a v-if="data.shipping_address"
                            :href="'https://wa.me/' + contactWhatsappFilter(data.shipping_address?.phone)"
                            target="_blank" class="font-medium mt-1">{{ data.shipping_address?.phone }}</a>
                    </template>

                </Column>
                <Column header="Shipping" style="width: 10%">
                    <template #body="{ data }">
                        <div class="">{{ data.shipping_address?.sub_subdivision }}</div>
                        <div class="mt-2">{{ data.shipping_method_label }}</div>
                    </template>
                </Column>
                <Column header="Payment" style="width: 15%">
                    <template #body="{ data }">
                        <div v-if="data.payments[0]">
                            {{ data.payments[0].payment_method_label }}
                        </div>
                    </template>
                </Column>

                <Column header="Status" style="width: 15%">
                    <template #body="{ data }">
                        <div :class="{
            'text-pink-600': data.status == 'Payment Failed',
            'text-orange-500': data.status == 'Pending Payment',
            'text-green-600': data.status == 'Payment Received'
        }" class="font-bold">
                            {{ data.status }}
                        </div>
                    </template>
                </Column>
                <Column header="Total" style="width: 15%">
                    <template #body="{ data }">
                        <div>
                            {{ 'RM ' + parseFloat(data.total_amount).toFixed(2) }}
                        </div>
                    </template>
                </Column>
                <Column>
                    <template #body="{ data }">
                        <router-link :to="'/ecommerce/orders/' + data.id_custom">View</router-link>
                    </template>
                </Column>

                <template #footer>
                    <Paginator :rows="rowsPerPage" :totalRecords="totalRecords" @page="onPageChange($event)"
                        :first="currentPage">
                    </Paginator>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
import router from '../../router';
import moment from "moment";
import Worker from "../../worker/countOneSecond.worker.js";
export default {
    data() {
        return {
            isLoading: { orders: false },
            orders: [],
            rowsPerPage: 25,
            totalRecords: 0,
            selectedOrders: [],
            orderQuantitySummary: {
                'Pending Payment': 0,
                'Payment Received': 0,
                'Order Confirmed': 0,
                'Packing': 0,
                'Ready For Collection': 0,
                'Completed': 0,
                'Cancelled': 0,
                'Shipped Out': 0,
            },
            actions: [
                { name: 'Change status to Pending Payment', code: 'Pending Payment' },
                { name: 'Change status to Payment Received', code: 'Payment Received' },
                { name: 'Change status to Order Confirmed', code: 'Order Confirmed' },
                { name: 'Change status to Packing', code: 'Packing' },
                { name: 'Change status to Ready For Collection/Shipped Out', code: 'Ready For Collection/Shipped Out' },
                { name: 'Change status to Completed', code: 'Completed' },
                { name: 'Change status to Cancelled', code: 'Cancelled' },
            ],
            selectedAction: null,
            selectedTab: { label: "All", value: "All", slug: 'all' },
            selectedTabIndex: 0,
            timer: null,
            query: {
                status: 'all',
                search: '',
                page: 1
            },
            currentPage: 1,
            notification: {
                on: false,
                last_order_created_at: null,
                refreshFrequency: 60,
                refreshTimeLeft: 0,
                acknowledged: true,
                resendNotificationFrequency: 30,
                resendNotificationTimeLeft: 0,
                timerWorker: null,
            },
        }
    },
    computed: {
        availableTabs() {
            const pendingPaymentTotal = this.orderQuantitySummary['Pending Payment'] ?? 0;
            const paymentReceivedTotal = this.orderQuantitySummary['Payment Received'] ?? 0;
            const shippedReadyTotal = (this.orderQuantitySummary['Ready For Collection'] ?? 0) + (this.orderQuantitySummary['Shipped Out'] ?? 0);
            const orderConfirmedTotal = this.orderQuantitySummary['Order Confirmed'] ?? 0;
            const packingTotal = this.orderQuantitySummary['Packing'] ?? 0;

            return [
                { label: "All", value: "All", slug: 'all' },
                { label: "Pending Payment (" + pendingPaymentTotal + ")", value: "Pending Payment", slug: "pending-payment" },
                { label: "Payment Received (" + paymentReceivedTotal + ")", value: "Payment Received", slug: "pending-received" },
                { label: "Order Confirmed (" + orderConfirmedTotal + ")", value: "Order Confirmed", slug: "order-confirmed" },
                { label: "Packing (" + packingTotal + ")", value: "Packing", slug: "packed" },
                { label: "Shipped/Ready (" + shippedReadyTotal + ")", value: "Shipped/Ready", slug: "shipped-ready" },
                { label: "Completed", value: "Completed", slug: "completed" },
                { label: "Cancelled", value: "Cancelled", slug: "cancelled" },
            ];
        }
    },
    beforeUnmount: function () {
        if (this.notification.timerWorker != null) {
            this.notification.timerWorker.terminate();
            this.notification.timerWorker = null;
        }
        if (this.notification.resendTimerWorker != null) {
            this.notification.resendTimerWorker.terminate();
            this.notification.resendTimerWorker = null;
        }
    },
    mounted: function () {
        // Get query params
        this.query = { ...this.$route.query };

        if (this.query.page == undefined) {
            this.query.page = 1;
        }

        this.currentPage = (this.query.page - 1) * this.rowsPerPage;

        // Find the tab index 
        if (this.query.status) {
            this.selectedTabIndex = this.availableTabs.findIndex((tab) => tab.slug == this.query.status);
        }

        this.getOrders();
    },
    methods: {
        turnOnNofications() {
            this.getOrders();

            if (Notification.permission !== "granted") {
                Notification.requestPermission();
            }

            new Notification("Notification Turned On", {
                body: "New orders will be notified.",
            });

            this.notification.on = true;
            this.notification.refreshTimeLeft = this.notification.refreshFrequency;

            this.notification.timerWorker = new Worker();

            // Update timer div with output from Web Worker
            this.notification.timerWorker.onmessage = () => {
                if (this.notification.acknowledged == true) {
                    if (this.notification.refreshTimeLeft <= 0) {
                        this.getOrders();
                    }
                    else if (this.isLoading.orders == false) {
                        this.notification.refreshTimeLeft -= 1;
                    }
                }
            };
        },
        sendNotification() {
            new Notification("New Order Received!", {
                body: "New order was received.",
            });

            this.notification.resendNotificationTimeLeft = this.notification.resendNotificationFrequency;
            this.notification.acknowledged = false;


            if (this.notification.resendTimerWorker != null) {
                this.notification.resendTimerWorker.terminate();
            }

            this.notification.resendTimerWorker = new Worker();

            this.notification.resendTimerWorker.onmessage = () => {
                if (this.notification.resendNotificationTimeLeft <= 0) {
                    this.sendNotification();
                } else {
                    this.notification.resendNotificationTimeLeft -= 1;
                }
            };
        },
        acknowledgeNotification() {
            this.notification.acknowledged = true;
            this.notification.refreshTimeLeft = this.notification.refreshFrequency;

            if (this.notification.resendTimerWorker != null) {
                this.notification.resendTimerWorker.terminate();
                this.notification.resendTimerWorker = null;
            }
        },
        onTabChange() {
            this.query.page = 1;
            this.query.status = this.availableTabs[this.selectedTabIndex].slug;
            this.selectedTab = this.availableTabs[this.selectedTabIndex];
            this.$router.push({ path: this.$route.fullPath, query: { ...this.query } });
            this.getOrders();
        },
        onPageChange($event) {
            this.query.page = $event.page + 1;
            this.$router.push({ path: this.$route.fullPath, query: { ...this.query } });
            this.getOrders();
        },
        onSearchTextChanged() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.query.page = 1;
                this.$router.push({ path: this.$route.fullPath, query: { ...this.query } });
                this.getOrders();
            }, 400);
        },
        getOrders: async function (isAutoRefresh = false) {
            this.isLoading.orders = true;
            this.selectedOrders = [];

            let url = '';

            if (isAutoRefresh) {
                url = process.env.VUE_APP_ECOMMERCE_API_URL + '/orders-admin?page=1';
            }
            else {
                const page = this.query.page;

                url = process.env.VUE_APP_ECOMMERCE_API_URL + '/orders-admin?page=' + (page);

                if (this.query.status && this.query.status != 'all') {
                    // Find the tab
                    let tab = this.availableTabs.find((tab) => {
                        return tab.slug == this.query.status;
                    });

                    url += '&status=' + tab.value;
                }

                if (this.query.search) {
                    url += '&q=' + this.query.search;
                }
            }

            await fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                }
            }).then(
                (result) => {

                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200) {
                        console.log("Error");
                        console.log(result);
                        return;
                    }

                    result.json().then((data) => {
                        this.rowsPerPage = data.orders.per_page;
                        this.orders = data.orders.data;
                        this.totalRecords = data.orders.total;

                        this.orderQuantitySummary = data.order_quantity_summary;
                        console.log(this.orderQuantitySummary);

                        if (this.notification.on && this.orders.length > 0) {
                            this.notification.refreshTimeLeft = this.notification.refreshFrequency;

                            // check if there is new order
                            if (this.orders[0].created_at != this.notification.last_order_created_at && this.notification.last_order_created_at != null) {
                                this.sendNotification();
                            }

                            this.notification.last_order_created_at = this.orders[0].created_at;
                        }
                    });
                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
            this.isLoading.orders = false;
        },
        dateTimeFormat(timestampString) {
            // If less than 24 hours ago, show hours ago
            if (moment().diff(moment(timestampString), 'hours') < 24) {
                return moment(timestampString).fromNow();
            }

            // If less than 7 days ago, show days ago
            if (moment().diff(moment(timestampString), 'days') < 7) {
                return moment(timestampString).fromNow();
            }

            // Display as format "dd MMM yyyy HH:mm"
            return moment(timestampString).format('DD MMM YYYY, HH:mm');
        },
        dateFormat(timestampString) {
            return moment(timestampString).format('DD MMM YYYY, HH:mm');

        },
        contactWhatsappFilter(number) {
            // Remove all non-digit characters and replace with empty string
            return number.replace(/\D/g, '');
        },
        async updateOrdersStatus() {
            const id_customs = this.selectedOrders.map(order => order.id_custom);
            const status = this.selectedAction.code;

            console.log({ id_customs, status })

            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/orders-admin/status', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    'id_customs': id_customs,
                    'status': status
                })
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200) {
                        console.log("Error");
                        console.log(result);
                        return;
                    }

                    result.json().then((data) => {
                        console.log(data)
                    });
                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
            this.getOrders();
        }
    },
};
</script>

<style scoped>
.customer-note-tooltip {
    margin-top: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 500;
    background-color: #fff176;
    cursor: pointer;
    width: fit-content;
    color: black;
}
</style>
