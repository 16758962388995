import axios from 'axios';

export default class ProductService {

	getProductsSmall() {
		return axios.get('data/products-small.json').then(res => res.data.data);
	}

	getProducts() {
		return axios.get('data/products.json').then(res => res.data.data);
	}

	getProductsWithOrdersSmall() {
		return axios.get('data/products-orders-small.json').then(res => res.data.data);
	}
	async getSuppliers() {
		try {
			const res = await axios.get(`items/suppliers`);
			return res.data.suppliers;
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async getStockGroups() {
		try {
			const res = await axios.get(`items/stock-groups`);
			return res.data.stockGroups;
		} catch (error) {
			console.log(error);
			return [];
		}
	}
}
