<template>
    <div class="flex gap-3 align-items-center">
        <div class="text-3xl">Ecommerce - Products</div>
        <router-link :to="'/ecommerce/products/add-new'"><Button label="Add New"></Button></router-link>
    </div>
    <div class="col-12">
        <div class="card">
            <DataTable lazy :value="products" :loading="isLoading.products" class="p-datatable-md hover:bg-gray-100"
                @sort="onSort($event)">
                <template #header>
                    <div class="flex flex-row">
                        <div class="flex flex-column">

                            <IconField class="mr-1 mb-1">
                                <InputIcon class="pi pi-search" />
                                <InputText v-model="query.search" style="width: 100%" @keydown="textChanged" />
                            </IconField>

                        </div>
                    </div>

                    <!-- Tabs -->
                    <TabMenu :model="availableTabs" @tab-change="onTabChange" v-model:activeIndex="selectedTabIndex" />

                    <div class="mt-3">
                        {{ this.total_records }} products found.
                    </div>
                </template>

                <!-- Image -->
                <Column header="" style="width: 8%">
                    <template #body="{ data }">
                        <div class="flex align-items-center" style="width: 60px;">
                            <img v-if="data.front_medias[0]" :src="getMostEfficientImage(data.front_medias[0], 80)"
                                width="60" style="object-fit:cover;" />
                        </div>
                    </template>
                </Column>
                <Column field="name" header="Product Name" style="width: 20%"></Column>
                <Column field="type" header="Type" style="width: 10%"></Column>
                <Column field="price_label" header="Price" style="width: 10%"></Column>

                <Column header="Categories" style="width: 10%">
                    <template #body="{ data }">
                        {{ categoriesLabel(data.categories) }}
                    </template>
                </Column>

                <Column header="SKU" style="width: 12%">
                    <template #body="{ data }">
                        {{ skuLabel(data.variants) }}
                    </template>
                </Column>

                <Column header="Status" field="status" style="width: 10%" sortable>
                    <template #body="{ data }">
                        {{ data.status }}
                    </template>
                </Column>
                <Column header="Actions" style="width: 10%">
                    <template #body="{ data }">
                        <router-link :to="'/ecommerce/products/' + data.slug">View</router-link>
                    </template>
                </Column>


                <template #footer>
                    <Paginator :rows="records_per_page" :totalRecords="total_records" @page="onPageChange($event)"
                        :first="currentPage">
                    </Paginator>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
import router from '../../router';
import moment from "moment";
export default {
    data() {
        return {
            isLoading: { products: false },
            products: [],
            records_per_page: 10,
            total_records: null,
            sortParams: "",
            currentPage: 0,
            availableTabs: [
                { label: "All", value: "All", slug: 'all' },
                { label: "Draft", value: "Draft", slug: "draft" },
                { label: "Published", value: "Published", slug: "published" },
            ],
            query: {
                page: 1,
                status: 'all',
            },
            selectedTabIndex: 0,
        }
    },
    mounted: function () {
        // Get query params
        this.query = { ...this.$route.query };

        if (this.query.page == undefined) {
            this.query.page = 1;
        }

        this.currentPage = (this.query.page - 1) * this.records_per_page;

        // Find the tab index 
        if (this.query.status) {
            this.selectedTabIndex = this.availableTabs.findIndex((tab) => tab.slug == this.query.status);
        }

        this.getProducts();
    },
    methods: {
        textChanged() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.$router.push({ path: this.$route.fullPath, query: { ...this.query } });
                this.getProducts();
            }, 400);
        },
        onTabChange() {
            this.query.page = 1;
            this.query.status = this.availableTabs[this.selectedTabIndex].slug;
            this.selectedTab = this.availableTabs[this.selectedTabIndex];
            this.$router.push({ path: this.$route.fullPath, query: { ...this.query } });
            this.getProducts();
        },
        onPageChange: function ($event) {
            this.query.page = $event.page + 1;
            this.$router.push({ path: this.$route.fullPath, query: { ...this.query } });
            this.getProducts();
        },
        getProducts: async function () {
            this.isLoading.products = true;

            let url = process.env.VUE_APP_ECOMMERCE_API_URL + '/products-admin';

            if (this.query.page) {
                url = url + '?page=' + this.query.page;
            }
            else {
                url = url + '?page=1';
            }

            if (this.query.search) {
                url = url + '&query=' + this.query.search;
            }

            if (this.query.status) {
                url = url + '&status=' + this.query.status;
            }

            if (this.sortParams) {
                url = url + '&sort_by=' + this.sortParams;
            }

            await fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                },
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200) {
                        console.log("Error");
                        console.log(result);
                        return;
                    }

                    result.json().then((data) => {
                        this.records_per_page = data.products.per_page;
                        this.total_records = data.products.total;
                        this.products = data.products.data;
                    });
                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
            this.isLoading.products = false;
        },
        dateTimeFormat(timestampString) {
            // If less than 24 hours ago, show hours ago
            if (moment().diff(moment(timestampString), 'hours') < 24) {
                return moment(timestampString).fromNow();
            }

            // If less than 7 days ago, show days ago
            if (moment().diff(moment(timestampString), 'days') < 7) {
                return moment(timestampString).fromNow();
            }

            // Display as format "dd MMM yyyy HH:mm"
            return moment(timestampString).format('DD MMM YYYY, HH:mm');
        },
        dateFormat(timestampString) {
            return moment(timestampString).format('DD MMM YYYY, HH:mm');

        },
        getMostEfficientImage(imageJsonData, width) {
            const originalWidth = imageJsonData.metadata_public.original_size.width
            const orginalUrl = imageJsonData.url

            // If original image size already less than size required, just return the image
            if (originalWidth <= width) {
                return orginalUrl
            }

            const alternativeSizes = imageJsonData.metadata_public.alternative_sizes

            for (let i = 0; i < alternativeSizes.length; i++) {
                if (alternativeSizes[i].width >= width) {
                    return alternativeSizes[i].url
                }
            }

            return orginalUrl; // If no image found, return the original image
        },
        categoriesLabel(categoriesArrayObject) {
            return categoriesArrayObject.map((category) => {
                return category.name;
            }).join(', ');
        },
        skuLabel(variantsArrayObject) {
            return variantsArrayObject.map((variant) => {
                return variant.sku;
            }).join(', ');
        },
        onSort(event) {
            this.sortParams = event.sortField + '-' + (event.sortOrder == 1 ? 'asc' : 'desc');
            this.getProducts();
        }
    },
};
</script>

<style scoped></style>
