<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h4>Users</h4>
        <DataTable :value="users" :loading="usersLoading">
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button type="button" icon="pi pi-user-plus" label="Add User" class="p-button-outlined mb-2"
                @click="openAddUserDialog" />
            </div>
          </template>
          <Column field="id" header="ID"></Column>
          <Column field="username" header="Username"></Column>
          <Column field="type" header="Type"></Column>
          <Column field="require_new_password" header="Require New Password"></Column>
          <Column>
            <template #body="{ data }">

              <Button v-if="data.type != 'admin'" label="Edit" class="p-button-primary mr-2"
                @click="openEditUserDialog(data)" />
              <Button v-if="data.type != 'admin'" label="Reset Password" icon="pi pi-refresh"
                class="p-button-primary mr-2" @click="openResetPasswordDialog(data)" />
              <Button v-if="data.type != 'admin'" label="Remove" icon="pi pi-trash" class="p-button-danger mr-2"
                @click="openRemoveDialog(data)" />
            </template>
          </Column>
        </DataTable>

        <!-- Remove user dialog -->
        <Dialog header="Remove User" v-model:visible="displayRemoveUserDialog" :style="{ width: '350px' }"
          :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Are you sure you want to proceed?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" @click="displayRemoveUserDialog = false" class="p-button-text" />
            <Button label="Yes" icon="pi pi-check" @click="removeUser" class="p-button-text" autofocus />
          </template>
        </Dialog>

        <!-- Reset Password Dialog -->
        <Dialog header="Reset Password" v-model:visible="displayResetPasswordDialog" :style="{ width: '350px' }"
          :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <div>
              <div class="mb-2">
                Resetting password of: {{ userToReset.username }}
              </div>
              <div>Are you sure you want to proceed?</div>
            </div>
          </div>
          <template #footer>
            <div v-if="!resetDone">
              <Button label="No" icon="pi pi-times" @click="displayResetPasswordDialog = false" class="p-button-text" />
              <Button label="Yes" icon="pi pi-check" @click="resetPassword" :loading="resetLoading"
                class="p-button-text" autofocus />
            </div>
            <div v-else>
              <div>New password: {{ resettedPassword }}</div>
            </div>
          </template>
        </Dialog>

        <!-- Add User Dialog -->
        <Dialog header="Add User" v-model:visible="displayAddUserDialog" :style="{ width: '350px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <div>

              <IconField class="w-full mb-2">
                <InputIcon class="pi pi-user" />
                <InputText type="text" placeholder="Username" name="username" v-model="userToAdd.username"
                  class="w-full" />
              </IconField>

              <IconField class="w-full">
                <InputIcon class="pi pi-lock" />
                <InputText type="password" placeholder="Password" name="password" v-model="userToAdd.password"
                  class="w-full" @keyup.enter="login" />
              </IconField>

              <Dropdown v-model="addUserInputs.userType" :options="addUserInputs.availableUserTypes"
                placeholder="Select" class="mt-4 h-full" />
              <div id="error-message" class="p-error mt-2">
                {{ addUserErrorMessage }}
              </div>
            </div>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" @click="displayAddUserDialog = false" :loading="addUserLoading"
              class="p-button-text" />
            <Button label="Yes" icon="pi pi-check" @click="addUser" :loading="addUserLoading" class="p-button-text"
              autofocus />
          </template>
        </Dialog>

        <!-- Edit User Dialog -->
        <Dialog header="Edit User" v-model:visible="editUserDialog.show" :style="{ width: '350px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <div>
              <div>{{ editUserDialog.userToEdit.username }}</div>
              <Dropdown v-model="editUserDialog.inputs.userType" :options="editUserDialog.inputs.availableUserTypes"
                placeholder="Select" class="mt-4 h-full" />
              <div id="error-message" class="p-error mt-2">
                {{ addUserErrorMessage }}
              </div>
            </div>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" @click="editUserDialog.show = false"
              :loading="editUserDialog.isLoading" class="p-button-text" />
            <Button label="Yes" icon="pi pi-check" @click="editUser" :loading="addUserLoading" class="p-button-text"
              autofocus />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      users: [],
      usersLoading: true,
      selectedUsers: [],
      displayRemoveUserDialog: false,
      displayResetPasswordDialog: false,
      displayAddUserDialog: false,
      displayEditUserDialog: false,
      userToRemove: {},
      userToReset: {},
      userToAdd: {},
      resetDone: false,
      resetLoading: false,
      resettedPassword: "",
      addUserLoading: false,
      addUserErrorMessage: "",
      addUserInputs: { userType: 'staff', availableUserTypes: ['admin', 'management', 'staff'] },
      editUserDialog: {
        show: false,
        userToEdit: {},
        inputs: { userType: null, availableUserTypes: ['admin', 'management', 'staff'] },
        isLoading: false,
      }
    };
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    openEditUserDialog: function (data) {
      this.editUserDialog.userToEdit = data;
      this.editUserDialog.inputs.userType = data.type;
      this.editUserDialog.show = true;
    },
    openRemoveDialog: function (data) {
      this.displayRemoveUserDialog = true;
      this.userToRemove = data;
    },
    openResetPasswordDialog: function (data) {
      this.resetDone = false;
      this.displayResetPasswordDialog = true;
      this.userToReset = data;
    },
    openAddUserDialog: function () {
      this.displayAddUserDialog = true;
      this.userToAdd = {};
    },
    removeUser: function () {
      axios({
        method: "DELETE",
        url: "user/" + this.userToRemove.id
      }).then(
        () => {
          this.getAllUsers();
          this.displayRemoveUserDialog = false;
          this.userToRemove = {};
        },
        (error) => {
          console.log("ERROR DELETING");
          console.log(error.response.data);
        }
      );

    },
    resetPassword: function () {
      this.resetLoading = true;
      axios({
        method: "POST",
        url: "user/resetPassword",
        data: {
          username: this.userToReset.username,
        },
      }).then(
        (result) => {
          this.resetDone = true;
          this.resettedPassword = result.data.newPassword;
          this.resetLoading = false;
        },
        (error) => {
          console.log(error.response.data);
          this.resetLoading = false;
        }
      );

      //   this.displayResetPasswordDialog = false;
    },
    editUser: function () {
      this.editUserDialog.isLoading = true;
      axios({
        method: "PATCH",
        url: "user/" + this.editUserDialog.userToEdit.id,
        data: {
          type: this.editUserDialog.inputs.userType,
        },
      }).then(
        () => {
          this.editUserDialog.isLoading = false;
          this.editUserDialog.show = false;
          this.getAllUsers();
        },
        (error) => {
          console.log(error)
        }
      );
    },
    addUser: function () {
      this.addUserLoading = true;
      axios({
        method: "POST",
        url: "user/create",
        data: {
          username: this.userToAdd.username,
          password: this.userToAdd.password,
          type: this.addUserInputs.userType,
        },
      }).then(
        () => {
          this.addUserLoading = false;
          this.displayAddUserDialog = false;
          this.userToAdd = {};
          this.getAllUsers();
        },
        (error) => {
          this.addUserErrorMessage = error.response.data.message;
          this.addUserLoading = false;
        }
      );
    },
    getAllUsers: function () {
      this.usersLoading = true;
      axios({
        method: "GET",
        url: "user/all",
      }).then(
        (result) => {
          this.users = result.data.users;
          this.usersLoading = false;
        },
        (error) => {
          console.log(error.response.data);
          this.usersLoading = false;
        }
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
