<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h4>Users</h4>
        <DataTable :value="tokens" :loading="loadingTokens">
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button type="button" icon="pi pi-user-plus" label="Add Token" class="p-button-outlined mb-2"
                @click="openAddTokenDialog" />
            </div>
          </template>
          <Column field="description" header="Description"></Column>
          <Column field="last_accessed" header="Last Accessed"></Column>
          <Column>
            <template #body="{ data }">
              <Button v-if="data.type != 'admin'" label="Remove" icon="pi pi-trash" class="p-button-danger mr-2"
                @click="openRemoveDialog(data)" />
            </template>
          </Column>
        </DataTable>

        <!-- Remove user dialog -->
        <Dialog header="Remove Token" v-model:visible="displayRemoveTokenDialog" :style="{ width: '350px' }"
          :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Are you sure you want to proceed?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" @click="displayRemoveUserDialog = false" class="p-button-text"
              :loading="loadingRemoveToken" />
            <Button label="Yes" icon="pi pi-check" @click="removeToken" class="p-button-text" autofocus
              :loading="loadingRemoveToken" />
          </template>
        </Dialog>

        <!-- Add Token Dialog -->
        <Dialog header="Add Token" v-model:visible="displayAddTokenDialog" :style="{ width: '350px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <div>
              <IconField class="w-full mb-2">
                <InputIcon class="pi pi-info-circle" />
                <InputText type="description" placeholder="Description" name="description"
                  v-model="tokenToAdd.description" class="w-full" />
              </IconField>
              <div id="error-message" class="p-error mt-2">
                {{ errorMessageAddToken }}
              </div>
            </div>
          </div>
          <template #footer>
            <Button v-if="!loadedAddToken" label="No" icon="pi pi-times" @click="displayAddTokenDialog = false"
              :loading="loadingAddToken" class="p-button-text" />
            <Button v-if="!loadedAddToken" label="Yes" icon="pi pi-check" @click="addToken" :loading="loadingAddToken"
              class="p-button-text" autofocus />
            <p v-if="loadedAddToken">{{ newToken }}</p>
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      tokens: [],
      loadingTokens: false,
      loadingAddToken: false,
      loadingRemoveToken: false,
      loadedAddToken: false,
      displayRemoveTokenDialog: false,
      displayAddTokenDialog: false,
      tokenToAdd: {},
      tokenToRemove: {},
      errorMessageAddToken: "",
      newToken: "",
    };
  },
  created() {
    this.getAllTokens();
  },
  methods: {
    openRemoveDialog: function (data) {
      this.displayRemoveTokenDialog = true;
      this.tokenToRemove = data;
      this.errorMessageRemoveToken = "";
    },
    openAddTokenDialog: function () {
      this.loadedAddToken = false;
      this.displayAddTokenDialog = true;
      this.tokenToAdd = {};
      this.errorMessageAddToken = "";
    },
    removeToken: function () {
      this.loadingRemoveToken = true;
      axios({
        method: "DELETE",
        url: "externalapitoken",
        data: {
          id: this.tokenToRemove.id,
        },
      }).then(
        () => {
          this.tokenToRemove = {};
          this.loadingRemoveToken = false;
          this.displayRemoveTokenDialog = false;
          this.tokenToRemove = {};
          this.getAllTokens();
        },
        (error) => {
          this.errorMessageRemoveToken = error.response.data.message;
          this.loadingRemoveToken = false;
        }
      );
    },
    addToken: function () {
      this.loadingAddToken = true;
      axios({
        method: "POST",
        url: "externalapitoken",
        data: {
          description: this.tokenToAdd.description,
        },
      }).then(
        (result) => {
          this.tokenToAdd = {};
          this.loadingAddToken = false;
          this.loadedAddToken = true;
          this.newToken = result.data.token;
          this.getAllTokens();
        },
        (error) => {
          this.errorMessageAddToken = error.response.data.message;
          this.loadingAddToken = false;
        }
      );
    },
    getAllTokens: function () {
      this.loadingTokens = true;
      axios({
        method: "GET",
        url: "externalapitoken",
      }).then(
        (result) => {
          this.loadingTokens = false;
          this.tokens = result.data.tokens;
        },
        (error) => {
          this.loadingTokens = false;
          console.log(error.response.data);
        }
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
