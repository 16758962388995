<template>
    <!-- Long Description -->
    <div class="mt-6">
        <div class="text-lg font-bold flex align-items-center gap-3">
            Long Description
            <div v-if="isEditing && props.type == 'existing-product'">
                <Button class="py-0 px-2" label="Save" @click="saveLongDescription" text></Button>
                <Button class="py-0 px-2" label="Cancel" @click="cancelEdit" severity="danger" text></Button>
            </div>
            <div v-else-if="props.type == 'existing-product'">
                <Button class="py-0 px-2" label="Edit" @click="editLongDescription" text></Button>
            </div>
        </div>
        <Editor ref="editorLongDescription" v-if="isEditing  || props.type=='new-product'" v-model="description_long_edited" editorStyle="height: 650px"
            class="mt-2">
            <template v-slot:toolbar>
                <span class="ql-formats">
                    <select v-tooltip.bottom="'Header'" class="ql-header"></select>
                    <select v-tooltip.bottom="'Font'" class="ql-font"></select>
                    <select v-tooltip.bottom="'Size'" class="ql-size"></select>
                    <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                    <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                    <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                    <button v-tooltip.bottom="'Code'" class="ql-code"></button>
                    <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                    <select v-tooltip.bottom="'Color'" class="ql-color"></select>
                    <select v-tooltip.bottom="'Background'" class="ql-background"></select>
                    <select v-tooltip.bottom="'Align'" class="ql-align"></select>
                    <button v-tooltip.bottom="'List'" class="ql-list" value="ordered"></button>
                    <button v-tooltip.bottom="'List'" class="ql-list" value="bullet"></button>
                    <button id="add-media-button" @click="modalAddImage.show = true" style="width: 60px;">Media</button>
                    <button id="add-raw-code-button" @click="addRawCodeClicked" style="width: 85px;">Raw Code</button>
                </span>
            </template>
        </Editor>
        <Editor v-else v-model="description_long_edited" editorStyle="height: 650px" class="mt-2" readonly>
            <template v-slot:toolbar>
                <span class="ql-formats">
                    View Only Mode - Click "Edit" to start editing.
                </span>
            </template>
        </Editor>
    </div>

    <ModalAddImage v-model="modalAddImage.show" @save="addImageLongDescription" />

    <Dialog v-model:visible="modalAddRawCode.show" modal header="Add Raw Code">

        <div class="mt-2 mb-2 text-lg font-medium">Code</div>
        <Textarea v-model="modalAddRawCode.code" rows="8" cols="60" />

        <div class="flex w-12 justify-content-end gap-3 mt-3">
            <Button label="Cancel" @click="modalAddRawCode.show = false"></Button>
            <Button type="button" label="Save Changes" class="" @click="saveAddRawCode"></Button>
        </div>
    </Dialog>
</template>

<script setup>
import { ref, defineModel, defineProps } from 'vue'
import Editor from 'primevue/editor';
import ModalAddImage from '@/components/Modal/ModalAddImage.vue';

const props = defineProps({
    type: {
        type: String,
        default: 'existing-product'
    }
})

const isEditing = ref(false)
const description_long_edited = defineModel('description_long')
const modalAddImage = ref({ show: false })
const editorLongDescription = ref(null)

// Copy the initial value to the edited value
const description_long_initial = ref(description_long_edited.value)

import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
const route = useRoute();
const router = useRouter();
const toast = useToast();

const editLongDescription = () => {
    isEditing.value = true
}

const cancelEdit = () => {
    description_long_edited.value = description_long_initial.value;
    isEditing.value = false
}


const saveLongDescription = async (field) => {
    let updatedData = description_long_edited.value;

    await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/products-admin/' + route.params.slug, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
            'Accept': 'application/json',
        },
        body: JSON.stringify({
            ['description_long']: updatedData
        })
    }).then(
        (result) => {
            if (result.status == 401) {
                router.push('/login');
                return;
            }

            if (result.status != 200) {
                console.log("Error - Not 200");

                result.json().then((data) => {
                    toast.add({
                        severity: "warn",
                        summary: "Failed. (" + field + ")",
                        detail: data.message,
                        life: 3000,
                    });
                });
                return;
            }
            else {
                result.json().then((data) => {
                    isEditing.value = false;

                    toast.add({
                        severity: "success",
                        summary: "Success. (" + 'desription_long' + ")",
                        detail: data.message,
                        life: 3000,
                    });

                    return;
                });
            }


        }
    ).catch(
        (error) => {
            console.log("Error");
            console.log(error);
        }
    );
}

const addImageLongDescription = (selectedMedias) => {
    // Iteratee over the selected medias
    selectedMedias.forEach(media => {
        // Get the index of the cursor
        let index = editorLongDescription.value.quill.getSelection() ? editorLongDescription.value.quill.getSelection().index : editorLongDescription.value.quill.getLength();
        // Insert the image at the cursor position
        editorLongDescription.value.quill.insertEmbed(
            index
            , 'image', media.url)

        // Insert a new line
        index = editorLongDescription.value.quill.getSelection() ? editorLongDescription.value.quill.getSelection().index : editorLongDescription.value.quill.getLength();
        editorLongDescription.value.quill.insertText(index, '\n', 'user');
    });
}

const modalAddRawCode = ref({ show: false, code: '' })

const addRawCodeClicked = () => {
    modalAddRawCode.value = { show: true, code: '' }
}

const saveAddRawCode = () => {
    if (modalAddRawCode.value.code == '') {
        modalAddRawCode.value = { show: false, code: '' }
    }

    let index = editorLongDescription.value.quill.getSelection() ? editorLongDescription.value.quill.getSelection().index : editorLongDescription.value.quill.getLength();
    editorLongDescription.value.quill.clipboard.dangerouslyPasteHTML(index, modalAddRawCode.value.code);

    // Insert a new line
    index = editorLongDescription.value.quill.getSelection() ? editorLongDescription.value.quill.getSelection().index : editorLongDescription.value.quill.getLength();
    editorLongDescription.value.quill.insertText(index, '\n', 'user');
    modalAddRawCode.value = { show: false, code: '' }
}
</script>