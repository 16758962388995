<template>
    <div v-if="product" class="flex gap-3">
        <div class="col-9 card">
            <!-- Name -->
            <div class="flex text-lg gap-3">
                <span class="font-bold">Name:</span>
                <div v-if="isEditing.name" class="flex gap-3 w-12">
                    <InputText v-model="inputs.name" class="p-inputtext-md w-6" />
                    <Button class="py-0 px-2" label="Save" @click="save('name')" text></Button>
                    <Button class="py-0 px-2" label="Cancel" @click="cancel('name')" severity="danger" text></Button>
                </div>
                <div v-else class="flex gap-3">
                    <div class=""> {{ product.name }}</div>
                    <Button class="py-0 px-2" label="Edit" @click="edit('name')" text></Button>
                </div>
            </div>

            <!------------------------------------------------------------------------------------------>
            <!------------------------------------------- Slug ----------------------------------------->
            <!------------------------------------------------------------------------------------------>
            <div class="flex text-lg gap-3 mt-3">
                <span class="font-bold">Slug:</span>
                <div v-if="isEditing.slug" class="flex gap-3 w-12">
                    <InputText v-model="inputs.slug" class="p-inputtext-md w-6" />
                    <Button class="py-0 px-2" label="Save" @click="save('slug')" text></Button>
                    <Button class="py-0 px-2" label="Cancel" @click="cancel('slug')" severity="danger" text></Button>
                </div>
                <div v-else class="flex gap-3">
                    <div class=""> {{ product.slug }}</div>
                    <Button class="py-0 px-2" label="Edit" @click="edit('slug')" text></Button>
                </div>
            </div>

            <!--------------------------------------------------------------------------------------------->
            <!------------------------------------------- Options ----------------------------------------->
            <!--------------------------------------------------------------------------------------------->
            <EcommerceProductSingleOptions :product="product" @saved="getProduct()" @edit="optionsEdited()" />
            
            <!---------------------------------------------------------------------------------------------->
            <!------------------------------------------- Variants ----------------------------------------->
            <!---------------------------------------------------------------------------------------------->
            <EcommerceProductSingleVariants ref="sectionVariantsRef" :product="product" @saved="getProduct()" />

            <!-------------------------------------------------------------------------------------------->
            <!------------------------------------------- Brand ------------------------------------------>
            <!-------------------------------------------------------------------------------------------->
            <!-- Brand -->
            <div class="text-lg font-bold flex align-items-center gap-3 mt-6 mb-2">
                Brand
                <div v-if="isEditing.brand">
                    <Button class="py-0 px-2" label="Save" @click="save('brand')" text></Button>
                    <Button class="py-0 px-2" label="Cancel" @click="cancel('brand')" severity="danger" text></Button>
                </div>
                <div v-else>
                    <Button class="py-0 px-2" label="Edit" @click="edit('brand')" text></Button>
                </div>
            </div>
            <Dropdown v-if="isEditing.brand" v-model="inputs.brand" filter :options="availableBrands" optionLabel="name"
                placeholder="Select a Brand" class="w-full md:w-14rem" />
            <div v-else>{{ product.brand.name }}</div>

            <!-- Categories -->
            <div class="text-lg font-bold flex align-items-center gap-3 mt-6 mb-2">
                Categories
                <div v-if="isEditing.categories">
                    <Button class="py-0 px-2" label="Save" @click="save('categories')" text></Button>
                    <Button class="py-0 px-2" label="Cancel" @click="cancel('categories')" severity="danger"
                        text></Button>
                </div>
                <div v-else>
                    <Button class="py-0 px-2" label="Edit" @click="edit('categories')" text></Button>
                </div>
            </div>

            <div v-if="isEditing.categories" class="flex flex-column align-items-stretch gap-2 w-12 lg:w-8 xl:w-24rem">

                <IconField class="">
                    <InputIcon class="pi pi-filter" />
                    <InputText v-model="filter.category" class="p-inputtext-md w-full" />
                </IconField>

                <div class="overflow-auto  pr-3" style="height: 300px;">
                    <div v-for="category in inputs.categories" :key="category.name">
                        <div class="mb-3 flex justify-content-between w-12"
                            v-if="(category.label.toUpperCase()).includes(filter.category.toUpperCase()) || filter.category == ''">

                            <div class="w-max">
                                <Checkbox v-model="category.selected" :binary="true"
                                    :inputId="category.slug + '-select'" :name="category.slug + '-select'" />
                                <label :for="category.slug + '-select'" class="ml-2"> {{ category.label }} </label>
                            </div>

                            <RadioButton v-model="inputs.primaryCategorySlug" :inputId="category.slug + '-primary'"
                                :value="category.slug" />
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <!-- Array of objects to string separated by commas -->
                {{ product.categories.map(category => category.name).join(", ") }}
            </div>
            <!-- Warranty Period -->
            <div class="mt-6">
                <div class="text-lg font-bold flex align-items-center gap-3">
                    Warranty Period
                    <div v-if="isEditing.warranty_period">
                        <Button class="py-0 px-2" label="Save" @click="save('warranty_period')" text></Button>
                        <Button class="py-0 px-2" label="Cancel" @click="cancel('warranty_period')" severity="danger"
                            text></Button>
                    </div>
                    <div v-else>
                        <Button class="py-0 px-2" label="Edit" @click="edit('warranty_period')" text></Button>
                    </div>
                </div>
                <InputText v-if="isEditing.warranty_period" v-model="inputs.warranty_period" class="mt-2" />
                <div v-else class="mt-2">{{ product.warranty_period || "-" }}</div>
            </div>

            <!-- Warranty Details -->
            <div class="mt-6">
                <div class="text-lg font-bold flex align-items-center gap-3">
                    Warranty Details
                    <div v-if="isEditing.warranty_details">
                        <Button class="py-0 px-2" label="Save" @click="save('warranty_details')" text></Button>
                        <Button class="py-0 px-2" label="Cancel" @click="cancel('warranty_details')" severity="danger"
                            text></Button>
                    </div>
                    <div v-else>
                        <Button class="py-0 px-2" label="Edit" @click="edit('warranty_details')" text></Button>
                    </div>
                </div>
                <Editor v-if="isEditing.warranty_details" v-model="inputs.warranty_details" editorStyle="height: 150px"
                    class="mt-2">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <select v-tooltip.bottom="'Header'" class="ql-header"></select>
                            <select v-tooltip.bottom="'Font'" class="ql-font"></select>
                            <select v-tooltip.bottom="'Size'" class="ql-size"></select>
                            <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                            <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                            <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                            <button v-tooltip.bottom="'Code'" class="ql-code"></button>
                            <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                            <select v-tooltip.bottom="'Color'" class="ql-color"></select>
                            <select v-tooltip.bottom="'Background'" class="ql-background"></select>
                            <select v-tooltip.bottom="'Align'" class="ql-align"></select>
                            <button v-tooltip.bottom="'Blockquote '" class="ql-blockquote"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="ordered"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="bullet"></button>
                            <button v-tooltip.bottom="'Code Block '" class="ql-code-block"></button>
                        </span>
                    </template>
                </Editor>
                <Editor v-else v-model="product.warranty_details" editorStyle="height: 150px" class="mt-2" readonly>
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <select v-tooltip.bottom="'Header'" class="ql-header"></select>
                            <select v-tooltip.bottom="'Font'" class="ql-font"></select>
                            <select v-tooltip.bottom="'Size'" class="ql-size"></select>
                            <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                            <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                            <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                            <button v-tooltip.bottom="'Code'" class="ql-code"></button>
                            <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                            <select v-tooltip.bottom="'Color'" class="ql-color"></select>
                            <select v-tooltip.bottom="'Background'" class="ql-background"></select>
                            <select v-tooltip.bottom="'Align'" class="ql-align"></select>
                            <button v-tooltip.bottom="'Blockquote '" class="ql-blockquote"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="ordered"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="bullet"></button>
                            <button v-tooltip.bottom="'Code Block '" class="ql-code-block"></button>
                        </span>
                    </template>
                </Editor>
            </div>

            <!-- Short Description -->
            <div class="mt-6">
                <div class="text-lg font-bold flex align-items-center gap-3">
                    Short Description
                    <div v-if="isEditing.description_short">
                        <Button class="py-0 px-2" label="Save" @click="save('description_short')" text></Button>
                        <Button class="py-0 px-2" label="Cancel" @click="cancel('description_short')" severity="danger"
                            text></Button>
                    </div>
                    <div v-else>
                        <Button class="py-0 px-2" label="Edit" @click="edit('description_short')" text></Button>
                    </div>
                </div>
                <Editor v-if="isEditing.description_short" v-model="inputs.description_short"
                    editorStyle="height: 150px" class="mt-2">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <select v-tooltip.bottom="'Header'" class="ql-header"></select>
                            <select v-tooltip.bottom="'Font'" class="ql-font"></select>
                            <select v-tooltip.bottom="'Size'" class="ql-size"></select>
                            <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                            <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                            <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                            <button v-tooltip.bottom="'Code'" class="ql-code"></button>
                            <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                            <select v-tooltip.bottom="'Color'" class="ql-color"></select>
                            <select v-tooltip.bottom="'Background'" class="ql-background"></select>
                            <select v-tooltip.bottom="'Align'" class="ql-align"></select>
                            <button v-tooltip.bottom="'Blockquote '" class="ql-blockquote"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="ordered"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="bullet"></button>
                            <button v-tooltip.bottom="'Code Block '" class="ql-code-block"></button>
                        </span>
                    </template>
                </Editor>
                <Editor v-else v-model="product.description_short" editorStyle="height: 150px" class="mt-2" readonly>
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <select v-tooltip.bottom="'Header'" class="ql-header"></select>
                            <select v-tooltip.bottom="'Font'" class="ql-font"></select>
                            <select v-tooltip.bottom="'Size'" class="ql-size"></select>
                            <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                            <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                            <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                            <button v-tooltip.bottom="'Code'" class="ql-code"></button>
                            <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                            <select v-tooltip.bottom="'Color'" class="ql-color"></select>
                            <select v-tooltip.bottom="'Background'" class="ql-background"></select>
                            <select v-tooltip.bottom="'Align'" class="ql-align"></select>
                            <button v-tooltip.bottom="'Blockquote '" class="ql-blockquote"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="ordered"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="bullet"></button>
                            <button v-tooltip.bottom="'Code Block '" class="ql-code-block"></button>
                        </span>
                    </template>
                </Editor>
            </div>

            <!-- Long Description -->
            <LongDescriptionEditor v-model:description_long="product.description_long"> </LongDescriptionEditor>

            <!-- Medias -->
            <div class="text-lg font-bold flex align-items-center gap-3 mt-6">
                Medias
                <div v-if="isEditing.medias">
                    <Button class="py-0 px-2" label="Add" @click="addMediaClicked" text></Button>
                    <Button class="py-0 px-2" label="Save" @click="save('medias')" text></Button>
                    <Button class="py-0 px-2" label="Cancel" @click="cancel('medias')" severity="danger" text></Button>
                </div>
                <div v-else>
                    <Button class="py-0 px-2" label="Edit" @click="edit('medias')" text></Button>
                </div>
            </div>
            <div class="flex p-3 border-solid border-round border-1 border-200 mt-2">
                <VueDraggableNext v-if="isEditing.medias" class="dragArea list-group w-12 flex flex-wrap gap-2"
                    :list="inputs.medias" ghost-class="ghost">
                    <div class="list-group-item bg-gray-300 rounded-md text-center cursor-pointer relative"
                        v-for="element, index in inputs.medias" style="width: 19%;" :key="element.url">
                        <img :src="element.url" alt="" style="width: 100%;">

                        <Button label="" icon="pi pi-trash" class="p-button-danger" style="
                                position: absolute;
                                top: 5px;
                                right: 5px;" @click="removeMedia(index)"></Button>
                    </div>
                </VueDraggableNext>
                <div v-else class="w-12 flex flex-wrap gap-2">
                    <div v-for="element in product.medias" style="width: 19%;" :key="element.url">
                        <img :src="element.url" alt="" style="width: 100%;">
                    </div>
                </div>
            </div>
        </div>

        <div class="col-3 card h-min">
            <div v-if="product.is_discontinued"
                class="font-medium text-lg bg-pink-100 p-2 px-3 w-max rounded border-round mb-3">
                Product Marked Discontinued</div>

            <div class="font-bold">Status</div>
            <div class="flex justify-content-between gap-3 w-full mt-2">
                <Dropdown v-model="inputs.selectedStatus" :options="availableStatuses" optionLabel="name"
                    placeholder="Select a status" class="flex-grow-1" />

                <Button label="Update" class="w-max" @click="save('status')"></Button>

            </div>
            <div class="flex mt-6 justify-content-end">
                <Button label="Delete Product Permanently" severity="danger" text @click="deleteProduct"></Button>
            </div>

        </div>
    </div>

    <ModalAddImage v-model="modalShow.addImage" @save="pushMedias" />
</template>

<script setup>
import { ref } from 'vue';
import router from '../../router';
import Editor from 'primevue/editor';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import ModalAddImage from '../../components/Modal/ModalAddImage.vue';
import InputText from 'primevue/inputtext';
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import LongDescriptionEditor from '@/components/Ecommerce/LongDescriptionEditor.vue';
import { VueDraggableNext } from 'vue-draggable-next'
import EcommerceProductSingleVariants from '../../components/Ecommerce/EcommerceProductSingle/EcommerceProductSingleVariants.vue';
import EcommerceProductSingleOptions from '../../components/Ecommerce/EcommerceProductSingle/EcommerceProductSingleOptions.vue';
const route = useRoute();
const toast = useToast();

const sectionVariantsRef = ref(null);

const isLoading = ref({ product: false });
const product = ref(null);
const inputs = ref({
    warranty_period: null,
    warranty_details: null,
    description_short: null,
    description_long: null,
    medias: null,
    variants: null,
    brand: null,
    categories: null,
    primaryCategorySlug: null,
    selectedStatus: null,
});
const isEditing = ref({
    warranty_period: false,
    warranty_details: false,
    description_short: false,
    description_long: false,
    medias: false,
    variants: false,
    brand: false,
    categories: false,
});
const modalShow = ref({
    addImage: false,
});
const availableBrands = ref([]);
const categories = ref([]);
const availableStatuses = [{ name: "Draft" }, { name: "Published" }];
const filter = ref({ category: '' });

// Methods

const getProduct = async () => {
    isLoading.value.product = true;
    sectionVariantsRef?.value?.cancel();
    await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/products-admin/' + route.params.slug, {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
            'Accept': 'application/json',
        },
    }).then(
        (result) => {
            if (result.status == 401) {
                router.push('/login');
                return;
            }

            if (result.status != 200 && result.status != 201) {
                console.log("Error");
                console.log(result);
                return;
            }

            result.json().then((data) => {
                product.value = data.product;
                availableBrands.value = data.available_brands;

                // Update status dropdown
                inputs.value.selectedStatus = { name: product.value.status };

                // Populate product options
                const tempProductOptions = [];
                product.value.product_options.forEach((productOption) => {
                    tempProductOptions[productOption.name] = productOption.product_option_values;
                });

                let newVariantsWithEmptyData = [{}];
                Object.entries(tempProductOptions).forEach(([key, values]) => {
                    let all = [];
                    values.forEach((value) => {
                        newVariantsWithEmptyData.forEach((combo) => {
                            all.push({ ...combo, [key]: value });
                        });
                    });
                    newVariantsWithEmptyData = all;
                });

                // Append data from product.variants to newVariantsWithEmptyData if matches
                for (let i = 0; i < newVariantsWithEmptyData.length; i++) {
                    let newVariant = newVariantsWithEmptyData[i];

                    let variant = product.value.variants.find((variant) => {
                        let isMatch = true;

                        Object.entries(newVariant).forEach(([key, value]) => {
                            const variantProductOption = variant.product_option_values.find((productOptionValue) => productOptionValue.product_option_name == key);
                            if (variantProductOption.name != value) {
                                isMatch = false;
                            }
                        });

                        return isMatch;
                    });

                    if (variant) {
                        newVariantsWithEmptyData[i] = variant;
                    }
                    else {
                        const newVariant = {};

                        newVariant.product_option_values = [];
                        Object.entries(newVariantsWithEmptyData[i]).forEach(([key, values]) => {
                            newVariant.product_option_values.push({ "product_option_name": key, "name": values })
                        });

                        newVariantsWithEmptyData[i] = newVariant;
                    }
                }

                product.value.variants = newVariantsWithEmptyData;

                // Flatten the categories tree
                let flattenCategories = [];
                let flattenCategoriesRecursive = (categories, step = 0) => {
                    categories.forEach((category) => {
                        // Append -- to the label to show hierarchy
                        category.label = '--'.repeat(step) + ' ' + category.name;

                        // Check if is selected
                        category.selected = false;
                        if (product.value.categories.find((productCategory) => productCategory.slug == category.slug)) {
                            category.selected = true;
                        }

                        flattenCategories.push(category);
                        if (category.children.length > 0) {
                            flattenCategoriesRecursive(category.children, step + 1);
                        }
                    });
                }
                flattenCategoriesRecursive(data.available_categories);
                categories.value = flattenCategories;
            });
        }
    ).catch(
        (error) => {
            console.log("Error");
            console.log(error);
        }
    );
    isLoading.value.product = false;
}

const optionsEdited = () => {
    sectionVariantsRef.value.cancel();
}

const edit = (field) => {
    inputs.value[field] = product.value[field];
    isEditing.value[field] = true;

    if (field == 'medias') {
        inputs.value.medias = JSON.parse(JSON.stringify(product.value.medias));
    }

    if (field == 'categories') {
        inputs.value.categories = JSON.parse(JSON.stringify(categories.value));
        inputs.value.primaryCategorySlug = product.value.primary_product_category.slug;
    }

}

const cancel = (field) => {
    inputs.value[field] = null;
    isEditing.value[field] = false;

    if (field == 'categories') {
        inputs.value.primaryCategorySlug = null;
    }
}

const removeMedia = (index) => {
    inputs.value.medias.splice(index, 1);
}

const pushMedias = (selectedMedias) => {
    inputs.value.medias.push(...selectedMedias);
}

const addMediaClicked = () => {
    modalShow.value.addImage = true;
}

const deleteProduct = async () => {
    // Prompt to confirm
    let resultPin = prompt("Are you sure you want to delete this product? \nThis is permanent and CANNOT be undone! Enter PIN to delete.", "");
    if (resultPin == null || resultPin == "") {
        return;
    }

    // Delete product
    await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/products-admin/' + route.params.slug, {
        method: 'DELETE',
        body: JSON.stringify({
            pin_delete: resultPin
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
            'Accept': 'application/json',
        }
    }).then(
        (result) => {
            if (result.status == 401) {
                router.push('/login');
                return;
            }

            if (result.status != 200) {
                console.log("Error - Not 200");

                result.json().then((data) => {
                    toast.add({
                        severity: "warn",
                        summary: "Failed",
                        detail: data.message,
                        life: 3000,
                    });
                });
                return;
            }
            else {
                result.json().then((data) => {
                    toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: data.message,
                        life: 3000,
                    });

                    router.push('/ecommerce/products');
                });
            }


        }
    ).catch(
        (error) => {
            console.log("Error");
            console.log(error);
        }
    );
}

const save = async (field) => {
    let updatedData = inputs.value[field];
    let updateField = field;

    if (field == 'medias') {
        updatedData = inputs.value[field].map((element) => {
            return element.id;
        });

        updateField = 'media_ids';
    }

    if (field == 'brand') {
        updatedData = inputs.value[field].slug;
        updateField = 'brand'
    }

    if (field == 'categories') {
        // Map only if .selected is true
        let categorySlugs = inputs.value.categories.filter((category) => {
            return category.selected;
        }).map((category) => {
            return category.slug;
        });

        updatedData = {
            category_slugs: categorySlugs,
            primary_category_slug: inputs.value.primaryCategorySlug,
        }

        updateField = 'category_data';
    }

    if (field == 'status') {
        updatedData = inputs.value.selectedStatus.name;
        updateField = 'status';
    }

    await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/products-admin/' + route.params.slug, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
            'Accept': 'application/json',
        },
        body: JSON.stringify({
            [updateField]: updatedData
        })
    }).then(
        (result) => {
            if (result.status == 401) {
                router.push('/login');
                return;
            }

            if (result.status != 200) {
                console.log("Error - Not 200");

                result.json().then((data) => {
                    toast.add({
                        severity: "warn",
                        summary: "Failed. (" + field + ")",
                        detail: data.message,
                        life: 3000,
                    });
                });
                return;
            }
            else {
                result.json().then((data) => {
                    if (field == 'medias') {
                        product.value[field] = [...inputs.value[field]];
                    }
                    else if (field == 'categories') {
                        product.value.categories = inputs.value.categories.filter((category) => {
                            return category.selected;
                        })
                        product.value['primary_product_category'] = product.value.categories.filter((category) => {
                            return category.slug == inputs.value.primaryCategorySlug;
                        })[0]
                    }
                    else if (field == 'variants') {
                        getProduct();
                    }
                    else {
                        product.value[field] = inputs.value[field];
                    }

                    inputs.value[field] = null;
                    isEditing.value[field] = false;

                    toast.add({
                        severity: "success",
                        summary: "Success. (" + field + ")",
                        detail: data.message,
                        life: 3000,
                    });

                    if (field == 'slug') {
                        product.value.slug = data.product_slug;
                        router.push('/ecommerce/products/' + data.product_slug);
                    }
                    return;
                });
            }


        }
    ).catch(
        (error) => {
            console.log("Error");
            console.log(error);
        }
    );
}

getProduct();
</script>

<style scoped></style>
