<template>
    <div>
        <h2>Shelfing</h2>
        <div class="card">
            <div class="text-2xl font-bold">Items With Many Shelf</div>
            <DataTable class="mt-3" ref="dt_itemsWithManyShelf" :value="itemsWithManyShelf" responsiveLayout="scroll"
                scrollable sortMode="multiple" :loading="isLoading.summary" scrollHeight="650px">

                <template #header>
                    <div style="text-align: left">
                        <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                    </div>
                </template>
                <Column sortable field="CODE" header="Code"></Column>
                <Column sortable field="DESCRIPTION" header="Description"></Column>
                <Column sortable field="BALSQTY" header="Quantity"></Column>
                <Column sortable field="SHELF" header="Shelf"></Column>
            </DataTable>

            <!-- <DataTable :value="reports" responsiveLayout="scroll" :loading="isLoading.shelfingReports">
                <template #header>
                    <div class="flex flex-row">
                        <Button type="button" icon="pi pi-plus" label="Add Shelfing Report"
                            class="p-button-outlined mb-2 mr-4" @click="showAddShelfingReportDialog" />
                    </div>
                </template>
                <template #footer>
                    <Paginator :rows="pagination.records_per_page" :totalRecords="pagination.total_records"
                        @page="onPageChange($event)" :first="paginator_first">
                    </Paginator>
                </template>
                <Column field="id" header="ID"></Column>

                <Column header="Time Created" style="width: 10%">
                    <template #body="{ data }">
                        <div :title="dateTimeFormat(data.created_at)">
                            {{ dateTimeFormat(data.created_at) }}
                        </div>
                    </template>
                </Column>

                <Column field="created_by" header="Created By (Agent)"></Column>
                <Column field="remark" header="Remark"></Column>
                <Column field="status" header="Status"></Column>
                <Column field="synced_at" header="Time Approved"></Column>
                <Column field="synced_by" header="Approved By (Agent)"></Column>
                <Column field="" header="">
                    <template #body="{ data }">
                        <div class="flex justify-content-end">
                            <Button v-if="data.status == 'SCANNING'" severity="success" @click="action(data.id, 'ACCEPT')"
                                text label="Accept"></Button>
                            <Button v-if="data.status == 'SCANNING' || data.status == 'ACCEPTED'" severity="danger"
                                @click="action(data.id, 'REJECT')" text label="Reject"></Button>
                            <Button v-if="data.status == 'REJECTED'" severity="warning" @click="action(data.id, 'RESUME')"
                                text label="Resume Scan"></Button>
                            <Button severity="info" @click="action(data.id, 'VIEW')" text label="View"></Button>
                        </div>
                    </template>

                </Column>
            </DataTable> -->
        </div>



    </div>
</template>

<script setup>
import axios from "axios";
import { ref, onMounted } from 'vue';

const dt_itemsWithManyShelf = ref();
const isLoading = ref({ summary: false });
const itemsWithManyShelf = ref([]);

onMounted(async () => {
    isLoading.value.summary = true;

    const params = new URLSearchParams();

    await axios({
        method: "GET",
        url: "shelfing/summary",
        params: params,
    }).then(
        (result) => {
            itemsWithManyShelf.value = result.data.items_long_shelf;
        },
        (error) => {
            console.log(error.response.data);
        }
    );

    isLoading.value.summary = false;
});

const exportCSV = () => {
    dt_itemsWithManyShelf.value.exportCSV();
};
</script>
  