<template>
	<ul v-if="props.items">
		<template v-for="(item, i) of props.items">
			<li v-if="visible(item) && !item.separator" :key="item.label || i"
				:class="[{ 'layout-menuitem-category': root, 'active-menuitem': activeIndex === i && !item.to && !item.disabled }]"
				role="none">
				<div v-if="root">
					<div class="layout-menuitem-root-text">{{ item.label }}</div>
					<appsubmenu :items="visible(item) && item.items" @menuitem-click="$emit('menuitem-click', $event)">
					</appsubmenu>
				</div>
				<div v-else>
					<router-link v-if="item.to" :to="item.to"
						:class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]" :style="item.style"
						@click="onMenuItemClick($event, item, i)" :target="item.target" exact role="menuitem" v-ripple>
						<i :class="item.icon"></i>
						<span>{{ item.label }}</span>
						<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
						<div v-if="item.badge != null && item.badge != 0 && item.badge != 0"
							class="ml-2 bg-red-500 font-medium text-white border-round-lg px-2 px-1">
							{{ item.badge }}
						</div>
					</router-link>
					<a v-if="!item.to" :href="item.url || '#'" :style="item.style"
						:class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
						@click="onMenuItemClick($event, item, i)" :target="item.target" role="menuitem" v-ripple>
						<i :class="item.icon"></i>
						<span>{{ item.label }}</span>
						<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
					</a>
					<transition name="layout-submenu-wrapper">
						<appsubmenu v-show="activeIndex === i" :items="visible(item) && item.items"
							@menuitem-click="$emit('menuitem-click', $event)"></appsubmenu>
					</transition>
				</div>
			</li>
			<li class="p-menu-separator" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i"
				role="separator"></li>
		</template>
	</ul>
</template>

<script setup>
import appsubmenu from './AppSubmenu.vue'
import { defineProps, ref, defineEmits } from 'vue'

const props = defineProps({
	items: Array,
	root: {
		type: Boolean,
		default: false
	}
})
const emit = defineEmits(['menuitem-click'])
const activeIndex = ref(null);

const onMenuItemClick = (event, item, index) => {
	if (item.disabled) {
		event.preventDefault();
		return;
	}

	if (!item.to && !item.url) {
		event.preventDefault();
	}

	//execute command
	if (item.command) {
		item.command({ originalEvent: event, item: item });
	}

	activeIndex.value = index === activeIndex.value ? null : index;

	emit('menuitem-click', {
		originalEvent: event,
		item: item
	});
}
const visible = (item) => {
	return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
}
</script>

<style scoped></style>
