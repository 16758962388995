<template>
    <div>
        <h2>Shelfing Report - #{{ this.$route.params.id }}</h2>
        <div class="card">

            <div class="flex w-100">
                <div class="flex flex-column gap-3 mr-4">
                    <div class="text-lg font-medium">Status:</div>
                    <div class="text-lg font-medium">Created By:</div>
                    <div class="text-lg font-medium">Created At:</div>
                    <div class="text-lg font-medium">Approved By:</div>
                    <div class="text-lg font-medium">Approved At:</div>

                </div>
                <div class="flex flex-column gap-3">
                    <div class="text-lg">{{ report.status }}</div>
                    <div class="text-lg">{{ report.created_by }}</div>
                    <div class="text-lg">{{ report.created_at }}</div>
                    <div class="text-lg">{{ report.synced_by }}</div>
                    <div class="text-lg">{{ report.synced_at }}</div>
                </div>
            </div>

            <!-- Edited Items -->
            <div class="mt-5 text-2xl font-bold">Items</div>
            <DataTable class="mt-3" :value="items" responsiveLayout="scroll" scrollable sortMode="multiple"
                :loading="isLoading.shelfingReport" scrollHeight="650px">
                <Column sortable field="item_code" header="Code"></Column>
                <Column sortable field="description" header="Description"></Column>
                <Column sortable field="balsqty_old" header="Quantity"></Column>
                <Column sortable field="shelf_old" header="Old Shelf"></Column>
                <Column sortable field="shelf_new" header="New Shelf"></Column>
            </DataTable>

            <!-- Lines -->
            <div class="mt-5 text-2xl font-bold">Lines</div>
            <DataTable class="mt-3" :value="report.lines" responsiveLayout="scroll" scrollable sortMode="multiple"
                :loading="isLoading.shelfingReport" scrollHeight="650px">
                <Column sortable field="created_at" header="Time Created" style="width: 10%">
                    <template #body="{ data }">
                        <div :title="dateTimeFormatFixed(data.created_at)">
                            {{ dateTimeFormat(data.created_at) }}
                        </div>
                    </template>
                </Column>
                <Column sortable field="shelf" header="Shelf"></Column>
                <Column sortable field="item_code" header="Code"></Column>
                <Column sortable field="description" header="Description"></Column>
                <Column sortable field="action" header="Action"></Column>
                <!-- <Column field="" header="">
                    <template #body="{ data }">
                        {{ data }}
                    </template>

                </Column> -->
            </DataTable>
        </div>
    </div>
</template>
  
<script>
import moment from "moment";
import axios from "axios";
// import moment from "moment";

export default {
    data() {
        return {
            isLoading: {
                shelfingReport: false,
            },
            report: {},
            items: [],
        };
    },
    created() {
        this.getShelfingReport();
    },
    mounted: function () {
    },
    methods: {
        dateTimeFormat(timestampString) {
            // If less than 7 days ago, show days ago
            if (moment().diff(moment(timestampString), 'days') < 7) {
                return moment(timestampString).fromNow();
            }

            // Display as format "dd MMM yyyy HH:mm"
            return moment(timestampString).format('DD MMM YYYY, HH:mm');
        },
        dateTimeFormatFixed(timestampString) {
            return moment(timestampString).format('DD MMM YYYY, HH:mm:ss');
        },
        getShelfingReport: async function () {
            this.isLoading.shelfingReport = true;

            await axios({
                method: "GET",
                url: "shelfing/reports/" + this.$route.params.id + "?include=edited_items",
            }).then(
                (result) => {
                    this.report = result.data.report;
                    this.items = result.data.edited_items;

                    // Change null to empty string for items shelf_old and shelf_new
                    this.items.forEach((item) => {
                        if (item.shelf_old == null) {
                            item.shelf_old = "";
                        }

                        if (item.shelf_new == null) {
                            item.shelf_new = "";
                        }
                    });


                },
                (error) => {
                    console.log(error.response.data);
                }
            );

            this.isLoading.shelfingReport = false;
        },
    }
};
</script>
  