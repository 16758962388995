<template>
    <h2>Ecommerce - Dashboard</h2>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>Dashboard</h5>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
