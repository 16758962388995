<template>
    <div v-if="order" class="flex">
        <div class="col-9 pt-0">
            <div class="card">
                <div class="text-3xl  font-medium">Order ID: {{ $route.params.id_custom }}</div>
                <div class="mt-1">{{ order ? dateFormat(order.created_at) : '' }}</div>
                <div class="flex gap-6 mt-4">
                    <div class="w-3">
                        <div class="text-lg font-semibold">Customer</div>
                        <div class="mt-2">
                            {{ order.user.name }}
                        </div>
                        <div> {{ order.user.email }}</div>
                        <div> {{ order.user.phone }}</div>
                    </div>
                    <div class="w-4">
                        <div class="text-lg font-semibold">Shipping Address</div>
                        <div class="mt-2">{{ order.shipping_address.first_name }} {{ order.shipping_address.last_name }}
                        </div>
                        <div class="mt-1 font-medium">{{ order.shipping_address.company_name }}</div>
                        <div class="mt-2">{{ order.shipping_address.street_address_1 }}</div>
                        <div class="">{{ order.shipping_address.street_address_2 }}</div>
                        <div class="">{{ order.shipping_address.postal_code }}, {{ order.shipping_address.sub_subdivision }}
                        </div>
                        <div class="">{{ order.shipping_address.subdivision }}, {{ order.shipping_address.country }}</div>

                        <div class="mt-3">{{ order.shipping_address.phone }}</div>

                        <div class="text-lg font-semibold mt-6">Shipping method</div>
                        <div class="mt-2">{{ order.shipping_method_label }}</div>
                    </div>
                    <div class="w-4">
                        <div class="text-lg font-semibold">Billing Address</div>
                        <div class="mt-2">{{ order.billing_address.first_name }} {{ order.billing_address.last_name }}</div>
                        <div class="mt-1 font-medium">{{ order.billing_address.company_name }}</div>
                        <div class="mt-2">{{ order.billing_address.street_address_1 }}</div>
                        <div class="">{{ order.billing_address.street_address_2 }}</div>
                        <div class="">{{ order.billing_address.postal_code }}, {{ order.billing_address.sub_subdivision }}
                        </div>
                        <div class="">{{ order.billing_address.subdivision }}, {{ order.billing_address.country }}</div>

                        <div class="mt-3">{{ order.billing_address.phone }}</div>
                    </div>
                </div>


                <div class="mt-6 flex flex-column gap-3">
                    <div v-for="line, index in order.lines" :key="index" class="flex gap-2">

                        <div style="width: 10%;">
                            <img :src="line.media_url" alt="" style="object-fit: contain;">
                        </div>
                        <div class="flex flex-column gap-1" style="width: 60%;">

                            <div>{{ line.sku }}</div>
                            <div>
                                {{ line.product_name }}
                            </div>
                            <div class="bg-gray-300 p-1 py-5">
                                {{ line.product_variant_name }}
                            </div>
                        </div>
                        <div class="" style="width: 20%;">
                            <div :class="{ 'line-through': line.discount }">{{ priceFormat(line.unit_price_gross) }}</div>
                            <div v-if="line.discount">{{ priceFormat(line.unit_price_gross - line.discount) }}</div>
                        </div>
                        <div style="width: 10%;">
                            x{{ line.quantity }}
                        </div>

                    </div>
                </div>

                <div class="mt-6 flex justify-content-between">
                    <div></div>

                    <div class="flex flex-column gap-2">
                        <div class="flex">
                            <div class="w-6rem">Subtotal:</div> {{ priceFormat(order.subtotal_amount) }}
                        </div>
                        <div class="flex">
                            <div class="w-6rem">Discount: </div>{{ priceFormat(order.discount_amount) }}
                        </div>
                        <div class="flex">
                            <div class="w-6rem">Shipping:</div> {{ priceFormat(order.shipping_amount) }}
                        </div>
                        <div class="flex">
                            <div class="w-6rem">Total:</div> {{ priceFormat(order.total_amount) }}
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="col-3">
            <div class="card">
                <div class="text-lg font-semibold pb-2">Order Status</div>
                {{ order.status }}
            </div>

            <div class="card">
                <div class="text-lg font-semibold pb-2">Order Cartons</div>
                <div class="mt-3">Estimated Cartons: {{ order.carton_estimate }}</div>

                <div v-if="order.carton_actual && isEditing.carton_actual == false" class="mt-2 flex align-items-center">
                    Actual Cartons: {{ order.carton_actual }}
                    <Button link label="Edit" @click="editCartons"></Button>
                </div>
                <div v-else class="mt-2 flex align-items-center gap-2 w-12">
                    <div class="w-full w-max w-12">Actual Cartons:</div>
                    <InputText v-model="inputs.carton_actual" class="w-2" />
                    <Button label="Save" @click="saveCartonsActual"></Button>
                </div>
            </div>

            <div class="card">
                <div class="text-lg font-semibold pb-2">Order Notes</div>

                <div v-for="note in order.notes" :key="note.created_at" class="p-2 bg-gray-100 my-2">

                    <div class="font-semibold">{{ note.user.name }} - {{ note.type }}</div>
                    <div class="mt-1">{{ note.is_internal ? 'Internal Note' : '' }}</div>
                    <div class="mt-2">{{ note.note }}</div>
                    <div class="mt-3">{{ dateFormat(note.created_at) }}</div>
                </div>
            </div>

            <div class="card">
                <div class="text-lg font-semibold pb-2">Payment Notes</div>

                <div v-for="status, index in order.payment_statuses" :key="index" class="p-2 bg-gray-100 my-2">
                    <div class="font-semibold">{{ status.order_payment.payment_method_label }} - {{
                        status.order_payment.count }} (RM{{ status.order_payment.amount }})</div>
                    <div class="mt-2">Status: {{ status.name }}</div>
                    <div class="mt-2">{{ status.note_private }}</div>

                    <div class="mt-3">{{ dateFormat(status.created_at) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../../router';
import moment from "moment";
export default {
    data() {
        return {
            isLoading: { order: false },
            order: null,
            inputs: { carton_actual: null },
            isEditing: { carton_actual: false }
        }
    },
    mounted: function () {
        this.getOrder();
    },
    methods: {
        getOrder: async function () {
            this.isLoading.order = true;
            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/orders-admin/' + this.$route.params.id_custom, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                },
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200) {
                        console.log("Error");
                        console.log(result);
                        return;
                    }

                    result.json().then((data) => {
                        this.order = data.order;
                    });
                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
            this.isLoading.order = false;
        },
        dateTimeFormat(timestampString) {
            // If less than 24 hours ago, show hours ago
            if (moment().diff(moment(timestampString), 'hours') < 24) {
                return moment(timestampString).fromNow();
            }

            // If less than 7 days ago, show days ago
            if (moment().diff(moment(timestampString), 'days') < 7) {
                return moment(timestampString).fromNow();
            }

            // Display as format "dd MMM yyyy HH:mm"
            return moment(timestampString).format('DD MMM YYYY, HH:mm');
        },
        dateFormat(timestampString) {
            return moment(timestampString).format('DD MMM YYYY, HH:mm');

        },
        priceFormat(price) {
            return "RM" + parseFloat(price).toFixed(2)
        },
        editCartons() {
            this.isEditing.carton_actual = true;
            this.inputs.carton_actual = this.order.carton_actual ? this.order.carton_actual : this.order.carton_estimate;
        },
        async saveCartonsActual() {
            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/orders-admin/' + this.$route.params.id_custom, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    carton_actual: this.inputs.carton_actual
                })
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200 && result.status != 201) {
                        console.log("Error - Not 200 or 201");

                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "warn",
                                summary: "Failed.",
                                detail: data.message,
                                life: 3000,
                            });
                        });
                        return;
                    }
                    else {
                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "success",
                                summary: "Success.",
                                detail: data.message,
                                life: 3000,
                            });
                        });
                        this.order.carton_actual = this.inputs.carton_actual;
                        this.isEditing.carton_actual = false;
                    }
                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
        }
    },

};
</script>

<style scoped></style>
