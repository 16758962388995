import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "./views/Dashboard.vue";
import CustomerNotification from "./views/CustomerNotification.vue";
import PreOrders from "./views/PreOrders.vue";
import Login from "./components/Login.vue";
import ChangePassword from "./components/ChangePassword.vue";
import Users from "./components/Users.vue";
import ExternalApiTokens from "./components/ExternalApiTokens.vue";
import Items from "./views/Item/Items.vue";
import ItemsSingle from "./views/Item/ItemsSingle.vue";
import AnalysisSales from "./views/Analysis/AnalysisSales.vue";
import AnalysisInventory from "./views/Analysis/AnalysisInventory.vue";
import AnalysisDeadStock from "./views/Analysis/AnalysisDeadStock.vue";
import ReorderStock from "./views/ReorderStock.vue";
import ShelfingReports from "./views/Shelfing/ShelfingReports.vue";
import ShelfingSummary from "./views/Shelfing/ShelfingSummary.vue";
import ShelfingReportSingle from "./views/Shelfing/ShelfingReportSingle.vue";
import ShelfingUpdate from "./views/Shelfing/ShelfingUpdate.vue";

import Agent from "./views/Agent/Agent.vue";

import Ecommerce from "@/views/Ecommerce/Ecommerce.vue";
import EcommerceOrders from "@/views/Ecommerce/EcommerceOrders.vue";
import EcommerceOrdersSingle from "@/views/Ecommerce/EcommerceOrdersSingle.vue";
import EcommerceLogs from "@/views/Ecommerce/EcommerceLogs.vue";
import EcommerceProducts from "@/views/Ecommerce/EcommerceProducts.vue";
import EcommerceFeaturedProducts from "@/views/Ecommerce/EcommerceFeaturedProducts.vue";
import EcommerceProductsSingle from "@/views/Ecommerce/EcommerceProductsSingle.vue";
import EcommerceProductsAddNew from "@/views/Ecommerce/EcommerceProductsAddNew.vue";
import EcommerceBrands from "@/views/Ecommerce/EcommerceBrands.vue";
import EcommerceCategories from "@/views/Ecommerce/EcommerceCategories.vue";
import EcommerceUsers from "@/views/Ecommerce/EcommerceUsers.vue";

import PurchaseRequests from '@/views/Purchase/PurchaseRequests'
import PurchaseInventorySummary from '@/views/Purchase/PurchaseInventorySummary'
import PurchaseRequestSingle from '@/views/Purchase/PurchaseRequestSingle'
import PurchaseOrders from '@/views/Purchase/PurchaseOrders'
import PurchaseOrdersSingle from '@/views/Purchase/PurchaseOrdersSingle'
import Suppliers from '@/views/Suppliers/Suppliers'
import SuppliersSingle from '@/views/Suppliers/SuppliersSingle'
import Logs from "./views/Logs.vue";

import StockIssue from "@/views/Tools/StockIssue.vue";

import RefillGroups from "@/views/Refill/RefillGroups.vue";
import RefillGroupsSingle from "@/views/Refill/RefillGroupsSingle.vue";
import RefillGroupsAddItems from "./views/Refill/RefillGroupsAddItems.vue";

import Teams from "@/views/Teams/Teams.vue"
import TeamsSingle from "@/views/Teams/TeamsSingle.vue"

import Claims from "./views/Claim/Claims.vue";
import ClaimBrands from "./views/Claim/ClaimBrands.vue";
import ClaimCategories from "./views/Claim/ClaimCategories.vue";

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/changepassword",
        name: "changepassword",
        component: ChangePassword,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/",
        name: "dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/customer-notification",
        name: "customer-notification",
        component: CustomerNotification,
        meta: {
            title : "Customer Notification",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/pre-orders",
        name: "pre-orders",
        component: PreOrders,
        meta: {
            title : "Pre Orders",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/agents",
        name: "agents",
        component: Agent,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/items",
        name: "items",
        component: Items,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/items/:code",
        name: "ItemsSingle",
        component: ItemsSingle,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/users",
        name: "users",
        component: Users,
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/externalapitokens",
        name: "externalapitokens",
        component: ExternalApiTokens,
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/analysis/dead-stock",
        name: "AnalysisDeadStock",
        component: AnalysisDeadStock,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/analysis/sales",
        name: "analysis-sales",
        component: AnalysisSales,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/analysis/inventory",
        name: "analysisinventory",
        component: AnalysisInventory,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/shelfing/shelfing-reports",
        name: "shelfing-reports",
        component: ShelfingReports,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/shelfing/shelfing-summary",
        name: "shelfing-summary",
        component: ShelfingSummary,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/shelfing/shelfing-update",
        name: "shelfing-update",
        component: ShelfingUpdate,
        meta: {
            requiresAuth: true,
            title: "Shelfing Update",
        },
    },
    {
        path: "/report/shelfing/:id",
        name: "shelfing-report-single",
        component: ShelfingReportSingle,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/report/reorderstock",
        name: "reorderstock",
        component: ReorderStock,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/ecommerce",
        name: "ecommerce",
        component: Ecommerce,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/ecommerce/orders",
        name: "ecommerce-orders",
        component: EcommerceOrders,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/ecommerce/orders/:id_custom",
        name: "ecommerce-orders-single",
        component: EcommerceOrdersSingle,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/ecommerce/products",
        name: "ecommerce-products",
        component: EcommerceProducts,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/ecommerce/featured-products",
        name: "ecommerce-featured-products",
        component: EcommerceFeaturedProducts,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/ecommerce/products/add-new",
        name: "ecommerce-products-add-new",
        component: EcommerceProductsAddNew,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/ecommerce/brands",
        name: "ecommerce-brands",
        component: EcommerceBrands,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        }
    },
    {
        path: "/ecommerce/categories",
        name: "ecommerce-categories",
        component: EcommerceCategories,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        }
    },
    {
        path: "/ecommerce/products/:slug",
        name: "ecommerce-products-single",
        component: EcommerceProductsSingle,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/ecommerce/users",
        name: "ecommerce-users",
        component: EcommerceUsers,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/ecommerce/logs",
        name: "ecommerce-logs",
        component: EcommerceLogs,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/logs",
        name: "logs",
        component: Logs,
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/purchase/inventory-summary",
        name: "purchase-inventory-summary",
        component: PurchaseInventorySummary,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/purchase-requests",
        name: "purchase-requests",
        component: PurchaseRequests,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/purchase-requests/:doc_no",
        name: "purchase-requests-single",
        component: PurchaseRequestSingle,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/purchase-orders",
        name: "purchase-orders",
        component: PurchaseOrders,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/purchase-orders/:doc_no",
        name: "purchase-orders-single",
        component: PurchaseOrdersSingle,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/suppliers",
        name: "suppliers",
        component: Suppliers,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/suppliers/:id",
        name: "suppliers-single",
        component: SuppliersSingle,
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: "/stock-issue",
        name: "stock-issue",
        component: StockIssue,
        meta: {
            requiresAuth: true,
        },
    },

    // --------------------- Refill Groups ---------------------
    {
        path: "/refill-groups",
        name: "refill-groups",
        component: RefillGroups,
        meta: {
            requiresAuth: true,
            title: "Refill Groups",
        },
    },
    {
        path: "/refill-groups/add-items",
        name: "refill-groups-add-items",
        component: RefillGroupsAddItems,
        meta: {
            requiresAuth: true,
            title: "Add Item Refill Group",
        },
    },
    {
        path: "/refill-groups/:id",
        name: "refill-groups-single",
        component: RefillGroupsSingle,
        meta: {
            title: "Refill Group",
            requiresAuth: true,
        },
    },
    // ---------------------------------------------------------
    {
        path: "/teams",
        name: "teams",
        component: Teams,
        meta: {
            title: "Teams",
            requiresAuth: true,
        },
    },
    {
        path: "/teams/:id",
        name: "teams-single",
        component: TeamsSingle,
        meta: {
            requiresAuth: true,
        },
    },
    // --------------------------------- Claim ------------------------

    {
        path: "/claim-brands",
        name: "claim-brands",
        component: ClaimBrands,
        meta: {
            title: "Claim Brands",
            requiresAuth: true,
        },
    },
    {
        path: "/claims",
        name: "claims",
        component: Claims,
        meta: {
            title: "Claims",
            requiresAuth: true,
        },
    },
    {
        path: "/claim-categories",
        name: "claim-categories",
        component: ClaimCategories,
        meta: {
            title: "Claim Categories",
            requiresAuth: true,
        },
    },
];


const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        // savedPosition is only available for popstate navigations.
        if (savedPosition) return savedPosition

        // if the returned position is falsy or an empty object,
        // will retain current scroll position.
        if (to.params.savePosition) return {}

        // scroll to anchor by returning the selector
        if (to.hash) {
            let position = { selector: to.hash }

            // specify offset of the element
            // if (to.hash === '#anchor2') {
            //   position.offset = { y: 100 }
            // }
            return position
        }

        // scroll to top by default
        return { x: 0, y: 0 }
    }
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? "KM - " + to.meta.title : "KM Dashboard";
    next();
});

export default router;
