<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <!---------------------------------- Select Items By Type --------------------------------->
        <!-- Select Items by -->
        <div class="flex md:align-items-center select-methods flex-column md:flex-row gap-2 md:gap-0">
          <h5 class="m-0 mr-3">Select Items By:</h5>

          <Dropdown v-model="selectedItemSelectionMethod" :options="itemSelectionMethods" optionLabel="name"
            placeholder="Select a method" />

          <!-- Item Search -->
          <div v-if="selectedItemSelectionMethod &&
            selectedItemSelectionMethod.name == 'Item Search'
          " class="flex justify-content-left flex-column sm:flex-row md:ml-2">
            <IconField class="">
              <InputIcon class="pi pi-search" />
              <InputText v-model="searchField" placeholder="Search" style="width: 100%" @keydown="textChanged" />
            </IconField>
          </div>

          <span class="md:ml-2">
            {{ selectedStockGroups.map(stockGroup => stockGroup.STOCKGROUP).join(', ') }}
          </span>

          <span class="md:ml-2">
            {{ selectedSuppliers.map(supplier => supplier.COMPANYNAME).join(', ') }}
          </span>

          <Button text class="md:ml-3" @click="resetItemSelectionMethod">Reset</Button>
        </div>

        <!-- Stock Group Data Table -->
        <div v-if="selectedItemSelectionMethod &&
          selectedItemSelectionMethod.name == 'Stock Group'
        " class="w-6 mt-2">
          <DataTable responsiveLayout="scroll" :value="stockGroups" v-model:selection="selectedStockGroups"
            class="p-datatable-sm" dataKey="STOCKGROUP" :scrollable="true" scrollHeight="250px">
            <Column selectionMode="multiple" columnResizeMode="fit" style="width: 75px;"></Column>
            <Column field="STOCKGROUP" header="Code"></Column>
          </DataTable>
        </div>

        <!-- Suppliers Data Table -->
        <div v-if="selectedItemSelectionMethod &&
          selectedItemSelectionMethod.name == 'Supplier'
        " class="w-6 mt-2">
          <DataTable responsiveLayout="scroll" :value="suppliers" v-model:selection="selectedSuppliers"
            class="p-datatable-sm" dataKey="id" :scrollable="true" scrollHeight="250px">
            <Column selectionMode="multiple" columnResizeMode="fit" style="width: 75px;"></Column>
            <Column field="COMPANYNAME" header="Company Name"></Column>
          </DataTable>
        </div>

        <!---------------------------------- END --------------------------------->

        <!-- Data Table -->
        <DataTable ref="dt" :value="items" responsiveLayout="scroll" class="mt-5 p-datatable-sm"
          :virtualScrollerOptions="{ itemSize: 36, numToleratedItems: 400 }" :loading="loading" @sort="onSort"
          sortMode="multiple" lazy scrollable :scrollHeight="tableHeight" :multiSortMeta="multiSortMeta"
          exportFilename="analysis-items">
          <template #header>
            <div class="flex flex-row mt-2 gap-4">
              <div class="flex flex-column gap-1">
                <div class="mb-1">Show Out of stock</div>
                <InputSwitch v-model="filters.show_out_of_stock" />
              </div>
              <div class="flex flex-column gap-1">
                <div class="mb-1">Show Discontinued</div>
                <InputSwitch v-model="filters.show_discontinued" />
              </div>
              <Button class="hidden md:block" icon="pi pi-external-link" label="Export"
                @click="getItems(true)"></Button>
            </div>
          </template>
          <Column field="code" header="Code" :sortable="true">
            <template #body="{ data }">
              <router-link :to="'/items/' + data.code">{{ data.code }}</router-link>
            </template>
          </Column>
          <Column header="Description" field="DESCRIPTION" :sortable="true">
            <template #body="{ data }">
              <div class="flex align-items-start">
                <div>{{ data.DESCRIPTION }}
                  <span v-if="data.days_in_stock < 30" v-tooltip="data.days_in_stock + ' days of data'" style="background-color: #ED1C24; color: white; padding:
                                    0.2rem; border-radius: 1rem;">
                    <i class="pi pi-exclamation-triangle" style=" font-size: 0.8rem;"></i>
                  </span>

                  <span v-else-if="data.days_in_stock < 60" v-tooltip="data.days_in_stock + ' days of data'" style="background-color: #FF7F27; padding:
                                    0.2rem; border-radius: 1rem;"> <i class="pi pi-exclamation-triangle"
                      style=" font-size: 0.8rem;"></i> </span>

                  <span v-else-if="data.days_in_stock < 90" v-tooltip="data.days_in_stock + ' days of data'" style="background-color: #fbff0e; padding:
                                    0.2rem; border-radius: 1rem;"> <i class="pi pi-exclamation-triangle"
                      style=" font-size: 0.8rem;"></i> </span>
                </div>
              </div>
            </template>
          </Column>

          <Column field="REFPRICE" header="Price" :sortable="true"> </Column>
          <Column field="BALSQTY" header="Qty" :sortable="true"></Column>
          <Column field="generated_avg_qty_sold_per_mth" header="Avg Qty Sold Per Mth" :sortable="true"></Column>
          <Column field="generated_avg_sales_per_mth" header="Avg Sales Per Mth" :sortable="true"></Column>
          <Column field="generated_stock_end_days" header="Stock End Days" :sortable="true" class="hidden">
          </Column>
          <Column field="stock_end_date" header="Stock End Date" :sortable="true">
            <template #body="{ data }">
              <div class="flex flex-column gap-1">
                <div>{{ data.stock_end_date }}</div>
                <div v-if="data.generated_stock_end_days">({{ data.generated_stock_end_days }} days)</div>
              </div>
            </template>
          </Column>
          <Column field="last_sold_date" header="Last Sold Date" :sortable="true">
            <template #body="{ data }">
              <div class="flex flex-column gap-1">
                <div>{{ data.last_sold_date }}</div>
                <div v-if="data.generated_last_sold_days">({{ data.generated_last_sold_days }} days)</div>
              </div>
            </template>
          </Column>
          <Column field="median_days_between_sales" header="Median Days Between Sales" :sortable="true"></Column>
          <Column field="days_in_stock" header="Days In Stock" :sortable="true"></Column>

          <template #footer>
            <Paginator :rows="pagination.records_per_page" :totalRecords="pagination.total_records"
              @page="onPageChange($event)" :first="paginator_first">
            </Paginator>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      filters: {
        show_out_of_stock: true,
        show_discontinued: false,
      },
      utility: {
        timer: null,
      },
      pagination: {
        total_records: 0,
      },
      query: {
        page: 1,
        sortBy: '',
        filters: '',
      },
      paginator_first: 0,
      tableHeight: "500px",
      multiSortMeta: [],
      // Get From backend
      items: [],
      stockGroups: [],
      suppliers: [],
      // Item selection type
      searchField: "",
      selectedItemSelectionMethod: { name: "Item Search", value: "item_search" },
      itemSelectionMethods: [
        { name: "Item Search", value: "item_search" },
        { name: "Stock Group", value: "stock_group" },
        { name: "Supplier", value: "supplier" },
      ],
      selectedStockGroups: [],
      selectedSuppliers: [],
      isMounted: false,
    };
  },
  watch: {
    filters: {
      handler() {
        this.onFilterChange();
      },
      deep: true
    },
    selectedItemSelectionMethod: {
      handler() {
        this.resetItemSelectionMethod();
      },
      deep: true
    },
    selectedStockGroups: {
      handler() {
        if (this.selectedStockGroups.length == 0) {
          this.query.stock_groups = undefined;
        }
        else {
          this.query.stock_groups = this.selectedStockGroups.map(stockGroup => stockGroup.STOCKGROUP).join(',');

        }

        this.query.search = undefined;
        this.searchField = '';
        this.$router.push({ path: this.$route.fullPath, query: { ...this.query } });

        this.getItems();
      },
      deep: true
    },
    selectedSuppliers: {
      handler() {
        if (this.selectedSuppliers.length == 0) {
          this.query.suppliers = undefined;
        }
        else {
          this.query.suppliers = this.selectedSuppliers.map(supplier => supplier.id).join(',');
        }

        this.query.search = undefined;
        this.searchField = '';
        this.$router.push({ path: this.$route.fullPath, query: { ...this.query } });

        this.getItems();
      },
      deep: true
    },
  },
  created: async function () {
    this.getStockGroups();
    this.getSuppliers();

    this.query = { ...this.$route.query };

    if (this.query.page === undefined) {
      this.query.page = 1;
    }

    if (this.query.filters !== undefined) {
      const filters = this.query.filters.split(',');

      filters.forEach((filter) => {
        const filterArray = filter.split('-');
        this.filters[filterArray[0]] = filterArray[1] === 'true' ? true : false;
      });
    }
    else {
      this.query.filters = 'show_out_of_stock-true,show_discontinued-false';
    }

    if (this.query.sortBy !== undefined && this.query.sortBy !== '') {
      const sortFields = this.query.sortBy.split(',');
      sortFields.forEach((sortField) => {
        const sortArray = sortField.split('-');

        const order = sortArray[1] === 'asc' ? 1 : -1;
        this.multiSortMeta.push({ field: sortArray[0], order: order });
      });
    }

    if (this.query.search !== undefined && this.query.search !== '') {
      this.searchField = this.query.search;
    }

    if (this.$route.query != undefined && this.$route.query != null && Object.keys(this.$route.query).length > 0) {
      this.getItems();
    }

    this.onResize();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })

    this.isMounted = true;
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
    this.isMounted = false;
  },
  methods: {
    resetItemSelectionMethod() {
      this.query.search = undefined;
      this.searchField = '';

      this.selectedStockGroups = [];
      this.selectedSuppliers = [];

      this.$router.push({ path: this.$route.fullPath, query: { ...this.query } });

      this.getItems();
    },
    async getSuppliers() {
      await axios({
        method: "GET",
        url: "items/suppliers"
      }).then(
        (result) => {
          this.suppliers = result.data.suppliers;
        },
        (error) => {
          console.log(error.response.data);
        }
      );
    },
    async getStockGroups() {
      await axios({
        method: "GET",
        url: "items/stock-groups"
      }).then(
        (result) => {
          this.stockGroups = result.data.stockGroups;
        },
        (error) => {
          console.log(error.response.data);
        }
      );
    },
    onResize() {
      this.tableHeight = window.innerHeight - 400 + 'px';

      if (this.tableHeight < 500) {
        this.tableHeight = '500px';
      }
    },
    textChanged: function () {
      clearTimeout(this.utility.timer);

      this.utility.timer = setTimeout(() => {
        this.query.search = this.searchField;
        this.$router.push({ path: this.$route.fullPath, query: { ...this.query } });
        this.getItems();
      }, 400);
    },
    onPageChange($event) {
      this.query.page = $event.page + 1;
      this.$router.push({ path: this.$route.fullPath, query: { ...this.query } });
      this.getItems();
    },
    onSort($event) {
      this.query.page = 1;
      // Pluck field string from multiSortMeta
      const sortBy = $event.multiSortMeta.map((item) => {
        const order = item.order === 1 ? "asc" : "desc";
        return item.field + "-" + order;
      });

      this.query.sortBy = sortBy.join(",");
      this.$router.push({ path: this.$route.fullPath, query: { ...this.query } });

      this.getItems();
    },
    onFilterChange() {
      // Iterate through filters and add to query
      this.query.filters = '';

      const filtersArray = []

      Object.keys(this.filters).forEach((key) => {
        if (this.filters[key] !== null) {
          filtersArray.push(key + '-' + this.filters[key])
        }
      });

      this.query.filters = filtersArray.join(',');
      this.$router.push({ path: this.$route.fullPath, query: { ...this.query } });

      this.getItems();
    },
    getItems: async function (exportTable = false) {
      if ((this.query.search == undefined || this.query.search == null || this.query.search == '') &&
        (this.query.stock_groups == undefined || this.query.stock_groups == null || this.query.stock_groups == '') &&
        (this.query.suppliers == undefined || this.query.suppliers == null || this.query.suppliers == '')) {
        this.items = [];
        return
      }

      this.loading = true;
      const params = new URLSearchParams();
      params.append("page", this.query.page);

      if (this.query.sortBy) {
        params.append("sortBy", this.query.sortBy);
      }

      if (this.query.search) {
        params.append("search", this.query.search);
      }

      if (this.query.stock_groups) {
        params.append("stock_groups", this.query.stock_groups);
      }

      if (this.query.filters) {
        params.append("filters", this.query.filters);
      }

      if (this.query.suppliers) {
        params.append("suppliers", this.query.suppliers);
      }

      if (exportTable == true) {
        params.append("export", true);
      }

      await axios({
        method: "GET",
        url: "analysis/items",
        params: params,
      }).then(
        (result) => {
          // Do this if still mounted
          if (!this.isMounted) {
            return;
          }

          this.$refs.dt.getVirtualScrollerRef().scrollToIndex(0);

          const tempItems = exportTable ? result.data.items : result.data.items.data;
          // Add field to items
          tempItems.forEach((item) => {
            item.REFPRICE = parseFloat(item.REFPRICE).toFixed(2);

            if (item.last_sold_date !== null) {
              // Get days between last sold days and today
              const lastSoldDate = new Date(item.last_sold_date);
              const today = new Date();
              const diffTime = (today - lastSoldDate);
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              item.generated_last_sold_days = diffDays;
            }

            if (item.stock_end_date !== null) {
              // Get days between stock end date and today
              const stockEndDate = new Date(item.stock_end_date);
              const today = new Date();
              const diffTime = Math.abs(today - stockEndDate);
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              item.generated_stock_end_days = diffDays;
            }

            if (item.median_days_between_sales !== null) {
              item.median_days_between_sales = Math.round(item.median_days_between_sales * 10) / 10;
            }
          });

          if (exportTable == true) {
            const oldItems = this.items;
            this.items = tempItems;
            // next tick
            this.$nextTick(() => {
              this.$refs.dt.exportCSV();

              // Reset items
              this.items = oldItems;
            });
            return;
          }

          this.items = tempItems;
          this.pagination.total_records = result.data.items.total
          this.pagination.records_per_page = result.data.items.per_page
          this.paginator_first = (this.query.page - 1) * this.pagination.records_per_page;

        },
        (error) => {
          console.log(error.response.data);
        }
      );
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
