<template>
    <div class="flex w-12 gap-3 relative">
        <div class="w-9 card">
            <!-- Name -->
            <div class="flex text-lg gap-3 align-items-center">
                <span class="font-bold w-2">Name:</span>
                <div class="flex gap-3 w-10">
                    <InputText v-model="inputs.name" class="p-inputtext-md w-6" />
                </div>
            </div>

            <!-- Slug -->
            <div class="flex text-lg gap-3 mt-3 align-items-center">
                <span class="font-bold w-2">Slug:</span>
                <div class="flex gap-3 w-10 ">
                    <InputText v-model="inputs.slug" class="p-inputtext-md w-6" />
                </div>
            </div>

            <!-- SEO Name -->
            <div class="flex text-lg gap-3 mt-3 align-items-center">
                <span class="font-bold w-2">SEO Title:</span>
                <div class="flex gap-3 w-10">
                    <InputText v-model="inputs.seo_title" class="p-inputtext-md w-6" />
                </div>
            </div>

            <!-- SEO Description -->
            <div class="flex text-lg gap-3 mt-3 align-items-center">
                <span class="font-bold w-2">SEO Description:</span>
                <div class="flex gap-3 w-10 ">
                    <InputText v-model="inputs.seo_description" class="p-inputtext-md w-6" />
                </div>
            </div>


            <!-- Brand -->
            <div class="text-lg font-bold flex align-items-center gap-3 mt-6 mb-2">
                Brand
            </div>
            <Dropdown v-model="inputs.brand" :options="availableBrands" optionLabel="name" placeholder="Select a Brand"
                filter class="w-full md:w-14rem" />

            <!-- Categories -->
            <div class="text-lg font-bold flex align-items-center gap-3 mt-6 mb-2">
                Categories
            </div>
            <div class="flex flex-column align-items-stretch gap-2 w-12 lg:w-8 xl:w-24rem">
                <IconField class="">
                    <InputIcon class="pi pi-filter" />
                    <InputText v-model="filter.category" class="p-inputtext-md w-full" />
                </IconField>
                <div style="height: 450px;" class="overflow-auto pr-3 px-2">
                    <div v-for="category in inputs.categories" :key="category.name">
                        <div class="mb-3 flex justify-content-between w-12"
                            v-if="(category.label.toUpperCase()).includes(filter.category.toUpperCase()) || filter.category == ''">
                            <div class="w-max">
                                <Checkbox v-model="category.selected" :binary="true"
                                    :inputId="category.slug + '-select'" :name="category.slug + '-select'" />
                                <label :for="category.slug + '-select'" class="ml-2"> {{ category.label }} </label>
                            </div>

                            <RadioButton v-model="inputs.primaryCategorySlug" :inputId="category.slug + '-primary'"
                                :value="category.slug" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- Warranty Period -->
            <div class="mt-6">
                <div class="text-lg font-bold flex align-items-center gap-3">
                    Warranty Period
                </div>
                <InputText v-model="inputs.warranty_period" class="mt-2" />
            </div>

            <!-- Warranty Details -->
            <div class="mt-6">
                <div class="text-lg font-bold flex align-items-center gap-3">
                    Warranty Details
                </div>
                <Editor v-model="inputs.warranty_details" editorStyle="height: 150px" class="mt-2">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <select v-tooltip.bottom="'Header'" class="ql-header"></select>
                            <select v-tooltip.bottom="'Font'" class="ql-font"></select>
                            <select v-tooltip.bottom="'Size'" class="ql-size"></select>
                            <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                            <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                            <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                            <button v-tooltip.bottom="'Code'" class="ql-code"></button>
                            <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                            <select v-tooltip.bottom="'Color'" class="ql-color"></select>
                            <select v-tooltip.bottom="'Background'" class="ql-background"></select>
                            <select v-tooltip.bottom="'Align'" class="ql-align"></select>
                            <button v-tooltip.bottom="'Blockquote '" class="ql-blockquote"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="ordered"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="bullet"></button>
                            <button v-tooltip.bottom="'Code Block '" class="ql-code-block"></button>
                        </span>
                    </template>
                </Editor>
            </div>

            <!-- Short Description -->
            <div class="mt-6">
                <div class="text-lg font-bold flex align-items-center gap-3">
                    Short Description
                </div>
                <Editor v-model="inputs.description_short" editorStyle="height: 150px" class="mt-2">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <select v-tooltip.bottom="'Header'" class="ql-header"></select>
                            <select v-tooltip.bottom="'Font'" class="ql-font"></select>
                            <select v-tooltip.bottom="'Size'" class="ql-size"></select>
                            <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                            <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                            <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                            <button v-tooltip.bottom="'Code'" class="ql-code"></button>
                            <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                            <select v-tooltip.bottom="'Color'" class="ql-color"></select>
                            <select v-tooltip.bottom="'Background'" class="ql-background"></select>
                            <select v-tooltip.bottom="'Align'" class="ql-align"></select>
                            <button v-tooltip.bottom="'Blockquote '" class="ql-blockquote"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="ordered"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="bullet"></button>
                            <button v-tooltip.bottom="'Code Block '" class="ql-code-block"></button>
                        </span>
                    </template>
                </Editor>
            </div>

            <!-- Long Description -->
            <LongDescriptionEditor v-model:description_long="inputs.description_long" type="new-product"> </LongDescriptionEditor>

            <!-- Long Description -->
            <div class="mt-6">
                <div class="text-lg font-bold flex align-items-center gap-3">
                    Long Description
                </div>
                <Editor ref="editorLongDescription" v-model="inputs.description_long" editorStyle="height: 320px"
                    class="mt-2">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <select v-tooltip.bottom="'Header'" class="ql-header"></select>
                            <select v-tooltip.bottom="'Font'" class="ql-font"></select>
                            <select v-tooltip.bottom="'Size'" class="ql-size"></select>
                            <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                            <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                            <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                            <button v-tooltip.bottom="'Code'" class="ql-code"></button>
                            <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                            <select v-tooltip.bottom="'Color'" class="ql-color"></select>
                            <select v-tooltip.bottom="'Background'" class="ql-background"></select>
                            <select v-tooltip.bottom="'Align'" class="ql-align"></select>
                            <button v-tooltip.bottom="'Blockquote '" class="ql-blockquote"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="ordered"></button>
                            <button v-tooltip.bottom="'List'" class="ql-list" value="bullet"></button>
                            <button v-tooltip.bottom="'Code Block '" class="ql-code-block"></button>
                            <button id="add-media-button" @click="addMediaToDescriptionClicked">Media</button>
                        </span>
                    </template>
                </Editor>
            </div>

            <!-- Medias -->
            <div class="flex mt-6">
                <div class="text-lg font-bold flex align-items-center gap-3">
                    Medias
                </div>
                <Button class="py-0 px-2" label="Add" @click="addMediaClicked" text></Button>
            </div>
            <div class="flex p-3 border-solid border-round border-1 border-200 mt-2">
                <draggable class="dragArea list-group w-12 flex flex-wrap gap-2" :list="inputs.medias"
                    ghost-class="ghost">
                    <div class="list-group-item bg-gray-300 rounded-md text-center cursor-pointer relative"
                        v-for="element, index in inputs.medias" style="width: 19%;" :key="element.url">
                        <img :src="element.url" alt="" style="width: 100%;">

                        <Button label="" icon="pi pi-trash" class="p-button-danger" style="
                                position: absolute;
                                top: 5px;
                                right: 5px;" @click="removeMedia(index)"></Button>
                    </div>
                </draggable>
            </div>

            <!-- Is Variable -->
            <div class="flex text-lg gap-3 align-items-center mt-6">
                <span class="font-bold w-2">Is Variable Product:</span>
                <InputSwitch v-model="inputs.isVariable" />
            </div>

            <!-- Variations -->
            <div v-if="inputs.isVariable" class="mt-3">
                <div class="flex text-lg gap-3 align-items-center mt-6 font-bold">
                    Variable Product Options
                </div>

                <div class="flex mt-4 mb-3 text-lg gap-3">
                    <div class="w-3">Option Name</div>
                    <div class="w-9">Option Values - Separated by commas (Eg: black,white,grey)</div>
                </div>

                <div v-for="option, index in inputs.options_string" :key="index" class="flex gap-3 mt-1">
                    <InputText v-model="option.name" class="w-3" />
                    <InputText v-model="option.values" class="w-9" />
                </div>

                <Button class="mt-3" text severity="primary" label="+ Add Option"
                    @click="inputs.options_string.push({ name: '', values: '' })"></Button>
                <Button class="mt-3 block" label="Save" @click="convertOptionsString"></Button>


                <div class="flex text-lg gap-3 align-items-center mt-6 font-bold">
                    Variants
                </div>

                <DataTable class="mt-3" :value="inputs.variants">
                    <Column header="Image" style="width: 10%">
                        <template #body="{ data, index }">
                            <div style="width:50px; height: 50px; cursor: pointer; background: grey;"
                                @click="selectVariantMediaClicked(index)">
                                <img :src="data.media ? data.media.url : 'test'" alt="" width="50"
                                    style="aspect-ratio: 1;" />
                            </div>
                        </template>
                    </Column>
                    <Column header="SKU" style="width: 10%">
                        <template #body="{ data }">
                            <InputText v-model="data.sku" style="width:100%;" />
                        </template>
                    </Column>

                    <Column header="Dimensions (cm x cm x cm)" style="width: 15%">
                        <template #body="{ data }">
                            <InputText v-model="data.length" style="width:33%;" />
                            <InputText v-model="data.width" style="width:33%;" />
                            <InputText v-model="data.height" style="width:33%;" />
                        </template>
                    </Column>

                    <Column header="Weight (kg)" style="width: 8%">
                        <template #body="{ data }">
                            <InputText v-model="data.weight" style="width:100%;" />
                        </template>
                    </Column>

                    <Column header="Cart Max Qty" style="width: 10%">
                        <template #body="{ data }">
                            <InputText v-model="data.add_to_cart_quantity_max_value" style="width:100%;" />
                        </template>
                    </Column>

                    <Column header="Sync Reduce Qty" style="width: 10%">
                        <template #body="{ data }">
                            <InputText v-model="data.inventory_quantity_reduce_value" style="width:100%;" />
                        </template>
                    </Column>

                    <Column v-for="option in inputs.product_options" :key="option.name" :header="option.name"
                        style="width: 10%">
                        <template #body="{ data }">
                            <span>{{ data.product_option_values.find(x => x.name === option.name) ?
                        data.product_option_values.find(x => x.name === option.name).value :
                        'Not found' }}</span>
                        </template>
                    </Column>
                </DataTable>
            </div>



            <!-- Simple -->
            <DataTable v-else class="mt-3" :value="inputs.variants">
                <Column header="SKU" style="width: 10%">
                    <template #body="{ data }">
                        <InputText v-model="data.sku" style="width:100%;" />
                    </template>
                </Column>

                <Column header="Dimensions (cm x cm x cm)" style="width: 15%">
                    <template #body="{ data }">
                        <InputText v-model="data.length" style="width:33%;" />
                        <InputText v-model="data.width" style="width:33%;" />
                        <InputText v-model="data.height" style="width:33%;" />
                    </template>
                </Column>

                <Column header="Weight (kg)" style="width: 8%">
                    <template #body="{ data }">
                        <InputText v-model="data.weight" style="width:100%;" />
                    </template>
                </Column>

                <Column header="Cart Max Qty" style="width: 10%">
                    <template #body="{ data }">
                        <InputText v-model="data.add_to_cart_quantity_max_value" style="width:100%;" />
                    </template>
                </Column>

                <Column header="Sync Reduce Qty" style="width: 10%">
                    <template #body="{ data }">
                        <InputText v-model="data.inventory_quantity_reduce_value" style="width:100%;" />
                    </template>
                </Column>
            </DataTable>

        </div>
        <div class="w-3 card h-min"><Button label="Save" @click="save"></Button></div>
    </div>
    <ModalAddImage v-model="modalShow.addImage" @save="pushMedias" />
    <ModalAddImage v-model="modalSelectVariantImage.show" @save="selectVariantMedia" />
</template>

<script>
import moment from "moment";
import Editor from 'primevue/editor';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import ModalAddImage from '../../components/Modal/ModalAddImage.vue';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import { VueDraggableNext } from 'vue-draggable-next'
import router from '../../router';
import LongDescriptionEditor from '@/components/Ecommerce/LongDescriptionEditor.vue';

export default {
    data() {
        return {
            isLoading: { product: false },
            product: null,
            inputs: {
                name: "",
                slug: "",
                brand: {},
                categories: [],
                primaryCategorySlug: null,
                warranty_period: null,
                warranty_details: null,
                description_short: null,
                description_long: null,
                medias: [],
                isVariable: true,
                variants: [{
                    media: null,
                    sku: null,
                    length: null,
                    width: null,
                    height: null,
                    weight: null,
                    add_to_cart_quantity_max_value: null,
                    inventory_quantity_reduce_value: null,
                    product_option_values: []
                }],
                product_options: [],
                options_string: [{ name: "", values: "" }],
            },
            modalShow: {
                addImage: false,
            },
            availableBrands: [],
            modalSelectVariantImage: {
                show: false,
                index: null,
            },
            filter: {
                category: '',
            }
        }
    },
    watch: {
        'inputs.name': function (val) {
            this.inputs.slug = val
                .replace(/[^A-Za-z0-9-\s]/g, "")
                .replace(/\s+/g, "-")
                .toLowerCase();
            this.inputs.seo_title = val;
        },
        'inputs.categories': {
            handler(val) {
                // If there is no primary category, set the first selected as primary
                if (this.inputs.primaryCategorySlug == null && val.length > 0) {
                    // Check if any category with selected as true
                    let primaryCategory = val.find(x => x.selected == true);

                    if (primaryCategory) {
                        this.inputs.primaryCategorySlug = primaryCategory.slug;
                    }
                }
            },
            deep: true
        }
    },
    components: {
        Editor, Button,
        draggable: VueDraggableNext,
        ModalAddImage,
        InputText,
        Checkbox, InputSwitch,
        LongDescriptionEditor
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            let url = process.env.VUE_APP_ECOMMERCE_API_URL + '/products-admin/add-product-data';

            await fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                },
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200) {
                        console.log("Error");
                        console.log(result);
                        return;
                    }

                    result.json().then((data) => {
                        console.log(data);
                        // Flatten the categories tree
                        let treeCategories = [...data.categories];
                        let flatennedCategories = [];

                        let flattenCategoriesRecursive = (categories, step = 0) => {
                            categories.forEach((category) => {
                                // Append -- to the label to show hierarchy
                                category.label = '--'.repeat(step) + ' ' + category.name;
                                category.selected = false;

                                flatennedCategories.push(category);
                                if (category.children.length > 0) {
                                    flattenCategoriesRecursive(category.children, step + 1);
                                }
                            });
                        }
                        flattenCategoriesRecursive(treeCategories);

                        this.inputs.categories = flatennedCategories;
                        this.availableBrands = data.brands;
                    });
                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
        },
        async save() {
            let url = process.env.VUE_APP_ECOMMERCE_API_URL + '/products-admin';

            const postData = {};
            postData.name = this.inputs.name;
            postData.slug = this.inputs.slug;
            postData.seo_title = this.inputs.seo_title;
            postData.seo_description = this.inputs.seo_description;
            postData.description_short = this.inputs.description_short;
            postData.description_long = this.inputs.description_long;
            postData.warranty_period = this.inputs.warranty_period;
            postData.warranty_details = this.inputs.warranty_details;
            postData.type = this.inputs.isVariable ? 'variable' : 'simple';

            postData.primary_category = this.inputs.primaryCategorySlug;
            postData.brand = this.inputs.brand.slug;

            postData.is_sync_to_shopee = false;
            postData.is_sync_to_facebook = true;

            // Map categories to array of slug only
            postData.categories = this.inputs.categories.filter(x => x.selected).map(x => x.slug);

            postData.media_ids = this.inputs.medias.map(x => x.id);

            if (this.inputs.isVariable) {
                postData.product_options = this.inputs.product_options;

                const variantsWithSKU = this.inputs.variants.filter(x => x.sku != null);

                postData.variants = variantsWithSKU.map((variant) => {
                    let variantData = {};
                    variantData.sku = variant.sku;
                    variantData.length = variant.length;
                    variantData.width = variant.width;
                    variantData.height = variant.height;
                    variantData.weight = variant.weight;
                    variantData.add_to_cart_quantity_max_value = variant.add_to_cart_quantity_max_value;
                    variantData.inventory_quantity_reduce_value = variant.inventory_quantity_reduce_value;
                    variantData.option_values = variant.product_option_values;
                    variantData.media_id = variant.media ? variant.media.id : null;

                    return variantData;
                });
            }

            if (!this.inputs.isVariable) {
                postData.simple_product_fields = {};
                postData.simple_product_fields.sku = this.inputs.variants[0].sku;
                postData.simple_product_fields.length = this.inputs.variants[0].length;
                postData.simple_product_fields.width = this.inputs.variants[0].width;
                postData.simple_product_fields.height = this.inputs.variants[0].height;
                postData.simple_product_fields.weight = this.inputs.variants[0].weight;
                postData.simple_product_fields.add_to_cart_quantity_max_value = this.inputs.variants[0].add_to_cart_quantity_max_value;
                postData.simple_product_fields.inventory_quantity_reduce_value = this.inputs.variants[0].inventory_quantity_reduce_value;
            }




            await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...postData
                })
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200 && result.status != 201) {
                        console.log("Error - Not 200");

                        result.json().then((data) => {
                            this.$toast.add({
                                severity: "warn",
                                summary: "Failed",
                                detail: data.message,
                                life: 3000,
                            });
                        });

                        return;
                    }

                    result.json().then((data) => {
                        this.$toast.add({
                            severity: "success",
                            summary: "Success",
                            detail: data.message,
                            life: 3000,
                        });

                        // Redirect to products page
                        router.push('/ecommerce/products');
                    });
                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
        },
        dateTimeFormat(timestampString) {
            // If less than 24 hours ago, show hours ago
            if (moment().diff(moment(timestampString), 'hours') < 24) {
                return moment(timestampString).fromNow();
            }

            // If less than 7 days ago, show days ago
            if (moment().diff(moment(timestampString), 'days') < 7) {
                return moment(timestampString).fromNow();
            }

            // Display as format "dd MMM yyyy HH:mm"
            return moment(timestampString).format('DD MMM YYYY, HH:mm');
        },
        dateFormat(timestampString) {
            return moment(timestampString).format('DD MMM YYYY, HH:mm');
        },
        priceFormat(price) {
            return "RM" + parseFloat(price).toFixed(2)
        },
        removeMedia(index) {
            this.inputs.medias.splice(index, 1);
        },
        pushMedias(selectedMedias) {
            this.inputs.medias.push(...selectedMedias);
        },
        addMediaClicked() {
            this.modalShow.addImage = true;
        },
        selectVariantMediaClicked(index) {
            this.modalSelectVariantImage.show = true;
            this.modalSelectVariantImage.index = index;
            console.log(index)
        },
        convertOptionsString() {
            this.inputs.product_options = [];
            this.inputs.options_string.forEach(
                (option_string) => {
                    console.log(option_string.name);
                    const values = option_string.values.split(',').map(x => x.trim());
                    this.inputs.product_options.push(
                        { name: option_string.name, values: values }
                    );
                }
            );

            // Generate variants with all options combination
            this.inputs.variants = this.generateVariants(this.inputs.product_options);
        },
        generateVariants(product_options) {
            let obj = {};
            product_options.forEach((option) => {
                obj[option.name] = option.values;
            });

            let combos = [{}];
            Object.entries(obj).forEach(([key, values]) => {
                let all = [];
                values.forEach((value) => {
                    combos.forEach((combo) => {
                        all.push({ ...combo, [key]: value });
                    });
                });
                combos = all;
            });

            const variants = [];

            combos.forEach((variantCombo) => {
                const variant = {
                    sku: null,
                    length: null,
                    width: null,
                    height: null,
                    weight: null,
                    add_to_cart_quantity_max_value: null,
                    inventory_quantity_reduce_value: null,
                    product_option_values: []
                };


                for (const [key, value] of Object.entries(variantCombo)) {
                    variant.product_option_values.push({ name: key, value: value });
                }

                variants.push(variant);
            });

            console.log(variants);

            return variants;
        },
        selectVariantMedia(selectedMedias) {
            this.inputs.variants[this.modalSelectVariantImage.index].media = selectedMedias[0];
        },
    },

};
</script>

<style scoped></style>
