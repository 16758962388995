<script setup>
import { ref, onMounted } from 'vue'
import ProductService from '@/service/ProductService';
import AutoCompleteSupplier from '@/components/AutoCompleteSupplier.vue';
import axios from 'axios'
import ButtonCopyToExcel from '@/components/ButtonCopyToExcel.vue';
import SelectStockGroup from '@/components/SelectStockGroup.vue';

const query = ref({});

const filters = ref({ show_discontinued: false });

const productService = ref(null);

const isLoading = ref({
    summary: false
});

const inputs = ref({
    selectedItemSelectionMethod: { name: "Supplier" },
    stockGroups: [],
    supplier: null,
})

const itemSelectionMethods = ref([
    { name: "Supplier" },
    { name: "Item Search" },
    { name: "Stock Group" },
]);

const resetItemSelectionMethod = () => {
    inputs.value.search = '';
    inputs.value.stockGroups = [];
    inputs.value.supplier = null;
}

const updateInputsToQuery = () => {
    query.value = {}

    if (inputs.value.search) {
        query.value.search = inputs.value.search;
    }

    if (inputs.value.stockGroups) {
        query.value.stockGroups = inputs.value.stockGroups.map((stockGroup) => stockGroup.STOCKGROUP)
    }

    if (inputs.value.supplier) {
        query.value.supplier_id = inputs.value.supplier.id;
    }

    // Iterate each filter and add to query
    let filtersString = ''
    for (const filterName in filters.value) {
        const value = filters.value[filterName];
        filtersString += `${filterName}-${value}`
    }
    if (filtersString != '') {
        query.value.filters = filtersString;
    }
}

const results = ref([]);

const generateSummary = async () => {
    updateInputsToQuery();

    isLoading.value.summary = true;
    const params = new URLSearchParams();
    if (query.value.search) {
        params.append("search", query.value.search);
    }

    if (query.value.stockGroups && query.value.stockGroups.length > 0) {
        params.append("stock_groups", query.value.stockGroups);
    }

    if (query.value.supplier_id) {
        params.append("supplier_id", query.value.supplier_id);
    }

    if (query.value.filters) {
        params.append("filters", query.value.filters);
    }

    params.append("export", true);

    await axios({
        method: "GET",
        url: "analysis/items",
        params: params,
    }).then(
        (result) => {
            let tempItems = result.data.items;

            const tempResults = [
                { title: "0-29 Days of Stock", items: [], worth_of_sales_per_mth: 0 },
                { title: "31-60 Days of Stock", items: [], worth_of_sales_per_mth: 0 },
                { title: "61-90 Days of Stock", items: [], worth_of_sales_per_mth: 0 },
            ];

            // Add generated field to items
            tempItems.forEach((item) => {
                // Round to 1 decimal
                item.REFPRICE = parseFloat(item.REFPRICE).toFixed(2);

                if (item.last_sold_date !== null) {
                    // Get days between last sold days and today
                    const lastSoldDate = new Date(item.last_sold_date);
                    const today = new Date();
                    const diffTime = (today - lastSoldDate);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    item.generated_last_sold_days = diffDays;
                }

                if (item.stock_end_date !== null) {
                    // Get days between stock end date and today
                    const stockEndDate = new Date(item.stock_end_date);
                    const today = new Date();
                    let diffTime = stockEndDate - today;

                    if (diffTime < 0) {
                        diffTime = 0;
                    }

                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                    item.generated_stock_end_days = diffDays;
                    item.generated_stock_end_days_including_po = diffDays;

                    // Check if any qty_po, if yes add into diffDays to get the actual days
                    if (item.qty_po > 0) {
                        const daysOfStockIncoming = Math.ceil(item.qty_po / item.generated_avg_qty_sold_per_mth * 30);
                        item.generated_stock_end_days_including_po = diffDays + daysOfStockIncoming;
                    }
                }

                item.median_days_between_sales = Math.round(item.median_days_between_sales * 10) / 10;

                if (item.generated_stock_end_days_including_po >= 0 && item.generated_stock_end_days_including_po <= 29) {
                    tempResults[0].items.push(item);
                    tempResults[0].worth_of_sales_per_mth += Math.floor(item.generated_avg_sales_per_mth);
                } else if (item.generated_stock_end_days_including_po >= 31 && item.generated_stock_end_days_including_po <= 60) {
                    tempResults[1].items.push(item);
                    tempResults[1].worth_of_sales_per_mth += Math.floor(item.generated_avg_sales_per_mth);
                } else if (item.generated_stock_end_days_including_po >= 61 && item.generated_stock_end_days_including_po <= 90) {
                    tempResults[2].items.push(item);
                    tempResults[2].worth_of_sales_per_mth += Math.floor(item.generated_avg_sales_per_mth);
                }


            });

            results.value = tempResults;

            // Scroll to card-result
            const cardResult = document.getElementById("card-result");
            cardResult.scrollIntoView({ behavior: "smooth" });
        },
        (error) => {
            console.log("Error getting");
            console.log(error.response.data);
        }
    );
    isLoading.value.summary = false;
}

// ------------------------------------ //
// --------- Dialog Purchases --------- //
// ------------------------------------ //
const dialogViewPurchases = ref({
    show: false,
    code: null,
    isLoading: false,
    purchase_requests: [],
    purchase_orders: [],
})

const viewPurchases = async (code) => {
    dialogViewPurchases.value.show = true;
    dialogViewPurchases.value.code = code;
    dialogViewPurchases.value.isLoading = true;


    await axios({
        method: "GET",
        url: "items/pending-purchases?code=" + code,
    }).then(
        (result) => {
            dialogViewPurchases.value.purchase_requests = result.data.purchase_requests;
            dialogViewPurchases.value.purchase_orders = result.data.purchase_orders;

            dialogViewPurchases.value.isLoading = false;
        },
        (error) => {
            dialogViewPurchases.value.isLoading = false;
            console.log(error.response.data);
        }
    );
}

// --------- Dialog Purchases --------- //

onMounted(() => {
    productService.value = new ProductService();
})
</script>

<template>
    <!-- Inputs -->
    <div class="card">
        <div class="flex gap-6">
            <div class="6">
                <!-- Select Items by -->
                <div>
                    <div class="flex align-items-center">
                        <div class="w-15rem text-lg font-medium">Select Items By:</div>
                        <Dropdown v-model="inputs.selectedItemSelectionMethod" :options="itemSelectionMethods"
                            optionLabel="name" placeholder="Select a method" @change="resetItemSelectionMethod" />

                        <IconField v-if="inputs.selectedItemSelectionMethod &&
                            inputs.selectedItemSelectionMethod.name == 'Item Search'
                        " class="">
                            <InputIcon class="pi pi-search" />
                            <InputText v-model="inputs.search" placeholder="Search item" />
                        </IconField>

                        <!-- Supplier Selection -->
                        <div v-if="inputs.selectedItemSelectionMethod &&
                            inputs.selectedItemSelectionMethod.name == 'Supplier'
                        ">
                            <AutoCompleteSupplier v-model="inputs.supplier">
                            </AutoCompleteSupplier>
                        </div>

                        <Button v-if="inputs.selectedItemSelectionMethod &&
                            inputs.selectedItemSelectionMethod.name == 'Item Search'
                        " text class="ml-3" @click="resetItemSelectionMethod">Reset</Button>
                    </div>

                    <!-- Stock Group Data Table -->
                    <div v-if="inputs.selectedItemSelectionMethod &&
                        inputs.selectedItemSelectionMethod.name == 'Stock Group'
                    " class="w-12 mt-2">
                        <SelectStockGroup v-model:selectedStockGroups="inputs.stockGroups" />
                    </div>
                </div>
                <Button label="Generate Report(s)" class="p-button-raised mt-4" @click="generateSummary"
                    :loading="isLoading.summary"
                    :disabled="inputs.selectedItemSelectionMethod.name == 'Supplier' && inputs.supplier == null
                        || inputs.selectedItemSelectionMethod.name == 'Item Search' && inputs.search.length < 3
                        || inputs.selectedItemSelectionMethod.name == 'Stock Group' && inputs.stockGroups.length < 1" />
            </div>
        </div>


        <!-- Results -->
        <div id="card-result" class="card flex flex-column gap-8 mt-6">
            <div v-for="result in results" :key="result.title">
                <div class="text-4xl font-medium">{{ result.title }}</div>
                <div class="text-lg">{{ result.items.length }} items</div>
                <div class="text-lg">Worth of sales per mth: RM{{ result.worth_of_sales_per_mth }}</div>

                <DataTable :loading="isLoading.summary" :value="result.items" responsiveLayout="scroll" scrollable
                    scrollHeight="calc(85vh - 180px)" size="small">
                    <template #header>
                        <div class="flex justify-content-left flex-column sm:flex-row align-items-center gap-2">
                            <ButtonCopyToExcel :rows="result.items" :disabled="result.items.length <= 0"
                                type="purchase_inventory_summary">
                            </ButtonCopyToExcel>
                        </div>
                    </template>
                    <Column field="code" header="Code" :sortable="true">
                        <template #body="{ data }">
                            <!-- Open in new tab -->
                            <a :href="'/items/' + data.code" target="_blank">{{ data.code }}</a>
                        </template>
                    </Column>
                    <Column header="Description" field="DESCRIPTION" :sortable="true">
                        <template #body="{ data }">
                            <div class="flex align-items-start">
                                <div>{{ data.DESCRIPTION }}
                                    <span v-if="data.days_in_stock < 30"
                                        v-tooltip="data.days_in_stock + ' days of data'" style="background-color: #ED1C24; color: white; padding:
                                    0.2rem; border-radius: 1rem;">
                                        <i class="pi pi-exclamation-triangle" style=" font-size: 0.8rem;"></i>
                                    </span>

                                    <span v-else-if="data.days_in_stock < 60"
                                        v-tooltip="data.days_in_stock + ' days of data'" style="background-color: #FF7F27; padding:
                                    0.2rem; border-radius: 1rem;"> <i class="pi pi-exclamation-triangle"
                                            style=" font-size: 0.8rem;"></i> </span>

                                    <span v-else-if="data.days_in_stock < 90"
                                        v-tooltip="data.days_in_stock + ' days of data'" style="background-color: #fbff0e; padding:
                                    0.2rem; border-radius: 1rem;"> <i class="pi pi-exclamation-triangle"
                                            style=" font-size: 0.8rem;"></i> </span>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column field="BALSQTY" header="Qty (Bal.)" :sortable="true"></Column>
                    <Column field="qty_pq" header="Qty (PQ)" :sortable="true">
                        <template #body="{ data }">
                            <div v-if="data.qty_pq > 0" class="flex gap-1 align-items-center">
                                {{ data.qty_pq }}
                                <Button icon="pi pi-info-circle" @click="viewPurchases(data.code)" severity="secondary"
                                    text class="p-0 w-min" />
                            </div>
                        </template>
                    </Column>
                    <Column field="qty_po" header="Qty (PO)" :sortable="true">
                        <template #body="{ data }">
                            <div v-if="data.qty_po > 0" class="flex gap-1 align-items-center">
                                {{ data.qty_po }}
                                <Button icon="pi pi-info-circle" @click="viewPurchases(data.code)" severity="secondary"
                                    text class="p-0 w-min" />
                            </div>
                        </template>
                    </Column>
                    <Column field="generated_avg_qty_sold_per_mth" header="Avg Qty Per Mth" :sortable="true">
                    </Column>
                    <Column field="generated_stock_end_days_including_po" header="Stock End Days" :sortable="true">
                    </Column>
                    <Column field="days_in_stock" header="Days In Stock" :sortable="true"></Column>
                </DataTable>
            </div>
        </div>
    </div>

    <!-- Show View Purchases Dialog -->
    <Dialog v-model:visible="dialogViewPurchases.show" modal header="View Purchases" :style="{ width: '50rem' }">
        <div class="mb-4">
            <div class="font-medium text-xl">Purchase Requests</div>
            <DataTable :loading="dialogViewPurchases.isLoading" :value="dialogViewPurchases.purchase_requests"
                size="small">
                <Column field="date" header="Date"></Column>
                <Column field="doc_no" header="Doc No"></Column>
                <Column field="description" header="description"></Column>
                <Column field="qty" header="Qty"></Column>
                <Column field="qty_fulfilled" header="Qty Fulfilled"></Column>
                <Column field="qty_pending" header="Qty Pending"></Column>
            </DataTable>
        </div>
        <div>
            <div class="font-medium text-xl">Purchase Orders</div>
            <DataTable :loading="dialogViewPurchases.isLoading" :value="dialogViewPurchases.purchase_orders"
                size="small">
                <Column field="date" header="Date"></Column>
                <Column field="doc_no" header="Doc No"></Column>
                <Column field="description" header="Description"></Column>
                <Column field="qty_pending" header="Qty Pending"></Column>
            </DataTable>
        </div>
    </Dialog>
</template>