<template>
    <AutoComplete v-model="model" :disabled="props.disabled" class="w-full" optionLabel="COMPANYNAME"
        :suggestions="filteredSuppliers" dropdown forceSelection completeOnFocus @complete="searchSupplier"
        @item-select="emit('onSupplierSelected')" />
</template>

<script setup>
import { ref, defineModel, defineProps, defineEmits } from 'vue';
import axios from 'axios';

const model = defineModel()
const emit = defineEmits(['onSupplierSelected'])
const props = defineProps(['disabled'])

const filteredSuppliers = ref([]);

const searchSupplier = (event) => {
    const query = event.query;

    let url = "suppliers";
    if (query) {
        url += "?search=" + query;
    }

    axios({
        method: "GET",
        url: url,
    }).then(
        (result) => {
            const suppliers = result.data.suppliers.map((supplier) => {
                return {
                    ...supplier
                };
            });

            filteredSuppliers.value = suppliers;
        },
        (error) => {
            console.log(error.response.data);
        }
    );
};
</script>