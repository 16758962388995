<template>

    <div class="flex gap-3">
        <div class="text-4xl font-medium">Claims</div>
        <ButtonAddClaim :categories="categories" :brands="brands" @saved="getClaims()"> </ButtonAddClaim>
    </div>
    <div class="flex gap-3 mt-3">
        <DataTable v-model:expandedRows="expandedRows" :value="claims" dataKey="id" size="small" class="w-10">
            <template #header>
                <div class="flex flex-wrap justify-content-between gap-2">
                    <div class="">
                        <SelectButton v-model="showClaimType" :options="claimTypeOptionsShow" aria-labelledby="basic"
                            @change="showClaimTypeChanged" />
                    </div>
                    <div>
                        <Button text icon="pi pi-plus" label="Expand All" @click="expandAll" />
                        <Button text icon="pi pi-minus" label="Collapse All" @click="collapseAll" />
                    </div>
                </div>
            </template>
            <template #footer>
                <Paginator :rows="pagination.records_per_page" :totalRecords="pagination.total_records"
                    @page="onPageChange($event)">
                </Paginator>
            </template>
            <Column expander style="width: 5rem" />
            <Column field="" header="ID & Date" style="width: 8.5rem;">
                <template #body="{ data }">
                    <div>{{ data.id_custom }}</div>
                    <div>{{ moment(data.date).format('YYYY-MM-DD') }}</div>
                </template>
            </Column>

            <!-- <Column field="id_custom" header="ID" style="width: 5rem;"></Column> -->
            <!-- <Column field="date" header="Date" style="width: 8rem;"></Column> -->
            <Column field="date_claimed" header="Claimed" style="width: 8rem;"></Column>
            <Column field="date_replacement_received" header="Replaced" style="width: 8rem;"></Column>
            <Column field="date_replacement_received" header="Settled" style="width: 8rem;"></Column>
            <Column field="agent_name" header="Agent" style="width: 8.5rem;"></Column>
            <Column field="customer_name" header="Customer" style="width: 9rem;">
                <template #body="{ data }">
                    <div v-if="data.claim_type == 'Customer'">
                        <div>{{ data.customer_name }}</div>
                        <div>{{ data.contact_details_1 }}</div>
                        <div>{{ data.contact_details_2 }}</div>
                    </div>
                    <div v-else>
                        <Tag value="KM" severity="info" />
                    </div>
                </template>
            </Column>
            <Column v-if="showClaimType == 'KM Stock'" field="is_fields" header="IS" style="width: 8rem;">
                <template #body="{ data }">
                    <div>{{ data.is_no ? data.is_no : '-' }}</div>
                    <div>{{ data.is_date ? data.is_date : '-' }}</div>
                </template>
            </Column>
            <Column v-if="showClaimType == 'KM Stock'" field="rc_fields" header="RC" style="width: 8rem;">
                <template #body="{ data }">
                    <div>{{ data.rc_no ? data.rc_no : '-' }}</div>
                    <div>{{ data.rc_date ? data.rc_date : '-' }}</div>
                </template>
            </Column>
            <Column field="category/brand" header="Category / Brand" style="width: 8rem;">
                <template #body="{ data }">
                    <div>
                        {{ data.claim_category.name }}
                    </div>
                    <div>
                        {{ data.claim_brand.name }}
                    </div>
                </template>
            </Column>
            <Column field="item_description" header="Item"></Column>
            <Column field="quantity" header="Quantity" style="width: 5rem;"></Column>
            <Column field="status" header="Status" style="width: 5rem;">
                <template #body="{ data }">

                    <Tag v-if="data.status == 'New'" severity="success" class="text-base">{{ data.status }}</Tag>
                    <Tag v-else severity="secondary" class="text-base">{{ data.status }}</Tag>
                </template>
            </Column>
            <Column field="actions" header="Actions" style="width: 8rem;">
                <template #body="{ data }">
                    <div class="flex gap-2">
                        <ButtonEditClaim :claimData="data" @saved="getClaims()"> </ButtonEditClaim>
                        <ButtonPrint :claimData="data"></ButtonPrint>
                    </div>
                </template>
            </Column>


            <template #expansion="slotProps">
                <div class="pb-3">
                    <div class="flex gap-4">
                        <div class="w-6">
                            <div class="card w-12">
                                <div class="mb-1 text-lg font-medium">Problem</div>
                                <div>{{ slotProps.data.problem }}</div>
                            </div>
                            <div class="card w-12">
                                <div class="mb-1 text-lg font-medium">Remark</div>
                                <div>{{ slotProps.data.remark }}</div>
                            </div>
                        </div>
                        <div class="card w-6">
                            <div class="mb-2 text-lg font-medium">Logs</div>
                            <div class="flex flex-column gap-2">
                                <div v-for="trail in slotProps.data.trails" :key="trail.id">
                                    <!-- created_at timestamp in format of 2024-Aug-02 hh:mm:ss -->
                                    [{{ moment(trail.created_at).format('YYYY-MM-DD h:mm:ss a') }}]
                                    {{ trail.content }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </DataTable>
        <div class="card w-20rem h-min">
            <div class="flex gap-3">
                <div class="text-2xl font-medium">Filters</div>
                <Button v-if="selectedFilters.length > 0" label="Clear All" icon="pi pi-times" iconPos="right"
                    class="py-1 px-2" severity="warning" @click="clearAllFilters()"> </Button>
            </div>

            <div class="flex flex-wrap gap-3 mt-3">
                <Tag v-for="filter in selectedFilters" :key="filter.key">
                    <Button :label="filter.value" icon="pi pi-times" iconPos="right" class="p-1"
                        @click="clearFilter(filter)">
                    </Button>
                </Tag>
            </div>

            <div class="mt-4 mb-1 text-lg font-medium">Brand</div>

            <Dropdown v-model="filters.brand" :options="brands" filter optionLabel="name" placeholder="Select a brand"
                @change="getClaims()">
            </Dropdown>

            <div class="mt-3 mb-1 text-lg font-medium">Category</div>
            <Dropdown v-model="filters.category" :options="categories" filter optionLabel="name"
                placeholder="Select a category" @change="getClaims()">
            </Dropdown>

            <div class="mt-3 mb-1 text-lg font-medium">Status</div>
            <Dropdown v-model="filters.status" :options="statusOptions" filter placeholder="Select a status"
                @change="getClaims()">
            </Dropdown>
        </div>
    </div>


</template>

<script setup>
import { ref, computed } from 'vue'
import ButtonEditClaim from '@/components/Claim/ButtonEditClaim.vue'
import ButtonAddClaim from '@/components/Claim/ButtonAddClaim.vue'
import ButtonPrint from '@/components/Claim/ButtonPrint.vue'
import axios from 'axios';
import moment from 'moment';
const claims = ref([]);
const categories = ref([]);
const brands = ref([]);
const isLoading = ref(false);

const showClaimType = ref("All");
const claimTypeOptionsShow = ['All', 'Customer', 'KM Stock'];

const pagination = ref({
    current_page: null,
    records_per_page: null,
    total_records: null,
    total_pages: null,
});


const getCategoriesAndBrands = async () => {
    await axios({
        method: "GET",
        url: "claims/brands-categories",
    }).then(
        (result) => {
            categories.value = result.data.categories;
            brands.value = result.data.brands;
        },
        (error) => {
            console.log("ERROR GETTING");
            console.log(error.response.data);
        }
    );
}

const onPageChange = ($event) => {
    getClaims($event.page + 1);
};

const getClaims = async (page = 1) => {
    isLoading.value = true;

    const params = {}

    for (const key in filters.value) {
        if (filters.value[key]) {
            if (key == 'category' || key == 'brand') {
                params[key] = filters.value[key].id;
                continue;
            }

            if (key == 'status') {
                params[key] = filters.value[key];
                continue;
            }
        }
    }


    if (showClaimType.value != 'All') {
        params.claim_type = showClaimType.value;
    }

    params.page = page;


    await axios({
        method: "GET",
        url: "claims",
        params: params,
    }).then(
        (result) => {
            pagination.value.current_page = result.data.claims.current_page;
            pagination.value.records_per_page = result.data.claims.per_page;
            pagination.value.total_records = result.data.claims.total;
            pagination.value.total_pages = result.data.claims.last_page;

            claims.value = result.data.claims.data;
        },
        (error) => {
            console.log("ERROR GETTING");
            console.log(error.response.data);
        }
    );
    isLoading.value = false;
}
// -------------------------- Filters START ------------------------------ //
const statusOptions = ['New', 'Testing', 'Claiming', 'Received', 'Settled', 'Pending Pickup', 'Closed'];
const filters = ref({
    brand: null,
    category: null,
    status: null
});

const selectedFilters = computed(() => {
    const selected = [];
    for (const key in filters.value) {
        if (filters.value[key]) {
            if (key == 'status') {
                selected.push({
                    key,
                    value: filters.value[key],
                });
                continue;
            }

            selected.push({
                key,
                value: filters.value[key].name,
            });
        }
    }
    return selected;
});

const clearFilter = (filter) => {
    filters.value[filter.key] = null;
    getClaims();
}

const clearAllFilters = () => {
    filters.value = {
        brand: null,
        category: null,
        status: null
    };
    getClaims();
};


// -------------------------- Filters END ------------------------------ //

// -------------------------- Claims Table START ------------------------------ //
const expandedRows = ref({});
const expandAll = () => {
    expandedRows.value = claims.value.reduce((acc, p) => (acc[p.id] = true) && acc, {});
};
const collapseAll = () => {
    expandedRows.value = null;
};

const showClaimTypeChanged = () => {
    console.log("showClaimType", showClaimType.value);
    getClaims();
}
// --------------------------- Claims Table END ------------------------------- //

getCategoriesAndBrands();
getClaims();
</script>


<style scoped></style>