<template>
    <h2>Logs</h2>

    <div class="overflow-scroll bg-white p-3" style="max-height: calc(100vh - 300px)">

        <div v-for="log in logs" :key="log" class="mb-1">
            {{ log }}
        </div>
    </div>
</template>

<script>
import router from '@/router';
import axios from "axios";
export default {
    data() {
        return {
            isLoading: { logs: false },
            logs: [],
        }
    },
    mounted: function () {
        this.getLogs();
    },
    methods: {
        getLogs: async function () {
            this.isLoading.logs = true;

            await axios({
                method: "GET",
                url: "admin/logs"
            }).then(
                (result) => {
                    console.log(result)
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200) {
                        console.log("Error");
                        console.log(result);
                        return;
                    }
                    this.logs = result.data.data;

                    // result.json().then((data) => {
                    //     this.logs = data.data;
                    //     console.log(data)
                    // });
                },
                (error) => {
                    console.log(error.response.data);
                }
            );

            this.isLoading.logs = false;
        }
    },
};
</script>

<style scoped></style>
