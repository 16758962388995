<template>
    <div>
        <h2>Shelfing</h2>
        <div class="card">


            <DataTable :value="reports" responsiveLayout="scroll" :loading="isLoading.shelfingReports">
                <template #header>
                    <div class="flex flex-row">
                        <Button type="button" icon="pi pi-plus" label="Add Shelfing Report"
                            class="p-button-outlined mb-2 mr-4" @click="showAddShelfingReportDialog" />
                    </div>
                </template>
                <template #footer>
                    <Paginator :rows="pagination.records_per_page" :totalRecords="pagination.total_records"
                        @page="onPageChange($event)" :first="paginator_first">
                    </Paginator>
                </template>
                <Column field="id" header="ID"></Column>

                <Column header="Time Created" style="width: 10%">
                    <template #body="{ data }">
                        <div :title="dateTimeFormat(data.created_at)">
                            {{ dateTimeFormat(data.created_at) }}
                        </div>
                    </template>
                </Column>

                <Column field="created_by" header="Created By (Agent)"></Column>
                <Column field="remark" header="Remark"></Column>
                <Column field="status" header="Status"></Column>
                <Column field="synced_at" header="Time Approved"></Column>
                <Column field="synced_by" header="Approved By (Agent)"></Column>
                <Column field="" header="">
                    <template #body="{ data }">
                        <div class="flex justify-content-end">
                            <Button v-if="data.status == 'SCANNING' || data.status == 'PENDING ACCEPT'" severity="success"
                                @click="action(data.id, 'ACCEPT')" text label="Accept"></Button>
                            <Button v-if="data.status == 'SCANNING' || data.status == 'ACCEPTED'|| data.status == 'PENDING ACCEPT'" severity="danger"
                                @click="action(data.id, 'REJECT')" text label="Reject"></Button>
                            <Button v-if="data.status == 'REJECTED' || data.status == 'PENDING ACCEPT'" severity="warning"
                                @click="action(data.id, 'RESUME')" text label="Resume Scan"></Button>
                            <Button severity="info" @click="action(data.id, 'VIEW')" text label="View"></Button>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>

        <!--------------------- Add Booking Dialog ---------------------->
        <Dialog header="Add New Report" v-model:visible="dialogAddShelfingReport.show" :style="{ width: '500px' }"
            :modal="true">

            <div class="flex flex-column align-items-center justify-content-center mb-2 gap-2">
                <InputText type="text" placeholder="Created By (Agent)" name="add_report_dialog_created_by"
                    v-model="dialogAddShelfingReport.inputs.created_by" class="w-full" autofocus />
                <InputText type="text" placeholder="Remark" name="add_report_dialog_remark"
                    v-model="dialogAddShelfingReport.inputs.remark" class="w-full" />
            </div>


            <template #footer>
                <Button label="No" icon="pi pi-times" @click="dialogAddShelfingReport.show = false" class="p-button-text"
                    :loading="dialogAddShelfingReport.isLoading" />
                <Button label="Yes" icon="pi pi-check" @click="addShelfingReport" class="p-button-text"
                    :loading="dialogAddShelfingReport.isLoading" />
            </template>
        </Dialog>


    </div>
</template>
  
<script>
import axios from "axios";
import moment from "moment";

export default {
    data() {
        return {
            isLoading: {
                shelfingReports: false,
            },
            pagination: {
                current_page: 0,
                records_per_page: 0,
                total_records: 0,
                total_pages: 0,
                first: 0,
            },
            dialogAddShelfingReport: {
                inputs: {
                    created_by: "",
                    remark: "",
                },
                show: false,
                isLoading: false,
            },
            query: { page: 1 },
            reports: [],
        };
    },
    created() {
        this.getShelfingReports();
    },
    mounted: function () {
    },
    methods: {
        async action(id, action) {
            if (action == "VIEW") {
                this.$router.push({ name: "shelfing-report-single", params: { id: id } });
                return;
            }

            if (action == "RESUME") {
                await axios({
                    method: "PATCH",
                    url: "shelfing/reports/" + id + "/status",
                    data: { status: "SCANNING" },
                }).then(
                    () => {
                        this.getShelfingReports();
                    },
                    (error) => {
                        console.log(error.response.data);
                    }
                );

                return;
            }

            if (action == "ACCEPT") {
                await axios({
                    method: "PATCH",
                    url: "shelfing/reports/" + id + "/status",
                    data: { status: "ACCEPTED" },
                }).then(
                    () => {
                        this.getShelfingReports();
                    },
                    (error) => {
                        console.log(error.response.data);
                    }
                );

                return;
            }

            if (action == "REJECT") {
                await axios({
                    method: "PATCH",
                    url: "shelfing/reports/" + id + "/status",
                    data: { status: "REJECTED" },
                }).then(
                    () => {
                        this.getShelfingReports();
                    },
                    (error) => {
                        console.log(error.response.data);
                    }
                );

                return;
            }
        },
        dateTimeFormat(timestampString) {
            // If less than 7 days ago, show days ago
            if (moment().diff(moment(timestampString), 'days') < 7) {
                return moment(timestampString).fromNow();
            }

            // Display as format "dd MMM yyyy HH:mm"
            return moment(timestampString).format('DD MMM YYYY, HH:mm');
        },
        dateTimeFixed(timestampString) {
            return moment(timestampString).format('DD MMM YYYY, HH:mm');
        },
        showAddShelfingReportDialog: function () {
            this.dialogAddShelfingReport.show = true;

            this.dialogAddShelfingReport.inputs.created_by = "";
            this.dialogAddShelfingReport.inputs.remark = "";
            this.dialogAddShelfingReport.isLoading = false;


        },
        addShelfingReport: async function () {
            this.dialogAddShelfingReport.isLoading = true;

            await axios({
                method: "POST",
                url: "shelfing/reports",
                data: this.dialogAddShelfingReport.inputs,
            }).then(
                () => {
                    this.dialogAddShelfingReport.show = false;
                    this.dialogAddShelfingReport.isLoading = false;
                    this.getShelfingReports();
                },
                (error) => {
                    console.log(error.response.data);
                    this.dialogAddShelfingReport.isLoading = false;
                }
            );

        },
        getShelfingReports: async function () {
            this.isLoading.shelfingReports = true;

            const params = new URLSearchParams();

            params.append("page", this.query.page);

            await axios({
                method: "GET",
                url: "shelfing/reports",
                params: params,
            }).then(
                (result) => {
                    this.pagination.current_page =
                        result.data.reports.current_page;
                    this.pagination.records_per_page =
                        result.data.reports.per_page;
                    this.pagination.total_records = result.data.reports.total;
                    this.pagination.total_pages = result.data.reports.last_page;
                    this.pagination.first = (this.query.page - 1) * this.pagination.records_per_page;

                    this.reports = result.data.reports.data;
                },
                (error) => {
                    console.log(error.response.data);
                }
            );
            this.isLoading.shelfingReports = false;
        },
        onPageChange($event) {
            this.query.page = $event.page + 1;
            this.getShelfingReports();
        },
    }
};
</script>
  