<script setup>
import { useToast } from 'primevue/usetoast';
import axios from "axios";
import { AgGridVue } from "ag-grid-vue3";  // the AG Grid Vue Component
import { reactive, ref, onMounted } from "vue";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { useItemsStore } from '@/stores/items';
import WindowItemSearchEventBus from '@/components/WindowItemSearch/WindowItemSearchEventBus';
import { useRoute, useRouter } from 'vue-router';
import { onUnmounted } from 'vue';
import ButtonCopyToSql from '@/components/ButtonCopyToSql.vue';
import ButtonCopyToExcel from '@/components/ButtonCopyToExcel.vue';
import moment from 'moment';

const route = useRoute();
const router = useRouter();
const toast = useToast();
const itemsStore = useItemsStore();
const currentMode = ref("new");
const gridApi = ref(null); // Optional - for accessing Grid's API

// Obtain API from grid's onGridReady event
const onGridReady = (params) => {
    gridApi.value = params.api;
};

const isLoading = ref({
    pasteFromSql: false,
})

const filteredSuppliers = ref();

const rowData = reactive([{}]);

rowData.value = [
    {
        sort_order: 0,
    },
]

const dialogSaved = ref({
    show: false,
    table: [],
    newDocNo: "",
});

const inputs = ref({
    date: new Date(),
    agentName: "",
    description: "",
});

const detail = ref({ doc_no: "", is_deleted: false, arrived_at: null });

// ------------------------------- //
// ------ Supplier Feature ------- //
// ------------------------------- //
const searchSupplier = (event) => {
    const query = event.query;

    let url = "suppliers";
    if (query) {
        url += "?search=" + query;
    }

    axios({
        method: "GET",
        url: url,
    }).then(
        (result) => {
            const suppliers = result.data.suppliers.map((supplier) => {
                return {
                    ...supplier
                };
            });

            filteredSuppliers.value = suppliers;
        },
        (error) => {
            console.log(error.response.data);
        }
    );
};

const onSupplierSelected = () => {
    inputs.value.days_for_shipment = inputs.value.supplier.days_for_shipment;
}
// ------------------------------- //

// ---------------------------- //
// ------ Agent Feature ------- //
// ---------------------------- //

const filteredAgents = ref();

const searchAgent = (event) => {
    const query = event.query;

    let url = "agents";
    if (query) {
        url += "?search=" + query;
    }

    axios({
        method: "GET",
        url: url,
    }).then(
        (result) => {
            const agents = result.data.agents.map((agents) => {
                return {
                    ...agents
                };
            });

            filteredAgents.value = agents;
        },
        (error) => {
            console.log(error.response.data);
        }
    );
};
// ---------------------------- //

const onCellValueChanged = async (editedRow) => {
    // Check if edited row is item_code, if yes then get item and add data
    if (editedRow.colDef.field == "item_code" && editedRow.data.item_code != null && editedRow.data.item_code != "") {
        const item = await itemsStore.getExtendedItemFromBackend(editedRow.data.item_code);

        if (item == null) {
            toast.add({
                severity: "error",
                summary: "Error",
                detail: "Item not found.",
                life: 1000,
            });

            // Clear the field
            editedRow.node.setDataValue("item_code", null);
            return;
        }

        if (item != null) {
            editedRow.node.setDataValue("description", item.DESCRIPTION);
            editedRow.node.setDataValue("description_2", item.DESCRIPTION2);
            editedRow.node.setDataValue("qty_per_carton", item.qty_per_carton);
            editedRow.node.setDataValue("qty", 1);
            editedRow.node.setDataValue("carton_length_cm", item.carton_length_cm);
            editedRow.node.setDataValue("carton_width_cm", item.carton_width_cm);
            editedRow.node.setDataValue("carton_height_cm", item.carton_height_cm);
            editedRow.node.setDataValue("cost", item.cost);
            editedRow.node.setDataValue("cost_currency", item.cost_currency);
        }
    }

    if (editedRow.colDef.field == "qty" || editedRow.colDef.field == "qty_per_carton") {
        if (editedRow.data.qty <= 0 || editedRow.data.qty_per_carton <= 0 || editedRow.data.qty_per_carton == null) {
            editedRow.node.setDataValue("cartons", null);
        }
        else {
            editedRow.node.setDataValue("cartons", Math.round(editedRow.data.qty / editedRow.data.qty_per_carton * 100) / 100);
        }
    }

    if (editedRow.colDef.field == "cartons") {
        if (editedRow.data.cartons <= 0 || editedRow.data.qty_per_carton <= 0 || editedRow.data.qty_per_carton == null) {
            editedRow.node.setDataValue("cartons", null);
        }
        else {
            editedRow.node.setDataValue("qty", Math.floor(editedRow.data.cartons * editedRow.data.qty_per_carton));
        }
    }

    // Check if last row is empty, if not, add new row
    if (editedRow.node.rowIndex === rowData.value.length - 1) {
        if (editedRow.data.item_code || editedRow.data.description || editedRow.data.quantity) {
            rowData.value.push({});

            gridApi.value.setRowData(rowData.value);

            // Navigate down
            const currentCell = gridApi.value.getFocusedCell();
            gridApi.value.startEditingCell({
                rowIndex: currentCell.rowIndex + 1,
                colKey: currentCell.column.colId
            });
        }
    }

    updateSortOrder();
};

const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
}

const addItemsToList = (items) => {
    // // Check if last row is empty object, if yes, remove it
    const lastRow = rowData.value[rowData.value.length - 1];
    if (Object.keys(lastRow).length === 0 || !lastRow || !lastRow.item_code && !lastRow.description && !lastRow.quantity) {
        rowData.value.pop();
    }

    const itemsToPush = [];
    for (const item of items) {
        itemsToPush.push({
            item_code: item.CODE,
            description: item.DESCRIPTION,
            description_2: item.DESCRIPTION2,
            qty_per_carton: item.qty_per_carton,
            carton_length_cm: item.carton_length_cm,
            carton_width_cm: item.carton_width_cm,
            carton_height_cm: item.carton_height_cm,
            cost: item.cost,
            cost_currency: item.cost_currency,
            qty: item.qty ? item.qty : 1,
            cartons: item.qty && item.qty_per_carton ? Math.round(item.qty / item.qty_per_carton * 100) / 100 : null,
        });
    }

    rowData.value = rowData.value.concat(itemsToPush);
    rowData.value.push({});
    gridApi.value.setRowData(rowData.value);

    updateSortOrder();
};

WindowItemSearchEventBus.on('add', (item) => {
    if (currentMode.value == "edit" || currentMode.value == "new") {
        addItemsToList(item);
    }
});

const gridOptions = ref({
    stopEditingWhenCellsLoseFocus: true,
    onCellValueChanged: onCellValueChanged,
    rowSelection: 'multiple',
    undoRedoCellEditing: true, // enables undo / redo
    undoRedoCellEditingLimit: 5, // restricts the number of undo / redo steps to 5
    enableCellChangeFlash: true,  // enables flashing to help see cell changes
    enterNavigatesVerticallyAfterEdit: true,
    suppressScrollOnNewData: true,
});

// Each Column Definition results in one Column.
const columnDefs = reactive({
    value: [
        {
            field: "item_code",
            headerName: 'Item Code',
            minWidth: 80,
            maxWidth: 100
        },
        {
            field: "description",
            minWidth: 300,
        },
        {
            field: "description_2",
            minWidth: 150,
            headerName: 'Description 2',
            hide: true
        },
        {
            field: "qty",
            headerName: 'Quantity',
            maxWidth: 110
        },
        {
            field: "qty_per_carton",
            headerName: 'Qty / Ctn',
            maxWidth: 110
        },
        {
            field: "cartons",
            headerName: 'Cartons',
            maxWidth: 100
        },
        {
            field: "carton_length_cm",
            maxWidth: 120,
            headerName: 'L (cm)',
        },
        {
            field: "carton_width_cm",
            maxWidth: 120,
            headerName: 'W (cm)',
        },
        {
            field: "carton_height_cm",
            maxWidth: 120,
            headerName: 'H (cm)',
        },
        {
            field: "cost",
            headerName: 'Cost',
        },
        {
            field: "cost_currency",
            headerName: 'Currency',
        },
    ],
});

// DefaultColDef sets props common to all Columns
const defaultColDef = {
    sortable: true,
    flex: 1,
    editable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
};

const removeSelectedRow = () => {
    const selectedData = gridApi.value.getSelectedRows();

    if (selectedData.length === 0) {
        return;
    }

    // Remove selected rows by sort_order
    selectedData.forEach((selectedRow) => {
        const index = rowData.value.findIndex((row) => row.sort_order === selectedRow.sort_order);
        rowData.value.splice(index, 1);
    });

    // Check if last row is empty, if yes, add new row
    const lastRow = rowData.value[rowData.value.length - 1];

    if (lastRow == undefined || (lastRow.item_code != null && lastRow.description != null && lastRow.qty != null)) {
        rowData.value.push({});
    }

    // Update sort_order
    updateSortOrder();

    // refresh grid
    gridApi.value.setRowData(rowData.value);
}

const updateSortOrder = () => {
    // Iterate through each rowData and update sort_order
    rowData.value.forEach((row, index) => {
        row.sort_order = index;
    });
}

const dialogSavedConfirm = async () => {
    // Redirect to purchase orders
    await router.push("/purchase-orders/" + dialogSaved.value.newDocNo);
    thingsToDoOnMount();
}

const save = () => {
    // Validate
    if (inputs.value.supplier == null) {
        toast.add({
            severity: "error",
            summary: "Missing input",
            detail: "Please select a supplier.",
            life: 2000,
        });
        return;
    }

    if (inputs.value.agent == null) {
        toast.add({
            severity: "error",
            summary: "Missing input",
            detail: "Please select a purchaser.",
            life: 2000,
        });
        return;
    }

    if (inputs.value.date == null) {
        toast.add({
            severity: "error",
            summary: "Missing input",
            detail: "Please select a date.",
            life: 2000,
        });
        return;
    }

    // Upload New Purchase Order
    if (currentMode.value == "new") {
        // Remove last line if empty
        const lastLine = rowData.value[rowData.value.length - 1];
        let excludeLastLine = true;
        for (const key in lastLine) {
            if (lastLine[key] != null && key != "sort_order") {
                excludeLastLine = false;
                break;
            }
        }

        const linesToUpload = excludeLastLine ? rowData.value.slice(0, -1) : rowData.value;

        axios({
            method: "POST",
            url: "purchase-orders",
            data: {
                date: inputs.value.date,
                supplier_id: inputs.value.supplier.id,
                agent_id: inputs.value.agent.id,
                description: inputs.value.description,
                days_for_shipment: inputs.value.days_for_shipment,
                lines: linesToUpload,
            },
        }).then(
            async (result) => {
                // Redirect to purchase orders
                // await router.push("/purchase-orders/" + result.data.doc_no);
                // thingsToDoOnMount();

                dialogSaved.value.show = true;
                dialogSaved.value.table = result.data.lines_update_summary;
                dialogSaved.value.newDocNo = result.data.doc_no;
            },
            (error) => {
                console.log(error.response.data);

                toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: error.response.data.message,
                    life: 2000,
                });
            }
        );
    }

    // Edit and Save Purchase Request
    if (currentMode.value == "edit") {
        // Remove last line if empty
        const lastLine = rowData.value[rowData.value.length - 1];
        let excludeLastLine = true;
        for (const key in lastLine) {
            if (lastLine[key] != null && key != "sort_order") {
                excludeLastLine = false;
                break;
            }
        }

        const linesToUpload = excludeLastLine ? rowData.value.slice(0, -1) : rowData.value;

        axios({
            method: "PUT",
            url: "purchase-orders/" + detail.value.doc_no,
            data: {
                date: inputs.value.date,
                supplier_id: inputs.value.supplier.id,
                agent_id: inputs.value.agent.id,
                description: inputs.value.description,
                days_for_shipment: inputs.value.days_for_shipment,
                lines: linesToUpload,
            },
        }).then(
            (result) => {
                dialogSaved.value.show = true;
                dialogSaved.value.table = result.data.lines_update_summary;
                dialogSaved.value.newDocNo = detail.value.doc_no;
            },
            (error) => {
                console.log(error.response.data);

                toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: error.response.data.message,
                    life: 2000,
                });
            }
        );
    }
}

const getPurchaseOrder = () => {
    const doc_no = route.params.doc_no;
    axios({
        method: "GET",
        url: "purchase-orders/" + doc_no
    }).then(
        (result) => {
            inputs.value =
            {
                date: result.data.purchase_order.date,
                supplier: {
                    ...result.data.purchase_order.supplier
                },
                agent: {
                    ...result.data.purchase_order.agent
                },
                description: result.data.purchase_order.description,
                days_for_shipment: result.data.purchase_order.days_for_shipment,
            }
            // Update lines' carton
            result.data.purchase_order.lines.forEach((line) => {
                if (line.qty < 1) {
                    line.cartons = 0;
                }
                else if (line.qty_per_carton <= 0) {
                    line.cartons = null;
                }
                else {
                    line.cartons = Math.round(line.qty / line.qty_per_carton * 100) / 100;
                }
            });

            rowData.value = result.data.purchase_order.lines;
            detail.value = result.data.purchase_order;
        },
        (error) => {
            if (error.response.status) {
                router.push("/purchase-orders");
                return;
            }
            console.log(error.response.data);
        }
    );
}

const thingsToDoOnMount = () => {
    // get doc_no from route
    const doc_no = route.params.doc_no;

    if (doc_no == "new") {
        currentMode.value = "new";
        detail.value.doc_no = "<< NEW >>"
        detail.value.arrived_at = null;
        detail.value.is_deleted = false;

        inputs.value = {
            date: new Date(),
            supplier: null,
            purchaser: null,
            description: "",
        }

        rowData.value = [
            {
                sort_order: 0,
            },
        ]
        gridApi?.value?.setRowData(rowData.value);

        // Allow Column Editable
        columnDefs.value.forEach((columnDef) => {
            if (columnDef.field != "qty_fulfilled") {
                columnDef.editable = true;
            }
        });
    } else {
        currentMode.value = "view";
        detail.value.doc_no = doc_no;

        getPurchaseOrder();

        // Disallow Column Editable
        columnDefs.value.forEach((columnDef) => {
            columnDef.editable = false;
        });
    }
}

onMounted(() => {
    thingsToDoOnMount();
    // On delete key pressed, check if any row is selected, if yes, remove selected row
    window.addEventListener('keydown', keyShortcuts);
});

onUnmounted(() => {
    // Remove event listener
    window.removeEventListener('keydown', keyShortcuts);
});

const keyShortcuts = (e) => {
    if (e.key === 'Delete') {
        removeSelectedRow();
    }

    if (e.key === 'F3') {
        e.preventDefault()
        if (currentMode.value != "edit" && currentMode.value != "new") {
            return;
        }
        gridApi.value.stopEditing();
        save();
    }

    if (e.key === 'F2') {
        if (currentMode.value != "view") {
            return;
        }

        if (detail.value.is_deleted) {
            return;
        }
        edit();
    }
}

const edit = () => {
    currentMode.value = "edit";

    // Add one empty row 
    rowData.value.push({});
    gridApi.value.setRowData(rowData.value);
    updateSortOrder();

    // Allow Column Editable
    columnDefs.value.forEach((columnDef) => {
        if (columnDef.field != "qty_fulfilled") {
            columnDef.editable = true;
        }
    });
}

const cancel = () => {
    // Pop up confirmation
    if (confirm("Are you sure you want to cancel? \nAll changes will be lost!")) {
        // Redirect to purchase orders
        if (currentMode.value == "new") {
            router.push("/purchase-orders");
        }
        else {
            thingsToDoOnMount();
        }
    }
}

const navigateToNew = async () => {
    await router.push("/purchase-orders/new");
    thingsToDoOnMount();
}

const pasteFromSql = () => {
    isLoading.value.pasteFromSql = true;
    navigator.clipboard.readText().then(async (text) => {
        // Convert XML to JSON
        const xml = new DOMParser().parseFromString(text, "text/xml");
        // Get datapacket > METADATA > fields > field where attrname = sdsDocDetail > Fields
        const fields = xml.querySelector("DATAPACKET > ROWDATA > ROW > sdsDocDetail")?.children;

        if (!fields) {
            toast.add({
                severity: "error",
                summary: "Error",
                detail: "No data found in clipboard.",
                life: 2000,
            });
            isLoading.value.pasteFromSql = false;
            return;
        }

        let counter = 0;

        const itemsToAdd = [];

        // Loop through fields and get the attr ITEMCODE, DESCRIPTION, QTY
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];

            const itemCode = field.attributes.ITEMCODE ? field.attributes.ITEMCODE.textContent : '';
            const description = field.attributes.DESCRIPTION ? field.attributes.DESCRIPTION.textContent : '';
            const qty = field.attributes.QTY ? Number(field.attributes.QTY.textContent) : 0;

            let itemResult = null;

            if (itemCode != '') {
                itemResult = await itemsStore.getItemsFromBackendWithCode(itemCode);

                if (itemResult == null) {
                    toast.add({
                        severity: "error",
                        summary: "ERROR",
                        detail: "Item not found: " + itemCode,
                        life: 3000,
                    });
                }
            }

            itemsToAdd.push({
                ...itemResult,
                CODE: itemCode,
                qty: qty,
                DESCRIPTION: description,
            });

            counter++;
        }

        addItemsToList(itemsToAdd);

        toast.add({
            severity: "success",
            summary: "Success",
            detail: "Successfully added " + counter + " rows.",
            life: 2000,
        });
        isLoading.value.pasteFromSql = false;
    });
}

// ----------------- Set Arrived or Unarrived ----------------- //
const dialogSetArrived = ref({
    show: false,
    date: new Date()
});

const dialogUnsetArrive = ref({
    show: false,
});

const setArrived = (newState) => {
    axios({
        method: "PATCH",
        url: "purchase-orders/" + detail.value.doc_no,
        data: {
            is_arrived: newState,
            arrived_date: dialogSetArrived.value.date
        }
    }).then(
        () => {
            dialogUnsetArrive.value.show = false;
            dialogSetArrived.value.show = false;
            dialogSetArrived.value.date = new Date();
            thingsToDoOnMount();
        },
        (error) => {
            console.log(error.response.data);

            toast.add({
                severity: "error",
                summary: "Error",
                detail: error.response.data.message,
                life: 2000,
            });
        }
    );

}

// ----------------- Set Delete or Undelete ----------------- //
const dialogDelete = ref({
    show: false
});

const dialogUndelete = ref({
    show: false
});

const setDeleteStatus = (newStatus) => {
    if (newStatus == true) {
        axios({
            method: "PUT",
            url: "purchase-orders/" + detail.value.doc_no + '/delete_state',
            data: {
                delete_state: true,
            }
        }).then(
            () => {
                dialogDelete.value.show = false;
                dialogUndelete.value.show = false;
                thingsToDoOnMount();
            },
            (error) => {
                console.log(error.response.data);

                toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: error.response.data.message,
                    life: 2000,
                });
            }
        );
    } else {
        axios({
            method: "PUT",
            url: "purchase-orders/" + detail.value.doc_no + '/delete_state',
            data: {
                delete_state: false,
            }
        }).then(
            () => {
                dialogDelete.value.show = false;
                dialogUndelete.value.show = false;
                thingsToDoOnMount();
            },
            (error) => {
                console.log(error.response.data);

                toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: error.response.data.message,
                    life: 2000,
                });
            }
        );
    }

}
</script>

<template>
    <!-- Set Arrive Date -->
    <Dialog v-model:visible="dialogSetArrived.show" modal header="Set PO as Arrived" :style="{ width: '25rem' }">
        <!-- Date -->
        <div class="flex align-items-center gap-3">
            <div class="flex flex-column w-6rem">
                <div class=" text-lg font-medium">Date:</div>
            </div>
            <Calendar v-model="dialogSetArrived.date" class="w-full"  dateFormat="yy-mm-dd"/>
        </div>

        <!-- Confirmation -->
        <div class="flex justify-content-end gap-2 mt-4">
            <Button type="button" label="Cancel" severity="secondary" @click="dialogSetArrived.show = false"></Button>
            <Button type="button" label="Confirm" severity="primary" @click="setArrived(true)"></Button>
        </div>
    </Dialog>

    <!-- Set Unarrive -->
    <Dialog v-model:visible="dialogUnsetArrive.show" modal header="Set PO as not yet arrived"
        :style="{ width: '25rem' }">
        <span class="p-text-primary block mt-2 mb-5">You will set this PO as not yet arrived. Are you sure?</span>
        <div class="flex justify-content-end gap-2">
            <Button type="button" label="Cancel" severity="secondary" @click="dialogDelete.show = false"></Button>
            <Button type="button" label="Confirm Not Arrived" severity="warning" @click="setArrived(false)"></Button>
        </div>
    </Dialog>

    <!-- Delete Purchase Request -->
    <Dialog v-model:visible="dialogDelete.show" modal header="Delete this Purchase Request" :style="{ width: '25rem' }">
        <span class="p-text-primary block mt-2 mb-5">You will set this record as deleted. Are you sure? (Record will
            still remain and can be recovered later)</span>
        <div class="flex justify-content-end gap-2">
            <Button type="button" label="Cancel" severity="secondary" @click="dialogDelete.show = false"></Button>
            <Button type="button" label="Confirm Delete" severity="danger" @click="setDeleteStatus(true)"></Button>
        </div>
    </Dialog>

    <!-- Undelete Purchase Request -->
    <Dialog v-model:visible="dialogUndelete.show" modal header="Undelete this Purchase Request"
        :style="{ width: '25rem' }">
        <span class="p-text-primary block mt-2 mb-5">This record was previously set as deleted. You are now undo-ing the
            delete.</span>
        <div class="flex justify-content-end gap-2">
            <Button type="button" label="Cancel" severity="secondary" @click="dialogDelete.show = false"></Button>
            <Button type="button" label="Confirm Undo Delete" severity="primary"
                @click="setDeleteStatus(false)"></Button>
        </div>
    </Dialog>


    <div class="flex flex-column gap-3">
        <div class="flex justify-content-between">
            <div class="flex align-items-center gap-3">
                <div class="text-3xl font-medium">Purchase Order</div>
                <ButtonCopyToExcel :rows="rowData.value"></ButtonCopyToExcel>
                <ButtonCopyToSql :rows="rowData.value"></ButtonCopyToSql>
                <Button @click="pasteFromSql" class="h-min" label="Paste from SQL" :loading="isLoading.pasteFromSql"
                    :disabled="currentMode == 'view'"></Button>

                <div v-if="detail.is_deleted" class="font-bold text-red-700">*THIS PO WAS SET AS DELETED AND CANNOT BE
                    EDITED*</div>
                <div v-else-if="detail.arrived_at" class="font-bold text-green-700">ARRIVED - {{ detail.arrived_at }}
                    ({{ moment().diff(detail.arrived_at, "days") }} days ago)
                </div>
            </div>
            <Button v-if="!detail.arrived_at" label="Set As Arrived" :disabled="currentMode != 'view'"
                severity="primary" outlined @click="dialogSetArrived.show = true"></Button>
            <Button v-else-if="currentMode != 'new'" label="Set As Not Arrived" :disabled="currentMode != 'view'"
                severity="secondary" outlined @click="dialogUnsetArrive.show = true"></Button>
        </div>
        <div class="flex gap-3">
            <div class="flex flex-column w-full">
                <div class="card flex justify-content-between"
                    :class="{ 'bg-red-100': detail.is_deleted, 'bg-green-100': detail.arrived_at }">
                    <div class="flex flex-column gap-2">
                        <!-- Supplier -->
                        <div class="flex align-items-center gap-3">
                            <div class="flex flex-column w-10rem">
                                <div class=" text-lg font-medium">Supplier:</div>
                            </div>

                            <AutoComplete :disabled="currentMode == 'view'" v-model="inputs.supplier" class="w-full"
                                optionLabel="COMPANYNAME" :suggestions="filteredSuppliers" dropdown forceSelection completeOnFocus
                                @complete="searchSupplier" @item-select="onSupplierSelected()" />
                        </div>

                        <!-- Purchaser -->
                        <div class="flex align-items-center gap-3">
                            <div class="flex flex-column w-10rem">
                                <div class=" text-lg font-medium">Purchaser:</div>
                            </div>
                            <AutoComplete :disabled="currentMode == 'view'" v-model="inputs.agent" class="w-full"
                                optionLabel="name" :suggestions="filteredAgents" dropdown forceSelection completeOnFocus
                                @complete="searchAgent" />
                        </div>

                        <!-- Description -->
                        <div class="flex align-items-center gap-3">
                            <div class="flex flex-column w-10rem gap-1">
                                <div class=" text-lg font-medium">Description:</div>
                                <div class=" text-sm font-medium">(Optional)</div>
                            </div>
                            <InputText v-model="inputs.description" :disabled="currentMode == 'view'" />
                        </div>
                    </div>

                    <div class="flex flex-column gap-2">
                        <!-- Doc No -->
                        <div class="flex align-items-center gap-3">
                            <div class="flex flex-column w-12rem">
                                <div class=" text-lg font-medium">Doc No:</div>
                            </div>
                            <div class="w-12rem">
                                <InputText v-model="detail.doc_no" disabled />
                            </div>
                        </div>

                        <!-- Date -->
                        <div class="flex align-items-center gap-3">
                            <div class="flex flex-column w-12rem">
                                <div class=" text-lg font-medium">Date:</div>
                            </div>
                            <div class="w-12rem">
                                <Calendar v-model="inputs.date" class="w-full" :disabled="currentMode == 'view'" dateFormat="yy-mm-dd" />
                            </div>
                        </div>

                        <div class="flex align-items-center justify-content-end gap-3">
                            <div class="flex flex-column w-12rem">
                                <div class=" text-lg font-medium">Days for Shipment:</div>
                            </div>
                            <div class="w-12rem">
                                <InputNumber v-model="inputs.days_for_shipment" inputId="integeronly"
                                    :disabled="currentMode == 'view'" />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Item Lines Table -->
                <ag-grid-vue class="ag-theme-alpine" style="height: calc(100vh - 250px);" :columnDefs="columnDefs.value"
                    :rowData="rowData.value" :defaultColDef="defaultColDef" rowSelection="multiple" animateRows="true"
                    @grid-ready="onGridReady" :gridOptions="gridOptions" @first-data-rendered="onFirstDataRendered">
                </ag-grid-vue>
            </div>

            <!-- Right Menu Controls -->
            <div class="flex flex-column gap-3">
                <router-link class="w-full" to="/purchase-orders/new">
                </router-link>
                <Button v-if="currentMode == 'view'" @click="navigateToNew" class="w-full" label="New"></Button>
                <Button v-else class="w-full" label="New" disabled></Button>

                <!-- Edit Button -->
                <Button @click="edit" label="Edit" :disabled="currentMode != 'view' || detail.is_deleted == true"
                    severity="warning"></Button>

                <!-- Delete Button -->
                <Button v-if="detail.is_deleted == false" @click="dialogDelete.show = true" label="Delete"
                    :disabled="currentMode != 'view'" severity="danger"></Button>
                <Button v-else @click="dialogUndelete.show = true" label="Undelete" :disabled="currentMode != 'view'"
                    severity="warning"></Button>

                <!-- Save Button -->
                <Button label="Save" :disabled="currentMode == 'view'" severity="success" @click="save"></Button>
                <Button @click="cancel" label="Cancel" :disabled="currentMode == 'view'"></Button>

                <router-link v-if="currentMode == 'view'" to="/purchase-orders">
                    <Button label="Browse" class="w-full"></Button>
                </router-link>
                <Button v-else label="Browse" disabled></Button>
            </div>
        </div>

        <Dialog v-model:visible="dialogSaved.show" modal header="PO Saved Successfully!" :style="{ width: '50vw' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" @hide="dialogSavedConfirm">

            <!-- Table to show -->
            <DataTable :value="dialogSaved.table" responsiveLayout="scroll" size="small">
                <Column field="item" header="Item"></Column>
                <Column field="qty" header="Qty"></Column>
                <Column field="summary" header="Summary"></Column>
            </DataTable>

            <template #footer>
                <Button type="button" label="Ok" @click="dialogSaved.show = false; dialogSavedConfirm()"></Button>
            </template>
        </Dialog>
    </div>
</template>

<style>
.ag-theme-alpine {
    --ag-grid-size: 6px;
}
</style>