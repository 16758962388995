
<template>
    <div>
        <h2>Supplier - {{ supplier.COMPANYNAME }}</h2>
        <div class="flex gap-6 mt-6" style="max-width: 50vw;">
            <div class="w-6">
                <div class="font-medium text-2xl">Available Stock Groups</div>
                <div class="bg-white overflow-y-scroll mt-3 p-3" style="height: 400px">
                    <div v-for="stockGroup in stockGroups" :key="stockGroup.STOCKGROUP"
                        class="card flex justify-content-between align-items-center py-2">
                        {{ stockGroup.STOCKGROUP }}
                        <Button type="button" icon="pi pi-plus" class="" label="Add" size="small"
                            @click="addStockGroup(stockGroup.STOCKGROUP)" />
                    </div>
                </div>
            </div>
            <div class="w-6">
                <div class="font-medium text-2xl">Added</div>
                <div class="bg-white overflow-y-scroll mt-3 p-3" style="height: 400px">
                    <div v-for="rule in stockGroupsAdded" :key="rule.id"
                        class="card flex justify-content-between align-items-center py-2">
                        {{ rule.STOCKGROUP }}
                        <Button type="button" icon="pi pi-plus" class="p-button-danger" label="Remove" size="small"
                            @click="removeStockGroup(rule)" />
                    </div>
                </div>
            </div>
        </div>

        <div class="font-medium text-2xl mt-6">Items</div>
        <div class="font-medium mt-1">{{ supplier.items ? supplier.items.length + " items" : ''}}</div>
        <DataTable :value="supplier.items" responsiveLayout="scroll" :loading="isLoading.supplierItems || isLoading.supplier" class="mt-3"
            size="small">
            <Column field="CODE" header="Code" sortable >
            </Column>
            <Column field="DESCRIPTION" header="Description" sortable ></Column>
            <Column field="DESCRIPTION2" header="Description 2" sortable ></Column>
            <Column field="STOCKGROUP" header="Stock Group" sortable ></Column>
            <Column field="BALSQTY" header="Qty" sortable ></Column>
            <Column field="REFPRICE" header="Price" sortable >
                <template #body="{ data }">
                    {{ data.REFPRICE ? parseFloat(data.REFPRICE).toFixed(2): '' }}
                </template>
            </Column>
        </DataTable>

    </div>
</template>
  
<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import ProductService from '@/service/ProductService';

import axios from "axios";

const supplier = ref({});


const isLoading = ref({ supplier: false, supplierItems: false });
const route = useRoute();
const stockGroups = ref([]);
let stockGroupsAdded = ref([]);
const productService = new ProductService();

onMounted(async () => {
    stockGroups.value = await productService.getStockGroups();
    getSupplier();
});

const sortStockGroups = function () {
    stockGroups.value.sort((a, b) => {
        if (a.STOCKGROUP < b.STOCKGROUP) {
            return -1;
        }
        if (a.STOCKGROUP > b.STOCKGROUP) {
            return 1;
        }
        return 0;
    });
}


const getSupplier = function () {
    isLoading.value.supplier = true;
    axios({
        method: "GET",
        url: "suppliers/" + route.params.id,
    }).then(
        (result) => {
            const supplierFromBackend = result.data.supplier;

            supplierFromBackend.rules.forEach(rule => {
                if (rule.value_type == 'STOCKGROUP' && rule.rule == 'include') {
                    stockGroupsAdded.value.push({
                        ruleId: rule.id,
                        STOCKGROUP: rule.value
                    });

                    // Find from stockGroups and remove, stockGroups is and object with key stockGroup
                    const index = stockGroups.value.findIndex(stockGroup => stockGroup.STOCKGROUP == rule.value);
                    if (index > -1) {
                        stockGroups.value.splice(index, 1);
                    }

                    sortStockGroups();

                }
            });

            supplier.value = result.data.supplier;
            isLoading.value.supplier = false;
        },
        (error) => {
            console.log(error.response.data);
            isLoading.value.supplier = false;
        }
    );
}

const addStockGroup = function (STOCKGROUP) {
    axios({
        method: "POST",
        url: "suppliers/rules",
        data: {
            supplier_id: route.params.id,
            value_type: 'STOCKGROUP',
            value: STOCKGROUP,
            rule: 'include',
        }
    }).then(
        (result) => {
            if (result.data.status == true) {
                const rule = result.data.rule;

                // If success, add to stockGroupsAdded and remove from stockGroups
                stockGroupsAdded.value.push({
                    ruleId: rule.id,
                    STOCKGROUP: rule.value
                });

                // Find from stockGroups and remove, stockGroups is and object with key stockGroup
                const index = stockGroups.value.findIndex(stockGroup => stockGroup.STOCKGROUP == STOCKGROUP);
                if (index > -1) {
                    stockGroups.value.splice(index, 1);
                }
                sortStockGroups();
                updateAllItemsBelongingToSupplier();
            }
        },
        (error) => {
            console.log(error.response.data);
        }
    );
}

const removeStockGroup = function (rule) {
    axios({
        method: "DELETE",
        url: "suppliers/rules/" + rule.ruleId,
    }).then(
        (result) => {
            if (result.data.status == true) {
                stockGroups.value.push({ STOCKGROUP: rule.STOCKGROUP });

                console.log(stockGroupsAdded.value)

                const index = stockGroupsAdded.value.findIndex(stockGroup => stockGroup.ruleId == rule.ruleId);
                if (index > -1) {
                    stockGroupsAdded.value.splice(index, 1);
                }
                sortStockGroups();
                updateAllItemsBelongingToSupplier();
            }
        },
        (error) => {
            console.log(error.response.data);
        }
    );
}
const updateAllItemsBelongingToSupplier = () => {
    isLoading.value.supplierItems = true;
    axios({
        method: "GET",
        url: "suppliers/" + route.params.id + "/items",
    }).then(
        (result) => {
            supplier.value.items = result.data.items;
            isLoading.value.supplierItems = false;
        },
        (error) => {
            console.log(error.response.data);
            isLoading.value.supplierItems = false;
        }
    );
}

</script>
  
<style scoped></style>