<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <span>KM</span>
    </router-link>
    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle" v-if="props.showMenus">
      <i class="pi pi-bars"></i>
    </button>

    <button class="p-link layout-topbar-menu-button layout-topbar-button" v-styleclass="{
      selector: '@next',
      enterClass: 'hidden',
      enterActiveClass: 'scalein',
      leaveToClass: 'hidden',
      leaveActiveClass: 'fadeout',
      hideOnOutsideClick: true,
    }">
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top align-items-center" v-if="props.showMenus">
      <li class="w-9rem">
        Items:
        <span v-if="itemsStore.isLoading" class="pi pi-spin pi-spinner p-button-icon p-button-icon-left"></span>
        <span v-else>Ready</span>
      </li>
      <!-- <li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li> -->
      <li>
        <button class="p-link layout-topbar-button" @click="logout">
          <i class="pi pi-sign-out"></i>
          <span>Logout</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import axios from "axios";
import { useItemsStore } from "@/stores/items";
import { useRouter } from "vue-router";
const router = useRouter();

const itemsStore = useItemsStore();

const props = defineProps(["showMenus"]);
const emit = defineEmits(['menu-toggle'])

const onMenuToggle = (event) => {
  emit("menu-toggle", event);
};

const logout = () => {
  axios({
    method: "POST",
    url: "user/logout",
  }).then(
    () => {
      router.push("/login");
      localStorage.removeItem("access_token");
      localStorage.removeItem("username");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
    },
    (error) => {
      console.log("Error Logging Out.");
      console.log(error);
    }
  );
}
</script>