<template>
    <Dialog v-model:visible="show" modal class="w-10">
        <FileUpload multiple mode="basic" name="demo[]" accept="image/*" customUpload @uploader="onFileChanged" />

        <div class="flex flex-wrap gap-4 mt-5">
            <div v-for="(media, index) in medias" :key="index">
                <div @click="() => toggleSelectMedia(media)" tabindex="0"
                    class="border border-gray-300 bg-white cursor-pointer focus:ring relative"
                    style="width:150px; height: 150px;"
                    v-if="media.file_type != 'video' || (media.file_type == 'video' && allowVideos == true)">

                    <img v-if="media.file_type == 'image'" :src="media.url" alt="" class="object-contain w-full h-full" />

                    <div v-if="media.file_type == 'video'" class="flex justify-center items-center w-full h-full relative">
                        <video>
                            <source :src="media.url" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div class="absolute h-full w-full bg-gray-500 opacity-70 flex justify-center items-center">
                            <i class="pi pi-video" style="font-size: 2rem"></i>
                        </div>
                    </div>

                    <div v-if="isSelected(media)" class="selected-overlay">
                        <div class="bg-blue-600 opacity-100 w-min p-2 rounded text-sm font-semibold text-white m-2">
                            Selected
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex w-12 justify-content-end gap-3">
            <Button label="Cancel" @click="closeModal"></Button>
            <Button type="button" label="Save Changes" class="" @click="saveSelectImage"></Button>
        </div>
    </Dialog>
</template>



<script>
import router from './../../router';

export default {
    data() {
        return {
            medias: [],
            isLoading: { medias: false },
            errorMessage: "",
            pagination: {},
            searchField: "",
            selectedMedias: [],
        };
    },
    props: {
        modelValue: Boolean,
        allowVideos: { type: Boolean, default: true },
        allowMultiple: { type: Boolean, default: true },
    },
    emits: ['update:modelValue', 'save'],
    computed: {
        show: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    async created() {
        this.getMediasFromApi({ page: 1 });
    },
    methods: {
        getMediasFromApi: async function ({ page = 1 }) {
            this.isLoading.medias = true;
            await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/medias?page=' + page, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                    'Accept': 'application/json',
                },
            }).then(
                (result) => {
                    if (result.status == 401) {
                        router.push('/login');
                        return;
                    }

                    if (result.status != 200 && result.status != 201) {
                        console.log("Error");
                        console.log(result);
                        return;
                    }

                    result.json().then((data) => {
                        this.medias = data.medias.data;
                    });
                }
            ).catch(
                (error) => {
                    console.log("Error");
                    console.log(error);
                }
            );
            this.isLoading.medias = false;
        },
        async onFileChanged(event) {
            this.medias = true;
            for (let i = 0; i < event.files.length; i++) {
                const file = event.files[i]

                const formData = new FormData();
                formData.append("file", file);

                await fetch(process.env.VUE_APP_ECOMMERCE_API_URL + '/medias', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
                        'Accept': 'application/json',
                    },
                    body: formData
                });
            }
            this.getMediasFromApi({ page: 1 });
            this.medias = false;
        },
        updateStateFromApiResponse({ apiResponse }) {
            this.medias = apiResponse.data.medias.data;
            this.pagination.current_page = apiResponse.data.medias.current_page;
            this.pagination.last_page = apiResponse.data.medias.last_page;
            this.pagination.per_page = apiResponse.data.medias.per_page;
            this.pagination.from = apiResponse.data.medias.from;
            this.pagination.to = apiResponse.data.medias.to;
            this.pagination.total = apiResponse.data.medias.total;
        },

        async goPrev() {
            this.getMediasFromApi({ page: this.pagination.current_page - 1 });
        },
        async goNext() {
            this.getMediasFromApi({ page: this.pagination.current_page + 1 });
        },

        toggleSelectMedia(media) {
            if (this.allowMultiple == false) {
                this.$emit("save", media);
                return;
            }

            if (this.isSelected(media)) {
                var index = this.selectedMedias.map((e) => e.id).indexOf(media.id);
                if (index > -1) {
                    this.selectedMedias.splice(index, 1);
                }
            } else {
                this.selectedMedias.push(media);
            }
        },
        isSelected(media) {
            if (this.selectedMedias.some((e) => e.id === media.id)) {
                return true;
            }
            return false;
        },
        closeModal() {
            this.selectedMedias = [];
            this.show = false;
        },
        saveSelectImage() {
            this.$emit("save", this.selectedMedias);
            this.closeModal();
        },
    },
};
</script>


<style scoped>
.selected-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0d00ff44;
    z-index: 1;
}
</style>