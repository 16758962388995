<script setup>

import { ref, onMounted } from "vue";
import { useRouter } from 'vue-router';
import moment from 'moment';

const users = ref([]);
const query = ref({
    page: 1,
    sort_by: null,
});
const isLoading = ref({
    users: false,
});
const pagination = ref({
    total_records: 0,
    records_per_page: 0,
    currentPage: 1,
});

const dateTimeFormat = (timestampString) => {
    // If less than 24 hours ago, show hours ago
    if (moment().diff(moment(timestampString), 'hours') < 24) {
        return moment(timestampString).fromNow();
    }

    // If less than 7 days ago, show days ago
    if (moment().diff(moment(timestampString), 'days') < 7) {
        return moment(timestampString).fromNow();
    }

    // Display as format "dd MMM yyyy HH:mm"
    return moment(timestampString).format('DD MMM YYYY, HH:mm');
}

const dateFormat = (timestampString) => {
    return moment(timestampString).format('DD MMM YYYY, HH:mm');

}

const onPageChange = (event) => {
    query.value.page = event.page + 1;
    getUsers();
}
const onSort = (event) => {
    query.value.sort_by = event.sortField + '-' + (event.sortOrder == 1 ? 'asc' : 'desc');
    getUsers();
}

const getUsers = async () => {
    isLoading.value.users = true;
    let url = '';
    let page = query.value.page;

    if (page == null) {
        page = 1;
    }

    url = process.env.VUE_APP_ECOMMERCE_API_URL + '/users-admin?page=' + (page);

    if (query.value.sort_by) {
        url += '&sort_by=' + query.value.sort_by;
    }

    await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token_ecommerce'),
            'Accept': 'application/json',
        }
    }).then(
        (result) => {
            const router = useRouter();

            if (result.status == 401) {
                router.push('/login');
                return;
            }

            if (result.status != 200) {
                console.log("Error");
                console.log(result);
                return;
            }

            result.json().then((data) => {
                users.value = data.users.data;
                console.log(data);

                pagination.value.total_records = data.users.total
                pagination.value.records_per_page = data.users.per_page
            });
        }
    ).catch(
        (error) => {
            console.log("Error");
            console.log(error);
        }
    );
    isLoading.value.users = false;
}

onMounted(() => {
    getUsers();
    pagination.value.currentPage = (query.value.page - 1) * pagination.value.records_per_page;
});

</script>

<template>
    <div>
        <div class="card">
            <DataTable lazy :value="users" :loading="isLoading.users" class="p-datatable-md hover:bg-gray-100"
                @sort="onSort($event)">
                <template #header>
                    <div class="flex flex-row">
                        <div class="flex flex-column">
                            <IconField class="mr-1 mb-1">
                                <InputIcon class="pi pi-search" />
                                <InputText v-model="query.search" style="width: 100%" @keydown="textChanged" />
                            </IconField>
                        </div>
                    </div>

                    <div class="mt-3">
                        {{ pagination.total_records }} users found.
                    </div>
                </template>

                <!-- Image -->
                <Column field="name" header="Name" sortable></Column>
                <Column field="email" header="Email" sortable></Column>
                <Column field="phone" header="Phone" sortable></Column>
                <Column field="is_from_woocommerce" header="From Woocommerce" sortable></Column>
                <Column field="created_at" header="Created" sortable>
                    <template #body="{ data }">
                        <div :title="dateFormat(data.created_at)">
                            {{ dateTimeFormat(data.created_at) }}
                        </div>
                    </template>
                </Column>
                <Column field="last_login_at" header="Last Login" sortable>
                    <template #body="{ data }">
                        <div :title="dateFormat(data.last_login_at)">
                            {{ dateTimeFormat(data.last_login_at) }}
                        </div>
                    </template>
                </Column>
                <Column field="socialite_google_id" header="Google ID" sortable></Column>
                <Column field="paid_orders_count" header="Orders" sortable></Column>
                <Column field="paid_orders_total_amount" header="Orders Amount" sortable></Column>
                <Column field="roles_appended" header="Roles"></Column>

                <template #footer>
                    <Paginator :rows="pagination.records_per_page" :totalRecords="pagination.total_records"
                        @page="onPageChange($event)" :first="currentPage">
                    </Paginator>
                </template>
            </DataTable>
        </div>
    </div>
</template>