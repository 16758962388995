<template>
    <div class="flex flex-column">
        <div class="flex flex-column lg:flex-row align-items-stretch">
            <div class="col-12 lg:col-6 xl:col-4 flex flex-column ">
                <div class="card">
                    <div class="flex gap-3">
                        <Calendar v-model="date" :manualInput="false" dateFormat="yy-mm-dd" />
                        <div class="flex gap-2">
                            <Button @click="date = moment(date).add(-1, 'days').format('YYYY-MM-DD')"
                                icon="pi pi-chevron-left" aria-label="Previous Date" />
                            <Button @click="date = moment(date).add(1, 'days').format('YYYY-MM-DD')"
                                icon="pi pi-chevron-right" aria-label="Next Date" />
                        </div>
                        <div>

                            <span v-if="isLoading.todaysSummary"
                                class="pi pi-spin pi-spinner p-button-icon p-button-icon-left"></span>
                        </div>
                    </div>
                </div>
                <div class="card flex-grow-1">
                    <div class="flex align-items-center mb-5 gap-2">
                        <span class="block font-bold text-xl">{{ moment(date).format('DD MMM YYYY') }}</span>
                        (updated {{ last_updated }})
                    </div>

                    <div class="flex gap-5">
                        <div class="flex-column">
                            <span class="block font-bold text-lg">Cash Sales</span>
                            <span class="block text-lg mt-2">RM {{ amount.cs.toLocaleString() }}</span>
                            <span class="block text-lg">x{{ quantity.cs }}</span>
                        </div>
                        <div class="flex-column">
                            <span class="block font-bold text-lg">Invoice</span>
                            <span class="block text-lg mt-2">RM {{ amount.iv.toLocaleString() }}</span>
                            <span class="block text-lg">x{{ quantity.iv }}</span>
                        </div>
                        <div class="flex-column">
                            <span class="block font-bold text-lg">CN</span>
                            <span class="block text-lg mt-2">RM {{ amount.cn.toLocaleString() }}</span>
                            <span class="block text-lg">x{{ quantity.cn }}</span>
                        </div>
                        <div class="flex-column">
                            <span class="block font-bold text-lg">Total</span>
                            <span class="block text-lg mt-2">RM {{ amount.total.toLocaleString() }}</span>
                            <span class="block text-lg">x{{ quantity.total }}</span>
                        </div>
                    </div>

                    <div v-if="warnings.length > 0" class="mt-3">
                        <div v-for="warning in warnings" :key="warning.message">
                            <span class="font-bold">{{ warning.title }} :</span>
                            {{ warning.message }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- Hourly cash sale histogram plot -->
            <div class=" col-12 lg:col-3 xl:col-4">
                <div class="card">
                    <div class="font-bold text-xl">Cash Sales - Quantity</div>
                    <!-- List out all agents -->
                    <div id="chart-hourly-histogram">
                        <apexchart height="250" :options="hourlyHistogramPlotOptions"
                            :series="hourlyQuantityHistogramSeries">
                        </apexchart>
                    </div>
                </div>
            </div>

            <!-- Hourly cash sale histogram plot -->
            <div class=" col-12 lg:col-3 xl:col-4">
                <div class="card">
                    <div class="font-bold text-xl">Cash Sales - Amount</div>
                    <!-- List out all agents -->
                    <div id="chart-hourly-histogram">
                        <apexchart height="250" :options="hourlyHistogramPlotOptions" :series="hourlyAmountHistogramSeries">
                        </apexchart>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex">

            <div class="col-12 xl:col-6 ">
                <div class="card">
                    <DataTable :value="groupedPersonSummaryTable">
                        <Column sortable field="agent" header="Agent"></Column>
                        <Column sortable field="cs_qty" header="CS (QTY)"></Column>
                        <Column sortable field="cs_amt" header="CS (AMT)"></Column>
                        <Column sortable field="iv_qty" header="IV (QTY)"></Column>
                        <Column sortable field="iv_amt" header="IV (AMT)"></Column>
                        <Column sortable field="total_qty" header="Total (QTY)"></Column>
                        <Column sortable field="total_amt" header="Total (AMT)"></Column>
                    </DataTable>
                </div>
            </div>

            <!-- Scatter plot by person -->
            <div class="col-12 xl:col-6">
                <div class="card">
                    <!-- List out all agents -->
                    <MultiSelect v-model="scatterPlotSelectedPersons" :options="(Object.keys(groupedByPerson)).sort()"
                        filter optionLabel="" placeholder="Select Agent" :maxSelectedLabels="3" class="w-full md:w-20rem" />
                    <div id="chart-scatter">
                        <apexchart height="350" :options="scatterPlotOptions" :series="scatterPlotSeries">
                        </apexchart>
                    </div>
                </div>
            </div>
        </div>



    </div>
</template>
  
<script setup>
import axios from "axios";
import moment from "moment";
import { ref, onMounted, watch, nextTick } from "vue";

// Initialize with today's date
const date = ref(moment().format("YYYY-MM-DD"));

const amount = ref({
    cs: 0,
    iv: 0,
    cn: 0,
    total: 0,
});

const quantity = ref({
    cs: 0,
    iv: 0,
    cn: 0,
    total: 0,
});

const groupedPersonSummaryTable = ref([]);
const groupedByPerson = ref({});
const last_updated = ref("");
const warnings = ref([]);
const isLoading = ref({ todaysSummary: false });

const hourlyQuantityHistogramSeries = ref([]);
const hourlyAmountHistogramSeries = ref([]);
const hourlyHistogramPlotOptions = ref({
    tooltip: {
        x: {
            show: true,
            format: 'yyyy-MM-dd HH:mm',
            formatter: (x) => {
                // Eg: 5PM to 6PM
                return moment(x).format("hA") + " to " + moment(x).add(1, 'hours').format("hA");
            }
        },
    },
    chart: {
        type: 'bar',
    },
    plotOptions: {
        bar: {
            columnWidth: "98%",
            strokeWidth: 2,
            borderRadius: 5,
            borderRadiusApplication: "end"
        }
    },
    dataLabels: { enabled: false },
    grid: {
        xaxis: {
            lines: {
                show: true
            }
        },
        yaxis: {
            lines: {
                show: true
            }
        },
    },
    xaxis: {
        type: 'datetime',
        format: 'HH:mm',
        labels: {
            datetimeUTC: false
        },
    },
    yaxis: {
        type: 'numeric',
        labels: {
            formatter: (value) => {
                return value.toLocaleString();
            }
        }
    }
})

const scatterPlotOptions = ref({
    tooltip: {
        x: {
            show: true,
            format: 'yyyy-MM-dd HH:mm',
            formatter: undefined,
        },
    },
    chart: {
        type: 'scatter',
        zoom: {
            type: 'xy'
        }
    },
    grid: {
        xaxis: {
            lines: {
                show: true
            }
        },
        yaxis: {
            lines: {
                show: true
            }
        },
    },
    xaxis: {
        type: 'datetime',
        format: 'HH:mm',
        labels: {
            datetimeUTC: false
        },
    },
    yaxis: {
    }
})

const scatterPlotSelectedPersons = ref([]);

const scatterPlotSeries = ref([
]);

const cash_sales = ref([]);
const invoices = ref([]);

// watch date change
watch(date, () => {
    getSummary()
});

watch(scatterPlotSelectedPersons, () => {
    computeScatterplotSeries();
});

onMounted(async () => {
    // Do on next tick  
    await nextTick()
    getSummary();
});

const computeGroupedByPerson = () => {
    // Loop through cash sales
    // For each cash sale, get the time and amount
    // Add to scatterPlotSeries
    groupedByPerson.value = {};

    const groupedByPersonForSummaryTable = {};

    cash_sales.value.forEach((trans) => {
        if (!groupedByPerson.value[trans.AGENT]) {
            groupedByPerson.value[trans.AGENT] = [];
        }

        groupedByPerson.value[trans.AGENT].push(
            [new Date(trans.DOCDATETIME).getTime(), parseFloat(trans.DOCAMT)]
        )

        // Calculate summary (invoice qty, invoice amount, cash sale qty, cash sale amount and total amount, total qty)
        if (!groupedByPersonForSummaryTable[trans.AGENT]) {
            groupedByPersonForSummaryTable[trans.AGENT] = {
                cs_qty: 0,
                cs_amt: 0,
                iv_qty: 0,
                iv_amt: 0,
                total_qty: 0,
                total_amtt: 0,
            };
        }

        groupedByPersonForSummaryTable[trans.AGENT].cs_qty++;
        groupedByPersonForSummaryTable[trans.AGENT].cs_amt = groupedByPersonForSummaryTable[trans.AGENT].cs_amt + parseFloat(trans.DOCAMT)
    });

    invoices.value.forEach((trans) => {
        // Calculate summary (invoice qty, invoice amount, cash sale qty, cash sale amount and total amount, total qty)
        if (!groupedByPersonForSummaryTable[trans.AGENT]) {
            groupedByPersonForSummaryTable[trans.AGENT] = {
                cs_qty: 0,
                cs_amt: 0,
                iv_qty: 0,
                iv_amt: 0,
                total_qty: 0,
                total_amtt: 0,
            };
        }

        groupedByPersonForSummaryTable[trans.AGENT].iv_qty++;
        groupedByPersonForSummaryTable[trans.AGENT].iv_amt = groupedByPersonForSummaryTable[trans.AGENT].iv_amt + parseFloat(trans.DOCAMT)
    });

    // Map to groupedPersonSummaryTable with rounded up amounts
    groupedPersonSummaryTable.value = [];
    Object.keys(groupedByPersonForSummaryTable).forEach((key) => {
        groupedPersonSummaryTable.value.push({
            agent: key,
            cs_qty: groupedByPersonForSummaryTable[key].cs_qty,
            cs_amt: Math.round(groupedByPersonForSummaryTable[key].cs_amt),
            iv_qty: groupedByPersonForSummaryTable[key].iv_qty,
            iv_amt: Math.round(groupedByPersonForSummaryTable[key].iv_amt),
            total_qty: groupedByPersonForSummaryTable[key].cs_qty + groupedByPersonForSummaryTable[key].iv_qty,
            total_amt: Math.round(groupedByPersonForSummaryTable[key].cs_amt) + Math.round(groupedByPersonForSummaryTable[key].iv_amt),
        })
    });

    // Set scatterPlotOptions.xaxis.min and max as 8am and 8pm
    const min = moment(date.value).startOf('day').add(8, 'hours').format("YYYY-MM-DD HH:mm");
    const max = moment(date.value).startOf('day').add(20, 'hours').format("YYYY-MM-DD HH:mm");
    // const min = moment(date.value).startOf('day').format("YYYY-MM-DD HH:mm");
    // const max = moment(date.value).endOf('day').format("YYYY-MM-DD HH:mm");

    scatterPlotOptions.value.xaxis.min = new Date(min).getTime();
    scatterPlotOptions.value.xaxis.max = new Date(max).getTime();

    scatterPlotOptions.value = {
        ...scatterPlotOptions.value,
    }
}


const computeScatterplotSeries = async () => {
    scatterPlotSeries.value = [];

    // Map groupedByPerson to scatterPlotSeries
    Object.keys(groupedByPerson.value).forEach((key) => {

        if (scatterPlotSelectedPersons.value.length > 0 && !scatterPlotSelectedPersons.value.includes(key)) {
            return;
        }
        scatterPlotSeries.value.push({
            name: key,
            data: groupedByPerson.value[key]
        })
    });
}

const computeHourlyHistogramSeries = async () => {
    const seriesAmtData = [];
    const seriesQtyData = [];
    for (let hour = 8; hour <= 20; hour++) {
        let amount = 0;
        let quantity = 0;
        cash_sales.value.forEach((item) => {
            const docdatetime = moment(item.DOCDATETIME).format("YYYY-MM-DD HH:mm");
            const hourFormatted = moment(docdatetime).format("HH");
            if (hourFormatted == hour) {
                amount += parseFloat(item.DOCAMT);
                quantity++;
            }
        });

        seriesAmtData.push({ x: new Date(`Jan 23 2023 ${hour}:30`).getTime(), y: amount });
        seriesQtyData.push({ x: new Date(`Jan 23 2023 ${hour}:30`).getTime(), y: quantity });
    }


    hourlyAmountHistogramSeries.value = [{
        name: 'Cash Sales - Amount',
        data: seriesAmtData
    }]

    hourlyQuantityHistogramSeries.value = [{
        name: 'Cash Sales - Quantity',
        data: seriesQtyData
    }]
}




const getSummary = async () => {
    isLoading.value.todaysSummary = true;

    const dateFormatted = moment(date.value).format("YYYY-MM-DD");
    await axios({
        method: "GET",
        url: "analysis/sales/todays-summary?date=" + dateFormatted,
    }).then(
        (result) => {
            cash_sales.value = result.data.cs;
            invoices.value = result.data.iv;

            // iterate result.data.cs and add 'docamt' to amount.cs
            amount.value = {
                cs: 0,
                iv: 0,
                cn: 0,
                total: 0,
            };

            warnings.value = [];

            result.data.cs.forEach((item) => {
                if (item.DOCAMT == null) {
                    item.DOCAMT = 0;
                }
                // String to float
                amount.value.cs += parseFloat(item.DOCAMT);

                // Check if DOCDATETIME date does not equals to DOCDATE
                const docdatetime = moment(item.DOCDATETIME).format("YYYY-MM-DD");
                const docdate = moment(item.DOCDATE).format("YYYY-MM-DD");

                if (item.DOCNO === "CS-00498147") {
                    console.log(docdatetime, docdate)
                }

                if (docdatetime !== docdate) {
                    warnings.value.push({
                        title: "Warning",
                        message: `Cash Sales ${item.DOCNO} - Date may be wrong.`
                    });
                }
            });

            result.data.iv.forEach((item) => {
                if (item.DOCAMT == null) {
                    item.DOCAMT = 0;
                }

                // String to float
                amount.value.iv += parseFloat(item.DOCAMT);

                // Check if DOCDATETIME date does not equals to DOCDATE
                const docdatetime = moment(item.DOCDATETIME).format("YYYY-MM-DD");
                const docdate = moment(item.DOCDATE).format("YYYY-MM-DD");

                if (docdatetime !== docdate) {
                    warnings.value.push({
                        title: "Warning",
                        message: `Cash Sales ${item.DOCNO} has different date and time.`
                    });
                }
            });

            result.data.cn.forEach((item) => {
                if (item.DOCAMT == null) {
                    item.DOCAMT = 0;
                }
                // String to float
                amount.value.cn += parseFloat(item.DOCAMT);

                // Check if DOCDATETIME date does not equals to DOCDATE
                const docdatetime = moment(item.DOCDATETIME).format("YYYY-MM-DD");
                const docdate = moment(item.DOCDATE).format("YYYY-MM-DD");

                if (docdatetime !== docdate) {
                    warnings.value.push({
                        title: "Warning",
                        message: `Cash Sales ${item.DOCNO} has different date and time.`
                    });
                }
            });

            // round to 2 decimal places
            amount.value.cs = Math.round(amount.value.cs * 100) / 100;
            amount.value.iv = Math.round(amount.value.iv * 100) / 100;
            amount.value.cn = Math.round(amount.value.cn * 100) / 100;

            amount.value.total = amount.value.cs + amount.value.iv - amount.value.cn;

            quantity.value.cs = result.data.cs.length;
            quantity.value.iv = result.data.iv.length;
            quantity.value.cn = result.data.cn.length;
            quantity.value.total = quantity.value.cs + quantity.value.iv + quantity.value.cn;

            // Convert last_updated to minutes,hours or days ago
            last_updated.value = moment(result.data.last_updated).fromNow();
            computeGroupedByPerson();
            computeScatterplotSeries();
            computeHourlyHistogramSeries();
        },
        (error) => {
            console.log(error.response.data);
        }
    );
    isLoading.value.todaysSummary = false;
}

</script>
  