<script setup>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import { contactWhatsappFilter } from '@/utils/contactWhatsappFilter';
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import moment from "moment";

const inputTextSearchTimerTask = ref(null);
const isLoading = ref({ pre_orders: null });
const inputs = ref({
    search_data: "",
    search_type: { name: "Item", value: "item" },
    availableSearchType: [
        { name: "Agent", value: "agent" },
        { name: "Customer", value: "customer" },
        { name: "Contact", value: "contact" },
        { name: "Item", value: "item" },
        { name: "Remark", value: "remark" },
    ],
    availableTabs: [
        { label: "Pending Order", value: "pending_order" },
        { label: "Pending Arrive", value: "pending_arrive" },
        { label: "Ready To Notify", value: "ready_to_notify" },
        { label: "Completed", value: "completed" },
        { label: "All", value: "all" },
    ],
    selectedTabIndex: 0,
    newPage: 0,
});

const preOrders = ref([]);
const userType = localStorage.getItem("user_type")
const pagination = ref({
    current_page: null,
    records_per_page: null,
    total_records: null,
    total_pages: null,
});

// On Mounted
onMounted(async () => {
    await getPreOrders();
});


// Functions
const getPreOrders = async () => {
    const params = new URLSearchParams();
    params.append("order_by_field", "date");
    params.append("order_by_direction", "DESC");

    params.append(
        "filter",
        inputs.value.availableTabs[inputs.value.selectedTabIndex].value
    );
    params.append("page", inputs.value.newPage + 1);

    if (inputs.value.search_data != "") {
        params.append("search_data", inputs.value.search_data);
        params.append("search_type", inputs.value.search_type.value);
    }

    await axios({
        method: "GET",
        url: "pre-orders",
        params: params,
    }).then(
        (result) => {
            pagination.value.current_page =
                result.data.pre_orders.current_page;
            pagination.value.records_per_page =
                result.data.pre_orders.per_page;
            pagination.value.total_records = result.data.pre_orders.total;
            pagination.value.total_pages = result.data.pre_orders.last_page;

            preOrders.value = result.data.pre_orders.data;
        },
        (error) => {
            console.log(error.response.data);
        }
    )
};

const isEditingAddPreOrderItem = (id) => {
    return dialogAddPreOrder.value.editingRows.some(row => row.id === id);
}

const onPageChange = function ($event) {
    inputs.value.newPage = $event.page;
    getPreOrders();
}
const onTabChange = function ($event) {
    inputs.value.selectedTabIndex = $event.index;
    getPreOrders();
}
const searchTypeChanged = function () {
    if (inputs.value.search_data != "") {
        getPreOrders();
    }
}

const generateSuggestions = function (event, dialog) {
    if (event.query.length < 3) {
        dialog.suggestions = [];
        return;
    }

    axios({
        method: "GET",
        url: "items/simple?search=" + event.query,
    }).then(
        (result) => {

            dialog.suggestions = result.data.items.map((item) => {
                return item.CODE + " - " + item.DESCRIPTION;
            });
        },
        (error) => {
            console.log(error.response.data);
        }
    );
}

const textChanged = function () {
    if (inputTextSearchTimerTask.value != null) {
        clearTimeout(inputTextSearchTimerTask.value);
    }

    inputTextSearchTimerTask.value = setTimeout(() => {
        getPreOrders();
    }, 400);
}


// -------------------------- Dialog Add Pre-Order Start -------------------------- //
const showAddPreOrderDialog = () => {
    v$dialogAdd.value.$reset()

    dialogAddPreOrder.value.errorMessage == ""
    dialogAddPreOrder.value.show = true;
    dialogAddPreOrder.value.date = new Date();
    dialogAddPreOrder.value.customer_name = "";
    dialogAddPreOrder.value.agent_name = "";
    dialogAddPreOrder.value.doc_no = "";
    dialogAddPreOrder.value.contact_details = "";
    dialogAddPreOrder.value.contact_details_2 = "";
    dialogAddPreOrder.value.remark = "";
    dialogAddPreOrder.value.items = [
        {
            id: "generated-1",
            item: null,
            code: null,
            quantity: 1,
        },
    ];
    dialogAddPreOrder.value.editingRows = [{ id: "generated-1" }];
}

const savePreOrderItem = function (id) {
    const index = dialogAddPreOrder.value.editingRows.findIndex(item => item.id === id);
    dialogAddPreOrder.value.editingRows.splice(index, 1);
    dialogAddPreOrder.value.editingRows = [...dialogAddPreOrder.value.editingRows];

    // If is last item, add new item
    let isLastItem = true;
    dialogAddPreOrder.value.editingRows.forEach((item) => {
        if (item.id > id) {
            isLastItem = false;
        }
    });

    if (isLastItem) {
        dialogAddPreOrderAddItem();
    }
}

const onRowEditSave = (event) => {
    let { newData, index } = event;
    dialogAddPreOrder.value.items[index] = newData;
}

const editAddPreOrderItem = function (id) {
    dialogAddPreOrder.value.editingRows.push({ id: id });
    dialogAddPreOrder.value.editingRows = [...dialogAddPreOrder.value.editingRows];
}

const removeAddPreOrderItem = function (id) {
    const index = dialogAddPreOrder.value.items.findIndex(item => item.id === id);

    if (index == -1) {
        return;
    }
    dialogAddPreOrder.value.items.splice(index, 1);
    dialogAddPreOrder.value.items = [...dialogAddPreOrder.value.items];

    // Remove from editingRows
    const editingRowsIndex = dialogAddPreOrder.value.editingRows.findIndex(item => item.id === id);
    dialogAddPreOrder.value.editingRows.splice(editingRowsIndex, 1);
    dialogAddPreOrder.value.editingRows = [...dialogAddPreOrder.value.editingRows];
}

const dialogAddPreOrderAddItem = function () {
    dialogAddPreOrder.value.id_counter = dialogAddPreOrder.value.id_counter + 1;
    const newItemId = 'generated-' + (dialogAddPreOrder.value.id_counter);
    dialogAddPreOrder.value.items.push({
        id: newItemId,
        item: null,
        quantity: 1,
    });

    //Newly added item will be editable
    dialogAddPreOrder.value.editingRows.push({
        id: newItemId,
    });
    dialogAddPreOrder.value.editingRows = [
        ...dialogAddPreOrder.value.editingRows,
    ];
    console.log(dialogAddPreOrder.value.editingRows)
}

const dialogAddPreOrderCreate = async function () {
    dialogAddPreOrder.value.loading = true;

    const isFormCorrect = await v$dialogAdd.value.$validate()

    if (!isFormCorrect) {
        dialogAddPreOrder.value.loading = false;
        console.log(v$dialogAdd.value.$errors)
        return;
    }

    //Item to code
    dialogAddPreOrder.value.items.forEach((item) => {
        if (item.item != null) {
            item.code = item.item.substring(0, 6);
        }
    });


    // Check if items are saved
    if (dialogAddPreOrder.value.editingRows.length > 0) {
        // check if only last item and is empty
        if (dialogAddPreOrder.value.editingRows.length == 1 && dialogAddPreOrder.value.items[dialogAddPreOrder.value.items.length - 1].item == null) {
            dialogAddPreOrder.value.items.pop();
            dialogAddPreOrder.value.editingRows.pop();
        } else {
            console.log(dialogAddPreOrder.value.editingRows)
            dialogAddPreOrder.value.errorMessage = "Items must be saved.";
            dialogAddPreOrder.value.loading = false;
            return;
        }
    }

    axios({
        method: "POST",
        url: "pre-orders",
        data: {
            date: dialogAddPreOrder.value.date,
            customer_name: dialogAddPreOrder.value.customer_name,
            agent_name: dialogAddPreOrder.value.agent_name,
            contact_details: dialogAddPreOrder.value.contact_details,
            contact_details_2: dialogAddPreOrder.value.contact_details_2,
            doc_no: dialogAddPreOrder.value.doc_no,
            remark: dialogAddPreOrder.value.remark,
            items: dialogAddPreOrder.value.items,
        },
    }).then(
        () => {
            dialogAddPreOrder.value.loading = false;
            dialogAddPreOrder.value.show = false;
            getPreOrders();
        },
        (error) => {
            if (error.response && error.response.data.message) {
                dialogAddPreOrder.value.errorMessage = error.response.data.message;
            } else {
                dialogAddPreOrder.value.errorMessage = "Server error.";
            }
            dialogAddPreOrder.value.loading = false;
        }
    );
}

const dialogAddPreOrder = ref({
    id_counter: 1,
    date: null,
    customer_name: null,
    agent_name: null,
    contact_details: null,
    contact_details_2: null,
    doc_no: null,
    remark: null,
    show: false,
    id: "",
    errorMessage: "",
    loading: false,
    items: [],
    suggestions: [],
    editingRows: [],
});

const rulesAdd = computed(() => ({
    dialogAddPreOrder: {
        date: { required },
        customer_name: { required },
        agent_name: { required },
        contact_details: { required },
    }
}))

const v$dialogAdd = useVuelidate(rulesAdd, { dialogAddPreOrder })
// -------------------------- Dialog Add Pre-Order END -------------------------- //

// -------------------------- Dialog Toggle Item Status START -------------------------- //
const dialogToggleItemStatus = ref({
    show: false,
    data: null,
    itemData: null,
    loading: false,
    errorMessage: "",
})

const showDialogToggleItemStatus = function (data, itemData) {
    dialogToggleItemStatus.value.data = data;
    dialogToggleItemStatus.value.itemData = itemData;
    dialogToggleItemStatus.value.loading = false;
    dialogToggleItemStatus.value.show = true;
    dialogToggleItemStatus.value.errorMessage = "";
}

const toggleItemStatus = function () {
    dialogToggleItemStatus.value.loading = true;

    // const data = dialogToggleItemStatus.data;
    const itemData = dialogToggleItemStatus.value.itemData;

    let field = "";
    let value = null;

    // If itemData.ordered is false, toggle to true
    if (itemData.ordered == false) {
        field = "ordered";
        value = true;
    } else if (itemData.notified == false) {
        field = "notified";
        value = true;
    } else if (itemData.notified == true) {
        field = "notified";
        value = false;
    }

    // const itemsFromData = [...data.items];
    const dataToPost = {
        id: itemData.id,
        field: field,
        value: value,
    };

    axios({
        method: "POST",
        url: "pre-orders/toggle-item-status",
        data: dataToPost,
    }).then(
        () => {
            dialogToggleItemStatus.value.loading = false;
            dialogToggleItemStatus.value.show = false;
            getPreOrders();
        },
        (error) => {
            if (error.response && error.response.data.message) {
                dialogToggleItemStatus.value.errorMessage = error.response.data.message;
            } else {
                dialogToggleItemStatus.value.errorMessage = "Server error.";
            }
            dialogToggleItemStatus.value.loading = false;
        }
    );
}
// -------------------------- Dialog Toggle Item Status END -------------------------- //

// ---------------------------------------------------- Dialog Remove START ---------------------------------------------------- //
const dialogRemovePreOrder = ref({
    show: false,
    id: null,
    errorMessage: "",
    isLoading: false,
});

const showRemovePreOrderDialog = function (id) {
    dialogRemovePreOrder.value.show = true;
    dialogRemovePreOrder.value.id = id;
}

const removePreOrder = function () {
    dialogRemovePreOrder.value.isLoading = true;
    axios({
        method: "DELETE",
        url: "pre-orders",
        data: {
            id: dialogRemovePreOrder.value.id.toString(),
        },
    }).then(
        () => {
            dialogRemovePreOrder.value.isLoading = false;
            dialogRemovePreOrder.value.show = false;
            dialogRemovePreOrder.value.id = null;
            getPreOrders();
        },
        (error) => {
            dialogRemovePreOrder.value.errorMessage = error.response.data.message;
            dialogRemovePreOrder.value.isLoading = false;
        }
    );
}
// ---------------------------------------------------- Dialog Remove END ---------------------------------------------------- //

// ---------------------------------------------------- Dialog Edit Start ---------------------------------------------------- //
const dialogEditPreOrder = ref({
    show: false,
    id: null,
    date: null,
    customer_name: null,
    agent_name: null,
    contact_details: null,
    contact_details_2: null,
    doc_no: null,
    remark: null,
    errorMessage: "",
    isLoading: false,
    item_id_counter: null,
    items: [
        {
            id: null,
            item: null,
            quantity: null,
            code: null,
            notified: null,
        },
    ],
    suggestions: [],
    editingRows: [],
});

const showEditPreOrderDialog = function (data) {
    dialogEditPreOrder.value.isLoading = false;
    dialogEditPreOrder.value.show = true;
    dialogEditPreOrder.value.id = data.id;
    dialogEditPreOrder.value.date = data.date;
    dialogEditPreOrder.value.customer_name = data.customer_name;
    dialogEditPreOrder.value.agent_name = data.agent_name;
    dialogEditPreOrder.value.contact_details = data.contact_details;
    dialogEditPreOrder.value.contact_details_2 = data.contact_details_2;
    dialogEditPreOrder.value.doc_no = data.doc_no;
    dialogEditPreOrder.value.remark = data.remark;
    dialogEditPreOrder.value.items = [...data.items];

    dialogEditPreOrder.value.items.forEach((item, index) => {
        dialogEditPreOrder.value.items[index].index = index;
        dialogEditPreOrder.value.items[index].item =
            item.code + " - " + item.description;
    });
}

const dialogEditDeleteItem = function (data) {
    // Remove items from array
    dialogEditPreOrder.value.items = dialogEditPreOrder.value.items.filter(item => item.index !== data.index);

    // Reset index
    dialogEditPreOrder.value.items.forEach((item, index) => {
        item.index = index;
    });

    dialogEditPreOrder.value.items = [...dialogEditPreOrder.value.items];
}

const dialogEditAddItem = function () {
    const newIndex = dialogEditPreOrder.value.items.length;
    dialogEditPreOrder.value.items.push({
        index: newIndex,
        item: null,
        quantity: 1,
        notified: false,
        id: null,
    });
}

const dialogEditSave = async function () {
    dialogEditPreOrder.value.isLoading = true;

    const isFormCorrect = await v$dialogEdit.value.$validate()

    if (!isFormCorrect) {
        dialogAddPreOrder.value.loading = false;
        dialogAddPreOrder.value.errorMessage = v$dialogEdit.value.$errors[0].$message;
        return;
    }

    //Item to code
    dialogEditPreOrder.value.items.forEach((item) => {
        if (item.item != null) {
            item.code = item.item.substring(0, 6);
        }
    });

    // Filter items, remove items with no code
    dialogEditPreOrder.value.items = dialogEditPreOrder.value.items.filter(item => item.code != null);
    axios({
        method: "PUT",
        url: "pre-orders",
        data: {
            id: dialogEditPreOrder.value.id,
            date: dialogEditPreOrder.value.date,
            customer_name: dialogEditPreOrder.value.customer_name,
            agent_name: dialogEditPreOrder.value.agent_name,
            contact_details: dialogEditPreOrder.value.contact_details,
            contact_details_2: dialogEditPreOrder.value.contact_details_2,
            doc_no: dialogEditPreOrder.value.doc_no,
            remark: dialogEditPreOrder.value.remark,
            items: dialogEditPreOrder.value.items,
        },
    }).then(
        () => {
            dialogEditPreOrder.value.isLoading = false;
            dialogEditPreOrder.value.show = false;
            getPreOrders();
        },
        (error) => {
            if (error.response && error.response.data.message) {
                dialogEditPreOrder.value.errorMessage = error.response.data.message;
            } else {
                dialogEditPreOrder.value.errorMessage = "Server error.";
            }
            dialogEditPreOrder.value.isLoading = false;
        }
    );
}
// ---------------------------------------------------- Dialog Edit END ---------------------------------------------------- //

const showTimeAgoFromTimestamp = function (timestamp) {
    return moment(timestamp).fromNow();
}
const showDateFromTimestamp = function (timestamp) {
    return moment(new Date(timestamp)).format("DD-MMM-YY");
}

const rulesEdit = computed(() => ({
    dialogEditPreOrder: {
        date: { required },
        customer_name: { required },
        agent_name: { required },
        contact_details: { required },
    }
}))

const v$dialogEdit = useVuelidate(rulesEdit, { dialogEditPreOrder })
</script>

<!--  ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  -->
<!--  -------------------------------------------------------------------------------------------------------- HTML --------------------------------------------------------------------------------------------------------  -->
<!--  ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  -->
<template>
    <div>
        <h2>Pre-Orders</h2>
        <DataTable :value="preOrders" responsiveLayout="scroll" :loading="isLoading.pre_orders" size="small">
            <template #header>
                <div class="flex flex-row">
                    <Button type="button" icon="pi pi-plus" label="Add Pre-Order" class="p-button-outlined mb-2 mr-4"
                        @click="showAddPreOrderDialog" />
                </div>
                <div class="flex flex-row">
                    <div class="flex flex-column">
                        <IconField class="mr-1 mb-1">
                            <InputIcon class="pi pi-search" />
                            <InputText v-model="inputs.search_data"
                                :placeholder="'Search by ' + inputs.search_type.name" style="width: 100%"
                                @keydown="textChanged" />
                        </IconField>
                    </div>
                    <Dropdown v-model="inputs.search_type" :options="inputs.availableSearchType" optionLabel="name"
                        placeholder="Select" class="mr-4 h-full" @change="searchTypeChanged" />
                </div>
                <TabMenu class="mt-3" :model="inputs.availableTabs" @tab-change="onTabChange($event)" />
                <div class="mt-3">
                    {{ pagination.total_records }} pre-orders found.
                </div>
            </template>
            <template #footer>
                <Paginator :rows="pagination.records_per_page" :totalRecords="pagination.total_records"
                    @page="onPageChange($event)">
                </Paginator>
            </template>

            <Column field="date" header="Date"></Column>
            <Column header="Customer">
                <template #body="{ data }">
                    <div class="flex flex-column">
                        <div>{{ data.customer_name }}</div>
                        <a :href="'https://wa.me/' + contactWhatsappFilter(data.contact_details)
            " target="_blank" rel="noopener noreferrer">{{ data.contact_details }}</a>
                        <a v-if="data.contact_details_2" :href="'https://wa.me/' + contactWhatsappFilter(data.contact_details_2)
            " target="_blank" rel="noopener noreferrer">{{ data.contact_details_2 }}</a>
                    </div>
                </template>
            </Column>
            <Column field="agent_name" header="Agent"></Column>
            <Column field="remark" header="Remark" style="max-width: 250px;"></Column>
            <Column field="items" header="Items">
                <template #body="{ data }">
                    <table class="items-table">
                        <tr>
                            <th class="nowrap">Code</th>
                            <th style="width:100%">Description</th>
                            <th>Qty</th>
                            <th class="nowrap">Qty Bal.</th>
                            <th class="nowrap">Arrived</th>
                            <th></th>
                        </tr>
                        <tr v-for=" item in data.items" :key="item.id">
                            <td :class="{
            readyToNotify:
                (item.available_stock >= item.quantity || item.arrived_qty >= item.quantity) && item.notified == false,
        }" class="nowrap">{{ item.code }}</td>
                            <td :class="{
            readyToNotify:
                (item.available_stock >= item.quantity || item.arrived_qty >= item.quantity) && item.notified == false,
        }">{{ item.description }}</td>
                            <td>{{ item.quantity }}</td>
                            <td :class="{
            readyToNotify:
                item.available_stock >= item.quantity && item.notified == false,
        }">{{ item.available_stock }}</td>
                            <td :class="{
            readyToNotify:
                item.arrived_qty >= item.quantity && item.notified == false,
        }">{{ item.arrived_qty }}</td>
                            <td>
                                <Button v-if="item.notified == true" :title="showTimeAgoFromTimestamp(item.notified_at)"
                                    :label="showDateFromTimestamp(item.notified_at)" class="p-button-success  nowrap"
                                    @click="showDialogToggleItemStatus(data, item)" :loading="item.loading"
                                    size="small" />
                                <Button v-else-if="item.ordered == false" label="NEW" class="p-button-info nowrap"
                                    @click="showDialogToggleItemStatus(data, item)" :loading="item.loading"
                                    size="small" />
                                <Button v-else size="small" label="Ordered" class="p-button-warning  nowrap"
                                    @click="showDialogToggleItemStatus(data, item)" :loading="item.loading" />
                            </td>
                        </tr>
                    </table>
                </template>
            </Column>
            <Column>
                <template #body="{ data }">
                    <div class="gap-2 flex flex-column">
                        <Button size="small" label="Edit" icon="pi pi-pencil" class="p-button-primary mr-2"
                            @click="showEditPreOrderDialog(data)" />
                        <Button size="small" v-if="userType != 'staff'" label="Remove" icon="pi pi-trash"
                            class="p-button-danger mr-2" @click="showRemovePreOrderDialog(data.id)" />
                    </div>
                </template>
            </Column>
        </DataTable>

        <!-- ---------------------- Remove Pre-Order dialog ---------------------- -->
        <Dialog header="Remove Pre-Order" v-model:visible="dialogRemovePreOrder.show" :style="{ width: '350px' }"
            :modal="true">
            <div class="flex align-items-center justify-content-left">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Are you sure you want to proceed?</span>
            </div>
            <div class="flex align-items-center justify-content-left">
                <div id="error-message" class="p-error mt-2">
                    {{ dialogRemovePreOrder.errorMessage }}
                </div>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="dialogRemovePreOrder.show = false" class="p-button"
                    :loading="dialogRemovePreOrder.isLoading" />
                <Button label="Yes" icon="pi pi-check" @click="removePreOrder" class="p-button" severity="danger"
                    :loading="dialogRemovePreOrder.isLoading" />
            </template>
        </Dialog>

        <!--------------------- Add Pre-Order Dialog ---------------------->
        <Dialog header="Add New Pre-Order" v-model:visible="dialogAddPreOrder.show" :style="{ width: '1000px' }"
            :modal="true">
            <div class="flex flex-column align-items-center justify-content-center gap-3">
                <div class="mb-2 w-full">
                    <div class="p-float-label">
                        <Calendar id="date" v-model="dialogAddPreOrder.date" :showIcon="true" class="w-full" dateFormat="yy-mm-dd"/>
                        <label for="date">Date</label>
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center mb-2 gap-2 w-full">
                    <InputText type="text" placeholder="Customer Name" name="customer_name"
                        :class="{ 'p-invalid': v$dialogAdd.dialogAddPreOrder.customer_name.$error }"
                        v-model="dialogAddPreOrder.customer_name" class="w-full" autofocus />
                    <InputText type="text" placeholder="Agent Name"
                        :class="{ 'p-invalid': v$dialogAdd.dialogAddPreOrder.agent_name.$error }" name="agent_name"
                        v-model="dialogAddPreOrder.agent_name" class="w-full" />
                </div>
                <div class="flex align-items-center justify-content-center mb-2 gap-2 w-full">
                    <InputText type="text" placeholder="Contact Details" name="contact_details"
                        :class="{ 'p-invalid': v$dialogAdd.dialogAddPreOrder.contact_details.$error }"
                        v-model="dialogAddPreOrder.contact_details" class="w-full mb-2" />
                    <InputText type="text" placeholder="Contact Details 2 (Optional)" name="contact_details_2"
                        v-model="dialogAddPreOrder.contact_details_2" class="w-full mb-2" />
                </div>

                <div class="flex flex-column gap-2 w-full">
                    <label for="add_dialog_doc_no">Doc No (Optional)</label>
                    <InputText id="add_dialog_doc_no" type="text" placeholder="EG: CS-000912 / IV-000321" name="remark"
                        v-model="dialogAddPreOrder.doc_no" class="w-full" />
                </div>

                <div class="flex flex-column gap-2 w-full">
                    <label for="add_dialog_remark">Remark (Optional)</label>
                    <InputText id="add_dialog_remark" type="text" placeholder="Remark (Optional)" name="remark"
                        v-model="dialogAddPreOrder.remark" class="w-full" />
                </div>

                <div class="card w-full">
                    <h5>Items</h5>
                    <Button label="Add Item" icon="pi pi-plus" @click="dialogAddPreOrderAddItem"
                        class="p-button-text" />
                    <DataTable :value="dialogAddPreOrder.items" editMode="row" dataKey="id"
                        v-model:editingRows="dialogAddPreOrder.editingRows" @row-edit-save="onRowEditSave"
                        responsiveLayout="scroll">
                        <Column header="Item" style="width: 45%">
                            <template #body="{ data, index }">
                                <div v-if="!isEditingAddPreOrderItem(data.id)">
                                    {{ data.item }}
                                </div>
                                <div v-else>
                                    <AutoComplete v-model="dialogAddPreOrder.items[index].item"
                                        :suggestions="dialogAddPreOrder.suggestions"
                                        @complete="generateSuggestions($event, dialogAddPreOrder)" forceSelection />
                                </div>
                            </template>
                        </Column>
                        <Column header="Quantity" style="width: 45%">
                            <template #body="{ data, index }">
                                <div v-if="!isEditingAddPreOrderItem(data.id)">
                                    {{ data.quantity }}
                                </div>
                                <div v-else>
                                    <InputText v-model="dialogAddPreOrder.items[index].quantity" />
                                </div>
                            </template>
                        </Column>

                        <Column header="Action" style="width: 10%">
                            <template #body="{ data }">
                                <div v-if="!isEditingAddPreOrderItem(data.id)" class="flex gap-2">
                                    <Button label="Remove" severity="danger" text
                                        @click="removeAddPreOrderItem(data.id)" />
                                    <Button label="Edit" severity="primary" text
                                        @click="editAddPreOrderItem(data.id)" />
                                </div>
                                <div v-else class="flex gap-2">
                                    <Button label="Remove" severity="danger" text
                                        @click="removeAddPreOrderItem(data.id)" />
                                    <Button label="Save" severity="primary" text @click="savePreOrderItem(data.id)" />
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
            <div class="flex align-items-center justify-content-left">
                <div v-if="v$dialogAdd && v$dialogAdd.$errors.length > 0" id="error-message" class="p-error mt-2">
                    {{ v$dialogAdd.$errors[0].$message }}
                </div>
                <div v-if="dialogAddPreOrder.errorMessage != ''" id="error-message" class="p-error mt-2">
                    {{ dialogAddPreOrder.errorMessage }}
                </div>
            </div>
            <template #footer>
                <Button label="Cancel" icon="pi pi-times" @click="dialogAddPreOrder.show = false" class="p-button"
                    severity="danger" :loading="dialogAddPreOrder.loading" />
                <Button label="Create" icon="pi pi-check" @click="dialogAddPreOrderCreate" class="p-button"
                    severity="success" :loading="dialogAddPreOrder.loading" />
            </template>
        </Dialog>

        <!--------------------- Edit Pre-Order Dialog ---------------------->
        <Dialog header="Edit Pre-Order" v-model:visible="dialogEditPreOrder.show" :style="{ width: '800px' }"
            :modal="true">
            <div class="flex flex-column gap-3 align-items-center justify-content-center">
                <div class="mb-2 w-full">
                    <div class="p-float-label">
                        <Calendar id="date" v-model="dialogEditPreOrder.date" :showIcon="true" class="w-full" dateFormat="yy-mm-dd" />
                        <label for="date">Date</label>
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center mb-2 gap-2 w-full">
                    <InputText type="text" placeholder="Customer Name" name="customer_name"
                        :class="{ 'p-invalid': v$dialogEdit.dialogEditPreOrder.customer_name.$error }"
                        v-model="dialogEditPreOrder.customer_name" class="w-full" autofocus />
                    <InputText type="text" placeholder="Agent Name"
                        :class="{ 'p-invalid': v$dialogEdit.dialogEditPreOrder.agent_name.$error }" name="agent_name"
                        v-model="dialogEditPreOrder.agent_name" class="w-full" />
                </div>
                <div class="flex align-items-center justify-content-center gap-2 w-full">
                    <InputText type="text" placeholder="Contact Details" name="contact_details"
                        :class="{ 'p-invalid': v$dialogEdit.dialogEditPreOrder.contact_details.$error }"
                        v-model="dialogEditPreOrder.contact_details" class="w-full mb-2" />
                    <InputText type="text" placeholder="Contact Details 2 (Optional)" name="contact_details_2"
                        v-model="dialogEditPreOrder.contact_details_2" class="w-full mb-2" />
                </div>

                <div class="flex flex-column gap-2 w-full">
                    <label for="add_dialog_doc_no">Doc No (Optional)</label>
                    <InputText id="add_dialog_doc_no" type="text" placeholder="EG: CS-000912 / IV-000321" name="remark"
                        v-model="dialogEditPreOrder.doc_no" class="w-full" />
                </div>

                <div class="flex flex-column gap-2 w-full">
                    <label for="add_dialog_remark">Remark (Optional)</label>
                    <InputText id="add_dialog_remark" type="text" placeholder="Remark (Optional)" name="remark"
                        v-model="dialogEditPreOrder.remark" class="w-full" />
                </div>

                <div class="card w-full">
                    <h5>Items</h5>
                    <Button label="Add Item" icon="pi pi-plus" @click="dialogEditAddItem()" class="p-button-text" />

                    <DataTable :value="dialogEditPreOrder.items" dataKey="index" responsiveLayout="scroll"
                        tableStyle="min-width: 100%">
                        <Column field="id" header="ID" style="width: 10%">
                        </Column>

                        <Column field="item" header="Item" style="width: 60%">
                            <template #body="{ data, field }">
                                <AutoComplete v-if="data['id'] == null" v-model="data[field]"
                                    :suggestions="dialogEditPreOrder.suggestions"
                                    @complete="generateSuggestions($event, dialogEditPreOrder)" forceSelection
                                    class="w-full" />

                                <div v-else>
                                    {{ data[field] }}
                                </div>
                            </template>
                        </Column>

                        <Column field="quantity" header="Quantity" style="width: 10%;">
                            <template #body="{ data, field }">
                                <InputNumber v-model="data[field]" />
                            </template>
                        </Column>

                        <Column header="Actions" style="width: 20%">
                            <template #body="{ data }">
                                <Button label="Delete" severity="danger" @click="dialogEditDeleteItem(data)"></Button>
                            </template>
                        </Column>

                    </DataTable>
                </div>
            </div>
            <div class="flex align-items-center justify-content-left">
                <div id="error-message" class="p-error mt-2">
                    {{ dialogEditPreOrder.errorMessage }}
                </div>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="dialogEditPreOrder.show = false" class="p-button-text"
                    :loading="dialogEditPreOrder.isLoading" />
                <Button label="Yes" icon="pi pi-check" @click="dialogEditSave()" class="p-button-text"
                    :loading="dialogEditPreOrder.isLoading" />
            </template>
        </Dialog>

        <!-- Toggle Ongoing Dialog -->
        <Dialog header="Toggle Item Status" v-model:visible="dialogToggleItemStatus.show" :style="{ width: '400px' }"
            :modal="true">
            <div class="flex align-items-center justify-content-left text-lg">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="dialogToggleItemStatus.itemData &&
            !dialogToggleItemStatus.itemData.ordered
            ">Changing this item to <span class="green-notified">ORDERED</span>.
                    <br>Are you sure?</span>
                <span v-else-if="dialogToggleItemStatus.itemData &&
            !dialogToggleItemStatus.itemData.notified
            ">Changing this item to <span class="green-notified">NOTIFIED</span>.
                    <br>Are you sure?</span>
                <span v-else>Changing this item to <span class="orange-ongoing">ORDERED</span>.
                    <br>Are you sure?</span>

            </div>
            <div class="flex align-items-center justify-content-left">
                <div id="error-message" class="p-error mt-2">
                    {{ dialogToggleItemStatus.errorMessage }}
                </div>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="dialogToggleItemStatus.show = false" class="p-button-text"
                    :loading="dialogToggleItemStatus.loading" />
                <Button label="Yes" icon="pi pi-check" @click="toggleItemStatus" class="p-button-text"
                    :loading="dialogToggleItemStatus.loading" />
            </template>
        </Dialog>
    </div>
</template>

<style scoped lang="scss">
.readyToNotify {
    background: yellow;
}

.green-notified {
    font-weight: bold;
    color: rgb(28, 151, 73);
}

.orange-ongoing {
    font-weight: bold;
    color: #ca850d;
}

table.items-table {
    border-collapse: collapse;

    th,
    td {
        border: 1px solid #00000032;
        padding: 2px 6px;
    }
}

.nowrap {
    overflow: hidden;
    white-space: nowrap;
}
</style>
<style lang="scss">
.p-inputnumber-input,
.p-inputtext {
    width: 100%;
}
</style>