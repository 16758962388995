<template>
    <Button label="New" @click="showAddNewClaimDialog"></Button>

    <!-- Show Add New Dialog -->
    <Dialog v-model:visible="dialogAddNewClaim.show" modal header="Add New Claim" :style="{ width: '78rem' }">
        <div class="flex flex-column gap-3">
            <div class="flex gap-4">
                <div>
                    <div class="mb-1 text-lg font-medium">Claim Type</div>
                    <SelectButton v-model="dialogAddNewClaim.claim_type" :options="claimTypeOptions"
                        aria-labelledby="basic" :allowEmpty="false" />
                </div>
                <div>
                    <div class="mb-1 text-lg font-medium">Date</div>
                    <Calendar v-model="dialogAddNewClaim.date" class="w-full" dateFormat="yy-mm-dd" />
                </div>
            </div>
            <div class="w-6">
                <div class="mb-1 text-lg font-medium">Agent Name</div>
                <InputText class="" v-model="dialogAddNewClaim.agent_name" type="text"
                    :invalid="v$dialogAdd.agent_name.$errors.length > 0" autofocus />
            </div>
            <div class="w-6" v-if="dialogAddNewClaim.claim_type == 'Customer'">
                <div class="mb-1 text-lg font-medium">Customer Name</div>
                <InputText class="" v-model="dialogAddNewClaim.customer_name" type="text"
                    :invalid="v$dialogAdd.customer_name.$errors.length > 0" />
            </div>
            <div class="flex gap-4 w-full" v-if="dialogAddNewClaim.claim_type == 'Customer'">
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">Contact Detail 1</div>
                    <InputText class="" v-model="dialogAddNewClaim.contact_details_1" type="text"
                        :invalid="v$dialogAdd.contact_details_1.$errors.length > 0" />
                </div>
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">Contact Detail 2 <span
                            class="font-normal text-base">(Optional)</span></div>
                    <InputText class="" v-model="dialogAddNewClaim.contact_details_2" type="text" />
                </div>
            </div>

            <div class="flex gap-4 w-full" v-if="dialogAddNewClaim.claim_type == 'KM Stock'">
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">IS Date <span class="font-normal text-base">(Optional)</span>
                    </div>
                    <Calendar v-model="dialogAddNewClaim.is_date" class="w-full" dateFormat="yy-mm-dd" />
                </div>
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">IS Doc No <span
                            class="font-normal text-base">(Optional)</span>
                    </div>
                    <InputText class="" v-model="dialogAddNewClaim.is_no" type="text" />
                </div>

                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">RC Date <span class="font-normal text-base">(Optional)</span>
                    </div>
                    <Calendar v-model="dialogAddNewClaim.rc_date" class="w-full" dateFormat="yy-mm-dd" />
                </div>
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">RC Doc No <span
                            class="font-normal text-base">(Optional)</span>
                    </div>
                    <InputText class="" v-model="dialogAddNewClaim.rc_no" type="text" />
                </div>
            </div>

            <div class="flex gap-4">
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">Brand</div>
                    <AutoComplete v-model="dialogAddNewClaim.selectedBrand" autoOptionFocus
                        :suggestions="dialogAddNewClaim.brandSuggestions" optionLabel="name"
                        @complete="dialogAddNewClaimGenerateBrandSuggestions($event)" forceSelection completeOnFocus
                        class="w-full" dropdown :invalid="v$dialogAdd.selectedBrand.$errors.length > 0" />
                </div>
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">Category</div>
                    <AutoComplete v-model="dialogAddNewClaim.selectedCategory" autoOptionFocus
                        :suggestions="dialogAddNewClaim.categorySuggestions" optionLabel="name"
                        @complete="dialogAddNewClaimGenerateCategorySuggestions($event)" forceSelection completeOnFocus
                        class="w-full" dropdown :invalid="v$dialogAdd.selectedCategory.$errors.length > 0" />
                </div>
            </div>
            <div class="flex gap-4 w-full">
                <div class="w-6">
                    <div class="mb-1 text-lg font-medium">Item</div>
                    <AutoComplete v-model="dialogAddNewClaim.selectedItem" autoOptionFocus
                        :suggestions="dialogAddNewClaim.itemSuggestions" optionLabel="label"
                        @complete="dialogAddNewClaimGenerateItemSuggestions($event)" forceSelection class="w-full"
                        :invalid="v$dialogAdd.selectedItem.$errors.length > 0" />
                </div>
                <div class="w-6 flex gap-3">
                    <div class="w-6">
                        <div class="mb-1 text-lg font-medium">Quantity</div>
                        <InputNumber v-model="dialogAddNewClaim.quantity" inputId="integeronly" class="w-12"
                            :invalid="v$dialogAdd.quantity.$errors.length > 0" />
                    </div>
                    <div class="w-6" v-if="dialogAddNewClaim.claim_type == 'Customer'">
                        <div class="mb-1 text-lg font-medium">Quantity in Shop <i class="pi pi-question-circle"
                                style="font-size: 1rem"
                                v-tooltip.top="'If customer brought back home, no item in shop then quantity is shop is 0. \n\nIf not, then key in how many quantity did customer left in our shop.'"></i>
                        </div>
                        <InputNumber :invalid="v$dialogAdd.quantity_in_shop.$errors.length > 0"
                            v-model="dialogAddNewClaim.quantity_in_shop" inputId="integeronly" class="w-12" />
                    </div>
                </div>
            </div>
            <div class="">
                <div class="mb-1 text-lg font-medium">Problem <i class="pi pi-question-circle" style="font-size: 1rem"
                        v-tooltip.top="'Problem with the item. EG: Motor Rosak.'"></i></div>
                <Textarea v-model="dialogAddNewClaim.problem" rows="3"></Textarea>
            </div>

            <div class="">
                <div class="mb-1 text-lg font-medium">Remark <i class="pi pi-question-circle" style="font-size: 1rem"
                        v-tooltip.top="'Other remarks regarding the claim.'"></i></div>
                <Textarea v-model="dialogAddNewClaim.remark" rows="5"></Textarea>
            </div>

        </div>

        <!-- Error message -->
        <div v-if="dialogAddNewClaim.errorMessage" class="mt-2 text-red-600" style="white-space: pre;">{{
            dialogAddNewClaim.errorMessage
            }}</div>

        <div class="flex gap-3 w-100 justify-content-end mt-6">
            <Button label="Cancel" class="p-button-raised" severity="secondary" @click="dialogAddNewClaim.show = false"
                :loading="dialogAddNewClaim.isLoading" />
            <Button label="Save" class="p-button-raised" @click="saveAddNewCategoryDialog"
                :loading="dialogAddNewClaim.isLoading" />
        </div>
    </Dialog>
</template>



<script setup>
import { ref, onMounted, defineProps, defineEmits} from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, minValue } from '@vuelidate/validators'
import axios from "axios";
import { convertPrimeVueCalendar } from '../../utils/convertPrimeVueCalendar';


const props = defineProps({
    brands: Array,
    categories: Array,
})
const emit = defineEmits(['saved'])
// ------------------ Dialog Add New Claim ------------------------------
const claimTypeOptions = ['Customer', 'KM Stock'];

const dialogAddNewClaimGenerateItemSuggestions = (event) => {
    axios({
        method: "GET",
        url: "items/simple?search=" + event.query + '&include_fields=SHELF',
    }).then(
        (result) => {
            dialogAddNewClaim.value.itemSuggestions = result.data.items.map((item) => {

                return {
                    ...item, label:
                        item.CODE + " - " + item.DESCRIPTION
                };
            });
        },
        (error) => {
            console.log(error.response.data);
        }
    );
}

const dialogAddNewClaim = ref({
    isLoading: false,
    date: new Date(),
    claim_type: "Customer",
    selectedBrand: null,
    brandSuggestions: [],
    selectedCategory: null,
    categorySuggestions: [],
    is_date: null,
    is_no: null,
    rc_date: null,
    rc_no: null,
    show: false,
    agent_name: "",
    customer_name: "",
    contact_details_1: "",
    contact_details_2: "",
    description: "",
    itemSuggestions: [],
    selectedItem: null,
    quantity: 1,
    quantity_in_shop: null,
    problem: "",
    remark: "",
    errorMessage: "",
});

const dialogAddNewClaimRules = {
    quantity_in_shop: { requiredIf: requiredIf(() => dialogAddNewClaim.value.claim_type == 'Customer'), numeric: true, minValue: minValue(0) },
    customer_name: { requiredIf: requiredIf(() => dialogAddNewClaim.value.claim_type == 'Customer') },
    selectedItem: { CODE: { required } },
    contact_details_1: { requiredIf: requiredIf(() => dialogAddNewClaim.value.claim_type == 'Customer') },
    agent_name: { required },
    selectedBrand: { required },
    selectedCategory: { required },
    quantity: { required, minValue: minValue(1) },
}

const v$dialogAdd = useVuelidate(dialogAddNewClaimRules, dialogAddNewClaim);

const showAddNewClaimDialog = () => {
    v$dialogAdd.value.$reset();

    dialogAddNewClaim.value = {
        isLoading: false,
        date: new Date(),
        claim_type: "Customer",
        selectedBrand: null,
        brandSuggestions: [],
        selectedCategory: null,
        categorySuggestions: [],
        is_date: null,
        is_no: null,
        rc_date: null,
        rc_no: null,
        show: true,
        agent_name: "",
        customer_name: "",
        contact_details_1: "",
        contact_details_2: "",
        description: "",
        itemSuggestions: [],
        selectedItem: null,
        quantity: 1,
        quantity_in_shop: null,
        problem: "",
        remark: "",
        errorMessage: "",
    };
}

const saveAddNewCategoryDialog = async () => {
    const isFormCorrect = await v$dialogAdd.value.$validate()

    if (!isFormCorrect) {

        console.log("Error!")
        // Update error message, by iterate through all errors
        const errors = v$dialogAdd.value.$errors;
        let errorMessage = "";

        for (const key in errors) {
            errorMessage += errors[key].$propertyPath + ": " + errors[key].$message + "\n\n";
        }

        dialogAddNewClaim.value.errorMessage = errorMessage;
        return;
    }

    dialogAddNewClaim.value.isLoading = true;

    const data = {
        date: convertPrimeVueCalendar(dialogAddNewClaim.value.date),
        claim_type: dialogAddNewClaim.value.claim_type,
        agent_name: dialogAddNewClaim.value.agent_name,
        customer_name: dialogAddNewClaim.value.customer_name,
        contact_details_1: dialogAddNewClaim.value.contact_details_1,
        contact_details_2: dialogAddNewClaim.value.contact_details_2,
        description: dialogAddNewClaim.value.description,
        ST_ITEM_CODE: dialogAddNewClaim.value.selectedItem.CODE,
        quantity: dialogAddNewClaim.value.quantity,
        quantity_in_shop: dialogAddNewClaim.value.quantity_in_shop,
        problem: dialogAddNewClaim.value.problem,
        remark: dialogAddNewClaim.value.remark,
        claim_brand_id: dialogAddNewClaim.value.selectedBrand.id,
        claim_category_id: dialogAddNewClaim.value.selectedCategory.id,
    };

    // If claim type is KM Stock, add IS Date and IS Doc No
    if (dialogAddNewClaim.value.claim_type == 'KM Stock') {
        data.is_date = convertPrimeVueCalendar(dialogAddNewClaim.value.is_date);
        data.is_no = dialogAddNewClaim.value.is_no;
        data.rc_date = convertPrimeVueCalendar(dialogAddNewClaim.value.rc_date);
        data.rc_no = dialogAddNewClaim.value.rc_no;
        data.quantity_in_shop = dialogAddNewClaim.value.quantity;
    }

    console.log(data);

    await axios({
        method: "POST",
        url: "claims",
        data: data,
    }).then(
        (result) => {
            dialogAddNewClaim.value.isLoading = false;
            dialogAddNewClaim.value.show = false;

            // emit saved
            emit('saved');
        },
        (error) => {
            dialogAddNewClaim.value.isLoading = false;
            dialogAddNewClaim.value.errorMessage = error.response.data.message;
        }
    );
}

const dialogAddNewClaimGenerateBrandSuggestions = (event) => {
    const query = event.query;
    let filtered = [];
    for (let i = 0; i < props.brands.length; i++) {
        let brand = props.brands[i];
        if (brand.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
            filtered.push(brand);
        }
    }
    dialogAddNewClaim.value.brandSuggestions = filtered;
}

const dialogAddNewClaimGenerateCategorySuggestions = (event) => {
    const query = event.query;
    let filtered = [];
    for (let i = 0; i < props.categories.length; i++) {
        let category = props.categories[i];
        if (category.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
            filtered.push(category);
        }
    }
    dialogAddNewClaim.value.categorySuggestions = filtered;
}
// ------------------ Dialog Add New Claim END ------------------------------
</script>


<style scoped></style>