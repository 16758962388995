<template>
    <!-- <SplitButton label="Print" @click="printAll" :model="splitButtonItems" /> -->
    <Button icon="pi pi-print" @click="printAll"></Button>
</template>

<script setup>
import { ref, onMounted, defineModel, defineProps, defineEmits } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, maxValue } from '@vuelidate/validators'
import axios from "axios";

const props = defineProps({
    claimData: {
        type: Object,
        required: true
    }
})

const splitButtonItems = [
    {
        label: 'Print All',
        command: () => {
            printAll()
        }
    },
    {
        label: 'Customer',
        command: () => {
            printCustomer()
        }
    },
    {
        label: 'KM Copy',
        command: () => {
            printKM()
        }
    },
]

const printAll = async () => {
    // Get Base64 pdf data
    await axios({
        method: "GET",
        url: "claims/" + props.claimData.id + "/print",
        responseType: "blob",
    }).then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);

        // Print the pdf without opening new tab
        const iframe = document.createElement("iframe");
        iframe.style.display = "none"
        iframe.src = fileURL;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
    });
}

const printCustomer = () => {

}

const printKM = () => {

}

</script>


<style>
.p-menuitem-link {
    color: #4b5563;
    ;
}

.p-button {
    padding: 0.5rem 0.75rem !important;
}
</style>