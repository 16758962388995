<template>
    <div>
        <h2>Agents</h2>
        <Button type="button" icon="pi pi-plus" label="Add Agent" class="p-button-outlined mb-2"
            @click="showAddAgentDialog" />
        <DataTable :value="agents" responsiveLayout="scroll" :loading="isLoading.agents" class="mt-3" size="small">
            <Column field="name" header="Agent Name"></Column>
        </DataTable>


        <!--------------------- Add Supplier Dialog Start ---------------------->
        <Dialog header="Add New Agent" v-model:visible="dialogAddAgent.show" :style="{ width: '400px' }" :modal="true">
            <form class="flex-column align-items-center justify-content-center" @submit.prevent="addAgent">
                <InputText type="text" placeholder="Agent's Name" name="agent_name" v-model="dialogAddAgent.agent_name"
                    class="w-full mb-2" />

                <input class="hidden" type="submit" value="Submit" />
            </form>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="dialogAddAgent.show = false" class="p-button-text"
                    :loading="isLoading.dialogAddAgent" />
                <Button label="Yes" icon="pi pi-check" @click="addAgent" class="p-button-text"
                    :loading="isLoading.dialogAddAgent" />
            </template>
        </Dialog>
        <!--------------------- Add Supplier Dialog End ---------------------->
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";

const agents = ref([]);
const isLoading = ref({ agents: false, dialogAddAgent: false });
const dialogAddAgent = ref({
    show: false,
    agent_name: ""
});

onMounted(() => {
    getAllAgents();
});

const getAllAgents = function () {
    isLoading.value.agents = true;
    axios({
        method: "GET",
        url: "agents",
    }).then(
        (result) => {
            agents.value = result.data.agents;
            isLoading.value.agents = false;
        },
        (error) => {
            console.log(error.response.data);
            isLoading.value.agents = false;
        }
    );
}

const showAddAgentDialog = () => {
    dialogAddAgent.value.show = true;
    dialogAddAgent.value.agent_name = "";
}

const addAgent = function () {
    isLoading.value.dialogAddAgent = true;
    axios({
        method: "POST",
        url: "agents",
        data: {
            name: dialogAddAgent.value.agent_name
        }
    }).then(
        () => {
            dialogAddAgent.value.show = false;
            isLoading.value.dialogAddAgent = false;
            getAllAgents();
        },
        (error) => {
            console.log(error.response.data);
            isLoading.value.dialogAddAgent = false;
        }
    );
}


</script>

<style scoped></style>