<script setup>
import { ref, onMounted, onUnmounted, defineProps } from 'vue'
import { useToast } from 'primevue/usetoast'

const startText = `<?xml version="1.0" standalone="yes"?>
<DATAPACKET Version="2.0">
  <METADATA>
    <FIELDS>
      <FIELD attrname="DOCKEY" fieldtype="i4"/>
      <FIELD attrname="DOCNO" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="80"/>
      <FIELD attrname="DOCNOEX" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="80"/>
      <FIELD attrname="DOCDATE" fieldtype="date"/>
      <FIELD attrname="POSTDATE" fieldtype="date"/>
      <FIELD attrname="TAXDATE" fieldtype="date"/>
      <FIELD attrname="CODE" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="20"/>
      <FIELD attrname="COMPANYNAME" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="200"/>
      <FIELD attrname="ADDRESS1" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="120"/>
      <FIELD attrname="ADDRESS2" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="120"/>
      <FIELD attrname="ADDRESS3" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="120"/>
      <FIELD attrname="ADDRESS4" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="120"/>
      <FIELD attrname="PHONE1" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="400"/>
      <FIELD attrname="MOBILE" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="400"/>
      <FIELD attrname="FAX1" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="400"/>
      <FIELD attrname="ATTENTION" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="140"/>
      <FIELD attrname="AREA" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="20"/>
      <FIELD attrname="AGENT" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="20"/>
      <FIELD attrname="PROJECT" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="40"/>
      <FIELD attrname="TERMS" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="20"/>
      <FIELD attrname="CURRENCYCODE" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="12"/>
      <FIELD attrname="CURRENCYRATE" fieldtype="fixedFMT" DECIMALS="8" WIDTH="18"/>
      <FIELD attrname="SHIPPER" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="60"/>
      <FIELD attrname="DESCRIPTION" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="600"/>
      <FIELD attrname="CANCELLED" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="2"/>
      <FIELD attrname="DOCAMT" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
      <FIELD attrname="LOCALDOCAMT" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
      <FIELD attrname="LANDINGCOST1" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
      <FIELD attrname="LANDINGCOST2" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
      <FIELD attrname="LOCALTOTALWITHCOST" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
      <FIELD attrname="D_AMOUNT" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
      <FIELD attrname="VALIDITY" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="600"/>
      <FIELD attrname="DELIVERYTERM" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="600"/>
      <FIELD attrname="CC" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="600"/>
      <FIELD attrname="DOCREF1" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="80"/>
      <FIELD attrname="DOCREF2" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="80"/>
      <FIELD attrname="DOCREF3" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="80"/>
      <FIELD attrname="DOCREF4" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="80"/>
      <FIELD attrname="BRANCHNAME" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="200"/>
      <FIELD attrname="DADDRESS1" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="120"/>
      <FIELD attrname="DADDRESS2" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="120"/>
      <FIELD attrname="DADDRESS3" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="120"/>
      <FIELD attrname="DADDRESS4" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="120"/>
      <FIELD attrname="DATTENTION" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="140"/>
      <FIELD attrname="DPHONE1" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="400"/>
      <FIELD attrname="DMOBILE" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="400"/>
      <FIELD attrname="DFAX1" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="400"/>
      <FIELD attrname="TAXEXEMPTNO" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="100"/>
      <FIELD attrname="ATTACHMENTS" fieldtype="bin.hex" SUBTYPE="Binary" WIDTH="8"/>
      <FIELD attrname="NOTE" fieldtype="bin.hex" SUBTYPE="Binary" WIDTH="8"/>
      <FIELD attrname="TRANSFERABLE" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="2"/>
      <FIELD attrname="UPDATECOUNT" fieldtype="i4"/>
      <FIELD attrname="PRINTCOUNT" fieldtype="i4"/>
      <FIELD attrname="DOCNOSETKEY" fieldtype="i8"/>
      <FIELD attrname="NEXTDOCNO" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="80"/>
      <FIELD attrname="CHANGED" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="2"/>
      <FIELD attrname="sdsDocDetail" fieldtype="nested">
        <FIELDS>
          <FIELD attrname="SEQ" fieldtype="i4"/>
          <FIELD attrname="STYLEID" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="10"/>
          <FIELD attrname="NUMBER" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="10"/>
          <FIELD attrname="ITEMCODE" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="60"/>
          <FIELD attrname="LOCATION" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="40"/>
          <FIELD attrname="BATCH" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="60"/>
          <FIELD attrname="PROJECT" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="40"/>
          <FIELD attrname="DESCRIPTION" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="400"/>
          <FIELD attrname="DESCRIPTION2" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="400"/>
          <FIELD attrname="DESCRIPTION3" fieldtype="bin.hex" SUBTYPE="Binary" WIDTH="8"/>
          <FIELD attrname="PERMITNO" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="40"/>
          <FIELD attrname="QTY" fieldtype="fixedFMT" DECIMALS="4" WIDTH="18"/>
          <FIELD attrname="UOM" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="20"/>
          <FIELD attrname="RATE" fieldtype="fixedFMT" DECIMALS="4" WIDTH="18"/>
          <FIELD attrname="SQTY" fieldtype="fixedFMT" DECIMALS="4" WIDTH="18"/>
          <FIELD attrname="SUOMQTY" fieldtype="fixedFMT" DECIMALS="4" WIDTH="18"/>
          <FIELD attrname="UNITPRICE" fieldtype="fixedFMT" DECIMALS="8" WIDTH="18"/>
          <FIELD attrname="DELIVERYDATE" fieldtype="date"/>
          <FIELD attrname="DISC" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="40"/>
          <FIELD attrname="TAX" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="20"/>
          <FIELD attrname="TARIFF" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="40"/>
          <FIELD attrname="TAXRATE" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="40"/>
          <FIELD attrname="TAXAMT" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
          <FIELD attrname="LOCALTAXAMT" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
          <FIELD attrname="TAXINCLUSIVE" fieldtype="i2"/>
          <FIELD attrname="AMOUNT" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
          <FIELD attrname="LOCALAMOUNT" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
          <FIELD attrname="TAXABLEAMT" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
          <FIELD attrname="IM_CURRENCYCODE" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="12"/>
          <FIELD attrname="IM_CURRENCYRATE" fieldtype="fixedFMT" DECIMALS="8" WIDTH="18"/>
          <FIELD attrname="IM_PURCHASEAMT" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
          <FIELD attrname="LANDINGCOST1" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
          <FIELD attrname="LANDINGCOST2" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
          <FIELD attrname="ACCOUNT" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="20"/>
          <FIELD attrname="WHTAX" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="20"/>
          <FIELD attrname="WHTAXRATE" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="40"/>
          <FIELD attrname="WHLOCALTAXAMT" fieldtype="fixedFMT" DECIMALS="2" WIDTH="18"/>
          <FIELD attrname="WHTAXACCOUNTDR" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="20"/>
          <FIELD attrname="WHTAXACCOUNTCR" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="20"/>
          <FIELD attrname="PRINTABLE" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="2"/>
          <FIELD attrname="FROMDOCTYPE" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="4"/>
          <FIELD attrname="FROMDOCKEY" fieldtype="i4"/>
          <FIELD attrname="FROMDTLKEY" fieldtype="i4"/>
          <FIELD attrname="TRANSFERABLE" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="2"/>
          <FIELD attrname="REMARK1" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="400"/>
          <FIELD attrname="REMARK2" fieldtype="string.uni" SUBTYPE="FixedChar" WIDTH="400"/>
          <FIELD attrname="CHANGED" fieldtype="string.uni" required="true" SUBTYPE="FixedChar" WIDTH="2"/>
          <FIELD attrname="CompanyItemCode" fieldtype="string.uni" WIDTH="60"/>
        </FIELDS>
        <PARAMS/>
      </FIELD>
    </FIELDS>
    <PARAMS/>
  </METADATA>
  <ROWDATA>
    <ROW PROJECT="----" CHANGED="T" CANCELLED="F">
        <sdsDocDetail>`;

const endText =
  `
        </sdsDocDetail>
    </ROW>
  </ROWDATA>
</DATAPACKET>
`

const rowStartText = `
          <ROWsdsDocDetail LOCATION="----" BATCH="" PROJECT="----" UOM="UNIT" RATE="1.0000" SUOMQTY="0.0000" UNITPRICE="0.00" DELIVERYDATE="" DISC="" TAX="" TAXAMT="0.00" LOCALTAXAMT="0.00" TAXINCLUSIVE="0" AMOUNT="0.00" LOCALAMOUNT="0.00" TAXABLEAMT="0.00" LANDINGCOST1="0.00" LANDINGCOST2="0.00" ACCOUNT="610-000" WHLOCALTAXAMT="0.00" PRINTABLE="T" TRANSFERABLE="T" CHANGED="T" `
const toast = useToast()

const props = defineProps({
  rows: {
    type: Object,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'po_pq'
  },
});

const objectFieldMapping = ref({
  description: 'description',
  item_code: 'item_code',
  qty: 'qty',
});

const copy = () => {
  const rows = props.rows;

  let text = startText;

  for (let i = 0; i < rows.length; i++) {
    // Check if last row and is empty, if so, skip
    if (i === rows.length - 1 && !rows[i][objectFieldMapping.value.item_code] && !rows[i][objectFieldMapping.value.qty] && !rows[i][objectFieldMapping.value.description]) {
      continue;
    }

    // Check if qty is 0 or empty, if so, skip
    if (props.type == 'reorder_stock' && (rows[i][objectFieldMapping.value.qty] == 0 || !rows[i][objectFieldMapping.value.qty])) {
      continue;
    }

    let row = rows[i];

    let description = row[objectFieldMapping.value.description];
    if (description) {
      description = description.replace(/"/g, '&quot;');
    } else {
      description = '';
    }

    let itemCode = row[objectFieldMapping.value.item_code];
    if (!itemCode) {
      itemCode = '';
    }

    text += rowStartText;
    text += `SEQ="${i + 1}" `;
    text += `ITEMCODE = "${itemCode}" `;
    text += `DESCRIPTION="${description}" `;
    text += `QTY="${row[objectFieldMapping.value.qty]}" `;
    text += `SQTY ="${row[objectFieldMapping.value.qty]}"`;
    text += '/>';
  }

  text += endText;

  navigator.clipboard.writeText(text)
  toast.add({
    severity: 'success', summary: 'Copied', detail: "You can now paste to SQL",
    life: 1000,
  })
}

// onmounted
onMounted(() => {
  if (props.type == 'reorder_stock') {
    objectFieldMapping.value.item_code = 'code';
    objectFieldMapping.value.description = 'DESCRIPTION';
    objectFieldMapping.value.qty = 'order_qty';
  }
})

onUnmounted(() => {
})

</script>

<template>
  <Button @click="copy" icon="pi pi-copy" class="h-min" label="Copy to SQL" :disabled="props.disabled"></Button>
</template>

<style></style>