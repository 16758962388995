<template>
	<div class="layout-menu-container">
		<AppSubmenu :items="props.model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
	</div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import AppSubmenu from '@/AppSubmenu.vue';

const props = defineProps({
	model: Array
});

const emit = defineEmits(['menuitem-click']);

const onMenuItemClick = (event) => {
	emit('menuitem-click', event);
}
</script>

<style scoped></style>