<template>
  <div>
    <h2>Items</h2>
    <DataTable :value="filteredItems" responsiveLayout="scroll" :loading="isLoadingSearch">
      <template #header>
        <div class="flex justify-content-left flex-column sm:flex-row">

          <IconField class="mb-2">
            <InputIcon class="pi pi-search" />
            <InputText v-model="searchField" placeholder="Search" style="width: 100%" @keydown="textChanged" />
          </IconField>

        </div>
        <div v-if="userSearched">
          {{ filteredItems ? filteredItems.length : "" }} items found
        </div>
      </template>
      <Column field="CODE" header="Code">
        <template #body="{ data }">
          <router-link :to="'/items/' + data.CODE">{{ data.CODE }}</router-link>
        </template>
      </Column>
      <Column header="Description">
        <template #body="{ data }">
          {{ data.DESCRIPTION }}
          <i class="pi pi-copy cursor-pointer" @click="copyToClipboard(data.DESCRIPTION, $toast)"></i>
        </template>
      </Column>

      <Column field="BALSQTY" header="Qty"></Column>
      <Column field="REFPRICE" header="Price"> </Column>
    </DataTable>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { copyToClipboard } from '@/utils/copyToClipboard';
import { useItemsStore } from "@/stores/items";

const itemsStore = useItemsStore();

const filteredItems = ref([]);
const searchField = ref("");
const isLoadingSearch = ref(false);
const userSearched = ref(false);
let textInputTimer = ref(null);

const textChanged = () => {
  clearTimeout(textInputTimer);
  textInputTimer = setTimeout(() => {
    searchItem();
  }, 400);
};

const searchItem = async () => {
  isLoadingSearch.value = true;
  userSearched.value = true;

  filteredItems.value = await itemsStore.searchItem(searchField.value);
  isLoadingSearch.value = false;
};
</script>

<style scoped></style>