<script setup>
import { ref, onMounted, defineModel, defineProps } from 'vue'
import axios from "axios";

const props = defineProps(['preselectedStockGroups'])

const selectedStockGroups = defineModel('selectedStockGroups')
const selectedStockGroupsToShow = ref();

const stockGroups = ref();
const stockGroupsToShow = ref();

const searchAvailableField = ref();
const searchAddedField = ref();

const updateStockGroupToShow = () => {
    // Check if searchAvailableField is not empty, then filter stockGroups
    if (searchAvailableField.value) {
        stockGroupsToShow.value = stockGroups.value.filter(stockGroup => stockGroup.STOCKGROUP.toLowerCase().includes(searchAvailableField.value.toLowerCase()));
    } else {
        stockGroupsToShow.value = stockGroups.value;
    }

    // Check if searchAddedField is not empty, then filter selectedStockGroups
    if (searchAddedField.value) {
        selectedStockGroupsToShow.value = selectedStockGroups.value.filter(stockGroup => stockGroup.STOCKGROUP.toLowerCase().includes(searchAddedField.value.toLowerCase()));
    } else {
        selectedStockGroupsToShow.value = selectedStockGroups.value;
    }
}

const getStockGroups = async () => {
    await axios({
        method: "GET",
        url: "items/stock-groups"
    }).then(
        (result) => {
            stockGroups.value = result.data.stockGroups;

            let preselectedStockGroups = props.preselectedStockGroups;

            // Check if is just a string and not empty string, then convert to array
            if (typeof preselectedStockGroups === 'string' && preselectedStockGroups !== '') {
                preselectedStockGroups = [preselectedStockGroups];
            }

            // Check if props.preselectedStockGroups is not empty, then add it to selectedStockGroups
            if (preselectedStockGroups && preselectedStockGroups.length > 0) {
                preselectedStockGroups.forEach(stockGroup => {
                    addStockGroup({ STOCKGROUP: stockGroup });
                });
            }

            updateStockGroupToShow();
        },
        (error) => {
            console.log(error.response.data);
            updateStockGroupToShow();
        }
    );
}

const addStockGroup = (stockGroupToAdd) => {
    selectedStockGroups.value.push(stockGroupToAdd);
    selectedStockGroups.value.sort((a, b) => {
        if (a.STOCKGROUP < b.STOCKGROUP) {
            return -1;
        }
        if (a.STOCKGROUP > b.STOCKGROUP) {
            return 1;
        }
        return 0;
    });

    selectedStockGroups.value = [...selectedStockGroups.value];

    const index = stockGroups.value.findIndex(stockGroup => stockGroup.STOCKGROUP == stockGroupToAdd.STOCKGROUP);
    if (index > -1) {
        stockGroups.value.splice(index, 1);
    }

    updateStockGroupToShow();
}

const removeStockGroup = (stockGroupToRemove) => {
    stockGroups.value.push(stockGroupToRemove);

    stockGroups.value.sort((a, b) => {
        if (a.STOCKGROUP < b.STOCKGROUP) {
            return -1;
        }
        if (a.STOCKGROUP > b.STOCKGROUP) {
            return 1;
        }
        return 0;
    });

    stockGroups.value = [...stockGroups.value];

    const index = selectedStockGroups.value.findIndex(selectedStockGroup => selectedStockGroup.STOCKGROUP == stockGroupToRemove.STOCKGROUP);
    if (index > -1) {
        selectedStockGroups.value.splice(index, 1);
    }

    updateStockGroupToShow();
}
const utility = ref({
    timer: null
})

const textChanged = () => {
    clearTimeout(utility.value.timer);
    utility.value.timer = setTimeout(() => {
        updateStockGroupToShow();
    }, 150);
}

onMounted(() => {
})

getStockGroups();
</script>

<template>
    <div class="flex gap-6" style="max-width: 750px;">
        <div class="w-6">
            <div class="font-medium text-2xl">Available Stock Groups</div>
            <div class="flex mt-2">
                <IconField class="">
                    <InputIcon class="pi pi-search" />
                    <InputText v-model="searchAvailableField" placeholder="Search" style="width: 100%"
                        @keydown="textChanged" />
                </IconField>
                <Button text class="" @click="searchAvailableField = ''; updateStockGroupToShow()">Reset</Button>
            </div>
            <div class="bg-white overflow-y-scroll mt-3 p-3" style="height: 400px">
                <div v-for="stockGroup in stockGroupsToShow" :key="stockGroup.STOCKGROUP"
                    class="card flex justify-content-between align-items-center py-2 my-1">
                    {{ stockGroup.STOCKGROUP }}
                    <Button type="button" icon="pi pi-plus" class="" label="Add" size="small"
                        @click="addStockGroup(stockGroup)" />
                </div>
            </div>
        </div>
        <div class="w-6">
            <div class="font-medium text-2xl">Added</div>
            <div class="flex mt-2">
                <IconField class="">
                    <InputIcon class="pi pi-search" />
                    <InputText v-model="searchAddedField" placeholder="Search" style="width: 100%"
                        @keydown="textChanged" />
                </IconField>
                <Button text class="" @click="searchAddedField = ''; updateStockGroupToShow()">Reset</Button>
            </div>
            <div class="bg-white overflow-y-scroll mt-3 p-3" style="height: 400px">
                <div v-for="stockGroup in selectedStockGroupsToShow" :key="stockGroup.STOCKGROUP"
                    class="card flex justify-content-between align-items-center py-2 my-1">
                    {{ stockGroup.STOCKGROUP }}
                    <Button type="button" icon="pi pi-plus" class="p-button-danger" label="Remove" size="small"
                        @click="removeStockGroup(stockGroup)" />
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>