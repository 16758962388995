import { defineStore } from 'pinia';
import axios from "axios";

export const useItemsStore = defineStore({
  id: 'items',
  state: () => ({
    items: [],
    isLoading: false,
    lastUpdated: null,
  }),


  actions: {
    async getExtendedItemFromBackend(itemCode) {
      try {
        const res = await axios({
          method: "GET",
          url: "items/extended?code=" + itemCode,
        })

        const item = res.data.item;

        // Flatten extended_fields
        if (item.extended_fields != null) {
          // Iterate extended fields object
          Object.keys(item.extended_fields).forEach((key) => {
            // Add the extended fields to the item if it doesn't exist
            if (item[key] == null) {
              item[key] = item.extended_fields[key];
            }
          });

          // Remove extended_fields from item
          delete item.extended_fields;
        }
        console.log(item);
        return item;
      }
      catch (error) {
        if (error.response.status == 404) {
          return null;
        }

        console.log("Error getting item from backend");
        console.log(error.response.data);
        return null;
      }
    },
    async getItemsFromBackend() {
      this.isLoading = true;
      const res = await axios({
        method: "GET",
        url: "items/simple?include_fields=BALSQTY,REFPRICE",
      }).then(
        (result) => {
          this.lastUpdated = new Date();
          this.isLoading = false;
          this.items = result.data.items

          // Change price to 2 decimal places
          this.items.forEach((item) => {
            item.REFPRICE = parseFloat(item.REFPRICE).toFixed(2);
          });

          return result.data.items;
        },
        (error) => {
          console.log(error.response.data);
        }
      );

      return res;
    },

    getItemsFromBackendWithCode: async function (itemCode) {
      const res = await axios({
        method: "GET",
        url: "items/simple?include_fields=BALSQTY,REFPRICE,extended_fields&code=" + itemCode,
      }).then(
        (result) => {
          const itemFromBackend = result.data.item

          if (itemFromBackend != null) {
            itemFromBackend.REFPRICE = parseFloat(itemFromBackend.REFPRICE).toFixed(2);

            // Flatten the extended fields
            if (itemFromBackend.extended_fields != null) {
              // Iterate extended fields object
              Object.keys(itemFromBackend.extended_fields).forEach((key) => {
                // Add the extended fields to the item
                itemFromBackend[key] = itemFromBackend.extended_fields[key];
              });
            }
          }

          return itemFromBackend;
        },
        (error) => {
          console.log(error.response.data);
        }
      );

      return res;
    },
    searchItemFromBackend: async function (searchQuery) {
      const res = await axios({
        method: "GET",
        url: "items/simple?include_fields=BALSQTY,REFPRICE,extended_fields&search=" + searchQuery,
      }).then(
        (result) => {
          const itemsFromBackend = result.data.items

          // Change price to 2 decimal places
          itemsFromBackend.forEach((item) => {
            item.REFPRICE = parseFloat(item.REFPRICE).toFixed(2);

            // Flatten the extended fields
            if (item.extended_fields != null) {
              // Iterate extended fields object
              Object.keys(item.extended_fields).forEach((key) => {
                // Add the extended fields to the item
                item[key] = item.extended_fields[key];
              });
            }
          });

          return itemsFromBackend;
        },
        (error) => {
          console.log(error.response.data);
        }
      );

      return res;
    },

    searchItem: async function (searchQuery) {
      // If lastUpdate more than 15 minutes ago, get items from backend
      if (this.lastUpdated == null) {
        await this.getItemsFromBackend();
      } else {
        var lastUpdated = new Date(this.lastUpdated);
        var now = new Date();
        var diff = Math.abs(now - lastUpdated) / 1000;
        diff /= 60;
        if (diff > 15) {
          await this.getItemsFromBackend();
        }
      }

      const filteredItems = [];

      this.userSearched = true;
      if (searchQuery.length < 2) {
        this.userSearched = false;
        return [];
      }

      this.items.forEach((item) => {
        // Search the code
        var itemCodeLower = "";
        if (item.CODE != null) {
          itemCodeLower = item.CODE.toLowerCase();
        }
        if (itemCodeLower.includes(searchQuery)) {
          filteredItems.push(item);
          return;
        }

        // Search the description
        var itemDescription = "";
        if (item.DESCRIPTION != null) {
          itemDescription = item.DESCRIPTION.toLowerCase();
        }

        var searchField = searchQuery.toLowerCase();

        var searchFieldWords = searchField.split(" ");
        var itemMatched = true;
        searchFieldWords.forEach((word) => {
          if (!itemDescription.includes(word)) {
            itemMatched = false;
          }
        });

        if (itemMatched) {
          filteredItems.push(item);
        }
      });

      // Sort Results by description
      filteredItems.sort((a, b) =>
        a.DESCRIPTION > b.DESCRIPTION
          ? 1
          : b.DESCRIPTION > a.DESCRIPTION
            ? -1
            : 0
      );

      return filteredItems;
    },
  }
});