<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios'
import moment from 'moment'

const router = useRouter();
const route = useRoute();

const purchaseOrders = ref([]);
const isLoading = ref({
    purchaseOrders: false,
});

const query = ref({
    page: 1
});

// ------ Paginator START ------ //
const pagination = ref({
    current_page: 1,
    last_page: 1,
    per_page: 100,
    total: 0,
});

const onPageChange = (event) => {
    query.value.page = event.page + 1;
    router.push({ path: route.fullPath, query: { ...query.value } });
    getPurchaseOrder();
}
// ------ Paginator End ------ //


// ------ DataTable filter START ------ //
const filters = ref();

const statuses = [
    'On The Way',
    'Arrived',
    'Deleted'
];

const getSeverity = (status) => {
    if (status === 'On The Way') return 'warning';
    if (status === 'Arrived') return 'success';
    if (status === 'Deleted') return 'danger';
}

const initFilters = () => {
    filters.value = {
        date: { value: null, matchMode: 'dateIs' },
        eta: { value: null, matchMode: 'dateIs' },
        arrived_at: { value: null, matchMode: 'dateIs' },
        doc_no: { value: null, matchMode: 'contains' },
        supplier: { value: null, matchMode: 'contains' },
        agent: { value: null, matchMode: 'contains' },
        description: { value: null, matchMode: 'contains' },
        status: { value: null, matchMode: 'contains' },
    }
}

initFilters();

const onFilter = ($event) => {
    query.value.page = 1;

    // Update query from filters
    let filters = "";

    for (const [filterkey, filterobject] of Object.entries($event.filters)) {
        if (filterobject.value === null) {
            continue;
        }

        if (filters !== "") {
            filters += ",";
        }

        if (filterkey == 'date' || filterkey == 'ets' || filterkey == 'eta' || filterkey =='arrived_at') {
            filters += filterkey + "-" + moment(filterobject.value).format("YYYYMMDD") + "-" + filterobject.matchMode;
            continue;
        }

        filters += filterkey + "-" + filterobject.value + "-" + filterobject.matchMode;
    }
    query.value.filters = filters;
    if (filters === "") filters = undefined;

    router.push({ path: route.fullPath, query: { ...query.value } });
    getPurchaseOrder();
};

// ------ DataTable sort START ------ //
const multiSortMeta = ref([]);

const onSort = ($event) => {
    query.value.page = 1;

    // Pluck field string from multiSortMeta
    const sortBy = $event.multiSortMeta.map((item) => {
        const order = item.order === 1 ? "asc" : "desc";
        return item.field + "-" + order;
    });

    query.value.sortBy = sortBy.join(",");
    router.push({ path: route.fullPath, query: { ...query.value } });
    getPurchaseOrder();
}

// ------ DataTable sort END ------ //

const clearFiltersAndSort = () => {
    query.value.page = 1;
    query.value.filters = undefined;
    query.value.sortBy = undefined;
    updateDataTableSortAndFilterFromQuery();

    router.push({ path: route.fullPath, query: { ...query.value } });
    getPurchaseOrder();
}

const updateDataTableSortAndFilterFromQuery = () => {
    multiSortMeta.value = [];
    if (query.value.sortBy !== undefined && query.value.sortBy !== '') {
        const sortFields = query.value.sortBy.split(',');
        sortFields.forEach((sortField) => {
            const sortArray = sortField.split('-');

            const order = sortArray[1] === 'asc' ? 1 : -1;
            multiSortMeta.value.push({ field: sortArray[0], order: order });
        });
        multiSortMeta.value = [...multiSortMeta.value];
    }

    initFilters();
    if (query.value.filters !== undefined && query.value.filters !== '') {
        const filtersFromQuery = query.value.filters.split(',');
        filtersFromQuery.forEach((filterFromQuery) => {
            const filterArray = filterFromQuery.split('-');
            filters.value[filterArray[0]].value = filterArray[1];
            filters.value[filterArray[0]].matchMode = filterArray[2];

            if (filterArray[0] == 'date' || filterArray[0] == 'ets' || filterArray[0] == 'eta') {
                filters.value[filterArray[0]].value = moment(filterArray[1], "YYYYMMDD").toDate();
            }
        });
    }
}

const getPurchaseOrder = () => {
    axios({
        method: "GET",
        url: "purchase-orders",
        params: query.value,
    }).then(
        (result) => {
            purchaseOrders.value = result.data.purchase_orders.data;
            console.log(result);
        },
        (error) => {
            console.log(error.response.data);
        }
    );

}

onMounted(() => {
    // Update query from route
    query.value = { ...route.query };

    updateDataTableSortAndFilterFromQuery();
    getPurchaseOrder();
})
</script>

<template>
    <div>
        <div class="flex align-items-center gap-3 mb-3">
            <div class="text-4xl font-medium">Purchase Orders</div>
            <router-link to="/purchase-orders/new">
                <Button label="New"></Button>
            </router-link>
        </div>
        <DataTable :value="purchaseOrders" responsiveLayout="scroll" lazy :loading="isLoading.purchaseRequests"
            size="small" @sort="onSort" sortMode="multiple" :multiSortMeta="multiSortMeta" v-model:filters="filters"
            @filter="onFilter($event)" filterDisplay="row" resizableColumns columnResizeMode="fit">
            <template #header>
                <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined @click="clearFiltersAndSort()" />
            </template>
            <template #footer>
                <Paginator :rows="pagination.per_page" :totalRecords="pagination.total" @page="onPageChange($event)"
                    :first="(pagination.current_page - 1) * pagination.per_page">
                </Paginator>
            </template>
            <Column field="date" header="Date" style="width: 12rem" sortable filterField="date" dataType="date"
                :showFilterMenu="false">
                <template #filter="{ filterModel, filterCallback }">
                    <Calendar v-model="filterModel.value" @date-select="filterCallback()" dateFormat="yy-mm-dd"
                        placeholder="yyyy-mm-dd" mask="9999/99/99" class="p-column-filter" />
                </template>
            </Column>
            <Column field="doc_no" header="Doc No" style="width: 12rem" sortable filterField="doc_no"
                :showFilterMenu="false">
                <template #body="{ data }">
                    <div class="flex gap-2">
                        <div :class="data.is_deleted ? 'font-bold text-red-600' : ''">{{ data.doc_no }}</div>
                    </div>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                        class="p-column-filter" placeholder="Search" />
                </template>
            </Column>
            <Column field="supplier" header="Supplier" style="width: 12rem" sortable filterField="" dataType="" :showFilterMenu="false">
                <template #body="{ data }">
                    {{ data.COMPANYNAME }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                        class="p-column-filter" placeholder="Search" />
                </template>
            </Column>
            <Column field="agent" header="Agent" sortable style="width: 12rem" filterField="agent" :showFilterMenu="false">
                <template #body="{ data }">
                    {{ data.agent_name }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                        class="p-column-filter" placeholder="Search" />
                </template>
            </Column>
            <Column field="description" header="Description" sortable filterField="description" :showFilterMenu="false">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                        class="p-column-filter" placeholder="Search" />
                </template>
            </Column>
            <Column field="eta" header="ETA" style="width: 12rem" sortable filterField="eta" dataType="date"
                :showFilterMenu="false">
                <template #filter="{ filterModel, filterCallback }">
                    <Calendar v-model="filterModel.value" @date-select="filterCallback()" dateFormat="yy-mm-dd"
                        placeholder="yyyy-mm-dd" mask="9999/99/99" class="p-column-filter" />
                </template>
            </Column>
            <Column field="arrived_at" header="Arrived Date" style="width: 12rem" sortable filterField="arrived_at"
                dataType="date" :showFilterMenu="false">
                <template #filter="{ filterModel, filterCallback }">
                    <Calendar v-model="filterModel.value" @date-select="filterCallback()" dateFormat="yy-mm-dd"
                        placeholder="yyyy-mm-dd" mask="9999/99/99" class="p-column-filter" />
                </template>
            </Column>
            <Column field="status" header="Status" filterField="status" sortable :showFilterMenu="false" style="width: 10rem">
                <template #body="{ data }">
                    <div v-if="data.is_deleted" class="font-bold text-red-600">Deleted</div>
                    <div v-else-if="data.arrived_at" class="font-bold text-green-600">Arrived</div>
                    <div v-else class="font-bold text-orange-500">On The Way</div>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses"
                        placeholder="Select One" class="p-column-filter" :showClear="true">
                        <template #option="slotProps">
                            <Tag :value="slotProps.option" :severity="getSeverity(slotProps.option)" />
                        </template>
                    </Dropdown>

                </template>
            </Column>
            <Column field="" header="Actions">
                <template #body="{ data }">
                    <router-link :to="'/purchase-orders/' + data.doc_no">
                        <div class="font-medium">
                            View
                        </div>
                    </router-link>
                </template>
            </Column>
        </DataTable>
    </div>
</template>